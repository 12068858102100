import {
    DragDropContext,
    Droppable,
} from 'react-beautiful-dnd';

import DinercapDraggableListItem from './DinercapDraggableListItem';

export default function DinercapDraggableList(props) {

    const { items, onDragEnd, handleClick } = props;

    return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {provided => (
          <div className='flex flex-col gap-2 list-none' ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <DinercapDraggableListItem 
                length={items.length}
                item={item} 
                index={index} 
                key={item.id} 
                handleClick={handleClick}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
    );
};
