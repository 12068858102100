import { Fragment, useEffect } from "react";
import { IoHomeOutline, IoDocumentTextOutline, IoDocumentOutline } from "react-icons/io5";

import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

import { urlDefault } from "../../../settings/urlConfig";
import { urlSystem } from "../../../settings/urlConfig";

import { useLogin } from "../../../context/LoginContext";
import { useFormatoSolicitud } from "../../../context/FormatoSolicitudContext";
import ConfigurarFormatoModal from "./ConfigurarFormatoModal";
import { useTipoSolicitud } from "../../../context/TipoSolicitudContext";
import { FormControl, InputLabel, Select } from "@mui/material";
import { useParams } from "react-router-dom";
import { useConfiguracionSolicitud } from "../../../context/ConfiguracionSolicitudContext";

export default function FormatosSolicitudScreen() {
    const tokenUsuario = "TknUsu";
    const tokenContraseña = "TknPass";
    let { bMostrarTipoSolicitud } = useParams();

    const { handleClickRuta } = useLogin();

    const { listaTiposSolicitudAll, handleClickObtenerTiposSolicitud } = useTipoSolicitud();

    const {
        listaFormatosSolicitud,
        handleClickObtenerFormatosSolicitud,
        handleClickFormatoSolicitud,
        modalOpenConfigurarFormato,
    } = useFormatoSolicitud();

    const { tipoSolicitudSeleccionado, handleChangeTipoSolicitudSeleccionado } = useConfiguracionSolicitud();

    useEffect(() => {
        // sessionStorage.setItem(tokenUsuario, "dinercap.webApp");
        // sessionStorage.setItem(tokenContraseña, "QFouWAUD");
        if (listaTiposSolicitudAll.length === 0) {
            handleClickObtenerTiposSolicitud();
        } else if (tipoSolicitudSeleccionado.iIDTipoSolicitud !== 0) {
            handleClickObtenerFormatosSolicitud(tipoSolicitudSeleccionado.iIDTipoSolicitud);
        } else {
            handleChangeTipoSolicitud(listaTiposSolicitudAll[0].iIDTipoSolicitud);
        }
        // eslint-disable-next-line
    }, [tipoSolicitudSeleccionado.iIDTipoSolicitud || listaTiposSolicitudAll]);

    const handleChangeTipoSolicitud = async (value) => {
        handleChangeTipoSolicitudSeleccionado(listaTiposSolicitudAll.find((x) => x.iIDTipoSolicitud === value));
    };

    const handleClickHome = (e) => {
        handleClickRuta(e, {
            pathname: urlDefault,
        });
    };

    const handleClickTipoSolicitudes = (e) => {
        handleClickRuta(e, {
            pathname: urlSystem.tiposSolicitudes,
        });
    };

    /*useEffect(() => {
        handleClickObtenerFormatosSolicitud()
        // eslint-disable-next-line
    }, []);*/

    return (
        <div>
            <div className="flex flex-row justify-between items-center h-14 p-3 border-b bg-header">
                <div className="flex flex-row items-center">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            className={
                                "flex !font-medium px-2 py-1 hover:!no-underline hover:!cursor-pointer hover:!bg-gray-100 hover:!rounded-full"
                            }
                            color="inherit"
                            onClick={(e) => handleClickHome(e)}
                        >
                            <IoHomeOutline className="text-2xl mr-2" />
                            Inicio
                        </Link>
                        {bMostrarTipoSolicitud === "true" && (
                            <Link
                                className={
                                    "flex !font-medium px-2 py-1 hover:!no-underline hover:!cursor-pointer hover:!bg-gray-100 hover:!rounded-full"
                                }
                                color="inherit"
                                onClick={(e) => handleClickTipoSolicitudes(e)}
                            >
                                <IoDocumentOutline className="text-2xl mr-2" />
                                <span>Tipos de Solicitudes</span>
                            </Link>
                        )}
                        <Typography color="textPrimary" className={"flex !font-medium px-2 py-1 "}>
                            <IoDocumentTextOutline className="text-2xl mr-2" />
                            <span>Formatos Solicitud</span>
                        </Typography>
                    </Breadcrumbs>
                </div>
            </div>

            <div className="max-w-3xl mx-auto p-4">
                <List className="flex flex-col gap-2">
                    <div>
                        <InputLabel>
                            <i className="fa fa-filter"></i>&nbsp;<b>Tipo de Solicitud:</b>
                        </InputLabel>
                        <FormControl size="small" className="">
                            <Select
                                variant="filled"
                                className="!text-sm !border-0"
                                labelId="tipo-solicitud-select-label"
                                id="tipo-solicitud-select"
                                value={tipoSolicitudSeleccionado.iIDTipoSolicitud}
                                label="Tipo de Solicitud"
                                defaultValue=""
                                //input={<CustomSelect />}
                                fullWidth
                                onChange={(e) => handleChangeTipoSolicitud(e.target.value)}
                            >
                                {listaTiposSolicitudAll.map((tipoSolicitud) => {
                                    return (
                                        <MenuItem
                                            key={tipoSolicitud.iIDTipoSolicitud}
                                            value={tipoSolicitud.iIDTipoSolicitud}
                                        >
                                            {tipoSolicitud.sNombre}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    {listaFormatosSolicitud.map((formatoSolicitud) => {
                        const labelId = `list-label-${formatoSolicitud.iIDFormatoSolicitud}`;

                        return (
                            <Fragment key={formatoSolicitud.iIDFormatoSolicitud}>
                                <ListItem
                                    className="flex bg-card !bg-white shadow !rounded-xl !py-4"
                                    role={undefined}
                                    button
                                    onClick={() => handleClickFormatoSolicitud(formatoSolicitud.iIDFormatoSolicitud)}
                                >
                                    <IoDocumentTextOutline className="text-gray-900 text-2xl mr-3" />
                                    <ListItemText
                                        className="flex-1 md:mr-36 text-gray-900"
                                        id={labelId}
                                        primary={formatoSolicitud.iIDFormatoSolicitud + "-" + formatoSolicitud.sNombreArchivo}
                                    />
                                </ListItem>
                            </Fragment>
                        );
                    })}
                </List>
            </div>
            {modalOpenConfigurarFormato && <ConfigurarFormatoModal />}
        </div>
    );
}
