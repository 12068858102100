import Enumerable from "linq";
import fileDownload from "js-file-download";
import Swal from "sweetalert2";
import { createContext, useContext, useState } from "react";
import { getModelTipoSolicitud } from "../models/TipoSolicitudModel";
import { getModelSolicitud, getFormCPColonia, getModelError } from "../models/SolicitudModel";
import { getFormEnvOriginador, getFormEnvNOriginador, getModelEnvOriginador } from "../models/EnvioOriginadorMod";
import { useLayout } from "./LayoutContext";
import { useLogin } from "./LoginContext";
import { fnHandleChangeText } from "../settings/handleConfig";

import {
    apiSolicitudChangeStatus,
    apiSolicituDetalle,
    apiCGetIdBuroSolicitud,
    apiCGetExisteProspecto,
    apiCGetExisteProspectoCURP,
    apiCGetDatosProspecto,
    apiCGetInfoEnvOriginador,
    apiCUpdateProspecto,
    apiCGetColoniaxCP,
    apiCGetEstastusbyUpdCP,
    apiCUpdateDatosDirCampo,
    apiSolicitudGetSolicitudes,
    apiSolicitudDisplayPDF,
    apiInfoSolicitudAprobada,
    apiChangeStatusSolicitud,
    apiSaveInfoSolicitudAprobada,
    apiCGetSolicitudAprobada,
    apiGetInfoSolicitudRechaza,
    apiSaveSolicitudRechazada,
    apiCGetSolicitudRechazada,
    apiCGetSolicitudBuro,
    apiCGuardarOriginador,
    apiCGetSolicitudesByRFC,
    apiCGetEstadosRelacionByTipoSolicitud,
    apiCGetCamposAutoFill,
    apiCGetVerificarScoreSolicitud,
    apiCGetScoreSolicitudBySolicitud,
    apiCGetReporteCreditoSolicitud,
    apiCGetVerificarReporteCreditoExistente,
    apiCGetInfoFinancieraByIidSolicitud
} from "../settings/apiConfig";
import { useRequest } from "./RequestContext";
import { fnPrevent } from "../settings/preventConfig";
import { serverMain } from "../settings/serverConfig";
import ReactSpeedometer from "react-d3-speedometer";
import { set } from "lodash";
import { CleaningServices } from "@mui/icons-material";

export const SolicitudContext = createContext();


export function SolicitudProvider(props) {
    const { Get, Post, DownloadFileBlob, DownloadFileBlobPromise } = useRequest();

    const { handleOpenAlert, handleLoader } = useLayout();
    const { usuarioOriginador } = useLogin();

    const [updateData, setUpdateData] = useState(false);
    const [listaSolicitudes, setListaSolicitudes] = useState([]);
    const [bAllEstatus, setbAllEstatus] = useState(false);
    const [listaEstadosSolicitud, setListaEstadosSolicitud] = useState([]);
    const [listaDetalleSolicitud, setListaDetalleSolicitud] = useState([]);
    const [listaCamposAutoFill, setListaCamposAutoFill] = useState([]);
    const [listaColonias, setColonias] = useState([]);
    const [listaEstatusUpd, setListaEstatusUpd] = useState([]);

    // Formulario dinámico...
    const [openFormAprobado, setOpenFormAprobado] = useState(false);
    const [listaForm, setListaForm] = useState([]);

    // Formulario solicitud rechazada...
    const [openFormRechazado, setOpenFormRechazado] = useState(false);
    const [listaCausasRechazo, setListaCausasRechazo] = useState([]);

    //Formulario envio originador
    const [openFormEnvioOriginador, setOpenFormEnvioOriginador] = useState(false);
    const [formTabIndex, setFormTabIndex] = useState(0);
    const [listaContactos, setListaContactos] = useState([]);
    const [listaContactosNuevo, setListaContactosNuevo] = useState([]);
    const [listaDireccion, setListaDireccion] = useState([]);
    const [listaDireccionNuevo, setListaDireccionNuevo] = useState([]);
    const [listaConyugue, setListaConyugue] = useState([]);
    const [listaConyugueNuevo, setListaConyugueNuevo] = useState([]);
    const [bAllCheks, setbAllChecks] = useState(false);
    const [bShowRadioButton, setbShowRadioButton] = useState(false);
    const [bShowRDireccion, setbShowRDireccion] = useState(false);
    const [bShowRConyuge, setbShowRConyuge] = useState(false);
    const [bchkEnviaDocumentos, setbchkEnviaDocumentos] = useState(false);
    const [miArray, setMiArray] = useState([]);
    const [mArrayDireccion, setmArrayDireccion] = useState([]);
    const [mArrayDireccionAct, setmArrayDireccionAct] = useState([]);
    const [mArrayConyuge, setmArrayConyuge] = useState([]);
    const [mArrayDocumentos, setmArrayDocumentos] = useState([]);
    const [rowsDocumentos, setRowsDocumentos] = useState([]);

    const [tipoSolicitudSeleccionado, setTipoSolicitudSeleccionado] = useState(getModelTipoSolicitud());
    const [tipoSolicitudSeleccionadoSelect, setTipoSolicitudSeleccionadoSelect] = useState(getModelTipoSolicitud());
    const [solicitudSeleccionado, setSolicitudSeleccionado] = useState(getModelSolicitud());
    const [errorResult, setErrorResult] = useState(getModelError());
    const [formCPColonia, setFormCPColonia] = useState(getFormCPColonia());
    const [formEnvioDatosOriginador, setFormEnvioDatosOriginador] = useState(getFormEnvOriginador());
    const [formEnvioDatosNOriginador, setFormEnvioDatosNOriginador] = useState(getFormEnvNOriginador());
    const [estadoSeleccionado, setEstadoSeleccionado] = useState(null);
    const [buroSolicitud, setBuroSolicitud] = useState(null);
    const [idProspectoOriginador, setIdProspectoOriginador] = useState(null);
    const [prospectoNewOriginador, setProspectoNewOriginador] = useState(null);
    const [prospectoActOriginador, setProspectoActOriginador] = useState(null);
    const [iIdSolicitudSeleccionado, setiIdSolicitudSeleccionado] = useState(0);
    const [scoreSolicitud, setScoreSolicitud] = useState();
    const [disabledBtnReporteCredito, setDisabledBtnReporteCredito] = useState(false);

    const [modalOpenSolicitudDetalle, setModalOpenSolicitudDetalle] = useState(false);
    const [openModalPruebaVida, setOpenModalPruebaVida] = useState(false);

    const [selectedRowsConyuge, setSelectedRowsConyuge] = useState([]);
    const [selectedRowsDireccion, setSelectedRowsDireccion] = useState([]);
    const [selectedRowsContactos, setSelectedRowsContactos] = useState([]);
    const [selectedRowsDocumentos, setSelectedRowsDocumentos] = useState([]);

    const handleClickObtenerSolicitudes = async (value) => {
        await fnObtenerSolicitudes(value, bAllEstatus);
    };

    const handleOpenModalSolicitudDetalle = async (value) => {
        setModalOpenSolicitudDetalle(value);
        if (value == false) {
            setColonias([]);
            setFormCPColonia(getFormCPColonia());
        }
    };

    const handleChangeTipoSolicitudSeleccionado = async (value) => {
        await setTipoSolicitudSeleccionado(value);
    };

    const handleChangeTipoSolicitudSeleccionadoSelect = async (value) => {
        await setTipoSolicitudSeleccionadoSelect(value);
    };

    const handleChangeSolicitudSeleccionado = async (value) => {
        await setSolicitudSeleccionado(value);
    };

    const handleClickSolicitud = async (value) => {
        await handleObtenerCamposAutoFill();
        await handleChangeSolicitudSeleccionado(value);
        setModalOpenSolicitudDetalle(true);
    };

    const handleChangeEstadosSolicitud = async (iIDTipoSolicitud) => {
        await fnObtenerEstadosPorTipoSolicitud(iIDTipoSolicitud);
    };

    const handleGetEstadosSolicitudUpd = async () => {
        await fnObtenerEstadosSolicitudUpd();
    };

    const handleSelectionModelChange = (newSelection, tab) => {
        let elemento = parseInt(String(newSelection.slice(-1)).replace(/\[|\]/g, ''));
        // Actualiza el estado de selección del tab correspondiente
        if (tab === 1) {
            setSelectedRowsConyuge(newSelection);
            handleAgregarConyugetoArray(elemento, newSelection);
        } else if (tab === 2) {
            setSelectedRowsDireccion(newSelection);
            handleAgregarDirecciontoArray(elemento, newSelection);
        } else if (tab === 3) {
            setSelectedRowsContactos(newSelection);
            handleAgregarContactotoArray(elemento, newSelection);
        } else if (tab === 4) {
            setSelectedRowsDocumentos(newSelection);
            handleCreateArrayDocs(newSelection);
        }
    };

    const handleSubmitUpdEstadoSolicitud = (e, piIdSolicitud, arrayCampos, motivo) => {
        fnPrevent(e);
        let data = new Array();
        arrayCampos.forEach((item) => {
            data.push({
                iIDBloqueSolicitudCampos: item.iIDBloqueSolicitudCampos,
                vValor: {
                    bRechazado: true,
                    sMotivoRechazo: motivo === "" || motivo === null ? "Sin motivo" : motivo,
                    bReqCorrecion: true,
                },
            });
        });
        const request = {
            iIdUsuario: 1,
            iIdTipoSolicitud: tipoSolicitudSeleccionado.iIDTipoSolicitud,
            iIdSolicitud: piIdSolicitud,
            aCamposSolicitud: data,
        };
        fnModificarEstadoSolicitud(request);
    };

    const handleGetBuroSolicitud = async (e) => {
        fnPrevent(e);
        const payload = {
            loader: "Obteniendo id buro credito...",
            url: apiCGetIdBuroSolicitud
                .replace("{piIdSolicitud}", solicitudSeleccionado.iIdSolicitud),
            alert: false,
        };
        const response = await Get(payload);
        if (response.iCodigo === 201) {
            const entBuro = {
                iIdBuro: response.result.iidburo,
                sNombre: response.result.snombre,
            }
            setBuroSolicitud(entBuro);

        } else {
            setBuroSolicitud(null);
        }
    };

    const handleGetInfoFinancieraByIdSolicitud = async (e) => {
        fnPrevent(e);
        const payload = {
            loader: "Obteniendo financiera...",
            url: apiCGetInfoFinancieraByIidSolicitud
                .replace("{piIdSolicitud}", solicitudSeleccionado.iIdSolicitud),
            alert: false,
        };
        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setScoreSolicitud(response.result);
        } else {
            setScoreSolicitud(null);
        }
    };

    const handleGetScoreSolicitud = async (e) => {
        fnPrevent(e);
        const payload = {
            loader: "Obteniendo id buro credito...",
            url: apiCGetVerificarScoreSolicitud.replace("{piIdSolicitud}", solicitudSeleccionado.iIdSolicitud),
            alert: true,
        };
        const response = await Get(payload);
        if (response.iCodigo === 201) {
            if (response.result.iidscore > 0) {
                Swal.fire({
                    title: "Aviso",
                    text: "Ya existe un score, ¿desea generar uno nuevo?",
                    icon: "info",
                    showCloseButton: true,
                    showDenyButton: true,
                    confirmButtonText: "Generar uno nuevo",
                    confirmButtonColor: "#3085d6",
                    denyButtonText: "Mostrar actual",
                    denyButtonColor: '#8CD4F5',
                    reverseButtons: true,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        fnCreateScore(1);
                        //Swal.fire('isConfirmed', '', 'info')
                    } else if (result.isDenied) {
                        fnCreateScore(0);
                    }
                });
            } else {
                fnCreateScore(0);
            }

        } else {
            setScoreSolicitud(null);
        }

    }

    const fnCreateScore = async () => {
        const payload = {
            loader: "Obteniendo Score...",
            url: apiCGetScoreSolicitudBySolicitud
                .replace("{piIDSolicitud}", solicitudSeleccionado.iIdSolicitud),
            alert: true
        };
        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setScoreSolicitud(response.result);
        }
    };

    const fnObtenerPDFReporteCredito = async (iidUsuarioModificacion, bActualiza) => {
        setDisabledBtnReporteCredito(true);
        const payload = {
            loader: "Generando PDF...",
            url: apiCGetReporteCreditoSolicitud
                .replace("{piIDSolicitud}", solicitudSeleccionado.iIdSolicitud)
                .replace("{iIdUsuario}", iidUsuarioModificacion)
                .replace("{bActualiza}", bActualiza),
            alert: true,
            extension: "pdf",
            nombre: 'ReporteCredito.pdf',
        };

        console.log(payload);

        const handleBlobError = (blob) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    try {
                        const errorResponse = JSON.parse(reader.result);
                        try {
                            const parsedMessage = JSON.parse(errorResponse.message);
                            // Si es un objeto JSON
                            if (typeof parsedMessage === "object" && parsedMessage !== null) {
                                errorResponse.message = parsedMessage.errores[0].mensaje;
                            }
                        } catch (e) {

                        }
                        resolve(errorResponse);
                    } catch (e) {
                        reject("No se pudo interpretar la respuesta del servidor.");
                    }
                };
                reader.onerror = reject;
                reader.readAsText(blob);
            });
        };

        await DownloadFileBlobPromise(payload)
            .then((res) => {
                fileDownload(res.data, payload.nombre);
                fnCreateScore();
                setDisabledBtnReporteCredito(false);
                handleOpenAlert("Archivo descargado exitosamente.", "success");
            })
            .catch((error) => {
                handleBlobError(error.response.data)
                    .then((errorData) => {
                        handleOpenAlert(errorData.message || "PDF no disponible para su descarga.");
                        setDisabledBtnReporteCredito(false);
                    })
                    .catch((parseError) => {
                        handleOpenAlert("PDF no disponible para su descarga.");
                        setDisabledBtnReporteCredito(false);
                    });
            })
            .finally(handleLoader());

    };

    const handleChangeGetListaSolicitudDetail = () => {
        fnObtenerDetalleSolicitud();
    };

    const handleChangeGetListaSolicitudDetailDocs = (pIdSolicitud, pIdTipoSolicitud) => {
        fnObtenerDetalleDoctosSolicitud(pIdSolicitud, pIdTipoSolicitud);
    };

    const handleChangeCreatePDF = (piIdSolicitud, pIdFormato, iIDTipoSolicitud) => {
        fnPrevent();
        const request = {
            iIDTipoSolicitud,
            iIdSolicitud: piIdSolicitud,
            iIdFormarto: pIdFormato,
            nombre: `Solicitud-${piIdSolicitud}-${pIdFormato}.pdf`,
        };
        fnObtenerPDFCreado(request);
    };

    const handleObtenerColoniasxCP = async () => {
        await fnObtenerColoniasxCp();
    };

    const fnObtenerColoniasxCp = async () => {

        const { txtCodigoPostal } = formCPColonia;

        const form = {
            ...formCPColonia,
            txtCodigoPostal: {
                ...txtCodigoPostal,
                //error: txtCodigoPostal.value === "",
            },

        };

        // setFormCPColonia(form);
        // if (
        //     Object.values(form)
        //         .map((x) => x.error)
        //         .includes(true)
        // ) {
        //     return;
        // }

        const payload = {
            loader: "Consultando colonias por código postal...",
            url: apiCGetColoniaxCP.replace("{pCp}", txtCodigoPostal.value),
            alert: true,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setColonias(response.result);
        }
        else {
            setColonias([]);
        }
    };

    const handleEnviarSolicitudOriginador = async (e, piIdSolicitud, iIDTipoSolicitud) => {
        fnPrevent(e);
        const request = {
            loader: "Enviando al originador",
            url: apiCGuardarOriginador.replace("{iIdSolicitud}", piIdSolicitud),
            alert: true,
        };

        const response = await Post(request);
        if (response.iCodigo === 201) {
            await fnObtenerSolicitudes(tipoSolicitudSeleccionadoSelect.iIDTipoSolicitud, bAllEstatus);
        }
    };

    const handleGetExisteProspecto = async (e, psRfc) => {
        fnPrevent(e);
        const payload = {
            loader: "Obteniendo registro de prospecto...",
            url: apiCGetExisteProspecto
                .replace("{RFC}", psRfc),
            alert: false,
        };
        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setIdProspectoOriginador(response.result);
            return response.result;
        }
        else {
            setIdProspectoOriginador(0);
            return 0;
        }
    };

    const handleGetExisteProspectoCURP = async (e, psCURP) => {
        fnPrevent(e);
        const payload = {
            loader: "Obteniendo registro de prospecto...",
            url: apiCGetExisteProspectoCURP
                .replace("{sCURP}", psCURP),
            alert: false,
        };
        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setIdProspectoOriginador(response.result);
            return response.result;
        }
        else {
            setIdProspectoOriginador(0);
            return 0;
        }
    };

    const handleGetProspecto = async (e, pIdProspecto) => {
        fnPrevent(e);
        const payload = {
            loader: "Obteniendo información de prospecto...",
            url: apiCGetDatosProspecto
                .replace("{pIdProspecto}", pIdProspecto),
            alert: false,
        };
        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setProspectoActOriginador(response.result);

            const entProspectoOrig = {
                iNoProspecto: response.result.noProspecto,
                sNombre: response.result.nombre,
                sAP: response.result.apellidoPaterno,
                sAM: response.result.apellidoMaterno,
                sCurp: response.result.curp,
                dFechaNac: response.result.fechaNacimiento,
                sRFC: response.result.rfc,
                sGenero: response.result.genero != null ? response.result.genero.nombreDetalle : '',
                sEstadoCiv: response.result.edoCivil != null ? response.result.edoCivil.nombreDetalle : '',
                iDepEconomicos: response.result.dependientesEconomicos,
                sNivelEstudios: response.result.nivelEstudios != null ? response.result.nivelEstudios.nombreDetalle : '',
                sNacionalidad: response.result.nacionalidad != null ? response.result.nacionalidad.nombreDetalle : '',
                sTipoProspecto: response.result.tipoProspecto != null ? response.result.tipoProspecto.nombreDetalle : '',
                iAniosResidencia: response.result.aniosResidencia,
                sPaisNac: response.result.paisNacimiento != null ? response.result.paisNacimiento.nombreDetalle : '',
                sEntNac: response.result.entidadNacimiento != null ? response.result.entidadNacimiento.nombreDetalle : '',
                sGiroMerc: response.result.giroMercantil != null ? response.result.giroMercantil.nombreDetalle : '',
                sActSol: response.result.actividadSolicitante != null ? response.result.actividadSolicitante.nombreDetalle : '',
                sPpe: response.result.ppe === true ? 'Si' : response.result.ppe === false ? 'No' : '',
            }

            let counter = 0;
            if (response.result.contactos.length > 0) {
                const _listContact = Enumerable.from(response.result.contactos)
                    .select((contact) => ({
                        id: counter += 1,
                        iIdContacto: contact.id,
                        Medio: contact.medioContacto.nombreDetalle,
                        Tipo: contact.tipoContacto.nombreDetalle,
                        Contacto: contact.telefono != null ? contact.telefono : contact.correo,
                        NombreContacto: contact.nombreContacto,
                    }))
                    .toArray();
                setListaContactos(_listContact);
            }

            let count = 0;
            if (response.result.direcciones.length > 0) {
                const _listDir = Enumerable.from(response.result.direcciones)
                    .select((direccion) => ({
                        id: count += 1,
                        iIdDir: direccion.id,
                        sDomicilio: direccion.calle,
                        sMunicipio: direccion.municipio,
                        sEstado: direccion.estado,
                        sCP: direccion.codigoPostal,
                        sTipoDir: direccion.tipoDireccion.nombreDetalle
                    }))
                    .toArray();
                setListaDireccion(_listDir);
                handleAddToArrayDirAct(response.result.direcciones);
            }

            if (response.result.conyugue != null) {
                const entConyugue = response.result.conyugue;
                let lstConyugue = [];
                lstConyugue.push({
                    id: 1,
                    iIdConyugue: 1,
                    sNombre: entConyugue.nombre + " " + entConyugue.apellidoPaterno + " " + entConyugue.apellidoMaterno,
                    sCurp: entConyugue.curp,
                    sRFC: entConyugue.rfc
                });
                setListaConyugue(lstConyugue);
            }

            const { txtNombre, txtAP, txtAM, txtCURP, txtFecNac, txtRFC, txtGenero, txtEC,
                txtDepEco, txtNivEst, txtNacionalidad, txtTipoPF, txtAR, txtPaisNac,
                txtEntNac, txtFIEL, txtGiroMerc, txtActSol, txtPersonaPE,
            } = formEnvioDatosOriginador;

            const form = {
                ...formEnvioDatosOriginador,
                txtNombre: {
                    ...txtNombre,
                    value: entProspectoOrig.sNombre,
                },
                txtAP: {
                    ...txtAP,
                    value: entProspectoOrig.sAP,
                },
                txtAM: {
                    ...txtAM,
                    value: entProspectoOrig.sAM,
                },
                txtCURP: {
                    ...txtCURP,
                    value: entProspectoOrig.sCurp,
                },
                txtFecNac: {
                    ...txtFecNac,
                    value: entProspectoOrig.dFechaNac,
                },
                txtRFC: {
                    ...txtRFC,
                    value: entProspectoOrig.sRFC,
                },
                txtGenero: {
                    ...txtGenero,
                    value: entProspectoOrig.sGenero,
                },
                txtEC: {
                    ...txtEC,
                    value: entProspectoOrig.sEstadoCiv,
                },
                txtDepEco: {
                    ...txtDepEco,
                    value: entProspectoOrig.iDepEconomicos,
                },
                txtNivEst: {
                    ...txtNivEst,
                    value: entProspectoOrig.sNivelEstudios,
                },
                txtNacionalidad: {
                    ...txtNacionalidad,
                    value: entProspectoOrig.sNacionalidad,
                },
                txtTipoPF: {
                    ...txtTipoPF,
                    value: entProspectoOrig.sTipoProspecto,
                },
                txtAR: {
                    ...txtAR,
                    value: entProspectoOrig.iAniosResidencia,
                },
                txtPaisNac: {
                    ...txtPaisNac,
                    value: entProspectoOrig.sPaisNac,
                },
                txtEntNac: {
                    ...txtEntNac,
                    value: entProspectoOrig.sEntNac,
                },
                txtGiroMerc: {
                    ...txtGiroMerc,
                    value: entProspectoOrig.sGiroMerc,
                },
                txtActSol: {
                    ...txtActSol,
                    value: entProspectoOrig.sActSol,
                },
                txtPersonaPE: {
                    ...txtPersonaPE,
                    value: entProspectoOrig.sPpe,
                },
            };

            setFormEnvioDatosOriginador(form);

        } else {
            errorResult.error = true;
            handleOpenAlert(response.message, "error");
        }
    };

    const handleGetProspectoNuevo = async (e, iIdSolicitud) => {
        fnPrevent(e);
        const payload = {
            loader: "Obteniendo información de prospecto nuevo...",
            url: apiCGetInfoEnvOriginador
                .replace("{iIdSolicitud}", iIdSolicitud),
            alert: false,
        };
        const response = await Get(payload);
        if (response.iCodigo === 201) {
            const entResult = response.result.prospecto;
            setProspectoNewOriginador(response.result);
            const entProspectoNOrig = {
                sNombre: entResult.nombre,
                sAP: entResult.apellidoPaterno,
                sAM: entResult.apellidoMaterno,
                sCurp: entResult.curp,
                dFechaNac: entResult.fechaNacimiento,
                sRFC: entResult.rfc,
                sGenero: entResult.genero.nombreDetalle,
                sEstadoCiv: entResult.edoCivil.nombreDetalle,
                iDepEconomicos: entResult.dependientesEconomicos,
                sNivelEstudios: entResult.nivelEstudios.nombreDetalle,
                sNacionalidad: entResult.nacionalidad.nombreDetalle,
                sTipoProspecto: entResult.tipoProspecto.nombreDetalle,
                iAniosResidencia: entResult.aniosResidencia,
                sPaisNac: entResult.paisNacimiento.nombreDetalle,
                sEntNac: entResult.entidadNacimiento.nombreDetalle,
                sGiroMerc: entResult.giroMercantil.nombreDetalle,
                sActSol: entResult.actividadSolicitante.nombreDetalle,
                sPpe: (entResult.ppe === 'false') ? 'No' : 'Si',
            }

            let counter = 0;
            if (response.result.contactos.length > 0) {
                const _listContactNew = Enumerable.from(response.result.contactos)
                    .select((contact) => ({
                        iIdContact: contact.idContacto,
                        Medio: contact.medioContacto,
                        Tipo: contact.tipoContacto,
                        Telefono: contact.telefono,
                        Correo: contact.correo,
                        mcClave: contact.mContactoClave,
                        tcClave: contact.tContactoClave,
                        Contacto: contact.telefono != null ? contact.telefono : contact.correo,
                        NombreContacto: contact.nombreContacto,
                        bCheck: false,
                    }))
                    .toArray();
                setListaContactosNuevo(_listContactNew);
            }
            if (response.result.direccion.direccion != null) {
                const entDir = response.result.direccion.direccion;
                let lstDirNew = [];
                lstDirNew.push({
                    iIdDirNew: 1,
                    sDomicilio: entDir.calle,
                    sMunicipio: entDir.municipio,
                    sEstado: entDir.estado,
                    sCP: entDir.codigoPostal,
                    sTipoDir: entDir.tipoDireccion.nombreDetalle
                });
                setListaDireccionNuevo(lstDirNew);
            }
            if (response.result.conyuge != null) {
                const entConyuge = response.result.conyuge;
                let lstConyugeNew = [];
                lstConyugeNew.push({
                    iIdConyugeNew: 1,
                    sNombre: entConyuge.nombre + " " + entConyuge.apellidoPaterno + " " + entConyuge.apellidoMaterno,
                    sCurp: entConyuge.curp,
                    sRFC: entConyuge.rfc
                });
                setListaConyugueNuevo(lstConyugeNew);
            }

            const { txtNNombre, txtNApellidoP, txtNApellidoM, txtNCURP, txtNFecNac, txtNRFC, txtNGenero, txtNEstC,
                txtNDepEco, txtNNivEst, txtNNacionalidad, txtNTipoPF, txtNAniosRe, txtNPaisNac,
                txtNEntNac, txtNFIEL, txtNGiroMerc, txtNActSol, txtNPersonaPE,
            } = formEnvioDatosNOriginador;
            const form = {
                ...formEnvioDatosNOriginador,
                txtNNombre: {
                    ...txtNNombre,
                    value: entProspectoNOrig.sNombre,
                },
                txtNApellidoP: {
                    ...txtNApellidoP,
                    value: entProspectoNOrig.sAP,
                },
                txtNApellidoM: {
                    ...txtNApellidoM,
                    value: entProspectoNOrig.sAM,
                },
                txtNCURP: {
                    ...txtNCURP,
                    value: entProspectoNOrig.sCurp,
                },
                txtNFecNac: {
                    ...txtNFecNac,
                    value: entProspectoNOrig.dFechaNac,
                },
                txtNRFC: {
                    ...txtNRFC,
                    value: entProspectoNOrig.sRFC,
                },
                txtNGenero: {
                    ...txtNGenero,
                    value: entProspectoNOrig.sGenero,
                },
                txtNEstC: {
                    ...txtNEstC,
                    value: entProspectoNOrig.sEstadoCiv,
                },
                txtNDepEco: {
                    ...txtNDepEco,
                    value: entProspectoNOrig.iDepEconomicos,
                },
                txtNNivEst: {
                    ...txtNNivEst,
                    value: entProspectoNOrig.sNivelEstudios,
                },
                txtNNacionalidad: {
                    ...txtNNacionalidad,
                    value: entProspectoNOrig.sNacionalidad,
                },
                txtNTipoPF: {
                    ...txtNTipoPF,
                    value: entProspectoNOrig.sTipoProspecto,
                },
                txtNAniosRe: {
                    ...txtNAniosRe,
                    value: entProspectoNOrig.iAniosResidencia,
                },
                txtNPaisNac: {
                    ...txtNPaisNac,
                    value: entProspectoNOrig.sPaisNac,
                },
                txtNEntNac: {
                    ...txtNEntNac,
                    value: entProspectoNOrig.sEntNac,
                },
                txtNGiroMerc: {
                    ...txtNGiroMerc,
                    value: entProspectoNOrig.sGiroMerc,
                },
                txtNActSol: {
                    ...txtNActSol,
                    value: entProspectoNOrig.sActSol,
                },
                txtNPersonaPE: {
                    ...txtNPersonaPE,
                    value: entProspectoNOrig.sPpe,
                },
            };

            setFormEnvioDatosNOriginador(form);

        } else {
            errorResult.error = true;
            handleOpenAlert(response.message, "error");
        }
    };

    const handleGetProspectoNuevoActual = async (e, pIdProspecto, iIdSolicitud, iIdTipoSolicitud) => {
        errorResult.error = false;
        await handleGetProspecto(e, pIdProspecto);//Información del originador
        await handleGetProspectoNuevo(e, iIdSolicitud);//Información para actualizar

        if (errorResult.error === false) {
            handleChangeGetListaSolicitudDetailDocs(iIdSolicitud, iIdTipoSolicitud);//Información de documentos
            setiIdSolicitudSeleccionado(iIdSolicitud);
            handleOpenFormEnvioOriginador(true);
        }
    };

    const fnHandleChangeChek = (form, setForm) => (e) => {
        const name = e.target.name;
        const value = e.target.checked;

        setForm({
            ...form,
            [name]: {
                ...form[name],
                value: value,
            },
        });
    };

    const handleChangeCheck = fnHandleChangeChek(formEnvioDatosOriginador, setFormEnvioDatosOriginador);

    const handleChangeFormCPColonia = fnHandleChangeText(formCPColonia, setFormCPColonia);

    //#region Metodo para listar documentos 
    const handlecreateData = () => {
        let data = [];
        let id = 1;

        for (let index = 0; index < listaDetalleSolicitud.length; index++) {
            let { aBloques } = listaDetalleSolicitud[index];
            if (aBloques != null) {
                for (let c = 0; c < aBloques.length; c++) {
                    let { bGrupo, sNombreBloque, aCampos } = aBloques[c];
                    if (!bGrupo) {
                        for (let b = 0; b < aCampos.length; b++) {
                            let { sNombre, vValor, ifInputForm, iIdDtoOriginador } = aCampos[b];
                            let valorCampo = vValor == null ? "" : vValor.sTexto;
                            let rechazado = vValor == null ? "" : vValor.bRechazado;
                            let motivoRechazo = vValor == null ? "" : vValor.sMotivoRechazo;
                            let bReqCorrecion = vValor == null ? "" : vValor.bReqCorrecion;
                            let fechaC = vValor == null ? "" : vValor.dtFechaCreacion;
                            let Tipo = ifInputForm == null ? "" : ifInputForm.sNombre;
                            let iIDBloqueSolicitudCampos = vValor === null ? 0 : vValor.iIDBloqueSolicitudCampos;
                            if (Tipo == "IMG" || Tipo == "FILE") {
                                data.push({
                                    id,
                                    iIDBloqueSolicitudCampos,
                                    Bloque: sNombreBloque,
                                    Campo: sNombre,
                                    Valor: valorCampo !== null ? String(valorCampo).trim() : "",
                                    Rechazado: rechazado !== "" ? (rechazado && !bReqCorrecion ? false : rechazado) : "",
                                    bGrupo,
                                    FechaCreacion: fechaC,
                                    Tipo,
                                    ubicacion: {
                                        lng: vValor !== null ? vValor.sLongitud : "",
                                        lat: vValor !== null ? vValor.sLatitud : "",
                                    },
                                    MotivoRechazo: motivoRechazo === null ? "Sin motivo" : motivoRechazo,
                                    ReqCorrecion: bReqCorrecion,
                                    RechazadoReal: rechazado,
                                    selectedValue: iIdDtoOriginador,
                                });
                                id++;
                            }

                        }
                    } else {
                        try {
                            for (let c = 0; c < aCampos.length; c++) {
                                const { aCamposAgrupado } = aCampos[c];
                                const sNombreCampo = aCampos[c].sNombre;
                                if (aCamposAgrupado.length > 0) {
                                    for (let b = 0; b < aCamposAgrupado.length; b++) {
                                        let { sNombre, vValor, ifInputForm, iIdDtoOriginador } = aCamposAgrupado[b];
                                        let valorCampo = vValor == null ? "" : vValor.sTexto;
                                        let rechazado = vValor == null ? "" : vValor.bRechazado;
                                        let bReqCorrecion = vValor == null ? "" : vValor.bReqCorrecion;
                                        let motivoRechazo = vValor == null ? "" : vValor.sMotivoRechazo;
                                        let fechaC = vValor == null ? "" : vValor.dtFechaCreacion;
                                        let Tipo = ifInputForm == null ? "" : ifInputForm.sNombre;
                                        let iIDBloqueSolicitudCampos =
                                            vValor === null ? 0 : vValor.iIDBloqueSolicitudCampos;
                                        if (Tipo == "IMG" || Tipo == "FILE") {
                                            data.push({
                                                id,
                                                iIDBloqueSolicitudCampos,
                                                Bloque: sNombreBloque,
                                                Campo: sNombreCampo + " - " + sNombre,
                                                Valor: valorCampo !== null ? String(valorCampo).trim() : "",
                                                Rechazado:
                                                    rechazado !== ""
                                                        ? rechazado && !bReqCorrecion
                                                            ? false
                                                            : rechazado
                                                        : "",
                                                bGrupo,
                                                FechaCreacion: fechaC,
                                                Tipo,
                                                ubicacion: {
                                                    lng: vValor !== null ? vValor.sLongitud : "",
                                                    lat: vValor !== null ? vValor.sLatitud : "",
                                                },
                                                MotivoRechazo: motivoRechazo === null ? "Sin motivo" : motivoRechazo,
                                                ReqCorrecion: bReqCorrecion,
                                                RechazadoReal: rechazado,
                                                selectedValue: iIdDtoOriginador,
                                            });
                                            id++;
                                        }
                                    }
                                } else {
                                    let { sNombre, vValor, ifInputForm, iIdDtoOriginador } = aCampos[c];
                                    let valorCampo = vValor == null ? "" : vValor.sTexto;
                                    let rechazado = vValor == null ? "" : vValor.bRechazado;
                                    let bReqCorrecion = vValor == null ? "" : vValor.bReqCorrecion;
                                    let motivoRechazo = vValor == null ? "" : vValor.sMotivoRechazo;
                                    let fechaC = vValor == null ? "" : vValor.dtFechaCreacion;
                                    let Tipo = ifInputForm == null ? "" : ifInputForm.sNombre;
                                    let iIDBloqueSolicitudCampos =
                                        vValor === null ? 0 : vValor.iIDBloqueSolicitudCampos;
                                    if (Tipo == "IMG" || Tipo == "FILE") {
                                        data.push({
                                            id,
                                            iIDBloqueSolicitudCampos,
                                            Bloque: sNombreBloque,
                                            Campo: sNombre,
                                            Valor: valorCampo !== null ? String(valorCampo).trim() : "",
                                            Rechazado:
                                                rechazado !== "" ? (rechazado && !bReqCorrecion ? false : rechazado) : "",
                                            bGrupo,
                                            FechaCreacion: fechaC,
                                            Tipo,
                                            ubicacion: {
                                                lng: vValor !== null ? vValor.sLongitud : "",
                                                lat: vValor !== null ? vValor.sLatitud : "",
                                            },
                                            MotivoRechazo: motivoRechazo === null ? "Sin motivo" : motivoRechazo,
                                            ReqCorrecion: bReqCorrecion,
                                            RechazadoReal: rechazado,
                                            selectedValue: iIdDtoOriginador,
                                        });
                                        id++;
                                    }
                                }
                            }
                        } catch (error) {

                        }
                    }
                }
            }
        }
        setRowsDocumentos(data);
    };

    const handleSelectChange = (event, id) => {
        const selectedValue = event.target.value;
        setRowsDocumentos(prevRows =>
            prevRows.map(row =>
                row.id === id ? { ...row, selectedValue } : row
            )
        );
    };
    //#endregion

    const existeArrayContactos = (id) => {
        return miArray.find(item => item.iIdContacto === id);
    };

    const handleAgregarContactotoArray = (itemAdd, newSelection) => {
        setbShowRadioButton(false);
        const existe = itemAdd != null ? existeArrayContactos(itemAdd) : false;
        if (!existe && !isNaN(itemAdd)) {
            Swal.fire({
                title: "Aviso",
                text: "¿Se va a agregar el contacto o se modificará?",
                icon: "info",
                showCloseButton: true,
                showDenyButton: true,
                confirmButtonText: "Modificar",
                confirmButtonColor: "#3085d6",
                denyButtonText: "Agregar",
                denyButtonColor: '#8CD4F5',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    setbShowRadioButton(true);
                    Swal.fire({
                        // title: "Elegir",
                        html: "Seleccionar un valor de los datos actuales <br> para actualizar.",
                        icon: "info",
                    });
                } else if (result.isDenied) {
                    setbShowRadioButton(false);
                    const selectedRowNew = prospectoNewOriginador.contactos.filter((item) => {
                        return item.idContacto === itemAdd;
                    });

                    const entContacto = {
                        iIdContacto: selectedRowNew[0].idContacto,
                        correo: selectedRowNew[0].correo,
                        id: null,
                        medioContacto: {
                            valor: selectedRowNew[0].mContactoClave,
                        },
                        nombreContacto: selectedRowNew[0].nombreContacto,
                        parentesco: {
                            id: selectedRowNew[0].parentesco,
                        },
                        pendienteVerificar: true,
                        pin: 0,
                        principal: true,
                        razonSocial: "",
                        telefono: selectedRowNew[0].telefono,
                        tipoContacto: {
                            valor: selectedRowNew[0].tContactoClave
                        },
                        tipoReferencia: {
                            valor: selectedRowNew[0].tipoReferencia
                        },
                        usuarioRegistra: "gerardo.duarte@dinercap.com",
                        verificado: true
                    }
                    const updatedArray = [...miArray, entContacto];
                    setMiArray(updatedArray);

                    Swal.fire({
                        title: "Agregar",
                        html: "El " + selectedRowNew[0].medioContacto + " se va a agregar con la nueva información.",
                        icon: "info",
                    });
                }
            });
        }
        else {
            if (miArray.length != newSelection.length) {
                let foundItem = null;
                let itemRemove = null;
                let contacto = null;
                for (const item of miArray) {
                    foundItem = newSelection.find(element => element === item.iIdContacto);
                    if (!foundItem) {
                        itemRemove = item;
                    }
                }
                // const itemEliminar = miArray.filter(elemento => !newSelection.includes(itemAdd));
                const nuevoArray = miArray.filter(item => item.iIdContacto !== itemRemove.iIdContacto);
                setMiArray(nuevoArray);
                const selectedRowNew = prospectoNewOriginador.contactos.filter((item) => {
                    return item.idContacto === itemRemove.iIdContacto;
                });

                contacto = selectedRowNew[0].telefono != null ? selectedRowNew[0].telefono : selectedRowNew[0].correo;

                Swal.fire({
                    title: "Contacto eliminado",
                    html: "Se ha desmarcado el registro " + selectedRowNew[0].medioContacto + " " + contacto + " para enviar al<br> originador se deberá marcar la opción nuevamente.",
                    icon: "info",
                    width: "30em",
                    confirmButtonText: "Ok",
                });
            }
        }
    }

    const handleAddToArray = (idActualizar, itemAdd) => {

        const selectedRowNew = prospectoActOriginador.contactos.filter((item) => {
            return item.id === idActualizar;
        });
        const entContacto =
        {
            iIdContacto: itemAdd[0].iIdContacto,
            correo: itemAdd[0].Correo,
            estatus: true,
            extension: null,
            fechaActualiza: new Date().toJSON(),
            fechaRegistro: new Date().toJSON(),
            id: idActualizar,
            medioContacto: {
                id: selectedRowNew[0].medioContacto.id,
                valor: itemAdd[0].mcClave,
            },
            nombreContacto: itemAdd[0].sNombreContacto,
            parentesco: null,
            // {
            //     id: selectedRowNew[0].parentesco != null ? selectedRowNew[0].parentesco.id == [] ? 0 : selectedRowNew[0].parentesco.id : null,
            //     valor: selectedRowNew[0].parentesco != null ? selectedRowNew[0].parentesco.valor == [] ? null : selectedRowNew[0].parentesco.valor : null,
            // },
            pendienteVerificar: false,
            pin: 0,
            principal: false,
            razonSocial: null,
            telefono: itemAdd[0].Telefono,
            tipoContacto: {
                id: selectedRowNew[0].tipoContacto.id,
                valor: itemAdd[0].tcClave
            },
            tipoReferencia: null,
            // {
            //     id: selectedRowNew[0].tipoReferencia != null ? selectedRowNew[0].tipoReferencia.id[0] : null,
            //     valor: selectedRowNew[0].tipoReferencia != null ? selectedRowNew[0].tipoReferencia.nombreDetalle[0] : null
            // },
            usuarioActualiza: null,
            usuarioRegistra: "gerardo.duarte@dinercap.com",
            verificado: false
        }
        const updatedArray = [...miArray, entContacto];
        setMiArray(updatedArray);

        Swal.fire({
            title: "Actualizar",
            text: "El " + selectedRowNew[0].medioContacto.nombreDetalle + " se va a actualizar con la nueva información.",
            icon: "info",
        });
    }

    const existeArrayDireccion = (id) => {
        return mArrayDireccion.find(item => item.iIdDir === id);
    };

    const handleAgregarDirecciontoArray = (itemAdd, newSelection) => {
        setbShowRDireccion(false);
        const existe = itemAdd != null ? existeArrayDireccion(itemAdd) : false;
        if (!existe && !isNaN(itemAdd)) {
            Swal.fire({
                title: "Aviso",
                text: "¿Se modificará la dirección?",
                icon: "info",
                showCloseButton: true,
                showDenyButton: true,
                confirmButtonText: "Modificar",
                confirmButtonColor: "#3085d6",
                denyButtonText: "Cancelar",
                denyButtonColor: '#8CD4F5',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    setbShowRDireccion(true);
                    Swal.fire({
                        html: "Seleccionar un valor de los datos actuales <br> para actualizar.",
                        icon: "info",
                    });
                }
            });
        }
        else {
            if (mArrayDireccion.length != newSelection.length) {
                let foundItem = null;
                let itemRemove = null;
                let contacto = null;
                for (const item of mArrayDireccion) {
                    foundItem = newSelection.find(element => element === item.iIdDir);
                    if (!foundItem) {
                        itemRemove = item;
                    }
                }
                const nuevoArray = mArrayDireccion.filter(item => item.iIdDir !== itemRemove.iIdDir);
                setmArrayDireccion(nuevoArray);
                // const selectedRowNew = prospectoNewOriginador.direccion.direccion.filter((item) => {
                //     return item.iIdDir === itemRemove.iIdDir;
                // });
                /*const itemEliminar = mArrayDireccion.filter(elemento => !newSelection.includes(itemAdd));
                const nuevoArray = mArrayDireccion.filter(item => item !== itemEliminar[0]);*/
                Swal.fire({
                    title: "Direccion eliminada",
                    html: "Se ha desmarcado el registro de dirección para enviar al<br> originador se deberá marcar la opción nuevamente.",
                    icon: "info",
                    width: "30em",
                    confirmButtonText: "Ok",
                });
            }
        }
    }

    const handleAddToArrayDir = (idActualizar, itemAdd) => {

        const selectedRow = prospectoActOriginador.direcciones;
        const selectedRowNew = prospectoNewOriginador.direccion.direccion;

        const entDireccion =
        {
            iIdDir: itemAdd[0].iIdDir,
            aniosDomicilio: selectedRowNew.aniosDomicilio != null ? parseInt(selectedRowNew.aniosDomicilio) : 0,
            calle: selectedRowNew.calle,
            casaHabitacionEs: {
                id: selectedRowNew.casaHabitacionEs.id,
                valor: selectedRow[0].casaHabitacionEs != null ? selectedRow[0].casaHabitacionEs.valor : ""
            },
            ciudad: "",
            codigoPostal: selectedRowNew.codigoPostal != null ? selectedRowNew.codigoPostal : 0,
            colonia: selectedRowNew.colonia,
            coloniaOtro: {
                asentamiento: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.asentamiento : "",
                ciudad: {
                    ciudad: "",
                    id: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.ciudad.id : 0,
                    municipio: {
                        estado: {
                            id: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.ciudad.municipio.estado.id : 0,
                            valor: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.ciudad.municipio.estado.valor : ""
                        },
                        id: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.ciudad.municipio.id : 0,
                        municipio: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.ciudad.municipio.municipio : ""
                    }
                },
                codigoPostal: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.codigoPostal : 0,
                estado: selectedRow[0].coloniaOtro != null ? selectedRowNew.coloniaOtro.estado : 0,
                id: selectedRow[0].coloniaOtro != null ? selectedRowNew.coloniaOtro.id : 0,
                municipio: {
                    estado: {
                        id: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.municipio.estado.id : 0,
                        valor: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.municipio.estado.valor : ""
                    },
                    id: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.municipio.id : 0,
                    municipio: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.municipio.municipio : ""
                },
                tipoAsentamiento: {
                    id: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.tipoAsentamiento.id : 0,
                    valor: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.tipoAsentamiento.valor : ""
                }
            },
            cruzamiento1: selectedRowNew.cruzamiento1,
            cruzamiento2: selectedRowNew.cruzamiento2,
            email1: null,
            email2: null,
            estado: selectedRowNew.estado,
            fechaActualizacion: new Date().toJSON(),
            fechaRegistro: new Date().toJSON,
            id: idActualizar,
            municipio: selectedRowNew.municipio,
            numExterno: selectedRowNew.numExterno,
            numInterno: selectedRowNew.numInterno,
            pais: null,
            telCel: null,
            telFijo: null,
            tipoDireccion: {
                id: selectedRowNew.tipoDireccion.id,
                valor: selectedRow[0].tipoDireccion.valor
            },
            tipoDireccionIss: null

        }
        const updatedArray = [...mArrayDireccion, entDireccion];
        setmArrayDireccion(updatedArray);

        Swal.fire({
            title: "Actualizar",
            text: "La dirección se va a actualizar con la nueva información.",
            icon: "info",
        });
    }

    const handleAddToArrayDirAct = (ListDir) => {

        if (ListDir != null && ListDir.length > 0) {
            const selectedRow = ListDir;

            const entDireccion =
            {
                aniosDomicilio: selectedRow[0].aniosDomicilio != null ? parseInt(selectedRow[0].aniosDomicilio) : 0,
                calle: selectedRow[0].calle,
                casaHabitacionEs: {
                    id: selectedRow[0].casaHabitacionEs != null ? selectedRow[0].casaHabitacionEs.id : 0,
                    valor: selectedRow[0].casaHabitacionEs != null ? selectedRow[0].casaHabitacionEs.valor : ""
                },
                ciudad: "",
                codigoPostal: selectedRow[0].codigoPostal != null ? selectedRow[0].codigoPostal : 0,
                colonia: selectedRow[0].colonia,
                coloniaOtro: {
                    asentamiento: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.asentamiento : "",
                    ciudad: {
                        ciudad: "",
                        id: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.ciudad.id : 0,
                        municipio: {
                            estado: {
                                id: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.ciudad.municipio.estado.id : 0,
                                valor: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.ciudad.municipio.estado.valor : ""
                            },
                            id: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.ciudad.municipio.id : 0,
                            municipio: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.ciudad.municipio.municipio : ""
                        }
                    },
                    codigoPostal: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.codigoPostal : 0,
                    estado: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.estado : 0,
                    id: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.id : 0,
                    municipio: {
                        estado: {
                            id: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.municipio.estado.id : 0,
                            valor: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.municipio.estado.valor : ""
                        },
                        id: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.municipio.id : 0,
                        municipio: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.municipio.municipio : ""
                    },
                    tipoAsentamiento: {
                        id: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.tipoAsentamiento.id : 0,
                        valor: selectedRow[0].coloniaOtro != null ? selectedRow[0].coloniaOtro.tipoAsentamiento.valor : ""
                    }
                },
                cruzamiento1: selectedRow[0].cruzamiento1,
                cruzamiento2: selectedRow[0].cruzamiento2,
                email1: null,
                email2: null,
                estado: selectedRow[0].estado,
                fechaActualizacion: new Date().toJSON(),
                fechaRegistro: new Date().toJSON,
                id: selectedRow[0].id,
                municipio: selectedRow[0].municipio,
                numExterno: selectedRow[0].numExterno,
                numInterno: selectedRow[0].numInterno,
                pais: null,
                telCel: null,
                telFijo: null,
                tipoDireccion: {
                    id: selectedRow[0].tipoDireccion.id,
                    valor: selectedRow[0].tipoDireccion.valor
                },
                tipoDireccionIss: null

            }
            setmArrayDireccionAct([entDireccion]);
        }
        else {
            Swal.fire({
                html: "No se encontró registro de dirección en el originador.",
                icon: "warning",
            });
        }
    }


    const existeArrayConyuge = (id) => {
        return mArrayConyuge.find(item => item.iIdConyugue === id);
    };

    const handleAgregarConyugetoArray = (itemAdd, newSelection) => {
        setbShowRConyuge(false);
        const existe = itemAdd != null ? existeArrayConyuge(itemAdd) : false;
        if (!existe && !isNaN(itemAdd)) {
            Swal.fire({
                title: "Aviso",
                text: "¿Se va a agregar el registro de conyuge o se modificará?",
                icon: "info",
                showCloseButton: true,
                showDenyButton: true,
                confirmButtonText: "Modificar",
                confirmButtonColor: "#3085d6",
                denyButtonText: "Agregar",
                denyButtonColor: '#8CD4F5',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    setbShowRConyuge(true);
                    Swal.fire({
                        html: "Seleccionar un valor de los datos actuales <br> para actualizar.",
                        icon: "info",
                    });
                } else if (result.isDenied) {
                    setbShowRConyuge(false);
                    const selectedRowNew = prospectoNewOriginador.conyuge;

                    const entConyuge = {
                        aniosResidencia: selectedRowNew.aniosResidencia === null || selectedRowNew.aniosResidencia === "" ? 0 : parseInt(selectedRowNew.aniosResidencia),
                        apellidoMaterno: selectedRowNew.apellidoMaterno,
                        apellidoPaterno: selectedRowNew.apellidoPaterno,
                        curp: selectedRowNew.curp,
                        fechaActualizacion: new Date().toJSON(),
                        fechaNacimiento: new Date().toJSON(),
                        id: null,
                        nacionalidad: {
                            id: selectedRowNew.nacionalidad.id,
                            valor: selectedRowNew.nacionalidad.valor
                        },
                        nombre: selectedRowNew.nombre,
                        noProspecto: prospectoActOriginador.noProspecto,
                        razonSocial: selectedRowNew.nombre + ' ' + selectedRowNew.apellidoPaterno + ' ' + selectedRowNew.apellidoMaterno,
                        rfc: selectedRowNew.rfc,
                        usuarioActualiza: null,
                        usuarioRegistra: "gerardo.duarte@dinercap.com"
                    }
                    const updatedArray = [...mArrayConyuge, entConyuge];
                    setmArrayConyuge(updatedArray);

                    Swal.fire({
                        title: "Agregar",
                        html: "El registro de conyuge se va a agregar con la nueva información.",
                        icon: "info",
                    });
                }
            });
        }
        else {
            if (mArrayConyuge.length != newSelection.length) {
                let foundItem = null;
                let itemRemove = null;
                for (const item of mArrayConyuge) {
                    foundItem = newSelection.find(element => element === item.iIdConyugue);
                    if (!foundItem) {
                        itemRemove = item;
                    }
                }
                const nuevoArray = mArrayConyuge.filter(item => item.iIdConyugue !== itemRemove.iIdConyugue);
                setmArrayConyuge(nuevoArray);
                // const itemEliminar = mArrayConyuge.filter(elemento => !newSelection.includes(itemAdd));
                // const nuevoArray = mArrayConyuge.filter(item => item !== itemEliminar[0]);
                // setmArrayConyuge(nuevoArray);
                Swal.fire({
                    title: "Contacto eliminado",
                    html: "Se ha desmarcado el registro de conyugue para enviar al<br> originador se deberá marcar la opción nuevamente.",
                    icon: "info",
                    width: "30em",
                    confirmButtonText: "Ok",
                });
            }
        }
    }

    const handleAddToArrayConyuge = (idActualizar, itemAdd) => {

        const selectedRow = prospectoActOriginador.conyugue;
        const entConyuge =
        {
            iIdConyugue: itemAdd[0].iIdConyugue,
            aniosResidencia: selectedRow.aniosResidencia === null || selectedRow.aniosResidencia === "" ? 0 : parseInt(selectedRow.aniosResidencia),
            apellidoMaterno: selectedRow.apellidoMaterno,
            apellidoPaterno: selectedRow.apellidoMaterno,
            curp: selectedRow.curp,
            fechaActualizacion: null,
            fechaNacimiento: null,
            id: idActualizar,
            nacionalidad: {
                id: selectedRow.nacionalidad.id,
                valor: selectedRow.nacionalidad.valor
            },
            nombre: selectedRow.nombre,
            noProspecto: prospectoActOriginador.noProspecto,
            razonSocial: selectedRow.nombre + ' ' + selectedRow.apellidoPaterno + ' ' + selectedRow.apellidoMaterno,
            rfc: selectedRow.rfc,
            usuarioActualiza: null,
            usuarioRegistra: "gerardo.duarte@dinercap.com"
        }
        const updatedArray = [...mArrayConyuge, entConyuge];
        setmArrayConyuge(updatedArray);

        Swal.fire({
            title: "Actualizar",
            text: "El registro de conyuge se va a actualizar con la nueva información.",
            icon: "info",
        });
    }

    const handleCreateArrayDocs = async (value) => {
        await CreateArrayDocuments(value);
    };

    const CreateArrayDocuments = async (arrDocumentos) => {
        setmArrayDocumentos([]);
        arrDocumentos.map((datos) => {
            const documento = rowsDocumentos.find((row) => row.id === datos);
            const entDocumento = {
                iIdBloqueSolicitudCampo: documento.iIDBloqueSolicitudCampos,
                iIdDtoOriginador: parseInt(documento.selectedValue),
                iIdSolicitud: iIdSolicitudSeleccionado,
                iIDTipoSolicitud: tipoSolicitudSeleccionado.iIDTipoSolicitud
            }
            const updatedArray = [...mArrayDocumentos, entDocumento];
            setmArrayDocumentos(updatedArray);
        })
    }

    const handleSubmitFormProspecto = async (e) => {
        const {
            chkNombre,
            chkAP, chkAM, chkCURP, chkFecNac, chkRFC, chkGenero, chkEC, chkDepEco, chkNivEst, chkNacionalidad,
            chkTipoPF, chkAR, chkPaisNac, chkEntNac, chkGiroMerc, chkActSol, chkPersonaPE,
        } = formEnvioDatosOriginador;

        const prospectoInfo = {
            actSolId: chkActSol.value ? prospectoNewOriginador.prospecto.actividadSolicitante.id : prospectoActOriginador.actividadSolicitante != null ? prospectoActOriginador.actividadSolicitante.id : 0,
            actSolVal: chkActSol.value ? prospectoNewOriginador.prospecto.actividadSolicitante.valor : prospectoActOriginador.actividadSolicitante != null ? prospectoActOriginador.actividadSolicitante.valor : "",
            aniosResidencia: chkAR.value ? prospectoNewOriginador.prospecto.aniosResidencia : prospectoActOriginador.aniosResidencia != null ? prospectoActOriginador.aniosResidencia : 0,
            apellidoP: chkAP.value ? prospectoNewOriginador.prospecto.apellidoPaterno : prospectoActOriginador.apellidoPaterno,
            apellidoM: chkAM.value ? prospectoNewOriginador.prospecto.apellidoMaterno : prospectoActOriginador.apellidoMaterno,
            curp: chkCURP.value ? prospectoNewOriginador.prospecto.curp : prospectoActOriginador.curp,
            depEco: chkDepEco.value ? prospectoNewOriginador.prospecto.dependientesEconomicos : prospectoActOriginador.dependientesEconomicos != null ? prospectoActOriginador.dependientesEconomicos : 0,
            edoCivilId: chkEC.value ? prospectoNewOriginador.prospecto.edoCivil.id : prospectoActOriginador.edoCivil != null ? prospectoActOriginador.edoCivil.id : 0,
            edoCivilValor: chkEC.value ? prospectoNewOriginador.prospecto.edoCivil.valor : prospectoActOriginador.edoCivil != null ? prospectoActOriginador.edoCivil.valor : "",
            entNacId: chkEntNac.value ? prospectoNewOriginador.prospecto.entidadNacimiento.id : prospectoActOriginador.entidadNacimiento != null ? prospectoActOriginador.entidadNacimiento.id : 0,
            entNacValor: chkEntNac.value ? prospectoNewOriginador.prospecto.entidadNacimiento.valor : prospectoActOriginador.entidadNacimiento != null ? prospectoActOriginador.entidadNacimiento.valor : "",
            fecNac: chkFecNac.value ? prospectoNewOriginador.prospecto.fechaNacimiento : prospectoActOriginador.fechaNacimiento,
            generoId: chkGenero.value ? prospectoNewOriginador.prospecto.genero.id : prospectoActOriginador.genero != null ? prospectoActOriginador.genero.id : 0,
            generoValor: chkGenero.value ? prospectoNewOriginador.prospecto.genero.valor : prospectoActOriginador.genero != null ? prospectoActOriginador.genero.valor : "",
            giroMercId: chkGiroMerc.value ? prospectoNewOriginador.prospecto.giroMercantil.id : prospectoActOriginador.giroMercantil != null ? prospectoActOriginador.giroMercantil.id : 0,
            giroMercValor: chkGiroMerc.value ? prospectoNewOriginador.prospecto.giroMercantil.valor : prospectoActOriginador.giroMercantil != null ? prospectoActOriginador.giroMercantil.valor : "",
            nacionalidadId: chkNacionalidad.value ? prospectoNewOriginador.prospecto.nacionalidad.id : prospectoActOriginador.nacionalidad != null ? prospectoActOriginador.nacionalidad.id : 0,
            nacionalidadValor: chkNacionalidad.value ? prospectoNewOriginador.prospecto.nacionalidad.valor : prospectoActOriginador.nacionalidad != null ? prospectoActOriginador.nacionalidad.valor : "",
            nivEstId: chkNivEst.value ? prospectoNewOriginador.prospecto.nivelEstudios.id : prospectoActOriginador.nivelEstudios != null ? prospectoActOriginador.nivelEstudios.id : 0,
            nivEstValor: chkNivEst.value ? prospectoNewOriginador.prospecto.nivelEstudios.valor : prospectoActOriginador.nivelEstudios != null ? prospectoActOriginador.nivelEstudios.valor : "",
            nombre: chkNombre.value ? prospectoNewOriginador.prospecto.nombre : prospectoActOriginador.nombre,
            paisNactId: chkPaisNac.value ? prospectoNewOriginador.prospecto.paisNacimiento.id : prospectoActOriginador.paisNacimiento != null ? prospectoActOriginador.paisNacimiento.id : 0,
            paisNacValor: chkPaisNac.value ? prospectoNewOriginador.prospecto.paisNacimiento.valor : prospectoActOriginador.paisNacimiento != null ? prospectoActOriginador.paisNacimiento.valor : "",
            ppe: chkPersonaPE.value ? prospectoNewOriginador.prospecto.ppe : prospectoActOriginador.ppe,
            RFC: chkRFC.value ? prospectoNewOriginador.prospecto.rfc : prospectoActOriginador.rfc,
            tipoPFId: chkTipoPF.value ? prospectoNewOriginador.prospecto.tipoProspecto.id : prospectoActOriginador.tipoProspecto != null ? prospectoActOriginador.tipoProspecto.id : 0,
            tipoPFValor: chkTipoPF.value ? prospectoNewOriginador.prospecto.tipoProspecto.valor : prospectoActOriginador.tipoProspecto != null ? prospectoActOriginador.tipoProspecto.valor : "",
        }

        const entProspecto = {
            iIdSolicitud: iIdSolicitudSeleccionado,
            iIdTipoSolicitud: tipoSolicitudSeleccionado.iIDTipoSolicitud,
            bEnviaDocumentos: bchkEnviaDocumentos,
            datosProspecto: {
                prospecto: {
                    actividadSolicitante: {
                        id: prospectoInfo.actSolId,
                        valor: prospectoInfo.actSolVal,
                    },
                    aniosResidencia: parseInt(prospectoInfo.aniosResidencia),
                    apellidoMaterno: prospectoInfo.apellidoP,
                    apellidoPaterno: prospectoInfo.apellidoM,
                    contactos: miArray.length > 0 ? miArray : prospectoActOriginador.contactos,
                    conyugue: mArrayConyuge[0],
                    curp: prospectoInfo.curp,
                    dependientesEconomicos: prospectoInfo.depEco != null ? parseInt(prospectoInfo.depEco) : 0,
                    direcciones: mArrayDireccion.length > 0 ? mArrayDireccion : mArrayDireccionAct,
                    edoCivil: {
                        id: prospectoInfo.edoCivilId,
                        valor: prospectoInfo.edoCivilValor
                    },
                    email1: null,
                    email2: null,
                    entidadNacimiento: {
                        id: prospectoInfo.entNacId,
                        valor: prospectoInfo.entNacValor
                    },
                    esCliente: null,
                    fechaActualizacion: new Date().toJSON(),
                    fechaConstitucion: null,
                    fechaNacimiento: prospectoInfo.fecNac,
                    fechaRegistro: new Date().toJSON(),
                    fiel: "",
                    genero: {
                        id: prospectoInfo.generoId,
                        valor: prospectoInfo.generoValor
                    },
                    giroMercantil: {
                        id: prospectoInfo.giroMercId,
                        valor: prospectoInfo.giroMercValor
                    },
                    idUsuarioActualiza: "gerardo.duarte@dinercap.com",
                    idUsuarioRegistra: "gerardo.duarte@dinercap.com",
                    listaProspectoEmpleo: [],
                    nacionalidad: {
                        id: prospectoInfo.nacionalidadId,
                        valor: prospectoInfo.nacionalidadValor
                    },
                    nivelEstudios: {
                        id: prospectoInfo.nivEstId,
                        valor: prospectoInfo.nivEstValor
                    },
                    nombre: prospectoInfo.nombre,
                    noProspecto: prospectoActOriginador.noProspecto,
                    paisConstitucion: null,
                    paisNacimiento: {
                        id: prospectoInfo.paisNactId,
                        valor: prospectoInfo.paisNacValor
                    },
                    ppe: prospectoInfo.ppe === '' ? null : Boolean(String(prospectoInfo.ppe).toLowerCase() == 'true' ? true : false),
                    prospectoAval: {
                        antiguedad: null,
                        ingresosMensuales: null,
                        libreGravamen: null,
                        noProspecto: prospectoActOriginador.noProspecto,
                        tienePropiedad: null,
                        tipoActividad: null
                    },
                    razonSocial: prospectoInfo.nombre + ' ' + prospectoInfo.apellidoP + ' ' + prospectoInfo.apellidoM,
                    relacionadosOk: [],
                    rfc: prospectoInfo.RFC,
                    telCel: null,
                    telFijo: null,
                    tipoProspecto: {
                        id: prospectoInfo.tipoPFId,
                        valor: prospectoInfo.tipoPFValor
                    }
                },
                prospectoAval: {
                    antiguedad: null,
                    ingresosMensuales: null,
                    libreGravamen: null,
                    noProspecto: prospectoActOriginador.noProspecto,
                    tienePropiedad: null,
                    tipoActividad: null
                }
            },
            documentos: mArrayDocumentos
        };

        await fn_updateProspecto(entProspecto);
    }

    const fn_updateProspecto = async (entProspecto) => {
        let loader = "Actualizando información en originador";
        const payload = {
            loader: loader,
            body: entProspecto,
            url: apiCUpdateProspecto,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            handleCloseFormEnvioOriginador();
            Swal.fire({
                title: "Envío a originador",
                text: "El registro se ha actualizado en el originador.",
                icon: "info",
            });

            if (response.message != "") {
                Swal.fire({
                    title: "Documentos",
                    text: response.message,
                    icon: "warning",
                });
            }
        }
    };

    //==========================================================================

    const handleSubmitFormCpColonia = async (e) => {
        fnPrevent(e);

        const { cmbBoxColonias, txtCodigoPostal } = formCPColonia;

        const form = {
            ...formCPColonia,
            cmbBoxColonias: {
                ...cmbBoxColonias,
                error: cmbBoxColonias.value === 0,
            },
            txtCodigoPostal: {
                ...txtCodigoPostal,
                error: txtCodigoPostal.value === "",
            },
        };

        setFormCPColonia(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }

        const entCamposValor = {
            iIdTipoSolicitud: tipoSolicitudSeleccionado.iIDTipoSolicitud,
            iIdSolicitud: solicitudSeleccionado.iIdSolicitud,
            sCp: txtCodigoPostal.value,
            sColonia: cmbBoxColonias.value,
            iIDUsuarioModificacion: 1,
        };
        await fn_updateValorCampoDir(entCamposValor);
    };

    const fn_updateValorCampoDir = async (entCamposValor) => {
        let loader = "Actualizando valor del campo";

        const payload = {
            loader: loader,
            body: entCamposValor,
            url: apiCUpdateDatosDirCampo,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            //Limpiar campos y busqueda de seguimiento solicitud detalle
            setColonias([]);
            setFormCPColonia(getFormCPColonia());
            handleChangeGetListaSolicitudDetail();
        }
    };

    const fnObtenerPDFCreado = async (request) => {
        const payload = {
            loader: "Generando PDF de la solicitud...",
            url: apiSolicitudDisplayPDF
                .replace("{piIdSolicitud}", request.iIdSolicitud)
                .replace("{piIdTipoSolicitud}", request.iIDTipoSolicitud)
                .replace("{piIdFormato}", request.iIdFormarto),
            alert: true,
            extension: "pdf",
            nombre: request.nombre,
        };
        await DownloadFileBlob(payload);
    };

    const handleChangeCreateReporteCreditoPDF = async () => {
        fnPrevent();
        const payload = {
            loader: "Obteniendo pdf...",
            url: apiCGetVerificarReporteCreditoExistente
                .replace("{piIDSolicitud}", solicitudSeleccionado.iIdSolicitud),
            alert: false
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            if (response.result.iidarchivo > 0) {
                Swal.fire({
                    title: "Aviso",
                    text: "Ya existe un reporte, ¿desea generar uno nuevo?",
                    icon: "info",
                    showCloseButton: true,
                    showDenyButton: true,
                    confirmButtonText: "Generar uno nuevo",
                    confirmButtonColor: "#3085d6",
                    denyButtonText: "Mostrar actual",
                    denyButtonColor: '#8CD4F5',
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        fnObtenerPDFReporteCredito(usuarioOriginador.iIdUsuario, true);
                    } else if (result.isDenied) {
                        fnObtenerPDFReporteCredito(usuarioOriginador.iIdUsuario, false);
                    }
                });
            }
            else {
                fnObtenerPDFReporteCredito(usuarioOriginador.iIdUsuario, false);
            }
        } else {
            handleOpenAlert(response.message, "error");
        }
    };




    const fnObtenerDetalleSolicitud = async () => {
        const payload = {
            loader: "Obteniendo detalles de la solicitud...",
            url: apiSolicituDetalle
                .replace("{piIDSolicitud}", solicitudSeleccionado.iIdSolicitud)
                .replace("{piIDTipoSolicitud}", tipoSolicitudSeleccionado.iIDTipoSolicitud),
            alert: true,
        };
        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setListaDetalleSolicitud(response.result);
        } else {
            setListaDetalleSolicitud([]);
        }
    };

    const fnObtenerDetalleDoctosSolicitud = async (pIdSolicitud, pIdTipoSolicitud) => {
        const payload = {
            loader: "Obteniendo lista de documentos de la solicitud...",
            url: apiSolicituDetalle
                .replace("{piIDSolicitud}", pIdSolicitud)
                .replace("{piIDTipoSolicitud}", pIdTipoSolicitud),
            alert: true,
        };
        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setListaDetalleSolicitud(response.result);
        } else {
            setListaDetalleSolicitud([]);
        }
    };

    const fnModificarEstadoSolicitud = async (request) => {
        const payload = {
            loader: "Guardando cambio de estado de la solcitud...",
            url: apiSolicitudChangeStatus,
            body: request,
            alert: true,
        };
        const response = await Post(payload);
        if (response.iCodigo === 201) {
            //Información incompleta
            await fnObtenerDetalleSolicitud();
        }
    };

    // const fnObtenerEstadosSolicitud = async () => {
    //     const payload = {
    //         loader: "Consultando estados de solicitud...",
    //         url: apiSolicitudGetGetEstadosByOrigen,
    //         alert: false,
    //     };
    //     const response = await Get(payload);
    //     if (response.iCodigo === 201) {
    //         setListaEstadosSolicitud(response.result);
    //     } else {
    //         setListaEstadosSolicitud([]);
    //     }
    // };

    const fnObtenerEstadosSolicitudUpd = async () => {
        const payload = {
            loader: "Consultando estados de solicitud...",
            url: apiCGetEstastusbyUpdCP,
            alert: false,
        };
        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setListaEstatusUpd(response.result);
        } else {
            setListaEstatusUpd([]);
        }
    };

    const fnObtenerEstadosPorTipoSolicitud = async (iIDTipoSolicitud) => {
        const payload = {
            loader: "Consultando estados de solicitud...",
            url: apiCGetEstadosRelacionByTipoSolicitud.replace("{piIDTipoSolicitud}", iIDTipoSolicitud),
            alert: false,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            const listaEstados = Enumerable.from(response.result)
                .select((item) => ({
                    iIdEstatus: item.iIdEstatus,
                    sNombre: item.sNombre,
                    iIDTipoSolicitud: item.iIDTipoSolicitud,
                    iIdPadre: item.iIdPadre,
                }))
                .toArray();
            setListaEstadosSolicitud(listaEstados);
        } else {
            setListaEstadosSolicitud([]);
        }
    };

    const fnObtenerSolicitudes = async (value, bEstatus) => {
        const payload = {
            loader: "Consultando solicitudes...",
            url: apiSolicitudGetSolicitudes.replace("{iIDTipoSolicitud}", value).replace("{bAllEstatus}", bEstatus),
            alert: false,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setListaSolicitudes(response.result);
            setUpdateData(true);

        } else {
            setListaSolicitudes([]);
            setUpdateData(true);
        }
    };

    // #region Formulario dinámico
    const handleOpenFormAprobado = async (solicitud, iIdEstadoSeleccionado) => {
        // Obtener los datos del formulario de solicitud aprobada...
        const infoSolicitudAprobada = await fnObtenerFormulario(solicitud.iIdTipoSolicitud);
        if (infoSolicitudAprobada !== null) {
            const infoForm = Enumerable.from(infoSolicitudAprobada)
                .select((item, index) => ({
                    displayName: item.sDescripcion,
                    displayOrder: index + 1,
                    required: true,
                    elementId: item.inputform.sNombre + "_" + String(item.iIdInfosolicitudaprobada),
                    type: item.inputform.sNombre,
                    readOnly: false,
                    isHidden: () => false,
                    validation: item.jReglas !== null ? JSON.parse(item.jReglas) : [],
                }))
                .toArray();
            setListaForm({
                formId: "FORM01",
                name: "Solicitud Aprobada",
                formElements: infoForm,
            });
            setSolicitudSeleccionado(solicitud);
            setEstadoSeleccionado(iIdEstadoSeleccionado);
            setOpenFormAprobado(true);
        }
    };

    const handleCloseFormAprobado = () => {
        setOpenFormAprobado(false);
    };

    const fnObtenerFormulario = async (iIdTipoSolicitud) => {
        let infoForm = null;
        try {
            const payload = {
                loader: "Obteniendo formulario",
                url: apiInfoSolicitudAprobada.replace("{iIdTipoSolicitud}", iIdTipoSolicitud),
                alert: false,
            };

            const response = await Get(payload);
            if (response.iCodigo === 201) {
                infoForm = response.result.length > 0 ? response.result : null;

                if (infoForm === null) {
                    handleOpenAlert("No se pudo obtener el formulario", "error");
                }
            } else {
                handleOpenAlert("No se pudo obtener el formulario", "error");
            }
        } catch (error) {
            handleOpenAlert(error.message, "error");
        }

        return infoForm;
    };

    const handleChangeStatusSolicitud = async (iIdEstatus, iIdSolicitud, iIdTipoSolicitud) => {
        const payload = {
            loader: "Cambiando estatus",
            url: apiChangeStatusSolicitud,
            body: {
                iIDestatus: iIdEstatus,
                iIdSolicitud: iIdSolicitud,
                iIdTipoSolicitud: iIdTipoSolicitud,
                iIDUsuarioModificacion: 100,
            },
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            await fnObtenerSolicitudes(tipoSolicitudSeleccionadoSelect.iIDTipoSolicitud, bAllEstatus);
            setEstadoSeleccionado(null);
        }
    };

    const handleSaveInfoSolicitudAprobada = async (data) => {
        try {
            let controles = Object.keys(data);
            let info = [];

            controles.forEach(function (control) {
                info.push({
                    iIDUsuarioCreacion: 1,
                    iIdinfosolicitudaprobada: parseInt(String(control).split("_")[1], 10),
                    iIdsolicitud: solicitudSeleccionado.iIdSolicitud,
                    sValor: data[String(control)] !== undefined ? data[String(control)] : "",
                });
            });

            const payload = {
                loader: "Guardando información",
                url: apiSaveInfoSolicitudAprobada,
                body: info,
                alert: true,
            };

            const response = await Post(payload);
            if (response.iCodigo === 201) {
                // Cambiar el status...
                await handleChangeStatusSolicitud(
                    estadoSeleccionado,
                    solicitudSeleccionado.iIdSolicitud,
                    tipoSolicitudSeleccionado.iIDTipoSolicitud
                );
            }
        } catch (error) {
            handleOpenAlert(error.message, "error");
        }
    };

    const handleObtenerInfoSolicitudAprobada = async (e, iIdSolicitud) => {
        e.preventDefault();
        const payload = {
            loader: "Obteniendo información",
            url: apiCGetSolicitudAprobada.replace("{piIdSolicitud}", iIdSolicitud),
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            const info = Enumerable.from(response.result)
                .select((item) => ({
                    iIdsolicitudesaprobadas: item.iIdsolicitudesaprobadas,
                    sValor: item.sValor !== null ? item.sValor : "",
                    catInfo: {
                        sDescripcion: item.catInfo.sDescripcion,
                        sTipoDato: item.catInfo.tipodato.sNombre,
                        sInputForm: item.catInfo.inputform.sNombre,
                    },
                }))
                .toArray();

            let sHtml = "";
            info.forEach(function (item) {
                sHtml +=
                    "<b>" +
                    item.catInfo.sDescripcion +
                    ": </b>" +
                    fnFormatoValor(item.sValor, item.catInfo.sTipoDato) +
                    "<br><br>";
            });

            Swal.fire({
                // title: "Información ",
                icon: "info",
                html: sHtml,
                showCloseButton: true,
                focusConfirm: false,
                showConfirmButton: false,
            });
        } else {
            handleOpenAlert(response.sMensaje, "error");
        }
    };

    const fnFormatoValor = (text, tipoDato) => {
        let sValor = null;

        switch (tipoDato) {
            case "MONEDA":
                const formatterDolar = new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                });

                sValor = formatterDolar.format(text);
                break;
            case "PORCENTAJE":
                sValor = text + "%";
                break;
            default:
                sValor = text;
                break;
        }

        return sValor;
    };
    // #endregion

    // #region Info solicitud rechazada
    const handleOpenFormRechazado = async (solicitud, iIdEstadoSeleccionado) => {
        let listaCausas = await fnObtenerInfoSolicitudRechazada(solicitud.iIdTipoSolicitud);
        if (listaCausas !== null) {
            listaCausas = Enumerable.from(listaCausas)
                .select((item) => ({
                    id: item.iIdInfosolicitudrechazada,
                    text: item.sDescripcion,
                }))
                .toArray();
            await setListaCausasRechazo(listaCausas);
            setOpenFormRechazado(true);
            setSolicitudSeleccionado(solicitud);
            setEstadoSeleccionado(iIdEstadoSeleccionado);
        }
    };

    const handleCloseFormRechazado = async () => {
        setOpenFormRechazado(false);
    };

    const fnObtenerInfoSolicitudRechazada = async (iIDTipoSolicitud) => {
        let infoForm = null;
        try {
            const payload = {
                loader: "Obteniendo información",
                url: apiGetInfoSolicitudRechaza.replace("{iIdTipoSolicitud}", iIDTipoSolicitud),
                alert: false,
            };

            const response = await Get(payload);
            if (response.iCodigo === 201) {
                infoForm = response.result.length > 0 ? response.result : null;

                if (infoForm === null) {
                    handleOpenAlert("No se pudo obtener las causas del rechazo.", "error");
                }
            } else {
                handleOpenAlert("No se pudo obtener las causas del rechazo.", "error");
            }
        } catch (error) {
            handleOpenAlert(error.message, "error");
        }

        return infoForm;
    };

    const handleSaveInfoSolicitudRechazada = async (data) => {
        try {
            let body = [];
            body.push({
                iIDUsuarioCreacion: 1,
                iIdinfosolicitudrechazada: data.sCausa,
                iIdsolicitud: solicitudSeleccionado.iIdSolicitud,
            });

            const payload = {
                loader: "Guardando la información",
                body: body,
                url: apiSaveSolicitudRechazada,
                alert: true,
            };

            const response = await Post(payload);
            if (response.iCodigo === 201) {
                // Cambiar el status...
                await handleChangeStatusSolicitud(
                    estadoSeleccionado,
                    solicitudSeleccionado.iIdSolicitud,
                    tipoSolicitudSeleccionado.iIDTipoSolicitud
                );
            }
        } catch (error) {
            handleOpenAlert(error.message, "error");
        }
    };

    const handleObtenerInfoSolicitudRechazada = async (e, iIdSolicitud) => {
        e.preventDefault();
        const payload = {
            loader: "Obteniendo información",
            url: apiCGetSolicitudRechazada.replace("{piIdSolicitud}", iIdSolicitud),
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            if (response.result.length > 0) {
                const info = Enumerable.from(response.result)
                    .select((item) => ({
                        iIdsolicitudesrechazadas: item.iIdsolicitudesrechazadas,
                        sDescripcion: item.catInfo.sDescripcion,
                    }))
                    .toArray();

                let sHtml = "";
                info.forEach(function (item) {
                    sHtml += "<b>" + "Causa: " + "</b>" + item.sDescripcion + "<br>";
                });

                Swal.fire({
                    // title: "Información ",
                    icon: "info",
                    html: sHtml,
                    showCloseButton: true,
                    focusConfirm: false,
                    showConfirmButton: false,
                });
            } else {
                handleOpenAlert("No se encontró información para la solicitud seleccionada", "error");
            }
        } else {
            handleOpenAlert(response.sMensaje, "error");
        }
    };
    // #endregion Info solicitud rechazada

    // #region Info Buró
    const handleObtenerInformacionBuro = async (event, iIdSolicitud) => {
        event.preventDefault();
        const payload = {
            loader: "Obteniendo la información del buró de crédito",
            url: apiCGetSolicitudBuro.replace("{piIdSolicitud}", iIdSolicitud),
            alert: true,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            const infoBuro = response.result[0];

            let sHtml = "";
            let texto = infoBuro.bBuroTitularcredit
                ? "SI " + " - Ultimos 4 dígitos: " + infoBuro.sBuroTarjetadigitos
                : "NO";
            sHtml =
                "¿Es usted titular de una tarjeta de crédito vigente? <br>" +
                "<b>R: " +
                texto +
                "</b><br>" +
                "¿Actualmente es titular y está pagando algún crédito hipotecario? <br>";
            texto = infoBuro.bBuroCredhipo ? "SI" : "NO";
            sHtml +=
                "<b>R: " +
                texto +
                "</b><br>" +
                "¿Ha sido usted titular de algún crédito automotriz en los últimos 24 meses?<br>";
            texto = infoBuro.bBuroCredauto ? "SI" : "NO";
            sHtml += "<b>R: " + texto + "</b>";

            Swal.fire({
                title: "Información",
                // icon: "info",
                html: sHtml,
                showCloseButton: true,
                focusConfirm: false,
                showConfirmButton: false,
            });
        }
    };
    // #endregion

    // #region Envio Datos Originador actualizar
    const handleOpenFormEnvioOriginador = async () => {
        setOpenFormEnvioOriginador(true);
    };

    const handleCloseFormEnvioOriginador = () => {
        setOpenFormEnvioOriginador(false);
        setbAllChecks(false);
        setbShowRadioButton(false);
        setbShowRDireccion(false);
        setbShowRConyuge(false);
        setbchkEnviaDocumentos(false);
        setMiArray([]);
        setmArrayDireccion([]);
        setmArrayConyuge([]);
        setmArrayDocumentos([]);
        setRowsDocumentos([]);
        setFormEnvioDatosOriginador(getFormEnvOriginador());
        setFormEnvioDatosNOriginador(getFormEnvNOriginador());
        setProspectoNewOriginador(null);
        setProspectoActOriginador(null);
        setiIdSolicitudSeleccionado(0);
    }

    const handleChangeTabIndex = (value) => {
        setFormTabIndex(value);
    };

    const handleChangeFormCampoEnvOrCheck = fnHandleChangeChek(
        formEnvioDatosOriginador,
        setFormEnvioDatosOriginador
    );

    // #endregion
    const handleClickNotificarCliente = async () => {
        //iIdEstatus, iIdSolicitud, iIdTipoSolicitud
        await handleChangeStatusSolicitud(
            7,
            solicitudSeleccionado.iIdSolicitud,
            tipoSolicitudSeleccionado.iIDTipoSolicitud
        );
        setSolicitudSeleccionado({ ...solicitudSeleccionado, iIDestatus: 7 });
    };

    const handleClickMostrarHistorial = async (e, rfc) => {
        e.preventDefault();
        const payload = {
            loader: "Consultando historial",
            url: apiCGetSolicitudesByRFC.replace("{rfc}", rfc),
            alert: true,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            if (response.result !== null) {
                let rows = "";

                response.result.forEach((item) => {
                    rows +=
                        "<tr>" +
                        "<td>" +
                        item.iIdSolicitud +
                        "</td>" +
                        "<td>" +
                        item.tTipoSolicitud.sDescripcion +
                        "</td>" +
                        "<td>" +
                        new Date(item.dtFechaCreacion).toLocaleDateString("en-GB") +
                        "</td>" +
                        "<td>" +
                        item.eEstados.sNombre +
                        "</td>" +
                        "<td>" +
                        (String(item.dtFechaModificacion) !== "0001-01-01T00:00:00"
                            ? new Date(item.dtFechaModificacion).toLocaleDateString("en-GB")
                            : "") +
                        "</td>" +
                        "</tr>";
                });

                const html =
                    "<div class='table-responsive'>" +
                    "<table class='table table-hover'>" +
                    "<thead>" +
                    "<tr>" +
                    "<th>FOLIO</th>" +
                    "<th>SOLICITUD</th>" +
                    "<th>FECHA CREACION</th>" +
                    "<th>ESTATUS</th>" +
                    "<th>ULTIMA MODIFICACION</th>" +
                    "</tr>" +
                    "</thead>" +
                    "<tbody>" +
                    rows +
                    "</tbody>" +
                    "</table>" +
                    "</div>";

                Swal.fire({
                    title: "<strong>Historial de solicitudes de: " + rfc + "</strong>",
                    html: html,
                    showCloseButton: false,
                    showCancelButton: false,
                    focusConfirm: false,
                    width: "60em",
                    confirmButtonText: "Aceptar",
                });
            } else {
                handleOpenAlert("No se encontró historial para mostrar");
            }
        }
    };

    const handleOpenModalPruebaVida = (value) => {
        setOpenModalPruebaVida(value);
    };

    const handleChangeAllEstatus = async (e) => {
        await setbAllEstatus(e.target.checked);
        await fnObtenerSolicitudes(tipoSolicitudSeleccionadoSelect.iIDTipoSolicitud, e.target.checked);
    };

    const handleChangeEnviaDocumentos = async (e) => {
        await setbchkEnviaDocumentos(e.target.checked);
    };

    const handleChangeAllChecks = (e) => {
        const {
            chkNombre,
            chkAP, chkAM, chkCURP, chkFecNac, chkRFC, chkGenero, chkEC, chkDepEco, chkNivEst, chkNacionalidad,
            chkTipoPF, chkAR, chkPaisNac, chkEntNac, chkGiroMerc, chkActSol, chkPersonaPE,
        } = formEnvioDatosOriginador;
        setbAllChecks(e.target.checked);

        chkNombre.value = e.target.checked;
        chkAP.value = e.target.checked;
        chkAM.value = e.target.checked;
        chkCURP.value = e.target.checked;
        chkFecNac.value = e.target.checked;
        chkRFC.value = e.target.checked;
        chkGenero.value = e.target.checked;
        chkEC.value = e.target.checked;
        chkDepEco.value = e.target.checked;
        chkNivEst.value = e.target.checked;
        chkNacionalidad.value = e.target.checked;
        chkTipoPF.value = e.target.checked;
        chkAR.value = e.target.checked;
        chkPaisNac.value = e.target.checked;
        chkEntNac.value = e.target.checked;
        chkGiroMerc.value = e.target.checked;
        chkActSol.value = e.target.checked;
        chkPersonaPE.value = e.target.checked;
    };

    const handleObtenerCamposAutoFill = async () => {
        const payload = {
            loader: "Obteniendo campos autofill...",
            url: apiCGetCamposAutoFill,
            alert: false,
        };
        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setListaCamposAutoFill(response.result);
        } else {
            setListaCamposAutoFill([]);
        }
    };

    const handleChangeData = async (value) => {
        setUpdateData(value);
    };

    return (
        <SolicitudContext.Provider
            value={{
                listaSolicitudes,
                updateData,
                tipoSolicitudSeleccionado,
                tipoSolicitudSeleccionadoSelect,
                solicitudSeleccionado,
                modalOpenSolicitudDetalle,
                listaEstadosSolicitud,
                listaDetalleSolicitud,
                listaForm,
                listaCausasRechazo,
                listaColonias,
                listaEstatusUpd,
                openFormAprobado,
                openFormRechazado,
                openModalPruebaVida,
                openFormEnvioOriginador,
                bAllEstatus,
                bAllCheks,
                bShowRadioButton,
                bShowRDireccion,
                bShowRConyuge,
                bchkEnviaDocumentos,
                listaCamposAutoFill,
                listaContactos,
                listaContactosNuevo,
                listaDireccion,
                listaDireccionNuevo,
                listaConyugue,
                listaConyugueNuevo,
                miArray,
                mArrayDireccion,
                mArrayConyuge,
                mArrayDocumentos,
                rowsDocumentos,
                buroSolicitud,
                scoreSolicitud,
                disabledBtnReporteCredito,
                formEnvioDatosOriginador,
                formEnvioDatosNOriginador,
                formTabIndex,
                formCPColonia,
                selectedRowsDireccion,
                selectedRowsConyuge,
                selectedRowsContactos,
                selectedRowsDocumentos,
                handleGetScoreSolicitud,
                handleOpenModalSolicitudDetalle,
                handleChangeTipoSolicitudSeleccionado,
                handleChangeTipoSolicitudSeleccionadoSelect,
                handleChangeGetListaSolicitudDetailDocs,
                handleChangeSolicitudSeleccionado,
                handleClickObtenerSolicitudes,
                handleClickSolicitud,
                handleChangeEstadosSolicitud,
                handleGetEstadosSolicitudUpd,
                handleSubmitUpdEstadoSolicitud,
                handleSubmitFormProspecto,
                handleSubmitFormCpColonia,
                handleChangeGetListaSolicitudDetail,
                handleChangeCreatePDF,
                handleGetExisteProspecto,
                handleGetExisteProspectoCURP,
                handleGetProspectoNuevoActual,
                handleOpenFormAprobado,
                handleCloseFormAprobado,
                handleOpenFormEnvioOriginador,
                handleCloseFormEnvioOriginador,
                handleChangeTabIndex,
                handleChangeCheck,
                handleChangeFormCampoEnvOrCheck,
                handleChangeFormCPColonia,
                handleGetBuroSolicitud,
                handleChangeStatusSolicitud,
                handleSaveInfoSolicitudAprobada,
                handleObtenerInfoSolicitudAprobada,
                handleOpenFormRechazado,
                handleCloseFormRechazado,
                handleSaveInfoSolicitudRechazada,
                handleObtenerInfoSolicitudRechazada,
                handleObtenerInformacionBuro,
                handleClickNotificarCliente,
                handleEnviarSolicitudOriginador,
                handleClickMostrarHistorial,
                handleOpenModalPruebaVida,
                handleChangeAllEstatus,
                handleChangeAllChecks,
                handleChangeEnviaDocumentos,
                handleObtenerCamposAutoFill,
                handleChangeCreateReporteCreditoPDF,
                handleGetInfoFinancieraByIdSolicitud,
                handleAddToArray,
                handleAddToArrayDir,
                handleAddToArrayConyuge,
                handlecreateData,
                handleSelectChange,
                handleCreateArrayDocs,
                handleObtenerColoniasxCP,
                handleChangeData,
                handleSelectionModelChange,
            }}
        >
            {props.children}
        </SolicitudContext.Provider>
    );
}

export function useSolicitud() {
    return useContext(SolicitudContext);
}
