export const urlBase = ""; //Produccion,Pre,QA

export const urlSystem = {
    login: "/login",
    tiposSolicitudes: "/tipos_solicitudes",
    configuracionSolicitud: "/configuracion_solicitud/:bMostrarTipoSolicitud",
    formatosSolicitud: "/formatos_solicitud/:bMostrarTipoSolicitud",
    seguimientosSolicitud: "/seguimientos_solicitud/:bMostrarTipoSolicitud",
    configuracionOCR: "/configuracion_ocr/:bMostrarTipoSolicitud",
    configuracionDatos: "/configuracion_datos/:bMostrarTipoSolicitud",
    catalogosGenerales: "/catalogos",
};

export const urlDefault = "/";
