import { createContext, useContext } from "react";

import { getResponseModel } from "../models/ResponseModel";
import { apiAuthenticate } from "../settings/apiConfig";
import { serverMain } from "../settings/serverConfig";
import { useLayout } from "./LayoutContext";
import { useToken } from "./TokenContext";
import axios from "axios";
import fileDownload from "js-file-download";

export const RequestContext = createContext();

export function RequestProvider(props) {
    const { appKey, appToken } = useToken();
    const { handleOpenAlert, handleLoader } = useLayout();

    const errorMessage = "Ocurrió un error al consumir el recurso solicitado";
    const errorService = "Ocurrió un error en el servicio solicitado. Intente más tarde";

    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoicmljYXJkbyIsImp0aSI6IjA3YmI5Zjk0LWYwNWItNGMxYy1hNjEwLTMyNzZlMmQ2ZGIwMiIsImV4cCI6MTY0NDgyODM0MSwiaXNzIjoiTXlJc3N1ZXIiLCJhdWQiOiJNeUlzc3VlciJ9.0ZQD44HY5tM3gsWoKUUOtcwAOfwAirYQY2vQIzkUYSc';

    const GetTokenLogin = async (payload) => {
        let response = getResponseModel();
        if (payload.loader) {
            handleLoader(payload.loader);
        }
        try {
            let usu = payload.body.sUsuario;
            let pass = payload.body.sPassword;
            const body = {
                username: usu,
                password: pass,
                tipoValidacion: 1,
            };
            const requestOptions = {
                body: JSON.stringify(body),
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const api = await fetch(serverMain + apiAuthenticate, requestOptions);
            let _response = await api.json();
            if (_response.hasOwnProperty("token")) {
                response.Code = 201;
                response.Message = "Login satisfactorio";
                response.Result = _response;
            } else {
                response.Code = 0;
                response.Message = "Error de autenticación";
                response.Result = null;
            }

            // sessionStorage.setItem("tkn", response.token);
            // sessionStorage.setItem("exptkn", response.expiration);
        } catch (error) {
            response.sCodigo = 500;
            response.sMensaje = errorMessage;
        }
        handleLoader();
        return response;
    };

    const GetToken = async () => {
        let response = getResponseModel();
        //Se busca la fecha de vencimiento para no regenerar el token varias veces
        let expiration = sessionStorage.getItem("exptkn");
        var date = new Date(expiration);
        var today = new Date();
        if (today > date) {
            try {
                let usu = sessionStorage.getItem("TknUsu");
                let pass = sessionStorage.getItem("TknPass");
                const body = {
                    username: usu,
                    password: pass,
                    tipoValidacion: 1,
                };
                const requestOptions = {
                    body: JSON.stringify(body),
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                };

                const api = await fetch(serverMain + apiAuthenticate, requestOptions);
                response = await api.json();
                sessionStorage.setItem("tkn", response.token);
                sessionStorage.setItem("exptkn", response.expiration);
            } catch (error) {
                response.sCodigo = 500;
                response.sMensaje = errorMessage;
            }
        }
        return response;
    };

    const Get = async (payload) => {
        let response = getResponseModel();
        if (payload.loader) {
            handleLoader(payload.loader);
        }
        try {
            await GetToken();
            let tokenUsuario = sessionStorage.getItem("tkn");
            const requestOptions = {
                method: "GET",
                body: JSON.stringify(payload.body),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tokenUsuario}`,
                },
            };

            const api = await fetch(serverMain + payload.url, requestOptions);
            response = await api.json();

            if (response.message) {
                response.sMensaje = response.message;
            }
            if (response.code) {
                response.iCodigo = response.code;
            }
            if (payload.alert) {
                if (response.iCodigo === 201) {
                    handleOpenAlert(response.sMensaje, "success");
                } else {
                    handleOpenAlert(response.sMensaje);
                }
            }
        } catch (error) {
            response.iCodigo = 500;
            response.sMensaje = errorMessage;
            //   if (payload.alert) {
            //     handleOpenAlert(response.sMensaje);
            //   }
            handleOpenAlert(response.sMensaje);
        }
        handleLoader();
        return response;
    };

    const GetFile = async (payload) => {
        let response = getResponseModel();
        if (payload.loader) {
            handleLoader(payload.loader);
        }
        try {
            const api = await fetch(serverMain + payload.url, {
                headers: {
                    AppKey: appKey,
                    AppToken: appToken,
                },
            });
            if (api.ok) {
                let file = await api.blob();
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(file);
                link.download = payload.filename;
                link.click();
                link.remove();
                response.sMensaje = "El archivo se ha descargado correctamente.";
                if (payload.alert) {
                    handleOpenAlert(response.sMensaje, "success");
                }
            } else {
                response.sCodigo = 500;
                response.sMensaje = errorService;
                // if (payload.alert) {
                //     handleOpenAlert(response.sMensaje);
                // }
                handleOpenAlert(response.sMensaje);
            }
        } catch (error) {
            response.iCodigo = 500;
            response.sMensaje = errorMessage;
            // if (payload.alert) {
            //     handleOpenAlert(response.sMensaje);
            // }
            handleOpenAlert(response.sMensaje);
        }
        handleLoader();
        return response;
    };

    const Post = async (payload) => {
        let response = getResponseModel();
        if (payload.loader) {
            handleLoader(payload.loader);
        }
        try {
            await GetToken();
            let tokenUsuario = sessionStorage.getItem("tkn");
            const api = await fetch(serverMain + payload.url, {
                method: "POST",
                body: JSON.stringify(payload.body),
                headers: {
                    "Content-Type": "application/json",
                    "X-Origin-Device": "2",
                    Authorization: `Bearer ${tokenUsuario}`,
                },
            });
            response = await api.json();
            if (response.message) {
                response.sMensaje = response.message;
            }
            if (response.code) {
                response.iCodigo = response.code;
            }
            if (payload.alert) {
                if (response.iCodigo === 201) {
                    handleOpenAlert(response.sMensaje, "success");
                } else {
                    handleOpenAlert(response.sMensaje);
                }
            }
        } catch (error) {
            response.sCodigo = 500;
            response.sMensaje = errorMessage;
            // if (payload.alert) {
            //     handleOpenAlert(response.sMensaje);
            // }
            handleOpenAlert(response.sMensaje);
        }
        handleLoader();
        return response;
    };

    const PostFile = async (payload) => {
        let response = getResponseModel();
        if (payload.loader) {
            handleLoader(payload.loader);
        }
        try {
            const api = await fetch(serverMain + payload.url, {
                method: "POST",
                body: payload.file,
                headers: {
                    "Content-Type": "application/json",
                    AppKey: appKey,
                    AppToken: appToken,
                },
            });
            response = await api.json();
            if (response.message) {
                response.sMensaje = response.message;
            }
            if (response.code) {
                response.iCodigo = response.code;
            }
            if (payload.alert) {
                if (response.iCodigo === 201) {
                    handleOpenAlert(response.sMensaje, "success");
                } else {
                    handleOpenAlert(response.sMensaje);
                }
            }
        } catch (error) {
            response.sCodigo = 500;
            response.sMensaje = errorMessage;
            // if (payload.alert) {
            //     handleOpenAlert(response.sMensaje);
            // }
            handleOpenAlert(response.sMensaje);
        }
        handleLoader();
        return response;
    };

    const PostFormData = async (payload) => {
        let response = getResponseModel();
        if (payload.loader) {
            handleLoader(payload.loader);
        }
        try {
            const api = await fetch(serverMain + payload.url, {
                method: "POST",
                body: payload.body,
                headers: {
                    AppKey: appKey,
                    AppToken: appToken,
                },
            });
            response = await api.json();
            if (response.message) {
                response.sMensaje = response.message;
            }
            if (response.code) {
                response.iCodigo = response.code;
            }
            if (payload.alert) {
                if (response.iCodigo === 201) {
                    handleOpenAlert(response.sMensaje, "success");
                } else {
                    handleOpenAlert(response.sMensaje);
                }
            }
        } catch (error) {
            response.sCodigo = 500;
            response.sMensaje = errorMessage;
            if (payload.alert) {
                handleOpenAlert(response.sMensaje);
            }
        }
        handleLoader();
        return response;
    };

    const DownloadFileBlob = async (payload) => {
        if (payload.loader) {
            handleLoader(payload.loader);
        }
        if (payload.alert) {
            await GetToken();
            let tokenUsuario = sessionStorage.getItem("tkn");
            axios
                .get(serverMain + payload.url, {
                    responseType: "blob",
                    headers: {
                        Authorization: `Bearer ${tokenUsuario}`,
                        "Content-Type": "application/" + payload.extension,
                    },
                })
                .then((res) => {
                    fileDownload(res.data, payload.nombre);
                    handleOpenAlert("Archivo descargado exitosamente.", "success");
                })
                .catch((error) => {
                    handleOpenAlert("PDF no disponible para su descarga.");
                })
                .finally(handleLoader());
        }
    };

    const DownloadFileBlobPromise = async (payload) => {
        if (payload.loader) {
            handleLoader(payload.loader);
        }
        if (payload.alert) {
            await GetToken();
            let tokenUsuario = sessionStorage.getItem("tkn");
            return axios
                .get(serverMain + payload.url, {
                    responseType: "blob",
                    headers: {
                        Authorization: `Bearer ${tokenUsuario}`,
                        "Content-Type": "application/" + payload.extension,
                    },
                })
        }
    };

    return (
        <RequestContext.Provider
            value={{ GetTokenLogin, Get, GetFile, Post, PostFile, PostFormData, DownloadFileBlob, DownloadFileBlobPromise }}
        >
            {props.children}
        </RequestContext.Provider>
    );
}

export function useRequest() {
    return useContext(RequestContext);
}
