import { Backdrop, CircularProgress, Typography } from "@mui/material";

import { useLayout } from "../../context/LayoutContext";
import { useStyle } from "../../context/StyleContext";

export default function DinercapLoader(props) {
    const { classes } = useStyle();
    const { loader } = useLayout();

    return (
        <Backdrop className={classes.backdrop} open={loader.open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <div className="center">
                <CircularProgress color="primary" />
                <br />
                <Typography variant="subtitle2" align="center" color="textSecondary">
                    {loader.message}
                </Typography>
            </div>
        </Backdrop>
    );
}
