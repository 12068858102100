import { imgCargaPreview } from "../settings/imgConfig";

export const getModelTipoSolicitud = () => ({
    iIDTipoSolicitud: 0,
    sDescripcion: "",
    sNombre: "",
    sURL: "",
    sIcono: "",
    sImagen: "",
    bActivo: false,
    sNombreArchivo: "",
});

export const getFormTipoSolicitud = () => ({
    txtDescripcionTipoSolicitud: {
        label: "Descripción tipo solicitud:",
        required: true,
        value: "",
        error: false,
        message: "La Descripción del tipo de solicitud es requerido",
        placeholder: "Ej. Solicitud de Crédito",
        maxlength: 200,
    },
    txtNombre: {
        label: "Nombre de tipo solicitud:",
        required: true,
        value: "",
        error: false,
        message: "Nombre de tipo solicitud es requerido",
        placeholder: "Nombre de tipo solicitud",
        maxlength: 200,
    },
    txtUrl: {
        label: "Url de tipo solicitud:",
        required: true,
        value: "",
        error: false,
        message: "Url de tipo solicitud es requerido",
        placeholder: "Url de tipo solicitud",
        maxlength: 2000,
    },
    slIcono: {
        label: "Icono de tipo solicitud:",
        required: true,
        value: 0,
        error: false,
        message: "Icono es requerido",
        placeholder: "",
        maxlength: 20,
    },
    slFinanciera: {
        label: "Financiera:",
        required: true,
        value: 0,
        error: false,
        message: "Financiera es requerida",
        placeholder: "",
        maxlength: 80,
    },
    slTipoArea: {
        label: "Tipo Area:",
        required: true,
        value: 0,
        error: false,
        message: "Tipo Area es requerida",
        placeholder: "",
        maxlength: 80,
    },
    chkVisible: {
        label: "Visible:",
        required: false,
        value: false,
        error: false,
        message: "",
        placeholder: "",
    },
    txtImagen: {
        label: "Imagen:",
        required: false,
        value: "",
        url: imgCargaPreview,
        nombre: "",
        error: false,
        message: "Imagen es requerido",
        placeholder: "",
        maxlength: 2000,
    },
});
