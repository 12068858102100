import { Button, Collapse, Fade, Grid, Typography } from "@mui/material";
import { imgLogoDinercap } from "../../settings/imgConfig";

import DinercapInputWhite from "../utils/DinercapInputWhite";
import DinercapPassword from "../utils/DinercapPassword";
import DinercapCancelForm from "./DinercapCancelForm";
import { inputVariant } from "../../settings/inputConfig";
import { useLogin } from "../../context/LoginContext";
import { useStyle } from "../../context/StyleContext";
import packageJson from '../../../package.json';
import { Version } from "../../settings/serverConfig";

export default function DinercapLogin() {
    const { classes } = useStyle();
    const {
        formLogin,
        formRecuperar,
        openFormRecuperar,
        openFormEnvioCorreo,
        ConfirmacionExitosa,
        openPortadaLogin,
        mostrarPassword,
        lngConfirmCorreo,
        openFormCancelAccount,
        handleToggleFormRecuperar,
        handleChangeMostrarPassword,
        handleChangeFormLogin,
        handleChangeFormRecuperar,
        handleSubmitLogin,
        handleSubmitRecuperar,
    } = useLogin();

    const { txtUsuarioDinercap, txtPasswordDinercap } = formLogin;
    const { txtRecuperarDinercap } = formRecuperar;

    const handleReload = () => {
        window.location.reload(true);
    };

    return (
        <div className="login-container">
            {openFormCancelAccount ? (
                <Grid container spacing={0} className="login-page" style={{ backgroundColor: '#1a2543' }}>
                    <Grid item md={8} xs={12} className="align-self-center">
                        <div className="login-logo-container">
                            <DinercapCancelForm />
                        </div>
                    </Grid>
                    <Grid item md={4} xs={12} className="cancel-logo">
                        <div>
                            <img src={imgLogoDinercap} alt="Logo Dinercap" />
                        </div>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={0} className="login-page">
                    <Grid item md={8} xs={12} className="align-self-center">
                        <div className="login-logo-container">
                            <Fade in={openPortadaLogin} timeout={1500}>
                                <img src={imgLogoDinercap} alt="Logo Dinercap" className="login-logo-img" />
                            </Fade>
                        </div>
                    </Grid>
                    {openFormEnvioCorreo && (
                        <Grid item md={4} xs={12} className="login-form-back">
                            <div>
                                <Collapse in={!openFormRecuperar}>
                                    <form className="login-form-container" onSubmit={handleSubmitRecuperar} noValidate>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                {ConfirmacionExitosa == null && (
                                                    <span className="ops-nor bold size-25">
                                                        {lngConfirmCorreo === "es"
                                                            ? "Estamos validando tu confirmación"
                                                            : "We are validating your confirmation"}
                                                    </span>
                                                )}
                                                {ConfirmacionExitosa === true && (
                                                    <span className="ops-nor bold size-25">
                                                        {lngConfirmCorreo === "es"
                                                            ? "¡Bienvenido a tu cuenta Dinercap!"
                                                            : "¡Welcome to your Dinercap account!"}
                                                    </span>
                                                )}
                                                {ConfirmacionExitosa === false && (
                                                    <span className="ops-nor bold size-25">
                                                        {lngConfirmCorreo === "es"
                                                            ? "Lo sentimos no pudimos encontrar tu cuenta para activarla"
                                                            : "Sorry we couldn't find your account to activate it"}
                                                    </span>
                                                )}
                                            </Grid>
                                            {ConfirmacionExitosa === true && (
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        {lngConfirmCorreo === "es"
                                                            ? "Tu cuenta Dinercap ha sido activada ya podrás utilizar la aplicación móvil."
                                                            : "Your Dinercap account has been activated and you can use the mobile application."}
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </form>
                                </Collapse>
                            </div>
                        </Grid>
                    )}
                    {!openFormEnvioCorreo && (
                        <Grid item md={4} xs={12} className="login-form-back">
                            <div>
                                <Collapse in={!openFormRecuperar}>
                                    <form className="login-form-container" onSubmit={handleSubmitLogin} noValidate>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <span className="ops-nor bold size-25">ACCESO AL SISTEMA</span>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DinercapInputWhite
                                                    name="txtUsuarioDinercap"
                                                    label={txtUsuarioDinercap.label}
                                                    value={txtUsuarioDinercap.value}
                                                    required={txtUsuarioDinercap.required}
                                                    onChange={handleChangeFormLogin}
                                                    variant={inputVariant}
                                                    fullWidth
                                                    autoFocus
                                                    autoComplete="off"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DinercapInputWhite
                                                    name="txtPasswordDinercap"
                                                    label={txtPasswordDinercap.label}
                                                    value={txtPasswordDinercap.value}
                                                    required={txtPasswordDinercap.required}
                                                    onChange={handleChangeFormLogin}
                                                    variant={inputVariant}
                                                    fullWidth
                                                    autoComplete="new-password"
                                                    type={mostrarPassword ? "text" : "password"}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <DinercapPassword
                                                                view={mostrarPassword}
                                                                setView={handleChangeMostrarPassword}
                                                                white
                                                            />
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    className={classes.button}
                                                    fullWidth
                                                >
                                                    ENTRAR
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {<Button
                                                    variant="text"
                                                    color="inherit"
                                                    type="button"
                                                    fullWidth
                                                    onClick={() => handleReload()}
                                                >
                                                    Recargar Página
                                                </Button>}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div>
                                                    <h1>Versión del paquete: {packageJson.version}</h1>
                                                </div>
                                            </Grid>
                                            {Version !== '' && (
                                                <Grid item xs={12}>
                                                    <div>
                                                        <h1>Versión: {Version}</h1>
                                                    </div>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </form>
                                </Collapse>
                                <Collapse in={openFormRecuperar}>
                                    <form className="login-form-container" onSubmit={handleSubmitRecuperar} noValidate>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <span className="ops-nor bold size-25">RECUPERAR ACCESO</span>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Para recuperar sus credenciales de acceso ingrese el correo electrónico
                                                    registrado en el sistema.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DinercapInputWhite
                                                    name="txtRecuperarDinercap"
                                                    label={txtRecuperarDinercap.label}
                                                    value={txtRecuperarDinercap.value}
                                                    required={txtRecuperarDinercap.required}
                                                    onChange={handleChangeFormRecuperar}
                                                    variant={inputVariant}
                                                    fullWidth
                                                    autoComplete="off"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    className={classes.button}
                                                    fullWidth
                                                >
                                                    Enviar credenciales
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="text"
                                                    color="inherit"
                                                    type="button"
                                                    fullWidth
                                                    onClick={() => handleToggleFormRecuperar(false)}
                                                >
                                                    Regresar al Login
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Collapse>
                            </div>
                        </Grid>
                    )}
                </Grid>
            )}
        </div>
    );
}
