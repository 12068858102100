import React, { useEffect } from "react";
import {
    Card,
    CardActionArea,
    CardContent,
    FormHelperText,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { faUpload, faCode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "@mui/lab/Skeleton";
import _ from "lodash";

import { useOriginador } from "../../../../context/catalogos_generales/OriginadorContext";
import { inputColor, inputVariant } from "../../../../settings/inputConfig";
import { useTipoSolicitud } from "../../../../context/TipoSolicitudContext";
import { useLayout } from "../../../../context/LayoutContext";
import DinercapModal from "../../../utils/DinercapModal";

export default function FormOriginador() {
    const {
        formOriginador,
        openFormOriginador,
        originadorSeleccionado,
        handleOpenFormOriginador,
        handleSubmitFormOriginador,
        handleChangeFormOriginador,
        handleChangeFileFormDocumento,
    } = useOriginador();

    const { listaTiposSolicitud, handleClickObtenerTiposSolicitud } = useTipoSolicitud();

    const { loader } = useLayout();

    const { txtFileDatos, txtNombreOriginador, cmbBoxTipoSolicitud, txtFileDocto } = formOriginador;

    useEffect(() => {
        handleClickObtenerTiposSolicitud();
    }, []);

    return (
        <DinercapModal
            title={
                originadorSeleccionado.iIdOriginadorTSolicitud === -1 ? "Agregar Originador" : "Actualizar Originador"
            }
            okFunc={handleSubmitFormOriginador}
            setOpen={handleOpenFormOriginador}
            open={openFormOriginador}
            okMessage="Guardar"
            size="small"
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" error={cmbBoxTipoSolicitud.error}>
                        <InputLabel id="demo-simple-select-error-label">{cmbBoxTipoSolicitud.label}</InputLabel>
                        <Select
                            labelId="demo-simple-select-error-label"
                            id={cmbBoxTipoSolicitud.name}
                            name={cmbBoxTipoSolicitud.name}
                            label={cmbBoxTipoSolicitud.label}
                            required={cmbBoxTipoSolicitud.required}
                            error={cmbBoxTipoSolicitud.error}
                            className="mb-2"
                            value={cmbBoxTipoSolicitud.value}
                            onChange={handleChangeFormOriginador}
                        >
                            <MenuItem value="">
                                <em>Seleccione el Tipo de Solicitud</em>
                            </MenuItem>
                            {listaTiposSolicitud.map((tiposSolicitud) => {
                                return (
                                    <MenuItem
                                        key={tiposSolicitud.iIDTipoSolicitud}
                                        value={tiposSolicitud.iIDTipoSolicitud}
                                    >
                                        {tiposSolicitud.sNombre}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>{cmbBoxTipoSolicitud.error && cmbBoxTipoSolicitud.message}</FormHelperText>
                    </FormControl>
                    <TextField
                        name={txtNombreOriginador.name}
                        label={txtNombreOriginador.label}
                        required={txtNombreOriginador.required}
                        value={txtNombreOriginador.value}
                        error={txtNombreOriginador.error}
                        helperText={txtNombreOriginador.error && txtNombreOriginador.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormOriginador}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        className="mb-2"
                        inputProps={{
                            maxLength: txtNombreOriginador.maxlength,
                        }}
                    />
                    <Card sx={{ maxWidth: 600 }} className="dashed">
                        <CardActionArea>
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    SELECCIONAR EL ARCHIVO XML - DATOS
                                </Typography>
                                {loader.open ? (
                                    <div>
                                        <Skeleton variant="react" width={530} height={140} />
                                    </div>
                                ) : (
                                    <label style={{ width: 100 }} htmlFor="contained-button-file">
                                        {txtFileDatos.nombreArchivo === "" ? (
                                            <div class="dashed530 text-center">
                                                <FontAwesomeIcon icon={faUpload} size="2x" />
                                            </div>
                                        ) : (
                                            <div class="dashed530 text-center">
                                                <FontAwesomeIcon icon={faCode} size="2x" />
                                                <h1>{txtFileDatos.nombreArchivo}</h1>
                                            </div>
                                        )}
                                    </label>
                                )}
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <input
                        accept="text/xml"
                        //className={classes.input}
                        style={{ display: "none" }}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={handleChangeFileFormDocumento}
                        name={txtFileDatos.name}
                        required={txtFileDatos.required}
                        error={txtFileDatos.error}
                        helperText={txtFileDatos.error && txtFileDatos.message}
                    />
                    <FormHelperText error={txtFileDatos.error}>
                        {txtFileDatos.error && txtFileDatos.message}
                    </FormHelperText>
                    <Card sx={{ maxWidth: 600 }} className="dashed">
                        <CardActionArea>
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    SELECCIONAR EL ARCHIVO XML - DOCUMENTOS
                                </Typography>
                                {loader.open ? (
                                    <div>
                                        <Skeleton variant="react" width={530} height={140} />
                                    </div>
                                ) : (
                                    <label style={{ width: 100 }} htmlFor="contained-button-file-02">
                                        {txtFileDocto.nombreArchivo === "" ? (
                                            <div class="dashed530 text-center">
                                                <FontAwesomeIcon icon={faUpload} size="2x" />
                                            </div>
                                        ) : (
                                            <div class="dashed530 text-center">
                                                <FontAwesomeIcon icon={faCode} size="2x" />
                                                <h1>{txtFileDocto.nombreArchivo}</h1>
                                            </div>
                                        )}
                                    </label>
                                )}
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <input
                        accept="text/xml"
                        //className={classes.input}
                        style={{ display: "none" }}
                        id="contained-button-file-02"
                        multiple
                        type="file"
                        onChange={handleChangeFileFormDocumento}
                        name={txtFileDocto.name}
                        required={txtFileDocto.required}
                        error={txtFileDocto.error}
                        helperText={txtFileDocto.error && txtFileDocto.message}
                    />
                    <FormHelperText error={txtFileDocto.error}>
                        {txtFileDocto.error && txtFileDocto.message}
                    </FormHelperText>
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
