import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";

import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

import { blue } from "@mui/material/colors";
import { useFormatoSolicitud } from "../../../context/FormatoSolicitudContext";
import { PictureAsPdf } from "@mui/icons-material";
import { useSolicitud } from "../../../context/SolicitudContext";

const useStyles = makeStyles()((theme) => {
    return {
        avatar: {
            backgroundColor: blue[100],
            color: blue[600],
        },
    };
});

export default function FormatoSolicitudDialog(props) {
    const classes = useStyles();
    const { onClose, selectedValue: iIdSolicitud, open } = props;

    const { listaFormatosSolicitud, handleClickObtenerFormatosSolicitud } = useFormatoSolicitud();

    const { tipoSolicitudSeleccionado } = useSolicitud();

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (iIdFormatoSolicitud) => {
        onClose(iIdSolicitud, iIdFormatoSolicitud);
    };

    useEffect(() => {
        if (open) {
            handleClickObtenerFormatosSolicitud(tipoSolicitudSeleccionado.iIDTipoSolicitud);
        }
    }, [open]);

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Seleccionar formato (folio: {iIdSolicitud})</DialogTitle>
            <List>
                {listaFormatosSolicitud.map((formatoSolicitud) => (
                    <ListItem
                        button
                        onClick={() => handleListItemClick(formatoSolicitud.iIDFormatoSolicitud)}
                        key={formatoSolicitud.iIDFormatoSolicitud}
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <PictureAsPdf />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={formatoSolicitud.sNombreArchivo} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

FormatoSolicitudDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.number.isRequired,
};
