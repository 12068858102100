import React, { useEffect, useState, Fragment } from "react";
import Enumerable from "linq";
import { makeStyles } from "tss-react/mui";
import { AppBar, Button, Dialog, Slide, Toolbar, Tooltip, Typography, Box, Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { IoAdd } from "react-icons/io5";
import { useDocumentosOCR } from "../../../../context/catalogos_generales/DocumentosOCRContext";
import FormDocumento from "./FormDocumento";
import DinercapTableHandle from "../../../utils/DinercapTableHandle";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles()((theme) => {
    return {
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        container: {
            maxHeight: 800,
        },
        root: {
            width: "100%",
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    };
});

export default function CatalogoDocumentosModal() {
    const {
        openCatDoctos,
        handleOpenCatDoctos,
        handleObtenerDocumentos,
        listaDocumentos,
        handleOpenFormDocumento,
        openFormDocumento,
        handleActualizarDocumento,
        handleEliminarDocumento,
    } = useDocumentosOCR();
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({
        iIdDocumetosocr: -1,
    });

    const crearData = () => {
        const _data = Enumerable.from(listaDocumentos)
            .select((docto, index) => ({
                id: index + 1,
                iIdDocumetosocr: docto.iIdDocumetosocr,
                sNombre: docto.sNombre,
                sNombrePatron: docto.sNombrePatron,
                urlXML: docto.urlXML,
                sTipo: docto.sTipo,
                fvalorJW: docto.fvalorJW,
            }))
            .toArray();
        setData(_data);
    };

    const columns = [
        { field: "id", title: "#", width: 50 },
        { field: "sNombre", title: "Nombre" },
        { field: "sTipo", title: "Tipo" },
        {
            field: "sNombrePatron",
            title: "Patrón",
            align: "center",
            Element: ({ row }) => {
                return (
                    <a href={row.urlXML} target="_blank" rel="noreferrer">
                        {row.sNombrePatron}
                    </a>
                );
            },
        },
        { field: "fvalorJW", title: "Porcentaje de similitud", align: "right" },
    ];

    const onDelete = (e, row) => {
        handleEliminarDocumento(e, row);
    };

    const onUpdate = (e, row) => {
        handleActualizarDocumento(e, row);
    };

    useEffect(() => {
        handleObtenerDocumentos();
    }, []);

    useEffect(() => {
        crearData();
    }, [listaDocumentos]);

    return (
        <Fragment>
            <Dialog
                key="form"
                fullScreen
                open={openCatDoctos}
                onClose={() => handleOpenCatDoctos(false)}
                TransitionComponent={Transition}
            >
                <Box sx={{ flexFlow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title} component="div" sx={{ flexGrow: 1 }}>
                                Catálogo Documentos
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => handleOpenCatDoctos(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
                <div className="flex flex-col gap-2 p-4">
                    <div className="flex flex-row justify-between items-center flex-wrap ml-2 mt-2 mr-2 ">
                        <div></div>
                        <div>
                            <Button
                                className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={(e) => handleOpenFormDocumento(true)}
                            >
                                <IoAdd className="text-xl mr-2" />
                                Crear
                            </Button>
                        </div>
                    </div>
                    <Box
                        component="div"
                        sx={{
                            width: "100%",
                            "& .odd": {
                                bgcolor: "rgba(0, 0, 0, 0.05)",
                            },
                            "& .even": {
                                bgcolor: "#ffffff",
                            },
                        }}
                    >
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                            <Grid item xs={12} md={8}>
                                <DinercapTableHandle
                                    id="iIdDocumetosocr"
                                    data={data}
                                    columns={columns}
                                    selectedRow={selectedRow}
                                    setRow={setSelectedRow}
                                    onDelete={onDelete}
                                    onUpdate={onUpdate}
                                    //onView={() => {}}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Dialog>
            {openFormDocumento && <FormDocumento />}
        </Fragment>
    );
}
