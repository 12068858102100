export const getModelOriginador = () => ({
    id: -1,
    iIdOriginadorTSolicitud: -1,
    iIdTipoSolicitud: -1,
    sNombre: "",
});

export const getFormOriginador = () => ({
    cmbBoxTipoSolicitud: {
        label: "Tipo de solicitud:",
        name: "cmbBoxTipoSolicitud",
        value: "",
        required: true,
        message: "El Tipo de Solicitud es requerido",
    },
    txtNombreOriginador: {
        label: "Nombre originador:",
        name: "txtNombreOriginador",
        required: true,
        value: "",
        error: false,
        message: "El nombre del origindor es requerido",
        placeholder: "",
        maxlength: 100,
    },
    txtFileDatos: {
        label: "XML Documento:",
        name: "txtFileDatos",
        required: true,
        value: "",
        nombreArchivo: "",
        error: false,
        message: "El XML de datos es requerido",
        placeholder: "",
        maxlength: 20000,
    },
    txtFileDocto: {
        label: "XML Documento:",
        name: "txtFileDocto",
        required: true,
        value: "",
        nombreArchivo: "",
        error: false,
        message: "El XML de documentos es requerido",
        placeholder: "",
        maxlength: 20000,
    },
});
