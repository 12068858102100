import React, { useEffect, useState, Fragment } from "react";
import Enumerable from "linq";
import { makeStyles } from "tss-react/mui";
import { AppBar, Button, Dialog, Slide, Toolbar, Typography, Box, Grid, FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { IoAdd } from "react-icons/io5";
import { useReglasOpeContext } from "../../../../context/catalogos_generales/ReglasOperadoresContext";
import { enumTipos } from "../../../../models/CatalogosModel";
import FormReglasOperadores from "./FormReglasOperadores";
import DinercapTableHandle from "../../../utils/DinercapTableHandle";
import Swal from "sweetalert2";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles()((theme) => {
    return {
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        container: {
            maxHeight: 800,
        },
        root: {
            width: "100%",
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    };
});

export default function CatalogoAsociarReglasOpeModal() {
    const {
        openCatReglasOpe,
        handleOpenCatReglasOpe,
        formReglasOperadores,
        listaReglasOperadores,
        openFormReglasOperadores,
        handleOpenFormReglasOperador,
        handleObtenerReglasOperadores,
        handleActualizarReglaOperador,
        validacionSeleccionado,
        handleChangeSelect,
    } = useReglasOpeContext();

    const { cmbBoxValidacion } = formReglasOperadores;

    const classes = useStyles();

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({
        id: -1,
    });

    const crearData = () => {
        const _data = Enumerable.from(listaReglasOperadores)
            .select((reglasope, index) => ({
                id: index + 1,
                sNombreValidacion: reglasope.sNombreValidacion,
                sTipoDato: reglasope.sTipoDato,
                sOperador: reglasope.sOperador,
            }))
            .toArray();
        setData(_data);
    };

    const columns = [
        { field: "id", title: "#", width: 50 },
        { field: "sNombreValidacion", title: "Validacion" },
        { field: "sTipoDato", title: "Tipos de dato" },
        { field: "sOperador", title: "Operador" },
    ];

    const onDelete = (e, row) => {

    };

    const onUpdate = (e, row) => {
        handleActualizarReglaOperador(e, row);
    };

    useEffect(() => {
        handleObtenerReglasOperadores();
    }, []);

    useEffect(() => {
        crearData();
    }, [listaReglasOperadores]);

    return (
        <Fragment>
            <Dialog
                key="form"
                fullScreen
                open={openCatReglasOpe}
                onClose={() => handleOpenCatReglasOpe(false)}
                TransitionComponent={Transition}
            >
                <Box sx={{ flexFlow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title} component="div" sx={{ flexGrow: 1 }}>
                                Reglas y operadores
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => handleOpenCatReglasOpe(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
                <div className="flex flex-col gap-2 p-4">
                    <Grid container direction="row" justifyContent="center" alignItems="center" >
                        <Grid md={2} />
                        <Grid xs md>
                            <InputLabel>
                                <i className="fa fa-filter"></i>&nbsp;<b>Validación:</b>
                            </InputLabel>
                            <FormControl fullWidth variant="outlined"
                                sx={{
                                    width: 250,
                                }}>
                                <Select
                                    name="slValidacionFilter"
                                    id="slValidacionFilter"
                                    value={validacionSeleccionado}
                                    onChange={handleChangeSelect}
                                    autoComplete="off"
                                    defaultValue={0}
                                >
                                    <MenuItem value={0}>
                                        <em>Todos las validaciones</em>
                                    </MenuItem>
                                    {enumTipos.map((validate) => {
                                        return (
                                            <MenuItem
                                                key={validate.id}
                                                value={validate.text}
                                            >
                                                {validate.text}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <div>
                            <Button
                                className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={(e) => handleOpenFormReglasOperador(true)}
                            >
                                <IoAdd className="text-xl mr-2" />
                                Crear
                            </Button>
                        </div>
                    </Grid>
                    <Box
                        component="div"
                        sx={{
                            width: "100%",
                            "& .odd": {
                                bgcolor: "rgba(0, 0, 0, 0.05)",
                            },
                            "& .even": {
                                bgcolor: "#ffffff",
                            },
                        }}
                    >
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                            <Grid item xs={12} md={8}>
                                <DinercapTableHandle
                                    id="id"
                                    data={data}
                                    columns={columns}
                                    selectedRow={selectedRow}
                                    setRow={setSelectedRow}
                                    onUpdate={onUpdate}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Dialog>
            {openFormReglasOperadores && <FormReglasOperadores />}
        </Fragment>
    );
}