import { useParams } from "react-router-dom";
import { IoHomeOutline, IoDocumentOutline } from "react-icons/io5";
import { AiOutlineFileSearch } from "react-icons/ai";
import Enumerable from "linq";
import Moment from "react-moment";

import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import { urlSystem } from "../../../settings/urlConfig";
import { urlDefault } from "../../../settings/urlConfig";

import { useEffect, useState } from "react";
import { IconButton, InputLabel, MenuItem, Select, TextField, Grid } from "@mui/material";
import { useTipoSolicitud } from "../../../context/TipoSolicitudContext";
import { makeStyles } from "tss-react/mui";

//DataGrid
import { DataGrid, esES, GridRow, GridToolbarExport, GridToolbarContainer } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import clsx from "clsx";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
//DataGrid

import { Button, Link } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import {
    CancelOutlined,
    Send,
    InfoOutlined,
    PictureAsPdf,
    DoneAll,
    Refresh,
    ScreenLockRotationOutlined,
} from "@mui/icons-material";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import SeguimientoSolicitudDetalle from "./SeguimientoSolicitudDetalle";
import FormatoSolicitudDialog from "./FormatoSolicitudDialog";
import FormSolicitudAprobada from "./FormSolicitudAprobada";
import FormSolicitudRechazada from "./FormSolicitudRechazada";
import FormSolicitudEnvOriginador from "./FormSolicitudEnvOriginador";
import { useSolicitud } from "../../../context/SolicitudContext";
import { useLogin } from "../../../context/LoginContext";

const useStyles = makeStyles()((theme) => {
    return {
        button: {
            margin: theme.spacing(1),
        },
        fControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        textField: {
            [theme.breakpoints.down("xs")]: {
                width: "100%",
            },
            margin: theme.spacing(1, 0.5, 1.5),
            "& .MuiSvgIcon-root": {
                marginRight: theme.spacing(0.5),
            },
            "& .MuiInput-underline:before": {
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
        },
    };
});

//DataGrid
function CustomRow(props) {
    const { className, index, ...other } = props;

    return <GridRow index={index} className={clsx(className, index % 2 === 0 ? "odd" : "even")} {...other} />;
}

//QuickSearchToolbar
function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

function QuickSearchToolbar(props) {
    const classes = useStyles();

    return (
        <div>
            <div>
                <GridToolbarContainer>
                    <GridToolbarExport csvOptions={{ fileName: "listaSolicitudesDinercap", utf8WithBom: true }} />
                </GridToolbarContainer>
            </div>
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Buscar..."
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? "visible" : "hidden" }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

export default function SeguimientosSolicitudScreen() {
    const tokenUsuario = "TknUsu";
    const tokenContraseña = "TknPass";
    const classes = useStyles();
    const { handleClickRuta } = useLogin();
    const { bMostrarTipoSolicitud } = useParams();
    const [openDialog, setOpenDialog] = useState(false);
    const [idSolicitudSelec, setIdSolicitudSelec] = useState(0);

    const { listaTiposSolicitudAll, handleClickObtenerTiposSolicitud } = useTipoSolicitud();

    const {
        listaSolicitudes,
        updateData,
        listaEstadosSolicitud,
        modalOpenSolicitudDetalle,
        tipoSolicitudSeleccionado,
        tipoSolicitudSeleccionadoSelect,
        existeProspecto,
        handleChangeTipoSolicitudSeleccionado,
        handleChangeTipoSolicitudSeleccionadoSelect,
        handleClickObtenerSolicitudes,
        handleChangeEstadosSolicitud,
        handleSubmitUpdEstadoSolicitud,
        handleClickSolicitud,
        handleChangeCreatePDF,
        handleGetExisteProspecto,
        handleGetExisteProspectoCURP,
        handleGetProspectoNuevoActual,
        openFormAprobado,
        openFormEnvioOriginador,
        handleOpenFormAprobado,
        handleCloseFormAprobado,
        handleChangeStatusSolicitud,
        handleObtenerInfoSolicitudAprobada,
        openFormRechazado,
        handleOpenFormRechazado,
        handleObtenerInfoSolicitudRechazada,
        handleObtenerInformacionBuro,
        handleEnviarSolicitudOriginador,
        handleClickMostrarHistorial,
        bAllEstatus,
        handleChangeAllEstatus,
        handleChangeData
    } = useSolicitud();

    useEffect(() => {
        if (listaTiposSolicitudAll.length === 0) {
            handleClickObtenerTiposSolicitud();
        } else if (tipoSolicitudSeleccionadoSelect.iIDTipoSolicitud !== 0) {
            handleClickObtenerSolicitudes(tipoSolicitudSeleccionadoSelect.iIDTipoSolicitud);
        } else {
            handleChangeTipoSolicitud(-1);
        }
        // eslint-disable-next-line
    }, [tipoSolicitudSeleccionadoSelect.iIDTipoSolicitud || listaTiposSolicitudAll]);

    const handleChangeTipoSolicitud = async (value) => {
        if (value != -1) {
            handleChangeTipoSolicitudSeleccionadoSelect(listaTiposSolicitudAll.find((x) => x.iIDTipoSolicitud === value));
        } else {
            handleChangeTipoSolicitudSeleccionadoSelect({
                iIDTipoSolicitud: -1,
                sDescripcion: "",
                sNombre: "",
            });
        }
    };

    useEffect(() => {
        handleChangeEstadosSolicitud(-1);
    }, []);

    const handleClickHome = (e) => {
        handleClickRuta(e, {
            pathname: urlDefault,
        });
    };

    const handleClickTipoSolicitudes = (e) => {
        handleClickRuta(e, {
            pathname: urlSystem.tiposSolicitudes,
        });
    };

    const handleChangeEstado = async (e, oSolicitud) => {
        const { target } = e;
        let iIdEstadoSeleccionado = e.target.value;
        if (target.value !== "0") {
            if (oSolicitud.bFinal) {
                Swal.fire("", "La solicitud seleccionada tiene un estado sin seguimiento", "info");
            } else {
                if (oSolicitud.sEstado === "Captura") {
                    Swal.fire(
                        "Cambiar estado",
                        "No puede modificar el estado de una solicitud con estado actual en: " + oSolicitud.sEstado,
                        "error"
                    );
                } else {
                    let estatus = Enumerable.from(listaEstadosSolicitud)
                        .where((p) => p.iIdEstatus === iIdEstadoSeleccionado)
                        .select((s) => ({ sNombre: s.sNombre, iIdEstatus: s.iIdEstatus }))
                        .toArray()[0];
                    if (oSolicitud.sEstado !== estatus.sNombre) {
                        if (estatus.iIdEstatus === 5) {
                            await handleChangeTipoSolicitudSeleccionado(
                                listaTiposSolicitudAll.find((x) => x.iIDTipoSolicitud === oSolicitud.iIdTipoSolicitud)
                            );
                            handleOpenFormAprobado(oSolicitud, iIdEstadoSeleccionado);
                        } else if (estatus.iIdEstatus === 6) {
                            await handleChangeTipoSolicitudSeleccionado(
                                listaTiposSolicitudAll.find((x) => x.iIDTipoSolicitud === oSolicitud.iIdTipoSolicitud)
                            );
                            handleOpenFormRechazado(oSolicitud, iIdEstadoSeleccionado);
                        } else {
                            let texto = `Cambio de estado de la solicitud para el folio: ${oSolicitud.iIdSolicitud}`;

                            const sHtml =
                                "<div class='overflow-hidden'>" +
                                "<strong>" +
                                "¿Cambiar estado a " +
                                estatus.sNombre +
                                "?" +
                                "</strong><br><br>" +
                                "<div class='row text-left'>" +
                                "<div class='col-md-4'>" +
                                "<strong>Folio:</strong>" +
                                "</div>" +
                                "<div class='col-md-8'>" +
                                oSolicitud.iIdSolicitud +
                                "</div>" +
                                "</div>" +
                                "<div class='row text-left'>" +
                                "<div class='col-md-4'>" +
                                "<strong>RFC:</strong>" +
                                "</div>" +
                                "<div class='col-md-8'>" +
                                oSolicitud.srfc +
                                "</div>" +
                                "</div>" +
                                "<div class='row text-left'>" +
                                "<div class='col-md-4'>" +
                                "<strong>Nombre:</strong>" +
                                "</div>" +
                                "<div class='col-md-8'>" +
                                oSolicitud.snombre +
                                "</div>" +
                                "</div>" +
                                "<div class='row text-left'>" +
                                "<div class='col-md-4'>" +
                                "<strong>Estado actual:</strong>" +
                                "</div>" +
                                "<div class='col-md-6'>" +
                                oSolicitud.sEstado +
                                "</div>" +
                                "</div>" +
                                "</div>";

                            Swal.fire({
                                // title: "¿Cambiar estado a " + estatus.sNombre + "?",
                                html: sHtml,
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Si",
                                cancelButtonText: "No",
                                reverseButtons: true,
                            }).then(async (result) => {
                                if (result.isConfirmed) {
                                    await handleChangeStatusSolicitud(
                                        iIdEstadoSeleccionado,
                                        oSolicitud.iIdSolicitud,
                                        oSolicitud.iIdTipoSolicitud
                                    );
                                }
                            });
                        }
                    }
                }
            }
        }
    };

    const handleClickDetailSolicitud = async (e, oSolicitud) => {
        await handleChangeTipoSolicitudSeleccionado(
            listaTiposSolicitudAll.find((x) => x.iIDTipoSolicitud === oSolicitud.iIdTipoSolicitud)
        );
        handleClickSolicitud(oSolicitud);
    };

    // const handleClickRechazar = (e, oSolicitud) => {
    //     let texto = `Rechazar la solicitud del folio: ${oSolicitud.iIdSolicitud}`;
    //     Swal.fire({
    //         title: "Rechazar solicitud",
    //         text: texto,
    //         icon: "warning",
    //         buttons: ["Cancelar", "Sí"],
    //         dangerMode: true,
    //         closeOnClickOutside: false,
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             //handleSubmitUpdEstadoSolicitud(e, oSolicitud.iIdSolicitud, iIdEstadoSeleccionado);
    //         }
    //     });
    // };

    const handleClickPDF = async (e, oSolicitud) => {
        e.preventDefault();
        await handleChangeTipoSolicitudSeleccionado(
            listaTiposSolicitudAll.find((x) => x.iIDTipoSolicitud === oSolicitud.iIdTipoSolicitud)
        );

        setIdSolicitudSelec(oSolicitud.iIdSolicitud);
        handleClickOpenDialog();
    };

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = (iIdSolicitud, iIdFormatoSolicitud) => {
        setOpenDialog(false);
        if (iIdSolicitud > 0 && iIdFormatoSolicitud > 0) {
            handleChangeCreatePDF(iIdSolicitud, iIdFormatoSolicitud, tipoSolicitudSeleccionado.iIDTipoSolicitud);
        }
    };

    const handleAprobar = async (e, oSolicitud) => {
        Swal.fire({
            title: "Enviar al originador",
            text: "¿Está seguro de enviar la información al originador?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await handleChangeTipoSolicitudSeleccionado(
                    listaTiposSolicitudAll.find((x) => x.iIDTipoSolicitud === oSolicitud.iIdTipoSolicitud)
                );
                let bExiste = false;
                const iExisteProspectoRFC = await handleGetExisteProspecto(e, oSolicitud.srfc);
                const iExisteProspectoCURP = await handleGetExisteProspectoCURP(e, oSolicitud.sCURP);
                if (iExisteProspectoCURP > 0 && iExisteProspectoRFC > 0 && iExisteProspectoCURP == iExisteProspectoRFC) {
                    bExiste = true;
                }
                if (bExiste) {
                    handleGetProspectoNuevoActual(e, iExisteProspectoRFC, oSolicitud.iIdSolicitud, oSolicitud.iIdTipoSolicitud);
                }
                else {
                    if (iExisteProspectoCURP > 0 || iExisteProspectoRFC > 0) {
                        Swal.fire("", "Ya existe una curp o rfc en el originador, pero no son iguales a esta solicitud, revisar con administración del sistema, para hacer el cambio correspondiente, y volver a procesar nuevamente", "info");
                    }
                    else {
                        await handleEnviarSolicitudOriginador(e, oSolicitud.iIdSolicitud, oSolicitud.iIdTipoSolicitud);
                    }
                }
            }
        });
    };

    const handleOpenInfoSwal = (e, iIdSolicitud, iIDestatus) => {
        switch (iIDestatus) {
            case 5:
                handleObtenerInfoSolicitudAprobada(e, iIdSolicitud);
                break;
            case 6:
                handleObtenerInfoSolicitudRechazada(e, iIdSolicitud);
                break;
            default:
                break;
        }
    };

    const columns = [
        {
            field: "Acciones",
            width: 300,
            disableExport: true,
            renderCell: (cellValues) => {
                return (
                    <Grid container>
                        <Grid item xs={3}>
                            <Tooltip title="Detalle de la solicitud">
                                <Button
                                    variant="contained"
                                    aria-label="detail"
                                    size="small"
                                    className="btn btn-primary mr-1"
                                    onClick={(event) => {
                                        handleClickDetailSolicitud(event, cellValues.row);
                                    }}
                                >
                                    <InfoOutlined />
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={3}>
                            <Tooltip title="Generar PDF">
                                <Button
                                    variant="contained"
                                    aria-label="PDF"
                                    size="small"
                                    className="btn btn-primary mr-1"
                                    onClick={(event) => {
                                        handleClickPDF(event, cellValues.row);
                                    }}
                                >
                                    <PictureAsPdf />
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={3}>
                            {cellValues.row.sEstado !== "Captura" ? (
                                <Tooltip
                                    title={
                                        !cellValues.row.bEnvOriginador
                                            ? "Enviar al Originador"
                                            : "La solicitud ha sido enviada"
                                    }
                                >
                                    <Button
                                        variant="contained"
                                        aria-label="Aprobar"
                                        size="small"
                                        className="btn btn-primary mr-1"
                                        onClick={(event) => {
                                            handleAprobar(event, cellValues.row);
                                        }}
                                    >
                                        {cellValues.row.bEnvOriginador ? <DoneAll /> : <Send />}
                                    </Button>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        {/* <Grid item xs={3}>
                            {cellValues.row.bBuroCredito ? (
                                <Tooltip title="Info buró">
                                    <Button
                                        variant="contained"
                                        aria-label="Ver información"
                                        size="small"
                                        className="btn btn-primary mr-1"
                                        onClick={(event) => {
                                            handleObtenerInformacionBuro(event, cellValues.row.iIdSolicitud);
                                        }}
                                    >
                                        <CreditCardIcon />
                                    </Button>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </Grid> */}
                    </Grid>
                );
            },
        },
        { field: "id", headerName: "ID", width: 50, hide: true },
        { field: "iIdSolicitud", headerName: "Folio", width: 80 },
        { field: "sNombreSolicitud", headerName: "Tipo solicitud", width: 200 },
        {
            field: "srfc",
            headerName: "RFC",
            width: 150,
            renderCell: (cellValues) => {
                return (
                    <Link
                        component="button"
                        underline="none"
                        variant="body2"
                        onClick={(e) => {
                            handleClickMostrarHistorial(e, cellValues.row.srfc);
                        }}
                    >
                        {cellValues.row.srfc}
                    </Link>
                );
            },
        },
        { field: "snombrecompleto", headerName: "Nombre", width: 250 },
        {
            field: "sEstado",
            headerName: "Estado actual",
            width: 200,
            renderCell: (cellValues) => {
                return (
                    <Chip
                        variant="outlined"
                        size="small"
                        label={cellValues.row.sEstado}
                        color="primary"
                        onClick={
                            cellValues.row.iIDestatus === 5 || cellValues.row.iIDestatus === 6
                                ? (e) => {
                                    handleOpenInfoSwal(e, cellValues.row.iIdSolicitud, cellValues.row.iIDestatus);
                                }
                                : null
                        }
                    />
                );
            },
        },
        {
            field: "dtFechaCreacion",
            headerName: "Fecha alta",
            width: 200,
            renderCell: (cellValues) => {
                return <Moment format="DD-MM-YYYY HH:mmA">{cellValues.row.dtFechaCreacion}</Moment>;
            },
            //editable: true,
        },
        {
            field: "dtFechaModificacion",
            headerName: "Fecha modificación",
            width: 200,
            renderCell: (cellValues) => {
                return String(cellValues.row.dtFechaModificacion) !== "0001-01-01T00:00:00" ? (
                    <Moment format="DD-MM-YYYY HH:mmA">{cellValues.row.dtFechaModificacion}</Moment>
                ) : (
                    <div></div>
                );
            },
            hide: true,
            //editable: true,
        },
        {
            field: "Estado siguiente",
            width: 200,
            disableExport: true,
            renderCell: (cellValues) => {
                return Enumerable.from(listaEstadosSolicitud)
                    .where(
                        (w) =>
                            w.iIdPadre === cellValues.row.iIDestatus &&
                            w.iIDTipoSolicitud === cellValues.row.iIdTipoSolicitud
                    )
                    .toArray().length > 0 ? (
                    <Select
                        id="slEstado"
                        value={0}
                        onChange={(e) => {
                            handleChangeEstado(e, cellValues.row);
                        }}
                    >
                        <MenuItem value="0">
                            <em>Seleccionar</em>
                        </MenuItem>
                        {Enumerable.from(listaEstadosSolicitud)
                            .where(
                                (w) =>
                                    w.iIdPadre === cellValues.row.iIDestatus &&
                                    w.iIDTipoSolicitud === cellValues.row.iIdTipoSolicitud
                            )
                            .toArray()
                            .map((estado) => {
                                return (
                                    <MenuItem key={estado.iIdEstatus} value={estado.iIdEstatus}>
                                        {estado.sNombre}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                ) : (
                    <></>
                );
            },
        },
    ];

    const [rows, setRows] = useState([]);
    const [data, setData] = useState([]);

    const createData = () => {
        if (updateData)
            handleChangeData(false);
        let data_tmp = Enumerable.from(listaSolicitudes)
            .select((solicitud, index) => ({
                id: index + 1,
                iIdSolicitud: solicitud.iIdSolicitud,
                iIdTipoSolicitud: solicitud.iIdTipoSolicitud,
                sNombreSolicitud: solicitud.tTipoSolicitud.sDescripcion,
                srfc: solicitud.eUsuario.srfc,
                snombre: solicitud.eUsuario.snombre,
                snombrecompleto: solicitud.eUsuario.snombrecompleto,
                sEstado: solicitud.eEstados.sNombre,
                bFinal: solicitud.eEstados.bFinal,
                iIDestatus: solicitud.iIDestatus,
                dtFechaCreacion: solicitud.dtFechaCreacion,
                dtFechaModificacion: solicitud.dtFechaModificacion,
                bBuroCredito: solicitud.bBuroCredito,
                bEnvOriginador: solicitud.bEnvOriginador,
                sPruebaVida: solicitud.sPruebaVida !== null ? solicitud.sPruebaVida : "",
                bPruebaVida: solicitud.bPruebaVida,
                sCURP: solicitud.eUsuario.scurp,
                ubicacion: {
                    lat: solicitud.sLatitud,
                    lng: solicitud.sLongitud,
                },
            }))
            .toArray();
        setData(data_tmp);
        requestSearch(searchText);
    };

    useEffect(() => {
        createData();
    }, [tipoSolicitudSeleccionado, listaSolicitudes, updateData]);

    const [pageSize, setPageSize] = useState(100);

    const [searchText, setSearchText] = useState("");
    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = data.filter((row) => {
            return Object.keys(row).some((field) => {
                const value = row[field];
                const searchableValue = typeof value === 'string' ? value.toString() : value;
                return searchRegex.test(searchableValue);
            });
        });
        setRows(filteredRows);
    };

    return (
        <div>
            <div className="flex flex-col md:flex-row items-start md:items-center md:justify-between h-14 p-3 border-b bg-header">
                <div className="flex flex-row items-center">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            className={
                                "flex !font-medium px-2 py-1 hover:!no-underline hover:!cursor-pointer hover:!bg-gray-100 hover:!rounded-full"
                            }
                            color="inherit"
                            onClick={(e) => handleClickHome(e)}
                        >
                            <IoHomeOutline className="text-2xl mr-2" />
                            Inicio
                        </Link>
                        {bMostrarTipoSolicitud === "true" && (
                            <Link
                                className={
                                    "flex !font-medium px-2 py-1 hover:!no-underline hover:!cursor-pointer hover:!bg-gray-100 hover:!rounded-full"
                                }
                                color="inherit"
                                onClick={(e) => handleClickTipoSolicitudes(e)}
                            >
                                <IoDocumentOutline className="text-2xl mr-2" />
                                <span>Tipos de Solicitudes</span>
                            </Link>
                        )}
                        <Typography color="textPrimary" className={"flex !font-medium px-2 py-1 "}>
                            <AiOutlineFileSearch className="text-2xl mr-2" />
                            <span>Seguimiento Solicitudes</span>
                        </Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <div className="flex flex-col gap-2 max-w-7xl mx-auto p-4">
                <div className="flex flex-row justify-between items-center">
                    <div>
                        <InputLabel>
                            <i className="fa fa-filter"></i>&nbsp;<b>Tipo de Solicitud:</b>
                        </InputLabel>
                        <Select
                            variant="filled"
                            className={classes.fControl}
                            labelId="tipo-solicitud-select-label"
                            id="tipo-solicitud-select"
                            value={tipoSolicitudSeleccionadoSelect.iIDTipoSolicitud}
                            label="Tipo de Solicitud"
                            fullWidth
                            defaultValue={0}
                            onChange={(e) => handleChangeTipoSolicitud(e.target.value)}
                        >
                            <MenuItem value={-1}>Todos los tipos de solicitud</MenuItem>
                            {listaTiposSolicitudAll.map((tipoSolicitud) => {
                                return (
                                    <MenuItem value={tipoSolicitud.iIDTipoSolicitud}>{tipoSolicitud.sNombre}</MenuItem>
                                );
                            })}
                        </Select>
                        <FormControlLabel
                            control={<Checkbox checked={bAllEstatus} onChange={handleChangeAllEstatus} />}
                            label="Todos los estatus"
                        />
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Tooltip title="Actualizar">
                                <Button
                                    className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    disableElevation
                                    onClick={(event) => {
                                        handleClickObtenerSolicitudes(tipoSolicitudSeleccionadoSelect.iIDTipoSolicitud);
                                    }}
                                >
                                    <Refresh className="text-xl mr-2" />
                                    Actualizar
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                    {/* Pending Status Filter */}
                </div>
                <Box
                    sx={{
                        height: 600,
                        width: "100%",
                        "& .odd": {
                            bgcolor: "rgba(0, 0, 0, 0.05)",
                        },
                        "& .even": {
                            bgcolor: "#ffffff",
                        },
                    }}
                >
                    <DataGrid
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        rows={rows}
                        columns={columns}
                        pageSize={pageSize}
                        disableSelectionOnClick
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        components={{ Row: CustomRow, Toolbar: QuickSearchToolbar }}
                        componentsProps={{
                            toolbar: {
                                value: searchText,
                                onChange: (event) => requestSearch(event.target.value),
                                clearSearch: () => requestSearch(""),
                            },
                        }}
                    />
                </Box>
            </div>
            {modalOpenSolicitudDetalle && <SeguimientoSolicitudDetalle />}
            {openDialog && (
                <FormatoSolicitudDialog
                    selectedValue={idSolicitudSelec}
                    open={openDialog}
                    onClose={handleCloseDialog}
                />
            )}
            {openFormAprobado && (
                <FormSolicitudAprobada openForm={openFormAprobado} handleCloseForm={handleCloseFormAprobado} />
            )}
            {openFormRechazado && <FormSolicitudRechazada open={openFormRechazado} />}
            {openFormEnvioOriginador && <FormSolicitudEnvOriginador />}
        </div>
    );
}
