// import React from "react";
import { useEffect, useState } from "react";
import {
    FormHelperText,
    Grid,
    TextField,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { inputColor, inputVariant } from "../../../../settings/inputConfig";
import { makeStyles } from "tss-react/mui";
import { useCatSolicitudRechazada } from "../../../../context/catalogos_generales/SolicitudRechazadaContext";
import DinercapModal from "../../../utils/DinercapModal";
import { useLayout } from "../../../../context/LayoutContext";

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: "none",
        },
        selectImg: {
            cursor: "pointer",
        },
    };
});

export default function FormConfiguracionSistema() {
    const {
        listaTipoSolicitud,
        handleListarTipoSolicitudes,
        openFormSolicitudRechazada,
        handleOpenFormSolicitudRechazada,
        formCatalogoSolicitudRechazada,
        handleChangeFormSolicitudR,
        handleSubmitFormSolicitudR,
        solicitudRSeleccionado,
    } = useCatSolicitudRechazada();

    const { slTipoSolicitud, txtDescripcion } = formCatalogoSolicitudRechazada;

    useEffect(() => {
        const llenarListaTipoSolicitudes = async () => {
            await handleListarTipoSolicitudes();
        };
        if (listaTipoSolicitud.length === 0) {
            llenarListaTipoSolicitudes();
        }
    }, [listaTipoSolicitud]);

    const [tipoDatoSeleccionado, setTipoDatoSeleccionado] = useState("");

    const handleChangeTipoSolicitud = (e, text) => {
        setTipoDatoSeleccionado(text);
    };

    return (
        <DinercapModal
            title={
                solicitudRSeleccionado.iIdInfosolicitudrechazada === 0 || solicitudRSeleccionado.iIdInfosolicitudrechazada === undefined
                ? "Agregar causa de solicitud rechazada"
                : "Actualizar causa de solicitud rechazada"
            }
            okFunc={handleSubmitFormSolicitudR}
            setOpen={handleOpenFormSolicitudRechazada}
            open={openFormSolicitudRechazada}
            okMessage="Guardar"
            size="small"
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <InputLabel id="lblTipoSolicitud">Tipo de solicitud</InputLabel>
                    <Select
                        name="slTipoSolicitud"
                        labelId="lblTipoSolicitud"
                        id="slTipoSolicitud"
                        label={slTipoSolicitud.label}
                        required={slTipoSolicitud.required}
                        value={slTipoSolicitud.value}
                        error={slTipoSolicitud.error}
                        defaultValue={0}
                        fullWidth
                        onChange={handleChangeFormSolicitudR}
                        // disabled={true}
                        className="mb-2"
                    >
                        <MenuItem value={0}>
                            <em>Seleccionar</em>
                        </MenuItem>
                        {listaTipoSolicitud.map((tipoS) => {
                            return (
                                <MenuItem
                                    onClick={(e) => handleChangeTipoSolicitud(e, String(tipoS.sNombre).toLowerCase())}
                                    key={tipoS.iIDTipoSolicitud}
                                    value={tipoS.iIDTipoSolicitud}
                                >
                                    {tipoS.sNombre}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText error={slTipoSolicitud.error}>
                        {slTipoSolicitud.error && slTipoSolicitud.message}
                    </FormHelperText>
                    <TextField
                        name={txtDescripcion.name}
                        label={txtDescripcion.label}
                        required={txtDescripcion.required}
                        value={txtDescripcion.value}
                        error={txtDescripcion.error}
                        helperText={txtDescripcion.error && txtDescripcion.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormSolicitudR}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        className="mb-2"
                        inputProps={{
                            maxLength: txtDescripcion.maxlength,
                        }}
                    />
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
