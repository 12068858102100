import { createContext, useContext, useState } from "react";
import { getModelValidacionCampos, getFormCamposValidacion } from "../models/ConfiguracionSolicitudModel";

import { fnPrevent, fnPreventBlur } from "../settings/preventConfig";
import { useRequest } from "./RequestContext";
import { useLayout } from "./LayoutContext";

import { 
    apiCampoBloqueListaBloquesRef, 
    apiCampoBloqueListaCamposRef, 
    apiCampoBloqueListaReglasByTipoDato,
    apiCampoBloqueValidacionDelete,
    apiCampoBloqueValidacionSave, 
    apiGetServers, 
    apiInputTypeBuscaOperadoresByInputType
} from "../settings/apiConfig";
import { useConfiguracionSolicitud } from "./ConfiguracionSolicitudContext";

export const CampoBloqueValidacionContext = createContext();

export function CampoBloqueValidacionProvider(props) {
    const { handleOpenAlert } = useLayout();
    const { Get, Post } = useRequest();
    const { 
        tipoSolicitudSeleccionado,
        campoBloqueSolicitudSeleccionado,
        formCamposValidacion,
        setFormCamposValidacion,
        setModalOpenFormCampoValidCU,
        handleListaCampoValidaciones
    } = useConfiguracionSolicitud();

    const [listaReglas, setListaReglas] = useState([]);
    const [listaCamposRef, setListaCamposRef] = useState([]);
    const [listaBloqueRef, setListaBloqueRef] = useState([]);
    const [listaOperadores, setListaOperadores] = useState([]);
    const [listaServers, setListaServers] = useState([]);
    const [validacionSeleccionado, setValidacionSeleccionado] = useState(getModelValidacionCampos());

    const handleListarReglas = () => {
        fnListarReglas();
    };

    const handleListarCamposRef = () => {
        fnListarCamposRef();
    };

    const handleListarBloquesRef = () => {
        fnListarBloquesRef();
    };

    const handleSubmitFormCampoValidacionCU = (e) => {
        fnPrevent(e);
        const { slReglaId, txtValorRef, slCampoRef, slBloqueRef, slOperadorId, txtMensajeError, 
            txtNombre, slServerId } = formCamposValidacion;

        const form = {
            ...formCamposValidacion,
            slReglaId: {
                ...slReglaId,
                error: slReglaId.value === 0,
            },
            txtNombre: {
                ...txtNombre,
                error: txtNombre.value === 0,
            },
            txtMensajeError: {
                ...txtMensajeError,
                error: txtMensajeError.value === "",
            },
        };
        setFormCamposValidacion(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }        
        const entCampoValidcion = {
            aValidacion: [
                {
                    iIDBloqueSolicitudCampos: campoBloqueSolicitudSeleccionado.iIDBloqueSolicitudCampos,
                    iIDUsuarioCreacion: 1,
                    iIdCamposValidacionReglas: slReglaId.value,
                    iIDTipoDato: campoBloqueSolicitudSeleccionado.iIDTipoDato,
                    bActivo: true,
                    bBaja: false,
                    iidcamporef: slCampoRef.value === 0 ? -1 : slCampoRef.value,
                    iidreglaref: -1,
                    iidbloqueref: slBloqueRef.value === 0 ? -1 : slBloqueRef.value,
                    svalorref: txtValorRef.value,
                    iidforeignkey: slServerId.value === 0 ?  -1 : slServerId.value,
                    sNombre: txtNombre.value,
                    smensajeerror: txtMensajeError.value,
                    ioperador: slOperadorId.value === 0 ? -1 : slOperadorId.value
                }
            ],
            iIDCampo:  campoBloqueSolicitudSeleccionado.iIDBloqueSolicitudCampos
        };
        fnGuardaValidacionCampo(entCampoValidcion);
    };

    const handleSubmitDeleteValidacion = (e, value) => {
        fnPrevent(e);
        const iIDBloqSolCampValidaciones = value;     
           
        if (iIDBloqSolCampValidaciones === 0) {
            handleOpenAlert("Seleccione un registro de validación para eliminar");
            return;
        }  
        const entCampoValidcion = {
            iIDBloqSolCampValidaciones: iIDBloqSolCampValidaciones,
            iIDBloqueSolicitudCampos: campoBloqueSolicitudSeleccionado.iIDBloqueSolicitudCampos,
            iIDUsuarioElimina: 10
        };
        fnEliminarValidacionCampo(entCampoValidcion);
    };

    const handleListarOperdoresPorTipoDato = () => {
        fnListarOperadoresByTipoDato();
    };

    const handleGetListServers = async () => {
        fnGetListServers();
    };

    const handleClickVerValidacion = async (objValidacion) => {
        setValidacionSeleccionado(objValidacion);
        fnFillFormValidacion(objValidacion);
        setModalOpenFormCampoValidCU(true);
    };

    const fnFillFormValidacion = async (objValidacion) => {        
        const { txtNombre, slReglaId, txtValorRef, slCampoRef, slBloqueRef, slOperadorId, txtMensajeError, slServerId } = getFormCamposValidacion();
        await setFormCamposValidacion({
                ...formCamposValidacion,
                iIDBloqSolCampValidaciones: objValidacion.iIDBloqSolCampValidaciones,
                txtNombre: {
                    ...txtNombre,
                    value: objValidacion.sNombre,
                },
                 slReglaId: {
                    ...slReglaId,
                    value: objValidacion.iIdCamposValidacionReglas,
                },
                txtValorRef: {
                    ...txtValorRef,
                    value: objValidacion.svalorref,
                },
                slCampoRef: {
                    ...slCampoRef,
                    value: objValidacion.iidcamporef
                },
                 slBloqueRef: {
                    ...slBloqueRef,
                    value: objValidacion.iidbloqueref
                },
                slOperadorId: {
                    ...slOperadorId,
                    value: objValidacion.ioperador
                },
                txtMensajeError: {
                    ...txtMensajeError,
                    value: objValidacion.smensajeerror
                },
                slServerId: {
                    ...slServerId,
                    value: objValidacion.iidforeignkey
                },
        });
    };

    const handleChangeValidacionNuevo = async () => {
        await setValidacionSeleccionado(getModelValidacionCampos());
    };
    //========================================================================

    const fnGetListServers = async () => {
        const payload = {
            loader: "Consultando listado de servers...",
            url: apiGetServers,
            alert: true
        };
        const response = await Get(payload);
        if (response.code === 201) {
            setListaServers(response.result);
        } else {
            setListaServers([]);
        }
    }

    const fnEliminarValidacionCampo = async (entCampoValidcion) => {
        const payload = {
            loader: "Eliminando campo validación...",
            url: apiCampoBloqueValidacionDelete,
            body: entCampoValidcion,
            alert: true,
        };
        const response = await Post(payload);
        if (response.iCodigo === 201) {
            setModalOpenFormCampoValidCU(false);
            await handleListaCampoValidaciones(campoBloqueSolicitudSeleccionado.iIDBloqueSolicitudCampos);
        }
        fnPreventBlur();
    };

    const fnGuardaValidacionCampo = async (entCampoValidciom) => {
        const payload = {
            loader: "Guardando campo validación...",
            url: apiCampoBloqueValidacionSave,
            body: entCampoValidciom,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            setModalOpenFormCampoValidCU(false);
            await handleListaCampoValidaciones(campoBloqueSolicitudSeleccionado.iIDBloqueSolicitudCampos);
        }
        fnPreventBlur();
    };

    const fnListarReglas = async () => {
        const payload = {
            loader: "Consultando listado de reglas...",
            url: apiCampoBloqueListaReglasByTipoDato.replace('{piIDTipoDato}', campoBloqueSolicitudSeleccionado.iIDTipoDato),
            alert: false,
        };

        const response = await Get(payload);
        if (response.code === 201) {
            setListaReglas(response.result);
        } else {
            setListaReglas([]);
        }
    };

    const fnListarCamposRef = async () => {
        const { iIDBloqueSolicitud } = campoBloqueSolicitudSeleccionado;
        const payload = {
            loader: "Consultando listado de campos ref...",
            url: apiCampoBloqueListaCamposRef.replace('{iIDBloque}', iIDBloqueSolicitud),
            alert: false,
        };

        const response = await Get(payload);
        if (response.code === 201) {
            setListaCamposRef(response.result);
        } else {
            setListaCamposRef([]);
        }
    };

    //por tipo de solicitud
    const fnListarBloquesRef = async () => {
        const payload = {
            loader: "Consultando listado de bloques ref...",
            url: apiCampoBloqueListaBloquesRef.replace('{piIDTipoSolicitud}', tipoSolicitudSeleccionado.iIDTipoSolicitud),
            alert: false,
        };

        const response = await Get(payload);
        if (response.code === 201) {
            setListaBloqueRef(response.result);
        } else {
            setListaBloqueRef([]);
        }
    };

    const fnListarOperadoresByTipoDato = async () => {
        const payload = {
            loader: "Consultando operadores por tipo de dato...",
            url: apiInputTypeBuscaOperadoresByInputType.replace('{iIDTipoDato}', campoBloqueSolicitudSeleccionado.iIDTipoDato),
            alert: false,
        };
        const response = await Get(payload);
        if (response.code === 201) {
            setListaOperadores(response.result);
        } else {
            setListaOperadores([]);
        }
    };

    return (
        <CampoBloqueValidacionContext.Provider
            value={{
                listaReglas,
                listaCamposRef,
                listaBloqueRef,
                listaOperadores,
                listaServers,
                validacionSeleccionado,
                handleListarReglas,
                handleListarCamposRef,
                handleListarBloquesRef,
                handleSubmitFormCampoValidacionCU,
                handleSubmitDeleteValidacion,
                handleListarOperdoresPorTipoDato,
                handleGetListServers,
                handleClickVerValidacion,
                handleChangeValidacionNuevo,
            }}
        >
            {props.children}
        </CampoBloqueValidacionContext.Provider>
    );
}

export function useCampoBloqueValidacion() {
    return useContext(CampoBloqueValidacionContext);
}