import { Fragment } from "react";
import DinercapAlert from "./utils/DinercapAlert";
import DinercapContent from "./layout/DinercapContent";
import DinercapLoader from "./utils/DinercapLoader";
import DinercapLogin from "./layout/DinercapLogin";
import { useLogin } from "../context/LoginContext";

export default function DinercapPage() {
    const { usuarioSesion, usuarioActivo } = useLogin();
    return (
        <Fragment>
            <DinercapLoader />
            <DinercapAlert />
            {
                usuarioSesion && usuarioActivo ? <DinercapContent /> : <DinercapLogin />
                /* {usuarioSesion && usuarioActivo && usuarioPermisos && entCatalogos ? <DinercapContent /> : <DinercapLogin />} */
                // <DinercapContent />
            }
        </Fragment>
    );
}
