import { Alert } from "@mui/lab";
import { Snackbar } from "@mui/material";
import { useLayout } from "../../context/LayoutContext";

export default function DinercapAlert() {
    const { alert, handleCloseAlert } = useLayout();

    return (
        <Snackbar
            open={alert.open}
            autoHideDuration={5000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
            <Alert onClose={handleCloseAlert} severity={alert.severity} elevation={6} variant="filled">
                {alert.message}
            </Alert>
        </Snackbar>
    );
}
