import React, { useEffect, useState, Fragment } from "react";
import Enumerable from "linq";
import { makeStyles } from "tss-react/mui";
import { AppBar, Button, Dialog, Grid, Slide, Toolbar, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DinercapTableHandle from "../../../utils/DinercapTableHandle";
import Swal from "sweetalert2";

import { IoAdd } from "react-icons/io5";

import { useAvisosPrivacidad } from "../../../../context/catalogos_generales/AvisosPrivacidadContext";

import Box from "@mui/material/Box";
import FormAvisoPrivacidad from "./FromAvisoPrivacidad";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles()((theme) => {
    return {
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        container: {
            maxHeight: 800,
        },
        root: {
            width: "100%",
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    };
});

export default function AvisosPrivacidadModal() {
    const {
        openAvisosPrivacidad,
        handleOpenAvisoPrivacidad,
        handleObtenerAvisosPrivacidad,
        handleActualizarAvisoPrivacidad,
        listaAvisosPrivacidad,
        openFormAvisoPrivacidad,
        handleOpenFormAvisoPrivacidad,
        handleEliminarAvisoPrivacidad,
    } = useAvisosPrivacidad();
    
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({
        iIdAvisoPrivacidad: -1,
    });


    const crearData = () => {
        const _data = Enumerable.from(listaAvisosPrivacidad)
            .select((avisoP, index) => ({
                id: index + 1,
                iIdAvisoPrivacidad: avisoP.iIdAvisoPrivacidad,
                iIdTipoSolicitud: avisoP.iIdTipoSolicitud,
                sNombreTipoSolicitud: avisoP.sNombreTipoSolicitud,
                sDescripcion: avisoP.sDescripcion,
                sBuroCredito: avisoP.sBuroCredito,
            }))
            .toArray();
        setData(_data);
    };

    const columns = [
        { field: "id", title: "#"},
        { field: "sNombreTipoSolicitud", title: "Tipo Solicitud"},
        {
            field: "sDescripcion",
            title: "Aviso de Privacidad",
            Element: ({ row }) => {
                return (
                    <Button onClick={() => {
                        Swal.fire({
                            title: "Aviso de Privacidad",
                            html: row.sDescripcion,
                            width: "60em",
                            confirmButtonText: "Ok",
                        });
                    }}>
                        Aviso de Privacidad {row.iIdAvisoPrivacidad}
                    </Button>
                );
            },
        },
        {
            field: "sBuroCredito",
            title: "Buro Credito",
            Element: ({ row }) => {
                return (
                    <Button onClick={() => {
                        Swal.fire({
                            title: "Buro Credito",
                            html: row.sBuroCredito,
                            confirmButtonText: "Ok",
                        });
                    }}>
                        Buro Credito {row.iIdAvisoPrivacidad}
                    </Button>
                );
            },
        },
    ];

    const onDelete = (e, row) => {
        handleEliminarAvisoPrivacidad(e, row);
    };

    const onUpdate = (e, row) => {
        handleActualizarAvisoPrivacidad(e, row);
    };

    useEffect(() => {
        handleObtenerAvisosPrivacidad();
    }, []);

    useEffect(() => {
        crearData();
    }, [listaAvisosPrivacidad]);

    return (
        <Fragment>
            <Dialog
                key="form"
                fullScreen
                open={openAvisosPrivacidad}
                onClose={() => handleOpenAvisoPrivacidad(false)}
                TransitionComponent={Transition}
            >
                <Box sx={{ flexFlow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title} component="div" sx={{ flexGrow: 1 }}>
                                Avisos de Privacidad
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => handleOpenAvisoPrivacidad(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
                <div className="flex flex-col gap-2 p-4">
                    <div className="flex flex-row justify-between items-center flex-wrap ml-2 mt-2 mr-2 ">
                        <div></div>
                        <div>
                            <Button
                                className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={(e) => handleOpenFormAvisoPrivacidad(true)}
                            >
                                <IoAdd className="text-xl mr-2" />
                                Crear
                            </Button>
                        </div>
                    </div>
                    <Box
                        component="div"
                        sx={{
                            height: 600,
                            width: "100%",
                            "& .odd": {
                                bgcolor: "rgba(0, 0, 0, 0.05)",
                            },
                            "& .even": {
                                bgcolor: "#ffffff",
                            },
                        }}
                    >
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} md={8}>
                            <DinercapTableHandle
                                id="iIdAvisoPrivacidad"
                                data={data}
                                columns={columns}
                                selectedRow={selectedRow}
                                setRow={setSelectedRow}
                                onDelete={onDelete}
                                onUpdate={onUpdate}
                                //onView={() => {}}
                            />
                        </Grid>
                    </Grid>
                    </Box>
                </div>
            </Dialog>
            {openFormAvisoPrivacidad && < FormAvisoPrivacidad/>}
        </Fragment>
    );
}