import { createContext, useContext } from "react";

import { makeStyles } from "tss-react/mui";
import { red } from "@mui/material/colors";
import theme from "../settings/themeConfig";

export const StyleContext = createContext();

const useStyles = makeStyles()((theme) => {
    return {
        absolute: {
            position: "absolute",
            top: theme.spacing(20),
            right: theme.spacing(3),
            zIndex: theme.zIndex.appBar + 1,
        },
        absoluteDiv: {
            padding: "10px 30px 20px",
            position: "fixed",
            top: theme.spacing(20),
            right: theme.spacing(12),
            lineHeight: 2.3,
            width: "auto",
            zIndex: theme.zIndex.appBar + 1,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 100000,
            color: "#fff",
            backgroundColor: "rgb(255 255 255 / 0.6)",
        },
        paperScrollBody: {
            verticalAlign: "top", // default center
        },
        dialogRoot: {
            zIndex: 100,
        },
        barra: {
            zIndex: 0,
        },
        button: {
            color: theme.palette.primary.main,
            backgroundColor: "#fff",
        },
        button_app: {
            color: "#086cae",
            backgroundColor: "#000",
        },
        rootNavBar: {
            backgroundColor: theme.palette.primary.main,
            color: "#fff",
        },
        link: {
            textDecoration: "none",
            color: "inherit",
        },
        nested: {
            paddingLeft: "50px !important",
        },
        drawerColor: {
            backgroundColor: "rgb(254 254 254 / 0.6)",
        },
        paperColor: {
            backgroundColor: theme.palette.primary.main,
        },
        list: {
            width: 300,
        },
        backColorMod: {
            backgroundColor: theme.palette.primary.main,
        },
        marginAccMod: {
            margin: "0px !important",
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        backColorSub: {
            backgroundColor: theme.palette.secondary.main,
        },
        marginAccSub: {
            margin: "0px !important",
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
        },
        hideLineAcc: {
            position: "initial",
        },
        cellButton: {
            padding: "0px 24px 0px 16px",
        },
        selectMain: {
            backgroundColor: theme.palette.primary.main,
            color: "#fff",
        },
        buttonCallCenter: {
            color: theme.palette.primary.main,
            backgroundColor: "#fff",
            textTransform: "none",
            marginTop: 10,
            marginBottom: 10,
            marginRight: 20,
        },
        buttonDanger: {
            color: "#fff",
            backgroundColor: red[500],
            textTransform: "none",
            marginTop: 10,
            marginBottom: 10,
            marginRight: 20,
        },
        ul: {
            display: "inline-flex",
            flexWrap: "initial",
        },
    };
});

export function StyleProvider(props) {
    const classes = useStyles();

    return <StyleContext.Provider value={{ classes }}>{props.children}</StyleContext.Provider>;
}

export function useStyle() {
    return useContext(StyleContext);
}
