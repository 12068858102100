import "./index.css";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom";

ReactDOM.render(
    <React.Fragment>
        <App />
    </React.Fragment>,
    document.getElementById("root")
);
