export const getModelAvisoPrivacidad = () => ({
    id: -1,
    iIdAvisoPrivacidad: -1,
    iIdTipoSolicitud: -1,
    sNombreTipoSolicitud: "",
    sDescripcion: "",
    sBuroCredito: "",

});

export const getFormAvisoPrivacidad = () => ({
    cmbBoxIdTipoSolicitud: {
        label: "Tipo de Solicitud:",
        name: "cmbBoxIdTipoSolicitud",
        required: true,
        value: "",
        error: false,
        isExist: false,
        message: "El Tipo de Solicitud es requerido",
        messageUnique: "No se puede repetir el tipo de solicitud",
        placeholder: "",
    },
    txtAvisoPrivacidad: {
        label: "Aviso de Privacidad:",
        name: "txtAvisoPrivacidad",
        required: true,
        value: "",
        error: false,
        message: "El Aviso de Privacidad es requerido",
        placeholder: "",
    },
    txtBuroCredito: {
        label: "Buro de Credito:",
        name: "txtBuroCredito",
        required: true,
        value: "",
        error: false,
        message: "El Buro de Crédito es requerido",
        placeholder: "",
    },
});
