//#region 
import React, { useEffect, useState, Fragment } from "react";
import { DataGrid, GridRowParams, esES } from '@mui/x-data-grid';
import {
    Grid,
    TextField,
    Radio,
    Box,
    Paper,
    Stack,
    Button,
    Select,
    MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { saveAs } from "file-saver";
import { useSolicitud } from "../../../context/SolicitudContext";
import DinercapModal from "../../utils/DinercapModal";
import { Tab, Tabs } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import { CloudDownloadOutlined, PhotoCameraOutlined } from "@mui/icons-material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import InnerImageZoom from "react-inner-image-zoom";
import { Link } from "@mui/material";
import {
    faCheck,
    faRemove,
} from "@fortawesome/free-solid-svg-icons";
import DinercapTablePersonalize from "../../utils/DinercapTablePersonalize";
import Enumerable from "linq";
import Swal from "sweetalert2";
import _ from 'lodash';
//#endregion

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormSolicitudEnvioOriginador() {
    const {
        openFormEnvioOriginador,
        handleOpenFormEnvioOriginador,
        handleCloseFormEnvioOriginador,
        handleSubmitFormProspecto,
        handlecreateData,
        handleSelectChange,
        handleSelectionModelChange,
        listaConyugueNuevo,
        listaDireccionNuevo,
        listaContactosNuevo,
        listaDetalleSolicitud,
        rowsDocumentos,
        selectedRowsDireccion,
        selectedRowsConyuge,
        selectedRowsContactos,
        selectedRowsDocumentos,
    } = useSolicitud();

    // #region Data 

    const [dataConyugeNuevo, setDataConyugeNuevo] = useState([]);

    const crearDataConyugeNuevo = () => {
        const _data = Enumerable.from(listaConyugueNuevo)
            .select((conyuge, index) => ({
                id: index + 1,
                iIdConyugeNew: 1,
                sNombre: conyuge.sNombre,
                sCurp: conyuge.sCurp,
                sRFC: conyuge.sRFC
            }))
            .toArray();
        setDataConyugeNuevo(_data);
    };

    const [dataDireccionesNuevo, setDataDireccionesNuevo] = useState([]);

    const crearDataDireccionesNuevo = () => {
        const _data = Enumerable.from(listaDireccionNuevo)
            .select((direccion, index) => ({
                id: index + 1,
                iIdDirNew: direccion.iIdDirNew,
                sDomicilio: direccion.sDomicilio,
                sMunicipio: direccion.sMunicipio,
                sEstado: direccion.sEstado,
                sCP: direccion.sCP,
                sTipoDir: direccion.sTipoDir
            }))
            .toArray();
        setDataDireccionesNuevo(_data);
    };

    const [dataContactosNuevo, setDataContactosNuevo] = useState([]);

    const crearDataContactosNuevo = () => {
        const _data = Enumerable.from(listaContactosNuevo)
            .select((contacto, index) => ({
                iIdContacto: contacto.iIdContact,
                sMedio: contacto.Medio,
                sTipo: contacto.Tipo,
                sContacto: contacto.Contacto,
                sNombreContacto: contacto.NombreContacto,
                Telefono: contacto.Telefono,
                Correo: contacto.Correo,
                mcClave: contacto.mcClave,
                tcClave: contacto.tcClave,
            }))
            .toArray();
        setDataContactosNuevo(_data);
    };

    //#endregion

    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        crearDataConyugeNuevo();
    }, [listaConyugueNuevo]);

    useEffect(() => {
        crearDataDireccionesNuevo();
    }, [listaDireccionNuevo]);

    useEffect(() => {
        crearDataContactosNuevo();
    }, [listaContactosNuevo]);

    useEffect(() => {
        handlecreateData();
    }, [listaDetalleSolicitud]);

    return (
        <DinercapModal
            title={
                "Envío datos originador"
            }
            okFunc={handleSubmitFormProspecto}
            setOpen={handleOpenFormEnvioOriginador}
            cancelFunc={handleCloseFormEnvioOriginador}
            open={openFormEnvioOriginador}
            okMessage="Actualizar originador"
        >
            <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth" textColor="primary" indicatorColor="primary">
                <Tab label="PERSONAL" />
                <Tab label="CONYUGE" />
                <Tab label="DIRECCION" />
                <Tab label="CONTACTOS" />
                <Tab label="DOCUMENTOS" />
            </Tabs>
            {selectedTab === 0 && (
                <PersonalSection />
            )}
            {selectedTab === 1 && (
                <ConyugeSection
                    rows={dataConyugeNuevo}
                    selectedRows={selectedRowsConyuge}
                    onSelectionModelChange={(newSelection) =>
                        handleSelectionModelChange(newSelection, 1)
                    }
                />
            )}
            {selectedTab === 2 && (
                <DireccionSection
                    rows={dataDireccionesNuevo}
                    selectedRows={selectedRowsDireccion}
                    onSelectionModelChange={(newSelection) =>
                        handleSelectionModelChange(newSelection, 2)
                    }
                />
            )}
            {selectedTab === 3 && (
                <ContactosSection
                    rows={dataContactosNuevo}
                    selectedRows={selectedRowsContactos}
                    onSelectionModelChange={(newSelection) =>
                        handleSelectionModelChange(newSelection, 3)
                    }
                />
            )}
            {selectedTab === 4 && (
                <DocumentosSection
                    rows={rowsDocumentos}
                    selectedRows={selectedRowsDocumentos}
                    onSelectionModelChange={(newSelection) =>
                        handleSelectionModelChange(newSelection, 4)
                    }
                    OnSelectChange={handleSelectChange}
                />
            )}
        </DinercapModal>
    );
}

//#region 
//#region Personal

function PersonalSection() {
    const {
        formEnvioDatosOriginador,
        formEnvioDatosNOriginador,
        handleChangeCheck,
        bAllCheks,
        handleChangeAllChecks,
    } = useSolicitud();

    const {
        chkNombre, chkAP, chkAM, chkCURP, chkFecNac, chkRFC, chkGenero, chkEC,
        chkDepEco, chkNivEst, chkNacionalidad, chkTipoPF, chkAR, chkPaisNac,
        chkEntNac, chkFIEL, chkGiroMerc, chkActSol, chkPersonaPE,
        txtNombre, txtAP, txtAM, txtCURP, txtFecNac, txtRFC, txtGenero, txtEC,
        txtDepEco, txtNivEst, txtNacionalidad, txtTipoPF, txtAR, txtPaisNac,
        txtEntNac, txtFIEL, txtGiroMerc, txtActSol, txtPersonaPE,
    } = formEnvioDatosOriginador;

    const {
        txtNNombre, txtNApellidoP, txtNApellidoM, txtNCURP, txtNFecNac, txtNRFC, txtNGenero, txtNEstC,
        txtNDepEco, txtNNivEst, txtNNacionalidad, txtNTipoPF, txtNAniosRe, txtNPaisNac,
        txtNEntNac, txtNFIEL, txtNGiroMerc, txtNActSol, txtNPersonaPE,
    } = formEnvioDatosNOriginador;

    const Div = styled('div')(({ theme }) => ({
        textAlign: 'center',
    }));

    const Item3 = styled(Paper)(({ theme }) => ({
        textAlign: 'center',
        padding: theme.spacing(1),
    }));

    const Item1 = styled(Paper)(({ theme }) => ({
        backgroundColor: '#abd5f0',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));

    const Item2 = styled(Paper)(({ theme }) => ({
        backgroundColor: '#82abd0',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox
                                name="chkAll"
                                checked={bAllCheks}
                                onChange={handleChangeAllChecks}
                                style={{
                                    transform: "scale(1.2)",
                                }}
                                //color="primary"
                                inputProps={{ "aria-label": "primary checkbox" }}
                            />}
                            label={<Typography style={{ fontWeight: "bold", fontSize: "1.2rem" }}>Marcar todos</Typography>}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Typography style={{ fontWeight: "bold" }} variant="h5" gutterBottom>
                            <Div>Nuevo</Div>
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">

                    </Grid>
                    <Grid item xs={5}>
                        <Typography style={{ fontWeight: "bold" }} variant="h5" gutterBottom>
                            <Div>Actual (Originador)</Div>
                        </Typography>
                    </Grid>
                </Stack>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkNombre" checked={chkNombre.value} onChange={(e) => handleChangeCheck(e, "chkNombre")} />}
                            label="Nombre" />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1 sx={{ width: "100%", }}>
                            <TextField
                                fullWidth
                                value={txtNNombre.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkNombre.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2 sx={{ width: "100%", }}>
                            <TextField
                                fullWidth
                                value={txtNombre.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkAP" checked={chkAP.value} onChange={(e) => handleChangeCheck(e, "chkAP")} />}
                            label="Apellido Paterno"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                value={txtNApellidoP.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkAP.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                value={txtAP.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkAM" checked={chkAM.value} onChange={(e) => handleChangeCheck(e, "chkAM")} />}
                            label="Apellido Materno"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                value={txtNApellidoM.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkAM.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                value={txtAM.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkCURP" checked={chkCURP.value} onChange={(e) => handleChangeCheck(e, "chkCURP")} />}
                            label="CURP"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                value={txtNCURP.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkCURP.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                value={txtCURP.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkFecNac" checked={chkFecNac.value} onChange={(e) => handleChangeCheck(e, "chkFecNac")} />}
                            label="Fecha Nacimiento"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                value={txtNFecNac.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkFecNac.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                value={txtFecNac.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkRFC" checked={chkRFC.value} onChange={(e) => handleChangeCheck(e, "chkRFC")} />}
                            label="RFC"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                value={txtNRFC.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkRFC.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                value={txtRFC.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkGenero" checked={chkGenero.value} onChange={(e) => handleChangeCheck(e, "chkGenero")} />}
                            label="Género"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                value={txtNGenero.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkGenero.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                value={txtGenero.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkEC" checked={chkEC.value} onChange={(e) => handleChangeCheck(e, "chkEC")} />}
                            label="Estado Civil"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                value={txtNEstC.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkEC.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                value={txtEC.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkDepEco" checked={chkDepEco.value} onChange={(e) => handleChangeCheck(e, "chkDepEco")} />}
                            label="Dep. Económicos"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                value={txtNDepEco.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkDepEco.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                value={txtDepEco.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkNivEst" checked={chkNivEst.value} onChange={(e) => handleChangeCheck(e, "chkNivEst")} />}
                            label="Nivel Estudios"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                value={txtNNivEst.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkNivEst.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                value={txtNivEst.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkNacionalidad" checked={chkNacionalidad.value} onChange={(e) => handleChangeCheck(e, "chkNacionalidad")} />}
                            label="Nacionalidad"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                value={txtNNacionalidad.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkNacionalidad.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                value={txtNacionalidad.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkTipoPF" checked={chkTipoPF.value} onChange={(e) => handleChangeCheck(e, "chkTipoPF")} />}
                            label="Tipo Persona Física"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                value={txtNTipoPF.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkTipoPF.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                value={txtTipoPF.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkAR" checked={chkAR.value} onChange={(e) => handleChangeCheck(e, "chkAR")} />}
                            label="Años Residencia"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                value={txtNAniosRe.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkAR.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                value={txtAR.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkPaisNac" checked={chkPaisNac.value} onChange={(e) => handleChangeCheck(e, "chkPaisNac")} />}
                            label="País de Nacimiento"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                value={txtNPaisNac.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkPaisNac.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                value={txtPaisNac.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkEntNac" checked={chkEntNac.value} onChange={(e) => handleChangeCheck(e, "chkEntNac")} />}
                            label="Entidad Nacimiento"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                value={txtNEntNac.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkEntNac.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                value={txtEntNac.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkGiroMerc" checked={chkGiroMerc.value} onChange={(e) => handleChangeCheck(e, "chkGiroMerc")} />}
                            label="Giro Mercantil"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                multiline
                                maxRows={3}
                                value={txtNGiroMerc.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkGiroMerc.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                multiline
                                maxRows={3}
                                value={txtGiroMerc.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkActSol" checked={chkActSol.value} onChange={(e) => handleChangeCheck(e, "chkActSol")} />}
                            label="Actividad Solicitante"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                multiline
                                maxRows={3}
                                value={txtNActSol.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkActSol.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                multiline
                                maxRows={3}
                                value={txtActSol.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row">
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={<Checkbox name="chkPersonaPE" checked={chkPersonaPE.value} onChange={(e) => handleChangeCheck(e, "chkPersonaPE")} />}
                            label="Persona Políticamente Expuesta"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Item1>
                            <TextField
                                fullWidth
                                value={txtNPersonaPE.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item1>
                    </Grid>
                    <Grid item xs="auto">
                        <Item3 key={0} elevation={0}>
                            <FontAwesomeIcon icon={chkPersonaPE.value ? faCheck : faRemove} size="2x" className="mr-1" />
                        </Item3>
                    </Grid>
                    <Grid item xs={5}>
                        <Item2>
                            <TextField
                                fullWidth
                                value={txtPersonaPE.value}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Item2>
                    </Grid>
                </Stack>
            </Grid>
        </Grid>
    );


}
//#endregion

//#region Conyuge

const ConyugeSection = ({ rows, selectedRows, onSelectionModelChange }) => {
    const {
        listaConyugue,
        bShowRConyuge,
        handleAddToArrayConyuge,
        mArrayConyuge,
    } = useSolicitud();

    const [dataConyuge, setDataConyuge] = useState([]);

    const crearDataConyuge = () => {
        const _data = Enumerable.from(listaConyugue)
            .select((conyuge, index) => ({
                id: index + 1,
                iIdConyugue: 1,
                sNombre: conyuge.sNombre,
                sCurp: conyuge.sCurp,
                sRFC: conyuge.sRFC
            }))
            .toArray();
        setDataConyuge(_data);
    };

    let radioChecked = listaConyugue.length === 0 ? 0 : [listaConyugue[0].id];
    const [selectionModelR, setSelectionModelR] = useState(radioChecked);
    radioChecked = selectionModelR;

    useEffect(() => {

    }, [selectedRows, rows]);

    useEffect(() => {
        crearDataConyuge();
    }, [listaConyugue]);

    useEffect(() => {
        if (selectedRowNew.length > 0)
            esIgual();
    }, [selectionModelR, dataConyuge]);

    let elemento = parseInt(String(selectedRows.slice(-1)).replace(/\[|\]/g, ''));
    const selectedRowNew = rows.filter((item) => {
        return item.iIdConyugeNew === elemento;
    });

    const selectedRow = dataConyuge.filter((item) => {
        return item.id === selectionModelR[0];
    });

    const esIgual = () => {
        if (selectedRow.length > 0) {
            handleAddToArrayConyuge(selectedRow[0].iIdConyugue, selectedRowNew);
        }
    };

    const columns = [
        {
            field: "radiobutton",
            headerName: "",
            width: 50,
            sortable: false,
            renderCell: (params) => (
                <Radio checked={radioChecked[0] === params.id} value={params.id} />
            )
        },
        { field: "iIdConyugue", headerName: "Id", width: 50, hide: true },
        { field: "sNombre", headerName: "Nombre", width: 300 },
        { field: "sCurp", headerName: "Curp", width: 250 },
        { field: "sRFC", headerName: "RFC", width: 250 },

    ];

    const columnsConyugeNew = [
        { field: "iIdConyugeNew", headerName: "Id", width: 50, hide: true },
        { field: "sNombre", headerName: "Nombre", width: 300 },
        { field: "sCurp", headerName: "Curp", width: 250 },
        { field: "sRFC", headerName: "RFC", width: 250 },
    ];

    return (
        <div className="card-body">
            <Box
                sx={{
                    height: 600,
                    width: "100%",
                }}
            >
                <Grid item xs={12} md={8}>
                    <Typography style={{ fontWeight: "bold" }} variant="h4" component="div" sx={{ flexGrow: 1 }}>
                        Nuevo
                    </Typography>
                </Grid>
                <br></br>
                <Grid item xs={12} md={8}>
                    <DataGrid
                        sx={{
                            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                display: "none"
                            }
                        }}
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    No hay información para mostrar
                                </Stack>
                            ),
                        }}
                        rows={rows}
                        columns={columnsConyugeNew}
                        autoHeight
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        hideFooter
                        selectionModel={selectedRows}
                        onSelectionModelChange={onSelectionModelChange}
                        checkboxSelection
                        disableRowSelectionOnClick
                        disableSelectionOnClick
                    />
                </Grid>
                <br></br>
                <Grid item xs={12} md={8}>
                    <Typography style={{ fontWeight: "bold" }} variant="h4" component="div" sx={{ flexGrow: 1 }}>
                        Actual (Originador)
                    </Typography>
                </Grid>
                <br></br>
                <Grid item xs={12} md={8}>
                    <DataGrid
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    No hay información para mostrar
                                </Stack>
                            ),
                        }}
                        rows={dataConyuge}
                        columns={columns}
                        autoHeight
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        hideFooter
                        columnVisibilityModel={{
                            // Hide columns, the other columns will remain visible
                            iIdConyugue: false,
                            radiobutton: bShowRConyuge,
                        }}
                        selectionModel={selectionModelR}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModelR(newSelectionModel);
                        }}
                    />
                </Grid>
            </Box>
        </div>
    );
}

//#endregion

//#region Direccion

const DireccionSection = ({ rows, selectedRows, onSelectionModelChange }) => {
    const {
        listaDireccion,
        bShowRDireccion,
        handleAddToArrayDir,
        mArrayDireccion
    } = useSolicitud();

    const [dataDirecciones, setDataDirecciones] = useState([]);

    const crearDataDirecciones = () => {
        const _data = Enumerable.from(listaDireccion)
            .select((direccion, index) => ({
                id: index + 1,
                iIdDir: direccion.iIdDir,
                sDomicilio: direccion.sDomicilio,
                sMunicipio: direccion.sMunicipio,
                sEstado: direccion.sEstado,
                sCP: direccion.sCP,
                sTipoDir: direccion.sTipoDir
            }))
            .toArray();
        setDataDirecciones(_data);
    };

    let radioChecked = [listaDireccion[0].id];
    const [selectionModelR, setSelectionModelR] = useState(radioChecked);
    radioChecked = selectionModelR;

    useEffect(() => {
    }, [selectedRows, rows]);

    useEffect(() => {
        crearDataDirecciones();
    }, [listaDireccion]);

    useEffect(() => {
        if (selectedRowNew.length > 0)
            esIgual();
    }, [selectionModelR, dataDirecciones]);


    let elemento = parseInt(String(selectedRows.slice(-1)).replace(/\[|\]/g, ''));
    const selectedRowNew = rows.filter((item) => {
        return item.iIdDirNew === elemento;
    });

    const selectedRow = dataDirecciones.filter((item) => {
        return item.id === selectionModelR[0];
    });

    const esIgual = () => {
        if (selectedRow.length > 0) {
            handleAddToArrayDir(selectedRow[0].iIdDir, selectedRowNew);
        }
    };

    const columns = [
        {
            field: "radiobutton",
            headerName: "",
            width: 50,
            sortable: false,
            renderCell: (params) => (
                <Radio checked={radioChecked[0] === params.id} value={params.id} />
            )
        },
        { field: "iIdDir", headerName: "Id", width: 50, hide: true },
        { field: "sDomicilio", headerName: "Domicilio", width: 200 },
        { field: "sMunicipio", headerName: "Municipio", width: 200 },
        { field: "sEstado", headerName: "Estado", width: 200 },
        { field: "sCP", headerName: "Código Postal", width: 150 },
        { field: "sTipoDir", headerName: "Tipo Dirección", width: 150 },

    ];

    const columnsDirNew = [
        { field: "iIdDirNew", headerName: "Id", width: 50, hide: true },
        { field: "sDomicilio", headerName: "Domicilio", width: 200 },
        { field: "sMunicipio", headerName: "Municipio", width: 200 },
        { field: "sEstado", headerName: "Estado", width: 200 },
        { field: "sCP", headerName: "Código Postal", width: 150 },
        { field: "sTipoDir", headerName: "Tipo Dirección", width: 150 },
    ];


    return (
        <div className="card-body">
            <Box
                sx={{
                    height: 600,
                    width: "100%",
                }}
            >
                <Grid item xs={12} md={8}>
                    <Typography style={{ fontWeight: "bold" }} variant="h4" component="div" sx={{ flexGrow: 1 }}>
                        Nuevo
                    </Typography>
                </Grid>
                <br></br>
                <Grid item xs={12} md={8}>
                    <DataGrid
                        sx={{
                            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                display: "none"
                            }
                        }}
                        rows={rows}
                        columns={columnsDirNew}
                        autoHeight
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        hideFooter
                        checkboxSelection
                        selectionModel={selectedRows}
                        onSelectionModelChange={onSelectionModelChange}
                        disableRowSelectionOnClick
                        disableSelectionOnClick
                    />
                </Grid>
                <br></br>
                <Grid item xs={12} md={8}>
                    <Typography style={{ fontWeight: "bold" }} variant="h4" component="div" sx={{ flexGrow: 1 }}>
                        Actual (Originador)
                    </Typography>
                </Grid>
                <br></br>
                <Grid item xs={12} md={8}>
                    <DataGrid
                        rows={dataDirecciones}
                        columns={columns}
                        autoHeight
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        hideFooter
                        columnVisibilityModel={{
                            // Hide columns, the other columns will remain visible
                            iIdDir: false,
                            radiobutton: bShowRDireccion,
                        }}
                        selectionModel={selectionModelR}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModelR(newSelectionModel);
                        }}
                    />
                </Grid>
            </Box>
        </div>
    );
}
//#endregion

//#region Contactos

const ContactosSection = ({ rows, selectedRows, onSelectionModelChange }) => {
    const {
        listaContactos,
        bShowRadioButton,
        handleAddToArray,
        miArray,
    } = useSolicitud();

    const [dataContactos, setDataContactos] = useState([]);

    const crearDataContactos = () => {
        const _data = Enumerable.from(listaContactos)
            .select((contacto, index) => ({
                id: index + 1,
                iIdContacto: contacto.iIdContacto,
                sMedio: contacto.Medio,
                sTipo: contacto.Tipo,
                sContacto: contacto.Contacto,
                sNombreContacto: contacto.NombreContacto,
            }))
            .toArray();
        setDataContactos(_data);
    };

    let radioChecked = [listaContactos[0].id];
    const [selectionModelR, setSelectionModelR] = useState(radioChecked);
    radioChecked = selectionModelR;

    useEffect(() => {
    }, [selectedRows, rows]);

    useEffect(() => {
        crearDataContactos();
    }, [listaContactos]);

    useEffect(() => {
        if (selectedRowNew.length > 0)
            esIgual();
    }, [selectionModelR, dataContactos]);


    let elemento = parseInt(String(selectedRows.slice(-1)).replace(/\[|\]/g, ''));
    const selectedRowNew = rows.filter((item) => {
        return item.iIdContacto === elemento;
    });

    const selectedRow = dataContactos.filter((item) => {
        return item.id === selectionModelR[0];
    });

    const esIgual = () => {
        if (selectedRow.length > 0) {
            if (selectedRow[0].sMedio == selectedRowNew[0].sMedio) {
                handleAddToArray(selectedRow[0].iIdContacto, selectedRowNew);
            }
            else {
                setSelectionModelR([]);
            }
        }
    };

    const columns = [
        {
            field: "radiobutton",
            headerName: "",
            width: 50,
            sortable: false,
            renderCell: (params) => (
                <Radio checked={radioChecked[0] === params.id} value={params.id} />
            )
        },
        { field: "iIdContacto", headerName: "Id", width: 50, hide: true },
        { field: "sMedio", headerName: "Medio", width: 200 },
        { field: "sTipo", headerName: "Tipo", width: 200 },
        { field: "sContacto", headerName: "Contacto", width: 200 },
        { field: "sNombreContacto", headerName: "Nombre Contacto", width: 400 },
    ];

    const columnsContactNew = [
        { field: "iIdContacto", headerName: "Id", width: 50, hide: true },
        { field: "sMedio", headerName: "Medio", width: 200 },
        { field: "sTipo", headerName: "Tipo", width: 200 },
        { field: "sContacto", headerName: "Contacto", width: 200 },
        { field: "sNombreContacto", headerName: "Nombre Contacto", width: 400 },
    ];

    return (
        <div className="card-body">
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                }}
            >
                <Grid item xs={12} md={8}>
                    <Typography style={{ fontWeight: "bold" }} variant="h4" component="div" sx={{ flexGrow: 1 }}>
                        Nuevo
                    </Typography>
                </Grid>
                <br></br>
                <Grid item xs={12} md={8}>
                    <Box>
                        <DataGrid
                            sx={{
                                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                    display: "none"
                                }
                            }}
                            rows={rows}
                            getRowId={(row) => row.iIdContacto}
                            columns={columnsContactNew}
                            autoHeight
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            hideFooter
                            checkboxSelection
                            selectionModel={selectedRows}
                            onSelectionModelChange={onSelectionModelChange}
                            disableRowSelectionOnClick
                            disableSelectionOnClick
                        />
                    </Box>
                </Grid>
                <br></br>
                <Grid item xs={12} md={8}>
                    <Typography style={{ fontWeight: "bold" }} variant="h4" component="div" sx={{ flexGrow: 1 }}>
                        Actual (Originador)
                    </Typography>
                </Grid>
                <br></br>
                <Grid item xs={12} md={8}>
                    <DataGrid
                        rows={dataContactos}
                        columns={columns}
                        autoHeight
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        hideFooter
                        columnVisibilityModel={{
                            // Hide columns, the other columns will remain visible
                            iIdContacto: false,
                            radiobutton: bShowRadioButton,
                        }}
                        //isRowSelectable={(params: GridRowParams) => params.row.sMedio != "Correo"}
                        selectionModel={selectionModelR}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModelR(newSelectionModel);
                        }}
                    />
                </Grid>
            </Box>
        </div>
    );
}
//#endregion

//#region Documentos
const DocumentosSection = ({ rows, selectedRows, onSelectionModelChange, OnSelectChange }) => {

    const {
        handleCreateArrayDocs,
        bchkEnviaDocumentos,
        handleChangeEnviaDocumentos,
    } = useSolicitud();

    const MapsURL = "https://www.google.com/maps/search/?api=1&query={lat},{lng}";
    const [urlImg, setUrlImg] = useState({ nombre: "", url: "" });
    const [rowSelected, setRowSelected] = useState(null);
    const [open, setOpen] = useState(false);
    const selectOptions = [
        { id: '3', label: 'PF -COMPROBANTE DOMICILIO' },
        { id: '11', label: 'DICTAMEN LEGAL SOLICITANTE' },
        { id: '25', label: 'PF - INE O PASAPORTE' },
        { id: '26', label: 'PF - NOMINA O ESTADO DE CUENTA' },
        { id: '27', label: 'PF - GENERALES' },
        { id: '28', label: 'PM - SOLICITANTE' },
        { id: '29', label: 'PM - AVAL' },
        { id: '30', label: 'PM - GARANTIA' },
        { id: '31', label: 'PM - ACTUALIZACIONES GENERALES' },
        { id: '32', label: 'PM - ACTUALIZACIONES LEGALES' },
        { id: '33', label: 'PM - ACTUALIZACIONES FINANCIERAS' },
        { id: '34', label: 'VERIFICACION DOMICILIARIA Y/O LABORAL' },
        { id: '35', label: 'CONTRATO' },
        { id: '36', label: 'CARATULA DE AUTORIZACION' },
        { id: '37', label: 'REPORTE DE CREDITO' },
        { id: '38', label: 'REPORTE COBRANZA' },
    ];

    const handleClickVerImg = (e, row) => {
        if (row.Valor !== "") {
            setRowSelected(row);
            setUrlImg({
                ...urlImg,
                nombre: row.Campo,
                url: row.Valor,
            });
            setOpen(true);
        } else {
            Swal.fire({
                title: "Imagen",
                text: "El campo es de tipo imagen pero no tiene valor.",
                icon: "warning",
            });
        }
    };

    const handleCloseModalImg = () => {
        setUrlImg({
            ...urlImg,
            nombre: "",
            url: "",
        });
        setOpen(false);
    };

    const verInfo = (e, row) => {
        e.preventDefault();
        let campo = row.Campo;
        let valor = row.Valor;
        Swal.fire({
            title: campo + ":",
            text: valor,
            icon: "info",
        });
    };

    const downloadImage = () => {
        const separador = "/";
        const result = rowSelected.Valor.split(separador);
        let fileName = result.length !== 0 ? result[result.length - 1] : "image.jpg";
        saveAs(rowSelected.Valor, fileName);
    };

    //#region columns
    const columns = [
        {
            field: "Acciones",
            align: "center",
            disableExport: true,
            renderCell: (cellValues) => {
                return (
                    <div>
                        {cellValues.row.Valor !== "" &&
                            (() => {
                                switch (cellValues.row.Tipo) {
                                    case "IMG":
                                        return (
                                            <Fragment>
                                                <Tooltip title="Ver imagen">
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="upload picture"
                                                        component="span"
                                                        onClick={(event) => {
                                                            handleClickVerImg(event, cellValues.row);
                                                        }}
                                                    >
                                                        <PhotoCameraOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                                {cellValues.row.ubicacion.lng !== null &&
                                                    cellValues.row.ubicacion.lat !== null ? (
                                                    <Tooltip title="Ubicación">
                                                        <a
                                                            href={MapsURL.replace(
                                                                "{lat}",
                                                                cellValues.row.ubicacion.lat
                                                            ).replace("{lng}", cellValues.row.ubicacion.lng)}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <LocationOnOutlinedIcon />
                                                        </a>
                                                    </Tooltip>
                                                ) : (
                                                    <></>
                                                )}
                                            </Fragment>
                                        );
                                    case "FILE":
                                        return (
                                            <Fragment>
                                                &nbsp;&nbsp;&nbsp;
                                                <Tooltip title="Descargar archivo">
                                                    <a href={cellValues.row.Valor} target="_blank" rel="noreferrer">
                                                        <CloudDownloadOutlined />
                                                    </a>
                                                </Tooltip>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {cellValues.row.ubicacion.lng !== null &&
                                                    cellValues.row.ubicacion.lat !== null ? (
                                                    <Tooltip title="Ubicación">
                                                        <a
                                                            href={MapsURL.replace(
                                                                "{lat}",
                                                                cellValues.row.ubicacion.lat
                                                            ).replace("{lng}", cellValues.row.ubicacion.lng)}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <LocationOnOutlinedIcon />
                                                        </a>
                                                    </Tooltip>
                                                ) : (
                                                    <></>
                                                )}
                                            </Fragment>
                                        );
                                    default:
                                        return "";
                                }
                            })()}
                    </div>
                );
            },
        },
        { field: "id", headerName: "ID", width: 100, hide: true },
        {
            field: "iIDBloqueSolicitudCampos",
            headerName: "Folio",
            width: 100,
            hide: true,
        },
        { field: "Campo", headerName: "Campo", flex: 1, },
        {
            field: "Valor",
            headerName: "Valor",
            width: 900,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Link
                        underline="none"
                        href="#"
                        onClick={(e) => {
                            verInfo(e, cellValues.row);
                        }}
                    >
                        {cellValues.row.Valor}
                    </Link>
                );
            },
            hide: true,
        },
        {
            field: 'select',
            headerName: '',
            flex: 1,
            renderCell: (params) => (
                <Select
                    disabled={!selectedRows.includes(params.row.id)}
                    value={params.row.selectedValue}
                    onChange={(event) => OnSelectChange(event, params.row.id)}
                    style={{ width: '100%' }}
                >
                    {selectOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            ),
        },
    ];
    //#endregion

    return (

        <div className="card-body">
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                }}
            >
                <div>
                    <FormControlLabel
                        control={<Checkbox checked={bchkEnviaDocumentos} onChange={handleChangeEnviaDocumentos} />}
                        label="Indica si deseas enviar los documentos de: Formato de solicitud, Formato de Buro y Reporte de crédito (Si se genero previamente) , ya que estos se generan automáticamente"
                    />
                </div>
                <Grid item xs={12} md={8}>
                    <Box>
                        <DataGrid
                            sx={{
                                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                    display: "none"
                                }
                            }}
                            rows={rows}
                            columns={columns}
                            autoHeight
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            hideFooter
                            checkboxSelection
                            selectionModel={selectedRows}
                            onSelectionModelChange={onSelectionModelChange}
                            getRowId={(row) => row.id}
                            disableRowSelectionOnClick
                            disableSelectionOnClick
                        />
                    </Box>
                </Grid>
            </Box>
            {urlImg.url !== "" && (
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseModalImg}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">Campo: {urlImg.nombre}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description" className="text-center">
                            <InnerImageZoom src={urlImg.url} zoomSrc={urlImg.url} zoomScale={1} width={500} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={downloadImage} color="secondary">
                            Descargar
                        </Button>
                        <Button onClick={handleCloseModalImg} color="primary">
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}

//#endregion
//#endregion