// import React from "react";
import { useEffect, useState } from "react";
import {
    FormHelperText,
    Grid,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
} from "@mui/material";
import { inputColor, inputVariant } from "../../../../settings/inputConfig";
import { makeStyles } from "tss-react/mui";
import { useConfiguracion } from "../../../../context/catalogos_generales/ConfiguracionContext";
import DinercapModal from "../../../utils/DinercapModal";
import { useLayout } from "../../../../context/LayoutContext";

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: "none",
        },
        selectImg: {
            cursor: "pointer",
        },
    };
});

export default function FormConfiguracionSistema() {
    const {
        openFormConfiguracion,
        handleOpenFormConfiguracion,
        formCatalogoConfiguracion,
        listaTipoDatos,
        handleListarTipoDatos,
        handleSubmitFormConfig,
        handleChangeFormConfig,
        configuracionSeleccionado,
    } = useConfiguracion();
    
    const { txtNombreConfig, txtValor, slTipoDeDatoCampoBloque, txtDescripcion } = formCatalogoConfiguracion;

    useEffect(() => {
        const llenarListaTipoDatos = async () => {
            await handleListarTipoDatos();
        };
        if (listaTipoDatos.length === 0) {
            llenarListaTipoDatos();
        }
    }, [listaTipoDatos]);

    const [tipoDatoSeleccionado, setTipoDatoSeleccionado] = useState("");

    const handleChangeTipoDato = (e, text) => {
        setTipoDatoSeleccionado(text);
    };

    return (
        <DinercapModal
            title={
                configuracionSeleccionado.iIdConfig === 0 || configuracionSeleccionado.iIdConfig === undefined
                ? "Agregar configuración de sistema"
                : "Actualizar configuración de sistema"
            }
            okFunc={handleSubmitFormConfig}
            setOpen={handleOpenFormConfiguracion}
            open={openFormConfiguracion}
            okMessage="Guardar"
            size="small"
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        name={txtNombreConfig.name}
                        label={txtNombreConfig.label}
                        required={txtNombreConfig.required}
                        value={txtNombreConfig.value}
                        error={txtNombreConfig.error}
                        helperText={txtNombreConfig.error && txtNombreConfig.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormConfig}
                        disabled={true}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        className="mb-2"
                        inputProps={{
                            maxLength: txtNombreConfig.maxlength,
                        }}
                    />
                    <TextField
                        name={txtValor.name}
                        label={txtValor.label}
                        required={txtValor.required}
                        value={txtValor.value}
                        error={txtValor.error}
                        helperText={txtValor.error && txtValor.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormConfig}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        className="mb-2"
                        inputProps={{
                            maxLength: txtValor.maxlength,
                        }}
                    />
                    <FormControl fullWidth
                        required
                        error={slTipoDeDatoCampoBloque.error}>
                        <InputLabel>{slTipoDeDatoCampoBloque.label}</InputLabel>
                        <Select
                            id={slTipoDeDatoCampoBloque.name}
                            label={slTipoDeDatoCampoBloque.label}
                            value={slTipoDeDatoCampoBloque.value}
                            name={slTipoDeDatoCampoBloque.name}
                            onChange={handleChangeFormConfig}
                            disabled={true}
                        >
                            <MenuItem value={0}>
                                <em>Seleccionar</em>
                            </MenuItem>
                            {listaTipoDatos.map((tipoI) => {
                                return (
                                    <MenuItem
                                        onClick={(e) => handleChangeTipoDato(e, String(tipoI.sNombre).toLowerCase())}
                                        key={tipoI.iIDTipoDato}
                                        value={String(tipoI.sNombre).toLowerCase()}
                                    >
                                        {String(tipoI.sNombre).toLowerCase()}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>{ slTipoDeDatoCampoBloque.error
                              && slTipoDeDatoCampoBloque.message}</FormHelperText>
                     </FormControl>
                    <TextField
                        name={txtDescripcion.name}
                        label={txtDescripcion.label}
                        required={txtDescripcion.required}
                        sx={{ mt: 1 }}
                        value={txtDescripcion.value}
                        error={txtDescripcion.error}
                        helperText={txtDescripcion.error && txtDescripcion.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormConfig}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        className="mb-2"
                        inputProps={{
                            maxLength: txtDescripcion.maxlength,
                        }}
                    />
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
