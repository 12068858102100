export const getModelOriginadorDinercap = () => ({
    id: -1,
    iIdCatOriginadorDinercap: -1,
    sCatalogo: "",
    sDescripcion: "",
    iIdCatSolicitudes: -1,
    iIdCatOriginador: -1,

});

export const getFormOriginadorDinercap = () => ({
    txtCatalogo: {
        label: "Identificador nodo xml:",
        name: "txtCatalogo",
        required: true,
        value: "",
        error: false,
        message: "El Catalogo es requerido",
        placeholder: "",
    },
    txtDescripcion: {
        label: "Descripcion:",
        name: "txtDescripcion",
        required: true,
        value: "",
        error: false,
        message: "La Descripcion es requerida",
        placeholder: "",
    },
    txtIdCatalogoSolicitud: {
        label: "Identificador campo fuente de datos:",
        name: "txtIdCatalogoSolicitud",
        required: true,
        value: "",
        error: false,
        message: "El Id Catalogo Solicitud es requerida",
        placeholder: "",
    },
    txtIdCatalogoOriginador: {
        label: "Id Catalogo Originador:",
        name: "txtIdCatalogoOriginador",
        required: true,
        value: "",
        error: false,
        message: "El Id Catalogo Originador es requerida",
        placeholder: "",
    },
    cmbBoxBloques: {
        label: "Bloques:",
        name: "cmbBoxBloques",
        value: "",
    },
    cmbBoxCampos: {
        label: "Campos con Fuente de Datos:",
        name: "cmbBoxCampos",
        value: "",
    },
});
