import { forwardRef } from "react";
import { Dialog, IconButton, Paper, Slide } from "@mui/material";
import { IoChevronBack } from "react-icons/io5";

import DinercapModalBody from "./DinercapModalBody";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DinercapModalFull(props) {
    const { open, setOpen, children, title, actions } = props;

    const mtButtons = actions === undefined ? null : actions;

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <div className="bg-modal-full flx-grw-1 pos-rel">
                <div>
                    <Paper elevation={0} className="border-b !rounded-none bg-header">
                        <div className="flex justify-between items-center w-full h-14 py-3 pr-3">
                            <div className="flex items-center">
                                <IconButton onClick={handleClose}>
                                    <IoChevronBack className="text-neutral-900" />
                                </IconButton>

                                <div className="text-lg font-medium">{title}</div>
                            </div>
                            <div>{mtButtons}</div>
                        </div>
                    </Paper>
                    <DinercapModalBody>{children}</DinercapModalBody>
                </div>
                <div style={{ height: 30 }}></div>
            </div>
        </Dialog>
    );
}
