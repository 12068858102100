import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    Grid,
    Tooltip,
    IconButton,
} from "@mui/material";
import { useEffect, useState, Fragment } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { CancelOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function DinercapTableHandle(props) {
    const {
        columns,
        data,
        defaultRow,
        id,
        onDelete = null,
        onUpdate = null,
        selectedRow,
        setRow,
        onView = null,
    } = props;

    const pager = [10, 50, 200, 500, 2000];
    const field = "field";

    const [rowsPerPage, setRowsPerPage] = useState(pager[0]);
    const [dataRows, setDataRows] = useState(data);
    const [filters, setFilters] = useState({});
    const [orderBy, setOrderBy] = useState("");
    const [order, setOrder] = useState(true);
    const [page, setPage] = useState(0);

    // Crear objecto de filtro
    useEffect(() => {
        const filterObj = {};
        columns.forEach((column) => {
            filterObj[column.field] = "";
        });
        setFilters(filterObj);
        // eslint-disable-next-line
    }, []);

    // Filtrar al escribir
    useEffect(() => {
        const keys = Object.keys(filters);
        const values = Object.values(filters);

        if (keys.length > 0) {
            if (values.every((x) => x === "")) {
                setDataRows(data);
            } else {
                let filterList = [...data];

                keys.forEach((key, i) => {
                    const filterText = values[i].trim().toUpperCase();

                    if (filterText !== "") {
                        filterList = filterList.filter((x) => {
                            return !x[key] ? false : x[key].toString().toUpperCase().includes(filterText);
                        });
                    }
                });
                setDataRows(filterList);
            }
        }
        // eslint-disable-next-line
    }, [filters]);

    // Actualizar datos originales
    useEffect(() => {
        setDataRows(data);
        const filterObj = { ...filters };
        const keys = Object.keys(filterObj);
        if (keys.length > 0) {
            keys.forEach((key) => {
                filterObj[key] = "";
            });
        }
        setFilters(filterObj);
        // eslint-disable-next-line
    }, [data]);

    if (!columns) {
        return;
    }

    if (!dataRows) {
        return;
    }

    // Cambiar de pagina
    const handleChangePage = (e, page) => {
        setPage(page);
    };

    // Cambiar las filas por pagina
    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    // Capturar al escribir en los filtros
    const handleChangeFilter = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
        setPage(0);
    };

    // Ordenar tabla
    const handleClickOrder = (field) => {
        const orderField = field === orderBy ? !order : order;

        let orderList = [];
        // eslint-disable-next-line
        if (orderField) {
            // eslint-disable-next-line
            orderList = dataRows.sort((a, b) => (a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0));
        } else {
            // eslint-disable-next-line
            orderList = dataRows.sort((a, b) => (a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0));
        }
        setDataRows(orderList);

        if (field === orderBy) {
            setOrder(orderField);
        } else {
            setOrderBy(field);
        }
    };

    // Al hacer clic en un fila de la tabla
    const handleClickRow = (valueRow) => () => {
        if (!defaultRow) {
            setRow(valueRow);
        } else {
            if (valueRow[id] === selectedRow[id]) {
                setRow(defaultRow);
            } else {
                setRow(valueRow);
            }
        }
    };

    const Cell = (props) => {
        const { columna, valor, registro } = props;
        const { Element = null, align = null, width = null, textOverflow = null } = columna;

        return Element === null ? (
            textOverflow == null ? ( 
                <TableCell
                    style={{ width: width !== null ? String(width) + "px" : "auto" }}
                    align={align === null ? "left" : align}
                >
                    {valor}
                </TableCell>
            ) : (
                <TableCell
                    style={{ width: width !== null ? String(width) + "px" : "auto" }}
                    align={align === null ? "left" : align}
                >
                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", width: "180px", display: "inline-block", textOverflow: "ellipsis" }}>{valor}</span>
                </TableCell>
            )
        ) : (
            textOverflow == null ? ( 
                <TableCell
                    style={{ width: width !== null ? String(width) + "px" : "auto" }}
                    align={align === null ? "left" : align}
                >
                    <Element row={registro} />
                </TableCell>
            ):
            (
                <TableCell
                    style={{ width: width !== null ? String(width) + "px" : "auto" }}
                    align={align === null ? "left" : align}
                >
                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", width: "180px", display: "inline-block", textOverflow: "ellipsis" }}><Element row={registro} /></span>
                </TableCell>
            )
        );
    };

    return (
        <div>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, i) => (
                                <TableCell key={i}>
                                    {!column.hideSort && (
                                        <Button
                                            startIcon={
                                                // eslint-disable-next-line
                                                orderBy === column.field ? (
                                                    order ? (
                                                        <ArrowUpwardIcon />
                                                    ) : (
                                                        <ArrowDownwardIcon />
                                                    )
                                                ) : (
                                                    <ArrowUpwardIcon />
                                                )
                                            }
                                            color={orderBy === column.field ? "secondary" : "primary"}
                                            onClick={() => handleClickOrder(column.field)}
                                        >
                                            {column.title}
                                        </Button>
                                    )}
                                </TableCell>
                            ))}
                            <TableCell align="center">
                                <Typography variant="button" color="primary">
                                    ACCIONES
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* style={{ whiteSpace: "nowrap" }} */}
                        <TableRow>
                            {columns.map((column, i) => (
                                <TableCell key={i}>
                                    {!column.hideFilter && (
                                        <TextField
                                            variant="outlined"
                                            name={column.field}
                                            fullWidth
                                            // eslint-disable-next-line
                                            placeholder={"Buscar en " + column.title + "..."}
                                            onChange={handleChangeFilter}
                                            autoComplete="off"
                                            value={!filters[column.field] ? "" : filters[column.field]}
                                            size="small"
                                        />
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                        {dataRows.length > 0 ? (
                            dataRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((dataRow, i) => (
                                <TableRow
                                    key={i}
                                    onClick={handleClickRow(dataRow)}
                                    style={{
                                        backgroundColor: dataRow[id] === selectedRow[id] ? "#eee" : "#fff",
                                        // cursor: 'pointer',
                                    }}
                                >
                                    {columns.map((column, j) => (
                                        <Cell
                                            key={j}
                                            columna={column}
                                            registro={dataRow}
                                            valor={dataRow[column[field]]}
                                        />
                                    ))}
                                    <TableCell align="center">
                                        <Grid>
                                            {onView !== null ? (
                                                <Tooltip title={"Detalle"}>
                                                    <span>
                                                        <IconButton size="small" onClick={(e) => onView(e, dataRow)}>
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                <></>
                                            )}
                                            {onUpdate !== null ? (
                                                <Tooltip title={"Editar"}>
                                                    <span>
                                                        <IconButton size="small" onClick={(e) => onUpdate(e, dataRow)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                <></>
                                            )}
                                            {onDelete !== null ? (
                                                <Tooltip title={"Eliminar"}>
                                                    <span>
                                                        <IconButton size="small" onClick={(e) => onDelete(e, dataRow)}>
                                                            <CancelOutlined />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                <></>
                                            )}
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    <Typography>No se encontraron registros para mostrar</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={dataRows.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={pager}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
}
