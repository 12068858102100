import React from "react";
import {
    FormHelperText,
    Grid,
    TextField,
    Card,
    CardActionArea,
    CardContent,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import { inputColor, inputVariant } from "../../../../settings/inputConfig";
import { makeStyles } from "tss-react/mui";
import { useDocumentosOCR } from "../../../../context/catalogos_generales/DocumentosOCRContext";
import DinercapModal from "../../../utils/DinercapModal";
import { useLayout } from "../../../../context/LayoutContext";
import Skeleton from "@mui/lab/Skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faCode } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: "none",
        },
        selectImg: {
            cursor: "pointer",
        },
    };
});

const enumTipos = {
    INE_FRENTE: "INE_FRENTE",
    INE_REVERSO: "INE_REVERSO",
    COMPROBANTE_DOMICILIO: "COMPROBANTE_DOMICILIO",
    OTRO_COMPROBANTE: "OTRO_COMPROBANTE",
};

export default function FormDocumento() {
    const classes = useStyles();
    const {
        openFormDocumento,
        handleOpenFormDocumento,
        formCatalogoDocumento,
        handleSubmitFormDocumento,
        handleChangeFormDocumento,
        documentoSeleccionado,
        handleChangeFileFormDocumento,
        handleChangeFormValor,
    } = useDocumentosOCR();

    const { loader } = useLayout();

    const { txtNombreDocto, txtTipo, txtArchivo, txtValorJW } = formCatalogoDocumento;
    return (
        <DinercapModal
            title={
                documentoSeleccionado.iIdDocumetosocr === 0 || documentoSeleccionado.iIdDocumetosocr === undefined
                    ? "Agregar documento"
                    : "Actualizar documento"
            }
            okFunc={handleSubmitFormDocumento}
            setOpen={handleOpenFormDocumento}
            open={openFormDocumento}
            okMessage="Guardar"
            size="small"
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        name={txtNombreDocto.name}
                        label={txtNombreDocto.label}
                        required={txtNombreDocto.required}
                        value={txtNombreDocto.value}
                        error={txtNombreDocto.error}
                        helperText={txtNombreDocto.error && txtNombreDocto.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormDocumento}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        className="mb-2"
                        inputProps={{
                            maxLength: txtNombreDocto.maxlength,
                        }}
                    />
                    <FormControl fullWidth required
                            error={txtTipo.error}>
                        <InputLabel>{txtTipo.label}</InputLabel>
                        <Select
                            id={txtTipo.name}
                            label={txtTipo.label}
                            name={txtTipo.name}
                            value={txtTipo.value}
                            onChange={handleChangeFormDocumento}
                        >
                            <MenuItem value="">
                                <em>Seleccione el tipo</em>
                            </MenuItem>
                            {Object.values(enumTipos).map((value) => (
                                <MenuItem value={value}>{value}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{txtTipo.error && txtTipo.message}</FormHelperText>
                    </FormControl>
                    <TextField
                        name={txtValorJW.name}
                        label={txtValorJW.label}
                        required={txtValorJW.required}
                        sx={{ mt: 1 }}
                        value={txtValorJW.value}
                        error={txtValorJW.error}
                        helperText={txtValorJW.error && txtValorJW.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormValor}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        className="mb-2"
                        InputProps={{
                            maxLength: txtValorJW.maxlength,
                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        />
                    {/*<TextField
                        name={txtValorJW.name}
                        label={txtValorJW.label}
                        required={txtValorJW.required}
                        sx={{ mt: 1 }}
                        value={txtValorJW.value}
                        error={txtValorJW.error}
                        helperText={txtValorJW.error && txtValorJW.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormValor}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        //className="mb-2"
                        inputProps={{
                            maxLength: txtValorJW.maxlength,
                        }}
                    /> */}
                    <Card sx={{ maxWidth: 600 }} className="dashed">
                        <CardActionArea>
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    SELECCIONAR ARCHIVO XML
                                </Typography>
                                {loader.open ? (
                                    <div>
                                        <Skeleton variant="react" width={530} height={140} />
                                    </div>
                                ) : (
                                    <label style={{ width: 100 }} htmlFor="contained-button-file">
                                        {txtArchivo.nombreArchivo === "" ? (
                                            <div class="dashed530 text-center">
                                                <FontAwesomeIcon icon={faUpload} size="2x" />
                                            </div>
                                        ) : (
                                            <div class="dashed530 text-center">
                                                <FontAwesomeIcon icon={faCode} size="2x" />
                                                <h1>{txtArchivo.nombreArchivo}</h1>
                                            </div>
                                        )}
                                    </label>
                                )}
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <input
                        accept="text/xml"
                        //className={classes.input}
                        style={{ display: "none" }}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={handleChangeFileFormDocumento}
                        name="txtArchivo"
                        required={txtArchivo.required}
                        error={txtArchivo.error}
                        helperText={txtArchivo.error && txtArchivo.message}
                    />
                    <FormHelperText error={txtArchivo.error}>{txtArchivo.error && txtArchivo.message}</FormHelperText>
                    
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
