export const getModelAlert = () => ({
    open: false,
    message: "",
    severity: "success",
});

export const getModelLoader = () => ({
    open: false,
    message: "Cargando...",
});
