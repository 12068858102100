import { createContext, useContext, useState } from "react";
import { apiGetGeoAreas, apiCSaveTipoArea } from "../../settings/apiConfig";
import { useRequest } from "../RequestContext";
import { useLayout } from "../LayoutContext";
import Enumerable from "linq";
import { getFormTipoAreaModel, getModelTipoArea } from "../../models/CatalogosModel";
import { fnHandleChangeTextValidations } from "../../settings/handleConfig";
import { fnPrevent } from "../../settings/preventConfig";
import Swal from "sweetalert2";

export const TipoAreaContext = createContext();

export function TipoAreaProvider(props) {
    const { Post, Get } = useRequest();
    const { handleOpenAlert } = useLayout();

    const [openTipoArea, setOpenTipoArea] = useState(false);
    const [listaTipoArea, setListaTipoArea] = useState([]);
    const [openFormTipoArea, setOpenFormTipoArea] = useState(false);
    const [formTipoArea, setFormTipoArea] = useState(getFormTipoAreaModel());
    const [tipoAreaSeleccionado, setTipoAreaSeleccionado] = useState(getModelTipoArea());
    const [isExistTipoArea, setIsExistTipoArea] = useState(false);

    const handleChangeFormTipoArea = fnHandleChangeTextValidations(formTipoArea, setFormTipoArea);

    const handleOpenTipoArea = (value) => {
        setOpenTipoArea(value);
    };
    const handleObtenerTipoArea = async () => {
        const payload = {
            loader: 'Consultando áreas',
            url: apiGetGeoAreas,
            alert: true,
        };
        const response = await Get(payload);
        if (response.iCodigo === 201) {
            const listTipoAreas = Enumerable.from(response.result)
                .select((item) => ({
                    iIDTipoArea: item.iIDTipoArea,
                    sNombreArea: item.sNombre,
                }))
                .toArray();
            setListaTipoArea(listTipoAreas);
        } else {
            handleOpenAlert("No se encontraron tipos de áreas.");

        }
    };

    const handleOpenFormTipoArea = async (value) => {
        setOpenFormTipoArea(value);
        if (!value) {
            setFormTipoArea(getFormTipoAreaModel());
            setTipoAreaSeleccionado(getModelTipoArea());
        }
    };


    const handleSubmitFormTipoArea = async (e) => {
        fnPrevent(e);

        const iIDTipoArea =
            tipoAreaSeleccionado.iIDTipoArea === undefined ? -1 : tipoAreaSeleccionado.iIDTipoArea;
        const { txtDescripcion } = formTipoArea;
        var sNombreArea = ""
        if (iIDTipoArea > 0) {
            const tipoarea = listaTipoArea.find(el => el.iIDTipoArea == iIDTipoArea);
            sNombreArea = tipoarea.sNombreArea;
        }

        if (sNombreArea != txtDescripcion.value || iIDTipoArea <= 0) {
            const exist = listaTipoArea.find(el => el.sNombreArea == txtDescripcion.value);
            if (exist) {
                txtDescripcion.isExist = true;
                setIsExistTipoArea(true);
                return false;
            }
        }

        const form = {
            ...formTipoArea,
            txtDescripcion: {
                ...txtDescripcion,
                error: txtDescripcion.value === "",
            }
        };

        setFormTipoArea(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }

        const entTipoArea = {
            iIDTipoArea: iIDTipoArea,
            sNombre: txtDescripcion.value,
            iIDUsuarioCreacion: iIDTipoArea === -1 ? 1 : 0,
            iIDUsuarioModificacion: iIDTipoArea !== -1 ? 1 : 0,
            iIDUsuarioElimina: 0,
        }

        await fn_guardarTipoArea(entTipoArea);
    };

    const fn_guardarTipoArea = async (entTipoArea) => {
        let loader = "";
        if (entTipoArea.iIDUsuarioCreacion > 0) {
            loader = "Guardando el área.";
        } else if (entTipoArea.iIDUsuarioModificacion > 0) {
            loader = "Actualizando el área.";
        } else if (entTipoArea.iIDUsuarioElimina > 0) {
            loader = "Eliminando el área.";
        }

        const payload = {
            loader: loader,
            body: entTipoArea,
            url: apiCSaveTipoArea,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            handleOpenFormTipoArea(false);
            setTipoAreaSeleccionado(getModelTipoArea());
            setFormTipoArea(getFormTipoAreaModel());
            await handleObtenerTipoArea();
        }
    };

    const handleActualizarTipoArea = (e, tipoArea) => {
        fnPrevent(e);
        setTipoAreaSeleccionado(tipoArea);

        const { txtDescripcion } = formTipoArea;
        const form = {
            ...formTipoArea,
            txtDescripcion: {
                ...txtDescripcion,
                value: tipoArea.sNombreArea,
            }
        };
        setFormTipoArea(form);
        setOpenFormTipoArea(true);
    };

    const handleEliminarTipoArea = async (e, tipoArea) => {

        fnPrevent(e);
        Swal.fire({
            title: "Eliminar tipo área",
            text: "¿Está seguro de eliminar el área " + tipoArea.sNombreArea + "?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await fn_eliminarTipoArea(tipoArea);
            }
        });
    };

    const fn_eliminarTipoArea = async (tipoArea) => {
        const entTipoArea = {
            iIDTipoArea: tipoArea.iIDTipoArea,
            iIDUsuarioElimina: 1,
            iIDUsuarioCreacion: 0,
            iIDUsuarioModificacion: 0,
        };

        await fn_guardarTipoArea(entTipoArea);
    };

    return (
        <TipoAreaContext.Provider
            value={{
                openTipoArea,
                listaTipoArea,
                openFormTipoArea,
                formTipoArea,
                tipoAreaSeleccionado,
                isExistTipoArea,
                setIsExistTipoArea,
                handleOpenTipoArea,
                handleObtenerTipoArea,
                handleOpenFormTipoArea,
                handleChangeFormTipoArea,
                handleSubmitFormTipoArea,
                handleActualizarTipoArea,
                handleEliminarTipoArea
            }}
        >
            {props.children}
        </TipoAreaContext.Provider>
    );
}

export function useTipoArea() {
    return useContext(TipoAreaContext);
}
