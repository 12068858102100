import { Button } from "@mui/material";
import { useStyle } from "../../context/StyleContext";
import React, { Component, useState } from 'react';
import { useLogin } from "../../context/LoginContext";

class DinercapCancelForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rfc: '',
            curp: '',
            nombre: '',
            telefono: '',
            argumento: '',
            errors_curp: false,
            errors_rfc: false,
            errors_telefono: false,
            errors: {
                rfc: false,
                curp: false,
                nombre: false,
                telefono: false,
                argumento: false,
            },
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    validateField = (fieldName) => {
        const { errors, [fieldName]: value } = this.state;
        let isValid = false;

        if (value.trim() !== '') {
            isValid = true;
        }

        this.setState((prevState) => ({
            errors: {
                ...prevState.errors,
                [fieldName]: !isValid,
            },
        }));

        return isValid;
    };

    validateFieldTipo = (fieldName) => {
        const { errors, [fieldName]: value } = this.state;
        let isValid = false;
        if (fieldName == "rfc") {
            isValid = this.validateRFC(value);
            this.setState({
                ["errors_" + fieldName]: !isValid
            });
        }
        else if (fieldName == "curp") {
            isValid = this.validateCURP(value);
            this.setState({
                ["errors_" + fieldName]: !isValid
            });
        }
        else if (fieldName == "telefono") {
            isValid = this.validatePhone(value);
            this.setState({
                ["errors_" + fieldName]: !isValid
            });
        }
        else {
            isValid = false;
        }
        return isValid;
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const isRFCValid = this.validateField('rfc');
        const isCURPValid = this.validateField('curp');
        const isNombreValid = this.validateField('nombre');
        const isTelefonoValid = this.validateField('telefono');
        const isArgumentoValid = this.validateField('argumento');
        if (isRFCValid && isCURPValid && isNombreValid && isTelefonoValid && isArgumentoValid) {

            const isRFCValid = this.validateFieldTipo("rfc");
            const isCURPValid = this.validateFieldTipo("curp");
            const isPhoneValid = this.validateFieldTipo("telefono");
            if (isRFCValid && isCURPValid && isPhoneValid) {
                const entSolicitudCancelacion = {
                    sRfc: this.rfc,
                    sCurp: this.curp,
                    sNombre: this.nombre,
                    sTelefono: this.telefono,
                    sArgumento: this.argumento,
                };
                const {
                    handleCorreoCancelacionSolicitud,
                } = useLogin();
                handleCorreoCancelacionSolicitud(entSolicitudCancelacion);
            }
        }
    }

    validateRFC = (rfc) => {
        const rfcPattern = /^[A-Z]{4}\d{6}[A-Z0-9]{3}$/;
        return rfcPattern.test(rfc);
    };

    validateCURP = (curp) => {
        const curpPattern = /^[A-Z]{4}[0-9]{6}[H,M][A-Z]{3}[0-9]{2}$/;
        return curpPattern.test(curp);
    };

    validateEmail = (email) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    };

    validatePhone = (phone) => {
        const phonePattern = /^\d+$/; // Expresión regular que verifica que solo haya dígitos
        const isValidPhone = phonePattern.test(phone) && phone.length == 10; // Máximo de 10 caracteres
        return isValidPhone;
    };


    render() {
        return (
            <div>
                <div className="form-container" style={{ backgroundColor: '#12b6cb', color: 'white', padding: '20px', borderRadius: '10px' }}>
                    <h2 style={{ textAlign: 'center', fontWeight: "bold", fontSize: '25px' }}>Solicitud de Cancelación de Cuenta</h2>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="rfc">RFC *:</label>
                            <input type="text" id="rfc" name="rfc" autocomplete="off" onChange={this.handleChange} value={this.rfc}
                                maxLength={13}
                                style={{ backgroundColor: 'white', color: '#000000', padding: '10px', width: '100%' }} />
                            {this.state.errors.rfc && <span className="error-message">Este campo es obligatorio.</span>}
                            {this.state.errors_rfc && <span className="error-message">No es valido.</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="curp">CURP *:</label>
                            <input type="text" id="curp" name="curp" autocomplete="off" onChange={this.handleChange} value={this.curp}
                                maxLength={18}
                                style={{ backgroundColor: 'white', color: '#000000', padding: '10px', width: '100%' }} />
                            {this.state.errors.curp && <span className="error-message">Este campo es obligatorio.</span>}
                            {this.state.errors_curp && <span className="error-message"> No es valido.</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="nombre">Nombre Completo *:</label>
                            <input type="text" id="nombre" name="nombre" autocomplete="off" onChange={this.handleChange} value={this.nombre}
                                maxLength={50}
                                style={{ backgroundColor: 'white', color: '#000000', padding: '10px', width: '100%' }} />
                            {this.state.errors.nombre && <span className="error-message">Este campo es obligatorio.</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="telefono">Teléfono *:</label>
                            <input type="text" id="telefono" name="telefono" autocomplete="off" onChange={this.handleChange} value={this.telefono}
                                maxLength={10}
                                style={{ backgroundColor: 'white', color: '#000000', padding: '10px', width: '100%' }} />
                            {this.state.errors.telefono && <span className="error-message">Este campo es obligatorio.</span>}
                            {this.state.errors_telefono && <span className="error-message"> No es valido.</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="argumento">Argumento *:</label>
                            <textarea id="argumento" name="argumento" onChange={this.handleChange} value={this.argumento}
                                maxLength={500}
                                style={{ backgroundColor: 'white', color: '#000000', padding: '10px', width: '100%', }} />
                            {this.state.errors.argumento && <span className="error-message">Este campo es obligatorio.</span>}
                        </div>
                        <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            style={{ color: "#000", backgroundColor: "#086cae" }}
                        >
                            Enviar Solicitud de Cancelación
                        </Button>
                    </form>
                </div>
            </div>
        );
    }
}

export default DinercapCancelForm;