import { Grid, Typography } from "@mui/material";

import DinercapInfoField from "../../utils/DinercapInfoField";
import DinercapModal from "../../utils/DinercapModal";
import { useConfiguracionSolicitud } from "../../../context/ConfiguracionSolicitudContext";

export default function EliminarBloqueSolicitud() {
    const {
        bloqueSolicitudSeleccionado,
        modalOpenEliminarBloqueSolicitud,
        handleOpenEliminarBloqueSolicitud,
        handleSubmitEliminarBloqueSolicitud,
    } = useConfiguracionSolicitud();

    return (
        <DinercapModal
            title="Eliminar Bloque"
            open={modalOpenEliminarBloqueSolicitud}
            setOpen={handleOpenEliminarBloqueSolicitud}
            okFunc={handleSubmitEliminarBloqueSolicitud}
            // align="center"
            size="small"
        >
            <Grid container spacing={1} className="center">
                <Grid item xs={12}>
                    <Typography paragraph align="center">
                        ¿Desea eliminar el siguiente bloque?
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <DinercapInfoField label="Bloque:" value={bloqueSolicitudSeleccionado.sNombreBloque} />
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
