// import React from "react";
import { useEffect, useState } from "react";
import {
    FormHelperText,
    Grid,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
} from "@mui/material";
import { inputColor, inputVariant } from "../../../../settings/inputConfig";
import { makeStyles } from "tss-react/mui";
import { useReglasOpeContext } from "../../../../context/catalogos_generales/ReglasOperadoresContext";
import { enumTipos } from "../../../../models/CatalogosModel";
import DinercapModal from "../../../utils/DinercapModal";

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: "none",
        },
        selectImg: {
            cursor: "pointer",
        },
    };
});

export default function FormConfiguracionSistema() {
    const {
        formReglasOperadores,
        openFormReglasOperadores,
        handleOpenFormReglasOperador,
        reglaOpeSeleccionado,
        listaTipoDatos,
        listaOperadores,
        tipoDatoSeleccionado,
        operadorSeleccionado,
        handleSubmitFormReglaOpe,
        handleOnChangeTipoSolicitud,
        handleOnChangeOperador,
        validacionSelect,
        handleOnChangeValidacion,
    } = useReglasOpeContext();

    const { slValidacion, slTipoDato, slOperador } = formReglasOperadores;
    // const [tipoDatoSeleccionado, setTipoDatoSeleccionado] = useState("");

    // useEffect(() => {
    //     const llenarListaTipoDatos = async () => {
    //         await handleListarTipoDatos();
    //     };
    //     if (listaTipoDatos.length === 0) {
    //         llenarListaTipoDatos();
    //     }
    // }, [listaTipoDatos]);

    // useEffect(() => {
    //     handleListarOperdoresPorTipoDato();
    // }, [listaOperadores]);

    const handleChangeTipoDato = (e, text) => {
        slTipoDato.value = text;
    };

    // useEffect(() => {
    //     const llenarListaOperadores= async () => {
    //         await handleListarOperdoresPorTipoDato();
    //     };
    //     if (listaOperadores.length === 0) {
    //         llenarListaOperadores();
    //     }
    // }, [listaOperadores]);

    return (
        <DinercapModal
            title={
                reglaOpeSeleccionado.id === 0 || reglaOpeSeleccionado.id === undefined
                ? "Agregar regla operador"
                : "Actualizar regla operador"
            }
            okFunc={handleSubmitFormReglaOpe}
            setOpen={handleOpenFormReglasOperador}
            open={openFormReglasOperadores}
            okMessage="Guardar"
            size="small"
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl fullWidth
                        required
                        error={slValidacion.error}>
                        <InputLabel>{slValidacion.label}</InputLabel>
                        <Select
                            name="slValidacion"
                            label={slValidacion.label}
                            id="slValidacion"
                            value={validacionSelect}
                            onChange={handleOnChangeValidacion}
                            autoComplete="off"
                            defaultValue={0}
                        >
                        {enumTipos.map((validate) => {
                            return (
                                <MenuItem
                                    key={validate.id}
                                    value={validate.text}
                                >
                                    {validate.text}
                                </MenuItem>
                            );
                        })}
                        </Select>
                        <FormHelperText>{ slTipoDato.error
                              && slTipoDato.message}</FormHelperText>
                    </FormControl>
                    {/* <TextField
                        name={txtValidacion.name}
                        label={txtValidacion.label}
                        required={txtValidacion.required}
                        value={txtValidacion.value}
                        error={txtValidacion.error}
                        helperText={txtValidacion.error && txtValidacion.message}
                        variant={inputVariant}
                        color={inputColor}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        className="mb-2"
                        inputProps={{
                            maxLength: txtValidacion.maxlength,
                        }}
                    /> */}
                    <FormControl fullWidth
                        required
                        error={slTipoDato.error}>
                        <InputLabel>{slTipoDato.label}</InputLabel>
                        <Select
                            id={slTipoDato.name}
                            label={slTipoDato.label}
                            sx={{ mt: 1 }}
                            value={tipoDatoSeleccionado}
                            name={slTipoDato.name}
                            onChange={handleOnChangeTipoSolicitud}
                        >
                            <MenuItem value={0}>
                                <em>Seleccionar</em>
                            </MenuItem>
                            {listaTipoDatos.map((tipoI) => {
                                return (
                                    <MenuItem
                                        // onClick={(e) => handleChangeTipoDato(e, tipoI.iIDTipoDato)}
                                        key={tipoI.iIDTipoDato}
                                        value={tipoI.iIDTipoDato}
                                    >
                                        {String(tipoI.sNombre).toUpperCase()}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>{ slTipoDato.error
                              && slTipoDato.message}</FormHelperText>
                     </FormControl>
                     <FormControl fullWidth
                        required
                        error={slOperador.error}>
                        <InputLabel>{slOperador.label}</InputLabel>
                        <Select
                            id={slOperador.name}
                            label={slOperador.label}
                            sx={{ mt: 1 }}
                            value={operadorSeleccionado}
                            name={slOperador.name}
                            onChange={handleOnChangeOperador}
                        >
                            <MenuItem value={0}>
                                <em>Seleccionar</em>
                            </MenuItem>
                            {listaOperadores.map((item) => {
                                return (
                                    <MenuItem key={item.iIdOperador} value={item.iIdOperador}>
                                        {item.sOperador}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>{ slOperador.error
                              && slOperador.message}</FormHelperText>
                     </FormControl>
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
