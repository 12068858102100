import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "tss-react/mui";
import { useParams } from "react-router-dom";
import Enumerable from "linq";

import { urlSystem, urlDefault } from "../../../settings/urlConfig";

import { useLogin } from "../../../context/LoginContext";
import { useConfiguracionDatos } from "../../../context/ConfiguracionDatosContext";

import {
    IoAdd,
    IoDocumentOutline,
    IoEllipsisVerticalOutline,
    IoHomeOutline,
    IoOptionsOutline,
    IoTrashOutline,
} from "react-icons/io5";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {
    Button,
    Checkbox,
    Grid,
    Container,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Collapse,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ListItemSecondaryAction,
    IconButton,
    Tooltip,
    Divider,
} from "@mui/material";
import { ExpandLess, ExpandMore, Assignment } from "@mui/icons-material";

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: "100%",
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    };
});

function CamposHijos(props) {
    const { campo/*, handleChangeItemCampo*/, handleClickItemCampo } = props;
    const { aCamposAgrupado } = campo;
    const [open, setOpen] = useState(false);

    const classes = useStyles();

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Fragment>
            <ListItem button onClick={handleClick}>
                <ListItemText primary={campo.sNombre} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="nav" disablePadding>
                    {aCamposAgrupado.map((campoAgrup, index) => (
                        <ListItem button key={index + 40000} className={classes.nested}>
                            {/* {campoAgrup.inputForms.sNombre === "IMG" && (
                                <ListItemIcon>
                                    <Tooltip title="¿Requiere OCR?">
                                        <Checkbox
                                            edge="start"
                                            checked={campoAgrup.bReqOCR}
                                            tabIndex={-1}
                                            disableRipple
                                            onChange={(e) => handleChangeItemCampo(e, campoAgrup)}
                                        />
                                    </Tooltip>
                                </ListItemIcon>
                            )} */}
                            <ListItemText primary={campoAgrup.sNombre} />
                            {/* {!["FILE", "IMG", "ALL"].some((el) => campoAgrup.inputForms.sNombre.includes(el)) && (
                                <ListItemSecondaryAction>
                                    <Tooltip title="Asignar documentos">
                                        <IconButton
                                            edge="end"
                                            onClick={(e) => {
                                                handleClickItemCampo(campoAgrup);
                                            }}
                                        >
                                            <Assignment />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            )} */}
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </Fragment>
    );
}

function DialogDocumentos(props) {
    const { open } = props;
    const {
        listaDocumentos,
        handleOpenDocumentos,
        campoSeleccionado,
        handleObtenerDatosDir,
        documentosSeleccionados,
        handleSubmitDocumentoCampo,
    } = useConfiguracionDatos();
    const classes = useStyles();
    const [checked, setChecked] = useState([]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleClick = (e) => {
        handleSubmitDocumentoCampo(e, checked);
    };

    useEffect(() => {
        handleObtenerDatosDir();
        setChecked(documentosSeleccionados);
    }, []);

    return (
        <Dialog maxWidth="xs" open={open}>
            <DialogTitle id="confirmation-dialog-title">{campoSeleccionado.sNombre}</DialogTitle>
            <DialogContent dividers>
                <div style={{ minWidth: 300 }}>
                    <List className={classes.root}>
                        {listaDocumentos.map((docto, index) => {
                            const labelId = `checkbox-list-label-${index + 1}`;

                            return (
                                <ListItem
                                    key={index + 1}
                                    role={undefined}
                                    dense
                                    button
                                    onClick={handleToggle(docto.iIdDatosDireccion)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={checked.indexOf(docto.iIdDatosDireccion) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ "aria-labelledby": labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={docto.sNombre} />
                                </ListItem>
                            );
                        })}
                    </List>
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => handleOpenDocumentos(false)} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleClick} color="primary">
                    Asignar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function ConfiguracionOCRScreen() {
    const { handleClickRuta } = useLogin();
    const {
        listaSolicitudes,
        handleObtenerSolicitudes,
        handleClickItemSolicitud,
        solicitudSeleccionado,
        listaBloques,
        bloqueSeleccionado,
        handleClickItemBloque,
        listaCampos,
        handleClickItemCampo,
        openDocumentos,
        //handleChangeItemCampo,
    } = useConfiguracionDatos();
    let { bMostrarTipoSolicitud } = useParams();

    const classes = useStyles();

    const handleClickHome = (e) => {
        handleClickRuta(e, {
            pathname: urlDefault,
        });
    };

    const handleClickTipoSolicitudes = (e) => {
        handleClickRuta(e, {
            pathname: urlSystem.tiposSolicitudes,
        });
    };

    useEffect(() => {
        handleObtenerSolicitudes();
    }, []);

    return (
        <div>
            <div className="flex flex-col md:flex-row items-start md:items-center md:justify-between h-14 p-3 border-b bg-header">
                <div className="flex flex-row items-center">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            className={
                                "flex !font-medium px-2 py-1 hover:!no-underline hover:!cursor-pointer hover:!bg-gray-100 hover:!rounded-full"
                            }
                            color="inherit"
                            onClick={(e) => handleClickHome(e)}
                        >
                            <IoHomeOutline className="text-2xl mr-2" />
                            Inicio
                        </Link>
                        {bMostrarTipoSolicitud === "true" && (
                            <Link
                                className={
                                    "flex !font-medium px-2 py-1 hover:!no-underline hover:!cursor-pointer hover:!bg-gray-100 hover:!rounded-full"
                                }
                                color="inherit"
                                onClick={(e) => handleClickTipoSolicitudes(e)}
                            >
                                <IoDocumentOutline className="text-2xl mr-2" />
                                <span>Tipos de Solicitudes</span>
                            </Link>
                        )}
                        <Typography color="textPrimary" className={"flex !font-medium px-2 py-1 "}>
                            <IoOptionsOutline className="text-2xl mr-2" />
                            <span>Configuración de informacion</span>
                        </Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <div className="flex flex-col gap-2 mx-auto p-4">
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <div className="flex flex-col bg-card shadow rounded-2xl overflow-hidden">
                                <div className="flex flex-col p-4 space-y-2 bg-white">
                                    <div className="font-medium leading-tight truncate text-black">
                                        Tipo solicitudes
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="p-4 bg-gray-50 text-black">
                                        <div className={classes.root}>
                                            <List component="nav">
                                                {listaSolicitudes.map((sol, index) => (
                                                    <ListItem
                                                        key={index + 10000}
                                                        button
                                                        selected={
                                                            solicitudSeleccionado.iIDTipoSolicitud ===
                                                            sol.iIDTipoSolicitud
                                                        }
                                                        onClick={() => handleClickItemSolicitud(sol)}
                                                    >
                                                        <ListItemIcon>
                                                            <i className={"fas fa-" + sol.sIcono}></i>
                                                        </ListItemIcon>
                                                        <ListItemText primary={sol.sDescripcion} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="flex flex-col bg-card shadow rounded-2xl overflow-hidden">
                                <div className="flex flex-col p-4 space-y-2 bg-white">
                                    <div className="font-medium leading-tight truncate text-black">
                                        {"Bloques de la solicitud: " + solicitudSeleccionado.sDescripcion}
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="p-4 bg-gray-50 text-black">
                                        <div className={classes.root}>
                                            {listaBloques.length > 0 ? (
                                                <List component="nav">
                                                    {listaBloques.map((bloque, index) => (
                                                        <ListItem
                                                            key={index + 20000}
                                                            button
                                                            selected={
                                                                bloqueSeleccionado.iIDBloqueSolicitud ===
                                                                bloque.iIDBloqueSolicitud
                                                            }
                                                            onClick={() => handleClickItemBloque(bloque)}
                                                        >
                                                            <ListItemIcon>
                                                                <i className={"fas fa-" + bloque.sIcono}></i>
                                                            </ListItemIcon>
                                                            <ListItemText primary={bloque.sNombreBloque} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            ) : (
                                                <div className="font-medium leading-tight truncate text-black text-center">
                                                    <h1>SELECCIONE UNA SOLICITUD</h1>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="flex flex-col bg-card shadow rounded-2xl overflow-hidden">
                                <div className="flex flex-col p-4 space-y-2 bg-white">
                                    <div className="font-medium leading-tight truncate text-black">
                                        {"Campos del bloque: " + bloqueSeleccionado.sNombreBloque}
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="p-4 bg-gray-50 text-black">
                                        <div className={classes.root}>
                                            {listaCampos.length > 0 ? (
                                                <List component="nav" height={400}>
                                                    {listaCampos.map((campo, index) =>
                                                        campo.aCamposAgrupado.length > 0 ? (
                                                            <CamposHijos
                                                                key={index + 30000}
                                                                campo={campo}
                                                                //handleChangeItemCampo={handleChangeItemCampo}
                                                                handleClickItemCampo={handleClickItemCampo}
                                                            />
                                                        ) : (
                                                            <div
                                                                key={index + 30000}
                                                                className={
                                                                    campo.entCatDocumentoDatos.length === 0
                                                                        ? "bg-white"
                                                                        : "bg-slate-100"
                                                                }
                                                            >
                                                                <ListItem button>
                                                                    {/* {campo.inputForms.sNombre === "IMG" && (
                                                                        <ListItemIcon>
                                                                            <Tooltip title="¿Requiere OCR?">
                                                                                <Checkbox
                                                                                    edge="start"
                                                                                    checked={campo.bReqOCR}
                                                                                    tabIndex={-1}
                                                                                    disableRipple
                                                                                    onChange={(e) =>
                                                                                        handleChangeItemCampo(e, campo)
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        </ListItemIcon>
                                                                    )} */}
                                                                    <ListItemText primary={campo.sNombre} />
                                                                    {listaCampos.filter((cam) => cam.bReqOCR).length >
                                                                    0 ? (
                                                                        !["FILE", "ALL"].some((el) =>
                                                                            campo.inputForms.sNombre.includes(el)
                                                                        ) &&
                                                                        (!campo.bReqOCR &&
                                                                        campo.inputForms.sNombre === "IMG" ? (
                                                                            <></>
                                                                        ) : (
                                                                            <ListItemSecondaryAction>
                                                                                <Tooltip title="Asignar documentos">
                                                                                    <IconButton
                                                                                        edge="end"
                                                                                        onClick={(e) => {
                                                                                            handleClickItemCampo(campo);
                                                                                        }}
                                                                                    >
                                                                                        <Assignment />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </ListItemSecondaryAction>
                                                                        )
                                                                        )
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </ListItem>
                                                                <Divider />
                                                            </div>
                                                        )
                                                    )}
                                                </List>
                                            ) : (
                                                <div className="font-medium leading-tight truncate text-black text-center">
                                                    <h1>SELECCIONE UN BLOQUE</h1>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    {openDocumentos && <DialogDocumentos open={openDocumentos} />}
                </Container>
            </div>
        </div>
    );
}
