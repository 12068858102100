import React, { useEffect } from "react";
import { FormHelperText, Grid, TextField, FormControl, InputLabel, Select, MenuItem, } from "@mui/material";
import { withStyles } from "tss-react/mui";
import InputBase from "@mui/material/InputBase";
import swal from "sweetalert";

import { useConfiguracionSolicitud } from "../../../../context/ConfiguracionSolicitudContext";
import { useAprobados } from "../../../../context/catalogos_generales/AprobadosContext";
import { inputColor, inputVariant } from "../../../../settings/inputConfig";
import { useTipoSolicitud } from "../../../../context/TipoSolicitudContext";
import DinercapModal from "../../../utils/DinercapModal";

export default function FormAprobado() {
    const {
        openFormAprobado,
        handleOpenFormAprobado,
        formAprobado,
        handleSubmitFormAprobado,
        handleChangeFormAprobado,
        aprobadoSeleccionado,
    } = useAprobados();

    const {
        listaTiposSolicitud,
        handleClickObtenerTiposSolicitud,
    } = useTipoSolicitud();

    const {
        listaTipoDatos,
        listaTipoForm,
        handleListarTipoDatos,
        handleListarTipoForms,
    } = useConfiguracionSolicitud();

    const { cmbBoxIdTipoSolicitud, txtDescripcion, cmbBoxIdTipoDato, cmbBoxIdInputForms, txtReglas, txtOrden } = formAprobado;

    const CustomSelect = withStyles(InputBase, (theme) => ({
        input: {
            border: "0px",
            paddingTop: "0px",
            paddingBottom: "0px",
            paddingLeft: "8px",
            paddingRight: "4px",
            marginTop: theme.spacing(3),
            "&:focus": {
                borderRadius: 4,
                boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
            },
        },
    }));

    const handleSave = (e) => {
        let continuar = true;
        if (txtReglas.value) {
            if (txtReglas.value === "") {
                continuar = false;
                swal("Guardar", "Debe de ingresar un valor para Reglas.", "error");
            } else {
                try {
                    JSON.parse(txtReglas.value);
                    if (
                        txtReglas.value.indexOf("{") === -1 ||
                        txtReglas.value.indexOf("}") === -1
                    ) {
                        continuar = false;
                        swal("Guardar", "El valor json ingresado en Reglas, no es válido.", "error");
                    }
                } catch (error) {
                    continuar = false;
                    swal("Guardar", "El valor ingresado en Reglas, no es válido.", "error");
                }
            }
        }
        if (continuar) {
            handleSubmitFormAprobado(e);
        }

    }

    useEffect(() => {
        handleListarTipoForms()
        handleClickObtenerTiposSolicitud();
    }, []);

    useEffect(() => {
        if (cmbBoxIdInputForms.value) {
            handleListarTipoDatos(cmbBoxIdInputForms.value);
        }
    }, [cmbBoxIdInputForms]);

    return (
        <DinercapModal
            title={
                aprobadoSeleccionado.iIdInfoSolicitudAprobada === -1
                    ? "Agregar Aprobado"
                    : "Actualizar Aprobado"
            }
            okFunc={handleSave}
            setOpen={handleOpenFormAprobado}
            open={openFormAprobado}
            okMessage="Guardar"
            size="small"
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" error={cmbBoxIdTipoSolicitud.error}>
                        <InputLabel id={cmbBoxIdTipoSolicitud.name}>{cmbBoxIdTipoSolicitud.label}</InputLabel>
                        <Select
                            name={cmbBoxIdTipoSolicitud.name}
                            labelId={cmbBoxIdTipoSolicitud.label}
                            id={cmbBoxIdTipoSolicitud.name}
                            label={cmbBoxIdTipoSolicitud.label}
                            required={cmbBoxIdTipoSolicitud.required}
                            value={cmbBoxIdTipoSolicitud.value}
                            error={cmbBoxIdTipoSolicitud.error}
                            onChange={handleChangeFormAprobado}
                            autoComplete="off"
                            defaultValue={0}
                        >
                            <MenuItem value={0}>
                                <em>Seleccione un tipo de solicitud</em>
                            </MenuItem>
                            {listaTiposSolicitud.map((solicitud) => {
                                    return (
                                        <MenuItem
                                            key={solicitud.iIDTipoSolicitud}
                                            value={solicitud.iIDTipoSolicitud}
                                        >
                                            {solicitud.sNombre}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                        <FormHelperText>{cmbBoxIdTipoSolicitud.error && cmbBoxIdTipoSolicitud.message}</FormHelperText>
                    </FormControl>
                    <TextField
                        name={txtDescripcion.name}
                        label={txtDescripcion.label}
                        required={txtDescripcion.required}
                        value={txtDescripcion.value}
                        error={txtDescripcion.error}
                        helperText={txtDescripcion.error && txtDescripcion.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormAprobado}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        className="mb-2"
                    />
                    <FormControl fullWidth variant="outlined" error={cmbBoxIdInputForms.error}>
                        <InputLabel id="demo-simple-select-error-label">{cmbBoxIdInputForms.label}</InputLabel>
                        <Select
                            labelId="demo-simple-select-error-label"
                            id={cmbBoxIdInputForms.name}
                            name={cmbBoxIdInputForms.name}
                            label={cmbBoxIdInputForms.label}
                            required={cmbBoxIdInputForms.required}
                            error={cmbBoxIdInputForms.error}
                            value={cmbBoxIdInputForms.value}
                            onChange={handleChangeFormAprobado}
                        >
                            <MenuItem value="">
                                <em>Seleccione un tipo de dato</em>
                            </MenuItem>
                            {listaTipoForm.map((form) => {
                                    return (
                                        <MenuItem
                                            key={form.iIDInputForms}
                                            value={form.iIDInputForms}
                                        >
                                            {form.sNombre}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                        <FormHelperText>{cmbBoxIdInputForms.error && cmbBoxIdInputForms.message}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth variant="outlined" error={cmbBoxIdTipoDato.error}>
                        <InputLabel id="demo-simple-select-error-label">{cmbBoxIdTipoDato.label}</InputLabel>
                        <Select
                            labelId="demo-simple-select-error-label"
                            id={cmbBoxIdTipoDato.name}
                            name={cmbBoxIdTipoDato.name}
                            label={cmbBoxIdTipoDato.label}
                            required={cmbBoxIdTipoDato.required}
                            error={cmbBoxIdTipoDato.error}
                            value={cmbBoxIdTipoDato.value}
                            onChange={handleChangeFormAprobado}
                        >
                            <MenuItem value="">
                                <em>Seleccione un tipo de dato</em>
                            </MenuItem>
                            {listaTipoDatos.map((datos) => {
                                    return (
                                        <MenuItem
                                            key={datos.piIDInputData}
                                            value={datos.piIDInputData}
                                        >
                                            {datos.sInputForm}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                        <FormHelperText>{cmbBoxIdTipoDato.error && cmbBoxIdTipoDato.message}</FormHelperText>
                    </FormControl>                    
                    <TextField
                        name={txtReglas.name}
                        label={txtReglas.label}
                        required={txtReglas.required}
                        value={txtReglas.value}
                        error={txtReglas.error}
                        helperText={txtReglas.error && txtReglas.message}
                        onChange={handleChangeFormAprobado}
                        variant={inputVariant}
                        color={inputColor}
                        autoComplete="off"
                        fullWidth
                        input={<CustomSelect />}
                        className="mb-2"
                        multiline
                        rows={4}
                        placeholder={txtReglas.placeholder}
                    />
                    <TextField
                        type="number"
                        name={txtOrden.name}
                        label={txtOrden.label}
                        required={txtOrden.required}
                        value={txtOrden.value}
                        error={txtOrden.error}
                        helperText={txtOrden.error && txtOrden.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormAprobado}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        className="mb-2"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
