import { createContext, useContext, useState } from "react";
import { getModelAlert, getModelLoader } from "../models/LayoutModel";

export const LayoutContext = createContext();

export function LayoutProvider(props) {
    const [alert, setAlert] = useState(getModelAlert());
    const [loader, setLoader] = useState(getModelLoader());
    const [rutaActual, setRutaActual] = useState(null);
    const [openMenuDrawer, setOpenMenuDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleClickUserMenu = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenAlert = (message = "", severity = "error") => {
        setAlert({
            open: true,
            message,
            severity,
        });
    };

    const handleCloseAlert = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setAlert({
            ...alert,
            open: false,
        });
    };

    const handleLoader = (message = "Cargando...") => {
        setLoader({
            open: message === "Cargando..." ? false : true,
            message,
        });
    };

    const handleChangeRuta = (ruta) => {
        setRutaActual(ruta);
    };

    const handleOpenMenu = (open) => (e) => {
        if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
            return;
        }

        setOpenMenuDrawer(open);
    };

    return (
        <LayoutContext.Provider
            value={{
                alert,
                loader,
                rutaActual,
                openMenuDrawer,
                isMenuOpen,
                anchorEl,
                handleClickUserMenu,
                handleCloseUserMenu,
                handleOpenAlert,
                handleCloseAlert,
                handleLoader,
                handleChangeRuta,
                handleOpenMenu,
            }}
        >
            {props.children}
        </LayoutContext.Provider>
    );
}

export function useLayout() {
    return useContext(LayoutContext);
}
