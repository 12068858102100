import { Fragment, useEffect, useState } from "react";
import {
    IoAdd,
    IoHomeOutline,
    IoDocumentOutline,
    IoEllipsisVerticalOutline,
    IoTrashOutline,
    IoDocumentTextOutline,
    IoOptionsOutline,
    IoDocumentsOutline,
} from "react-icons/io5";
import { AiOutlineFileSearch } from "react-icons/ai";
import { HiOutlinePencil } from "react-icons/hi";

import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

import { urlDefault, urlSystem } from "../../../settings/urlConfig";

import { useLogin } from "../../../context/LoginContext";
import { useTipoSolicitud } from "../../../context/TipoSolicitudContext";
import { useConfiguracionSolicitud } from "../../../context/ConfiguracionSolicitudContext";

import FormTipoSolicitud from "./FormTipoSolicitud";
import EliminarTipoSolicitud from "./EliminarTipoSolicitud";
import { useSolicitud } from "../../../context/SolicitudContext";

export default function TiposSolicitudesScreen() {
    const tokenUsuario = "TknUsu";
    const tokenContraseña = "TknPass";
    const {
        listaTiposSolicitudAll,
        tipoSolicitudSeleccionado,
        handleChangeTipoSolicitudSeleccionado,
        handleClickObtenerTiposSolicitud,
        handleClickNuevoTipoSolicitud,
        handleClickEditarTipoSolicitud,
        handleClickEliminarTipoSolicitud,
        handleClickCloneTipoSolicitud,
    } = useTipoSolicitud();

    const { handleChangeTipoSolicitudSeleccionado: handleChangeConfigTipoSolicitudSeleccionado } =
        useConfiguracionSolicitud();

    const { handleChangeTipoSolicitudSeleccionadoSelect } = useSolicitud();

    const [anchorTipoSolicitud, setAnchorTipoSolicitud] = useState(null);

    useEffect(() => {
        // sessionStorage.setItem(tokenUsuario, "dinercap.webApp");
        // sessionStorage.setItem(tokenContraseña, "QFouWAUD");
        handleClickObtenerTiposSolicitud();
        // eslint-disable-next-line
    }, []);

    const { handleClickRuta } = useLogin();

    const handleClickHome = (e, value) => {
        handleClickRuta(e, {
            pathname: urlDefault,
        });
    };

    const handleClickMenuTipoSolicitud = (e, value) => {
        setAnchorTipoSolicitud(e.currentTarget);
        handleChangeTipoSolicitudSeleccionado(value);
    };

    const handleClickMenuItemConfigurarSolicitud = (e, value) => {
        handleChangeConfigTipoSolicitudSeleccionado(value);
        handleClickRuta(e, {
            pathname: urlSystem.configuracionSolicitud.replace(":bMostrarTipoSolicitud", "true"),
        });
        handleCloseMenuTipoSolicitud();
    };

    const handleClickMenuItemFormatosSolicitud = (e, value) => {
        handleChangeConfigTipoSolicitudSeleccionado(value);
        handleClickRuta(e, {
            pathname: urlSystem.formatosSolicitud.replace(":bMostrarTipoSolicitud", "true"),
        });
        handleCloseMenuTipoSolicitud();
    };

    const handleClickMenuItemSeguimientosSolicitud = (e, value) => {
        handleChangeTipoSolicitudSeleccionadoSelect(value);
        handleClickRuta(e, {
            pathname: urlSystem.seguimientosSolicitud.replace(":bMostrarTipoSolicitud", "true"),
        });
        handleCloseMenuTipoSolicitud();
    };

    const handleClickMenuItemEditarTipoSolicitud = () => {
        handleCloseMenuTipoSolicitud();
        handleClickEditarTipoSolicitud();
    };

    const handleClickMenuItemEliminarTipoSolicitud = () => {
        handleCloseMenuTipoSolicitud();
        handleClickEliminarTipoSolicitud();
    };

    const handleClickMenuItemCloneTipoSolicitud = () => {
        handleCloseMenuTipoSolicitud();
        handleClickCloneTipoSolicitud();
    };

    const handleCloseMenuTipoSolicitud = () => {
        setAnchorTipoSolicitud(null);
    };

    return (
        <div>
            <div className="flex flex-row justify-between items-center h-14 p-3 border-b bg-header">
                <div className="flex flex-row items-center">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            className={
                                "flex !font-medium px-2 py-1 hover:!no-underline hover:!cursor-pointer hover:!bg-gray-100 hover:!rounded-full"
                            }
                            color="inherit"
                            onClick={(e) => handleClickHome(e)}
                        >
                            <IoHomeOutline className="text-2xl mr-2" />
                            Inicio
                        </Link>
                        <Typography color="textPrimary" className={"flex !font-medium px-2 py-1"}>
                            <IoDocumentOutline className="text-2xl mr-2" />
                            <span>Tipos de Solicitudes</span>
                        </Typography>
                    </Breadcrumbs>
                </div>
            </div>

            <div className="max-w-3xl mx-auto p-4">
                <div className="flex flex-row justify-end">
                    <Button
                        className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                        size="small"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={handleClickNuevoTipoSolicitud}
                    >
                        <IoAdd className="text-xl mr-1" />
                        Crear
                    </Button>
                </div>

                <List className="flex flex-col gap-2">
                    {listaTiposSolicitudAll.map((tipoSolicitud) => {
                        const labelId = `list-label-${tipoSolicitud.iIDTipoSolicitud}`;

                        return (
                            <Fragment key={tipoSolicitud.iIDTipoSolicitud}>
                                <ListItem
                                    className="flex bg-card !bg-white shadow !rounded-xl !py-4"
                                    role={undefined}
                                    button
                                    // onClick={(e) => handleClickTipoSolicitud(e, tipoSolicitud.iIDTipoSolicitud)}
                                >
                                    <i className={"fas fa-" + tipoSolicitud.sIcono}></i>
                                    <ListItemText
                                        className="ml-2 flex-1 sm:mr-40 text-gray-900"
                                        id={labelId}
                                        primary={tipoSolicitud.iIDTipoSolicitud + "-" +tipoSolicitud.sNombre}
                                    />

                                    <ListItemSecondaryAction className="flex">
                                        <Tooltip arrow title="Configurar solicitud" placement="top">
                                            <IconButton
                                                edge="end"
                                                className="!hidden sm:!block !bg-sky-400 !text-white !text-2xl !rounded-lg !shadow !mx-1 !p-2"
                                                onClick={(e) =>
                                                    handleClickMenuItemConfigurarSolicitud(e, tipoSolicitud)
                                                }
                                            >
                                                <IoOptionsOutline />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip arrow title="Formatos solicitud" placement="top">
                                            <IconButton
                                                edge="end"
                                                className="!hidden sm:!block !bg-cyan-400 !text-white !text-2xl !rounded-lg !shadow !mx-2 !p-2"
                                                onClick={(e) => handleClickMenuItemFormatosSolicitud(e, tipoSolicitud)}
                                            >
                                                <IoDocumentTextOutline />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip arrow title="Seguimientos solicitud" placement="top">
                                            <IconButton
                                                edge="end"
                                                className="!hidden sm:!block !bg-teal-400 !text-white !text-2xl !rounded-lg !shadow !mx-1 !p-2"
                                                onClick={(e) =>
                                                    handleClickMenuItemSeguimientosSolicitud(e, tipoSolicitud)
                                                }
                                            >
                                                <AiOutlineFileSearch />
                                            </IconButton>
                                        </Tooltip>
                                        <IconButton
                                            edge="end"
                                            className="w-10 !text-xl !rounded-full !ml-1 !-mr-2 !p-2"
                                            onClick={(e) => handleClickMenuTipoSolicitud(e, tipoSolicitud)}
                                        >
                                            <IoEllipsisVerticalOutline />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Fragment>
                        );
                    })}
                </List>

                <Menu
                    id="menu-tipo-solicitud"
                    anchorEl={anchorTipoSolicitud}
                    keepMounted
                    open={Boolean(anchorTipoSolicitud)}
                    onClose={handleCloseMenuTipoSolicitud}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                >
                    <MenuItem dense onClick={handleClickMenuItemEditarTipoSolicitud}>
                        <HiOutlinePencil className="text-gray-600 text-xl mr-3" />
                        <ListItemText className="text-gray-600">Editar</ListItemText>
                    </MenuItem>

                    <div className="sm:hidden">
                        <MenuItem
                            dense
                            onClick={(e) => handleClickMenuItemConfigurarSolicitud(e, tipoSolicitudSeleccionado)}
                        >
                            <IoOptionsOutline className="text-gray-600 text-xl mr-3" />
                            <ListItemText className="text-gray-600">Configurar</ListItemText>
                        </MenuItem>

                        <MenuItem
                            dense
                            onClick={(e) => handleClickMenuItemFormatosSolicitud(e, tipoSolicitudSeleccionado)}
                        >
                            <IoDocumentTextOutline className="text-gray-600 text-xl mr-3" />
                            <ListItemText className="text-gray-600">Formatos</ListItemText>
                        </MenuItem>

                        <MenuItem
                            dense
                            onClick={(e) => handleClickMenuItemSeguimientosSolicitud(e, tipoSolicitudSeleccionado)}
                        >
                            <AiOutlineFileSearch className="text-gray-600 text-xl mr-3" />
                            <ListItemText className="text-gray-600">Seguimientos</ListItemText>
                        </MenuItem>
                    </div>

                    <Divider />

                    <MenuItem dense onClick={handleClickMenuItemEliminarTipoSolicitud}>
                        <IoTrashOutline className="text-gray-600 text-xl mr-3" />
                        <ListItemText className="text-gray-600">Eliminar</ListItemText>
                    </MenuItem>
                    <Divider />

                    <MenuItem dense onClick={handleClickMenuItemCloneTipoSolicitud}>
                        <IoDocumentsOutline className="text-gray-600 text-xl mr-3" />
                        <ListItemText className="text-gray-600">Clonar</ListItemText>
                    </MenuItem>
                </Menu>
            </div>

            <FormTipoSolicitud />
            <EliminarTipoSolicitud />
        </div>
    );
}
