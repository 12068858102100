import { createContext, useContext, useState } from "react";
import Enumerable from "linq";
import { useRequest } from "../RequestContext";
import { useLayout } from "../LayoutContext";
import { apiCGetAvisosPrivacidad, apiCSaveAvisosPrivacidad } from "../../settings/apiConfig";
import Swal from "sweetalert2";

import { getFormAvisoPrivacidad, getModelAvisoPrivacidad } from "../../models/AvisoPrivacidadModel";
import { fnPrevent } from "../../settings/preventConfig";
import { fnHandleChangeTextValidations } from "../../settings/handleConfig";

export const AvisosPrivacidadContext = createContext();

export function AvisosPrivacidadProvider(props) {
    const { handleOpenAlert } = useLayout();
    const { Get, Post } = useRequest();

    //Avisos de Privacidad
    const [openAvisosPrivacidad, setOpenAvisosPrivacidad] = useState(false);
    const [listaAvisosPrivacidad, setListaAvisosPrivacidad] = useState([]);
    const [formAvisoPrivacidad, setFormAvisoPrivacidad] = useState(getFormAvisoPrivacidad());
    const [avisoPrivacidadSeleccionada, setAvisoPrivacidadSeleccionada] = useState(getModelAvisoPrivacidad());
    const [isExistTipoSolicitud, setIsExistTipoSolicitud] = useState(false);
    const [openFormAvisoPrivacidad, setOpenFormAvisoPrivacidad] = useState(false);

    const handleOpenAvisoPrivacidad = (value) => {
        setOpenAvisosPrivacidad(value);
    };

    const handleObtenerAvisosPrivacidad = async () => {
        const payload = {
            loader: "Consultando Avisos de Privacidad",
            url: apiCGetAvisosPrivacidad,
            alert: true,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            if (response.result.length > 0) {
                const _listaAvisosPrivacidad = Enumerable.from(response.result)
                    .select((avisoP) => ({
                        iIdAvisoPrivacidad: avisoP.iIdAvisoPrivacidad,
                        iIdTipoSolicitud: avisoP.iIdTipoSolicitud,
                        sNombreTipoSolicitud: avisoP.entTipoSolicitud.sNombre,
                        sDescripcion: avisoP.sDescripcion,
                        sBuroCredito: avisoP.sBuroCredito,
                    }))
                    .toArray();
                setListaAvisosPrivacidad(_listaAvisosPrivacidad);
            } else {
                handleOpenAlert("Error al obtener las Avisos de Privacidad", "error");
            }
        }

    };

    const handleOpenFormAvisoPrivacidad = (value) => {
        setOpenFormAvisoPrivacidad(value);
        if (!value) {
            setFormAvisoPrivacidad(getFormAvisoPrivacidad());
            setAvisoPrivacidadSeleccionada(getModelAvisoPrivacidad());
        }
    }

    const handleChangeFormAvisoPrivacidad = fnHandleChangeTextValidations(formAvisoPrivacidad, setFormAvisoPrivacidad);

    const handleSubmitFormAvisoPrivacidad = async (e) => {

        fnPrevent(e);
        const iIdAvisoP = avisoPrivacidadSeleccionada.iIdAvisoPrivacidad === undefined ? -1 : avisoPrivacidadSeleccionada.iIdAvisoPrivacidad;
        const { txtAvisoPrivacidad, txtBuroCredito, cmbBoxIdTipoSolicitud } = formAvisoPrivacidad;

        var IdTipoSolicitud = "";
        if (iIdAvisoP > 0) {
            const avisoprivacidad = listaAvisosPrivacidad.find(el => el.iIdAvisoPrivacidad == iIdAvisoP);
            IdTipoSolicitud = avisoprivacidad.iIdTipoSolicitud;
        }

        if (IdTipoSolicitud != cmbBoxIdTipoSolicitud.value || iIdAvisoP <= 0) {
            const exist = listaAvisosPrivacidad.find(el => el.iIdTipoSolicitud == cmbBoxIdTipoSolicitud.value);
            if (exist) {
                cmbBoxIdTipoSolicitud.isExist = true;
                setIsExistTipoSolicitud(true);
                return false;
            }
        }

        const form = {
            ...formAvisoPrivacidad,
            txtAvisoPrivacidad: {
                ...txtAvisoPrivacidad,
                error: isQuillEmpty(txtAvisoPrivacidad.value),
            },
            txtBuroCredito: {
                ...txtBuroCredito,
                error: isQuillEmpty(txtBuroCredito.value),
            },
            cmbBoxIdTipoSolicitud: {
                ...cmbBoxIdTipoSolicitud,
                error: cmbBoxIdTipoSolicitud.value === "",
            },
        };
        setFormAvisoPrivacidad(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }
        const entAvisoPrivacidad = {
            iIdAvisoPrivacidad: iIdAvisoP,
            sDescripcion: txtAvisoPrivacidad.value,
            sBuroCredito: txtBuroCredito.value,
            iIdTipoSolicitud: cmbBoxIdTipoSolicitud.value,
            iIDUsuarioCreacion: iIdAvisoP === -1 ? 1 : 0,
            iIDUsuarioModificacion: iIdAvisoP !== -1 ? 1 : 0,
            iIDUsuarioElimina: 0,
        };

        await fn_guardarAvisoPrivacidad(entAvisoPrivacidad);
    };

    function isQuillEmpty(value) {
        if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img")) {
            return true;
        }
        return false;
    }

    const fn_guardarAvisoPrivacidad = async (entAvisoPrivacidad) => {
        let loader = "";
        if (entAvisoPrivacidad.iIDUsuarioCreacion > 0) {
            loader = "Guardando el documento.";
        } else if (entAvisoPrivacidad.iIDUsuarioModificacion > 0) {
            loader = "Actualizando el documento.";
        } else if (entAvisoPrivacidad.iIDUsuarioElimina > 0) {
            loader = "Eliminando el documento.";
        }

        const payload = {
            loader: loader,
            body: entAvisoPrivacidad,
            url: apiCSaveAvisosPrivacidad,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            handleOpenFormAvisoPrivacidad(false);
            setAvisoPrivacidadSeleccionada(getModelAvisoPrivacidad());
            setFormAvisoPrivacidad(getFormAvisoPrivacidad());
            await handleObtenerAvisosPrivacidad();
        }
    };

    const handleActualizarAvisoPrivacidad = (e, avisoPrivacidad) => {
        fnPrevent(e);
        setAvisoPrivacidadSeleccionada(avisoPrivacidad);

        const { cmbBoxIdTipoSolicitud, txtAvisoPrivacidad, txtBuroCredito } = formAvisoPrivacidad;

        let form = {
            ...formAvisoPrivacidad,
            cmbBoxIdTipoSolicitud: {
                ...cmbBoxIdTipoSolicitud,
                value: avisoPrivacidad.iIdTipoSolicitud,
            },
            txtAvisoPrivacidad: {
                ...txtAvisoPrivacidad,
                value: avisoPrivacidad.sDescripcion,
            },
            txtBuroCredito: {
                ...txtBuroCredito,
                value: avisoPrivacidad.sBuroCredito,
            },
        };

        setFormAvisoPrivacidad(form);
        setOpenFormAvisoPrivacidad(true);
    };

    const handleEliminarAvisoPrivacidad = async (e, avisoPrivacidad) => {
        fnPrevent(e);
        Swal.fire({
            title: "Eliminar Aviso de Privacidad",
            text: "¿Está seguro de eliminar la Aviso de Privacidad " + avisoPrivacidad.iIdAvisoPrivacidad + "?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await fn_eliminarAvisoPrivacidad(avisoPrivacidad);
            }
        });
    };

    const fn_eliminarAvisoPrivacidad = async (avisoPrivacidad) => {
        const entAvisoPrivacidad = {
            iIdAvisoPrivacidad: avisoPrivacidad.iIdAvisoPrivacidad,
            iIDUsuarioElimina: 1,
            iIDUsuarioCreacion: 0,
            iIDUsuarioModificacion: 0,
        };
        await fn_guardarAvisoPrivacidad(entAvisoPrivacidad);
    };

    return (
        <AvisosPrivacidadContext.Provider
            value={{
                openAvisosPrivacidad,
                openFormAvisoPrivacidad,
                formAvisoPrivacidad,
                avisoPrivacidadSeleccionada,
                listaAvisosPrivacidad,
                isExistTipoSolicitud,
                setIsExistTipoSolicitud,
                handleOpenAvisoPrivacidad,
                handleActualizarAvisoPrivacidad,
                handleObtenerAvisosPrivacidad,
                handleOpenFormAvisoPrivacidad,
                handleChangeFormAvisoPrivacidad,
                handleSubmitFormAvisoPrivacidad,
                handleEliminarAvisoPrivacidad,
            }}
        >
            {props.children}
        </AvisosPrivacidadContext.Provider>
    );

}

export function useAvisosPrivacidad() {
    return useContext(AvisosPrivacidadContext);
}
