import React, { useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import map from "lodash/map";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Box, Grid, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useSolicitud } from "../../../context/SolicitudContext";

export default function FormSolicitudRechazada(props) {
    const { open } = props;
    const { handleCloseFormRechazado, listaCausasRechazo, handleSaveInfoSolicitudRechazada } = useSolicitud();
    const {
        formState: { errors },
        handleSubmit,
        register,
        reset,
    } = useForm();

    const [causa, setCausa] = useState("");

    const handleChange = (event) => {
        setCausa(event.target.value);
    };
    const handleAprobar = async (data) => {
        await handleSaveInfoSolicitudRechazada(data);
        reset();
        handleCloseFormRechazado();
    };

    return (
        <Dialog
            fullWidth={true}
            size="sm"
            open={open}
            onClose={handleCloseFormRechazado}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Solicitud Rechazada</DialogTitle>
            <DialogContent>
                <Box sx={{ mt: 1 }} component="form" onSubmit={handleSubmit(handleAprobar)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1">Seleccione la causa del rechazo de la solicitud.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="id-perfil">Causa</InputLabel>
                                <Select
                                    labelId="id-perfil"
                                    label="Causa"
                                    error={!!errors.sCausa}
                                    id="sCausa"
                                    value={causa}
                                    {...register("sCausa")}
                                    onChange={handleChange}
                                >
                                    {listaCausasRechazo.map((item, i) => (
                                        <MenuItem key={i} value={item.id}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <center>
                                <Button onClick={handleCloseFormRechazado} className="btn btn-danger">
                                    Cancelar
                                </Button>
                                &nbsp;&nbsp;&nbsp;
                                <Button type="submit" className="btn btn-primary">
                                    Guardar
                                </Button>
                            </center>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
