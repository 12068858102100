import React, { useEffect } from "react";
import map from "lodash/map";
import get from "lodash/get";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Grid from "@mui/material/Grid";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const RadioOption = ({ item, currentValue, handleChange, readOnly }) => {
    return (
        <Grid item xs={12}>
            <input
                className="form-check-input"
                type="radio"
                id={item.elementvalueId}
                disabled={readOnly}
                value={item.elementvalueId}
                checked={item.elementvalueId === currentValue}
                onChange={handleChange}
            />
            <label className="form-check-label" htmlFor={item.elementvalueId}>
                {item.displayName}
            </label>
        </Grid>
    );
};

function getOptionList(formElementValues) {
    const list =
        formElementValues && formElementValues.length
            ? map(formElementValues, (item) => {
                  return (
                      <MenuItem key={item.elementvalueId} value={item.elementvalueId}>
                          {item.displayName}
                      </MenuItem>
                  );
              })
            : null;

    return list;
}

export const SimpleSelectElement = (props) => {
    const { valueMap, element, handleChange, register, errors } = props;
    const { formElementValues } = element;

    return (
        <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
                <InputLabel id="demo-simple-select-label">{element.displayName}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id={element.elementId}
                    value={get(valueMap, element.elementId)}
                    disabled={element.readOnly}
                    onChange={handleChange}
                    error={!!errors[String(element.elementId)]}
                    helperText={errors[String(element.elementId)] ? errors[String(element.elementId)].message : null}
                    {...register(
                        element.elementId,
                        element.required
                            ? { required: { value: true, message: element.displayName + " es requerido" } }
                            : []
                    )}
                >
                    <MenuItem value="">
                        <em>Ninguno</em>
                    </MenuItem>
                    {getOptionList(formElementValues)}
                </Select>
            </FormControl>
        </Grid>
    );
};

export const PasswordElement = (props) => {
    const { valueMap, element, handleChange, register, errors } = props;
    return (
        <Grid item xs={12}>
            <TextField
                type="password"
                id={element.elementId}
                defaultValue={get(valueMap, element.elementId)}
                placeholder="Password"
                onChange={handleChange}
                disabled={element.readOnly}
                label={element.displayName}
                error={!!errors[String(element.elementId)]}
                helperText={errors[String(element.elementId)] ? errors[String(element.elementId)].message : null}
                {...register(
                    element.elementId,
                    element.required
                        ? {
                              required: { value: true, message: element.displayName + " es requerido" },
                          }
                        : []
                )}
            />
        </Grid>
    );
};

export const CheckboxElement = (props) => {
    const { valueMap, element, handleChange, register, errors } = props;

    return (
        <Grid item xs={12}>
            <FormControlLabel
                control={
                    <Checkbox
                        defaultChecked={get(valueMap, element.elementId)}
                        onChange={handleChange}
                        disabled={element.readOnly}
                        id={element.elementId}
                        name={element.elementId}
                        {...register(element.elementId)}
                    />
                }
                label={element.displayName}
            />
        </Grid>
    );
};

export const PrintElement = (props) => {
    const { element } = props;
    return (
        <Grid item xs={12}>
            <label htmlFor="formControlRange">{element.displayName}</label>
        </Grid>
    );
};

export const TextElement = (props) => {
    const { valueMap, element, handleChange, register, errors } = props;

    return (
        <Grid item xs={12}>
            <TextField
                fullWidth
                id={element.elementId}
                autoComplete="off"
                name={element.elementId}
                onChange={handleChange}
                defaultValue={get(valueMap, element.elementId)}
                disabled={element.readOnly}
                label={element.displayName}
                variant="outlined"
                error={!!errors[String(element.elementId)]}
                helperText={errors[String(element.elementId)] ? errors[String(element.elementId)].message : null}
                {...register(element.elementId, element.validation)}
            />
        </Grid>
    );
};

export const TextHtmlEditor = (props) => {
    const { valueMap, element, handleChange, register, errors, setValueForm } = props;
    const [valor, setValor] = React.useState("");

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
            ["link"],
        ],
    };

    const onChange = (value) => {
        setValor(value);
        setValueForm(element.elementId, value);
    };

    useEffect(() => {
        register(element.elementId);
    }, [register]);

    return (
        <Grid item xs={12}>
            <ReactQuill
                modules={modules}
                theme="snow"
                value={valor}
                onChange={onChange}
                placeholder={element.displayName}
            />
        </Grid>
    );
};

export const TextAreaElement = (props) => {
    const { valueMap, element, handleChange, register, errors } = props;

    return (
        <Grid item xs={12}>
            <TextField
                fullWidth
                multiline
                id={element.elementId}
                name={element.elementId}
                defaultValue={get(valueMap, element.elementId)}
                disabled={element.readOnly}
                onChange={handleChange}
                rows={4}
                variant="outlined"
                label={element.displayName}
                error={!!errors[String(element.elementId)]}
                helperText={errors[String(element.elementId)] ? errors[String(element.elementId)].message : null}
                {...register(element.elementId, element.validation)}
            />
        </Grid>
    );
};

export const RadioElement = (props) => {
    const { valueMap, element, handleChange, register, errors } = props;
    const { formElementValues } = element;
    const currentValue = get(valueMap, element.elementId);
    const list =
        formElementValues && formElementValues.length
            ? map(formElementValues, (item) => (
                  <FormControlLabel
                      key={item.elementvalueId}
                      value={item.elementvalueId}
                      control={<Radio />}
                      label={item.displayName}
                      {...register(element.elementId)}
                  />
              ))
            : null;

    return (
        <Grid item xs={12}>
            <FormControl component="fieldset" error={!!errors[String(element.elementId)]}>
                <FormLabel>{element.displayName}</FormLabel>
                <RadioGroup
                    row
                    aria-label="gender"
                    name={element.elementId}
                    id={element.elementId}
                    value={get(valueMap, element.elementId)}
                    onChange={handleChange}
                    readOnly={element.readOnly}
                    {...register(
                        element.elementId,
                        element.required
                            ? {
                                  required: { value: true, message: element.displayName + " es requerido" },
                              }
                            : []
                    )}
                >
                    {list}
                </RadioGroup>
                <FormHelperText>
                    {errors[String(element.elementId)] ? errors[String(element.elementId)].message : null}
                </FormHelperText>
            </FormControl>
        </Grid>
    );
};
