import { createContext, useContext, useState } from "react";
import { apiGetGeocercas, apiGetGeoAreas, apiSaveGeocercas } from "../../settings/apiConfig";
import { useRequest } from "../RequestContext";
import { useLayout } from "../LayoutContext";
import Enumerable from "linq";
import { getFormZonaGeograficaModel } from "../../models/CatalogosModel";
import { fnHandleChangeTextValidations } from "../../settings/handleConfig";
import { fnPrevent } from "../../settings/preventConfig";

export const ZonasGeograficasContext = createContext();

export function ZonasGeograficasProvider(props) {
    const { Get, Post } = useRequest();
    const { handleOpenAlert } = useLayout();

    const [openZonas, setOpenZonas] = useState(false);
    const [listaZonas, setListaZonas] = useState([]);
    const [openFormZona, setOpenFormZona] = useState(false);
    const [listaPolygon, setListaPolygon] = useState([]);
    const [formZonaGeografica, setFormZonaGeografica] = useState(getFormZonaGeograficaModel());
    const [listaGeoAreas, setListaGeoAreas] = useState([]);
    const [isExistZonaGeografica, setIsExistZonaGeografica] = useState(false);
    const [zonaGeograficaSeleccionada, setZonaGeograficaSeleccionada] = useState({
        iIDGeocerca: -1,
    });

    const [center, setCenter] = useState({
        lat: 20.96703926747006,
        lng: -89.62371903439255,
    });

    const handleChangeFormTipoSolicitud = fnHandleChangeTextValidations(formZonaGeografica, setFormZonaGeografica);

    const handleOpenZonas = (value) => {
        setOpenZonas(value);
    };

    const handleObtenerGeocercas = async () => {
        const payload = {
            loader: "Obteniendo las geocercas",
            url: apiGetGeocercas,
            alert: true,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            const listGeo = Enumerable.from(response.result)
                .select((item) => ({
                    iIDGeocerca: item.iIDGeocerca,
                    sDescripcion: item.sDescripcion,
                    iIDTipoArea: item.geoArea.iIDTipoArea,
                    sNombreArea: item.geoArea.sNombre,
                    paths: item.paths,
                }))
                .toArray();
            setListaZonas(listGeo);
        } else {
            handleOpenAlert("No se encontraron geocercas configuradas.");
        }
    };

    const handleOpenFormZona = async (value) => {
        setOpenFormZona(value);
        setIsExistZonaGeografica(false);
        if (!value) {
            setFormZonaGeografica(getFormZonaGeograficaModel());
            setListaPolygon([]);
            setZonaGeograficaSeleccionada({
                iIDGeocerca: -1,
            });
        }
    };

    const handleUpdateForm = (row) => {
        setZonaGeograficaSeleccionada(row);

        const { txtDescripcion, slTipoArea } = formZonaGeografica;

        const form = {
            ...formZonaGeografica,
            txtDescripcion: {
                ...txtDescripcion,
                value: row.sDescripcion,
            },
            slTipoArea: {
                ...slTipoArea,
                value: row.iIDTipoArea,
            },
        };

        if (row.paths.length > 0) {
            var lngList = [];
            var latList = [];
            var lat1,
                lat2,
                lng1,
                lng2 = 0;
            var center = {
                lat: 0,
                lng: 0,
            };

            row.paths.forEach((p) => {
                lngList.push(p.lng);
                latList.push(p.lat);
            });
            lng1 = Math.min(...lngList);
            lat1 = Math.min(...latList);
            lng2 = Math.max(...lngList);
            lat2 = Math.max(...latList);
            center.lat = lat1 + (lat2 - lat1) / 2;
            center.lng = lng1 + (lng2 - lng1) / 2;
            setCenter({
                lat: center.lat,
                lng: center.lng,
            });
        }

        setListaPolygon([
            {
                id: row.iIDGeocerca,
                accion: "UDPATE",
                paths: Enumerable.from(row.paths)
                    .select((p) => ({
                        lat: parseFloat(p.lat),
                        lng: parseFloat(p.lng),
                    }))
                    .toArray(),
            },
        ]);
        setFormZonaGeografica(form);
        handleOpenFormZona(true);
    };

    const handleAddPolygon = (data) => {
        setListaPolygon([...listaPolygon, data]);
    };

    const handleRemovePolygon = (id) => {
        const polygons = listaPolygon.filter((pol) => pol.id !== id);
        setListaPolygon(polygons);
    };

    const handleUpdatePolygon = (id, data) => {
        const newList = listaPolygon.map((item) => {
            if (item.id === id) {
                const updatedItem = {
                    ...item,
                    paths: data,
                };

                return updatedItem;
            }

            return item;
        });
        setListaPolygon(newList);
    };

    const handleObtenerGeoAreas = async () => {
        const payload = {
            loader: false,
            url: apiGetGeoAreas,
            alert: false,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            const listAreas = Enumerable.from(response.result)
                .select((item) => ({
                    id: item.iIDTipoArea,
                    text: item.sNombre,
                }))
                .toArray();

            setListaGeoAreas(listAreas);
        } else {
            setListaGeoAreas([]);
        }
    };

    const handleSubmitFormTipoSolicitud = async (e) => {
        fnPrevent(e);
        const iIDGeocerca =
            zonaGeograficaSeleccionada.iIDGeocerca === undefined ? -1 : zonaGeograficaSeleccionada.iIDGeocerca;
        const { txtDescripcion, slTipoArea } = formZonaGeografica;


        var sDescripcion = ""
        if (iIDGeocerca > 0) {
            const geocerca = listaZonas.find(el => el.iIDGeocerca == iIDGeocerca);
            sDescripcion = geocerca.sDescripcion;
        }

        if (sDescripcion != txtDescripcion.value || iIDGeocerca <= 0) {
            const exist = listaZonas.find(el => el.sDescripcion == txtDescripcion.value);
            if (exist) {
                txtDescripcion.isExist = true;
                setIsExistZonaGeografica(true);
                return false;
            }
        }

        const form = {
            ...formZonaGeografica,
            txtDescripcion: {
                ...txtDescripcion,
                error: txtDescripcion.value === "",
            },
            slTipoArea: {
                ...slTipoArea,
                error: slTipoArea.value === "" || slTipoArea.value === 0,
            },
        };

        setFormZonaGeografica(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }

        if (listaPolygon.length === 1) {
            const polygon = listaPolygon[0];

            const body = {
                iIDGeocerca: iIDGeocerca,
                sDescripcion: txtDescripcion.value,
                geoArea: {
                    iIDTipoArea: slTipoArea.value,
                    sNombre: "",
                },
                paths: Enumerable.from(polygon.paths)
                    .select((item) => ({
                        lat: String(item.lat),
                        lng: String(item.lng),
                    }))
                    .toArray(),
                iIDUsuarioCreacion: iIDGeocerca === -1 ? 1 : -1,
                iIDUsuarioModificacion:
                    iIDGeocerca !== -1 ? iIDGeocerca : -1,
                iIDUsuarioElimina: -1,
            };

            const payload = {
                loader: "Guardando...",
                url: apiSaveGeocercas,
                body: body,
                alert: true,
            };

            const response = await Post(payload);
            if (response.iCodigo === 201) {
                await handleObtenerGeocercas();
                handleOpenFormZona(false);
            }
        } else {
            handleOpenAlert("Dibuje un poligono para continuar.");
        }
    };

    const handleEliminarZonaGeografica = async (idZona) => {
        const body = {
            iIDGeocerca: idZona,
            iIDUsuarioCreacion: -1,
            iIDUsuarioModificacion: -1,
            iIDUsuarioElimina: 1,
        };

        const payload = {
            loader: "Eliminando...",
            url: apiSaveGeocercas,
            body: body,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            await handleObtenerGeocercas();
        }
    };

    return (
        <ZonasGeograficasContext.Provider
            value={{
                center,
                openZonas,
                listaZonas,
                openFormZona,
                listaPolygon,
                formZonaGeografica,
                listaGeoAreas,
                zonaGeograficaSeleccionada,
                isExistZonaGeografica,
                setIsExistZonaGeografica,
                handleOpenZonas,
                handleObtenerGeocercas,
                handleOpenFormZona,
                handleAddPolygon,
                handleUpdatePolygon,
                handleRemovePolygon,
                handleChangeFormTipoSolicitud,
                handleObtenerGeoAreas,
                handleSubmitFormTipoSolicitud,
                handleUpdateForm,
                handleEliminarZonaGeografica,
            }}
        >
            {props.children}
        </ZonasGeograficasContext.Provider>
    );
}

export function useZonasGeograficas() {
    return useContext(ZonasGeograficasContext);
}
