import { urlSystem } from "../../../settings/urlConfig";

import { useLogin } from "../../../context/LoginContext";
import { useSolicitud } from "../../../context/SolicitudContext";
import { Perfiles } from "../../../settings/enumConfig";

import {
    imgTiposSolicitudes,
    imgConfigurarSolicitud,
    imgFormatosSolicitud,
    imgSeguimientosSolicitud,
    imgLogoDinercap,
    imgConfigurarOCR,
    imgConfigurarDatos,
    imgCatalogosGenerales,
} from "../../../settings/imgConfig";

export default function HomeScreen() {
    const { handleClickRuta, handleClickSignOff, usuarioOriginador } = useLogin();

    const handleClickItemTiposSolicitudes = (e) => {
        handleClickRuta(e, {
            pathname: urlSystem.tiposSolicitudes,
        });
    };

    const handleClickItemConfiguracionSolicitud = (e) => {
        handleClickRuta(e, {
            pathname: urlSystem.configuracionSolicitud.replace(":bMostrarTipoSolicitud", "false"),
        });
    };

    const handleClickItemFormatosSolicitud = (e) => {
        handleClickRuta(e, {
            pathname: urlSystem.formatosSolicitud.replace(":bMostrarTipoSolicitud", "false"),
        });
    };

    const handleClickItemSeguimientosSolicitud = (e) => {
        handleClickRuta(e, {
            pathname: urlSystem.seguimientosSolicitud.replace(":bMostrarTipoSolicitud", "false"),
        });
    };

    const handleClickItemConfiguracionOCR = (e) => {
        handleClickRuta(e, {
            pathname: urlSystem.configuracionOCR.replace(":bMostrarTipoSolicitud", "false"),
        });
    };

    const handleClickItemConfiguracionDatos = (e) => {
        handleClickRuta(e, {
            pathname: urlSystem.configuracionDatos.replace(":bMostrarTipoSolicitud", "false"),
        });
    };

    const handleClickItemCatalogos = (e) => {
        handleClickRuta(e, {
            pathname: urlSystem.catalogosGenerales,
        });
    };

    return (
        <div>
            <div className="flex flex-row justify-between items-center h-14 p-3 border-b bg-header">
                <div className="flex flex-row items-center">
                    <img className="w-full h-25 object-contain py-8 cursor-pointer" src={imgLogoDinercap} alt="logoD" />
                </div>
                <button type="button" className="btn btn-confirm btn-md float-right" onClick={handleClickSignOff}>
                    <strong>Salir</strong>
                </button>
            </div>

            <div className="max-w-7xl mx-auto p-8">
                {usuarioOriginador != null && usuarioOriginador != undefined &&
                    <div className="grid grid-cols-1 sm:grid-cols-2 2sm:grid-cols-3 2md:grid-cols-4 gap-8">
                        {usuarioOriginador.iIdPerfil === Perfiles.Admin &&
                            <div className="flex flex-col bg-card shadow rounded-2xl overflow-hidden">
                                <img
                                    className="w-full h-40 object-contain bg-blue-300 py-8 cursor-pointer"
                                    src={imgTiposSolicitudes}
                                    alt=""
                                    onClick={(e) => handleClickItemTiposSolicitudes(e)}
                                />
                                <div className="flex flex-col p-4 space-y-2 bg-white">
                                    <div className="font-medium leading-tight truncate text-black">Tipos Solicitudes</div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="p-4 border-t text-right bg-gray-50">
                                        <button
                                            type="button"
                                            className="btn btn-confirm btn-block btn-md"
                                            onClick={(e) => handleClickItemTiposSolicitudes(e)}
                                        >
                                            <strong>Ver tipos solicitudes</strong>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                        {usuarioOriginador.iIdPerfil === Perfiles.Admin &&
                            <div className="flex flex-col bg-card shadow rounded-2xl overflow-hidden">
                                <img
                                    className="w-full h-40 object-contain bg-sky-300 py-8 cursor-pointer"
                                    src={imgConfigurarSolicitud}
                                    alt=""
                                    onClick={(e) => handleClickItemConfiguracionSolicitud(e)}
                                />
                                <div className="flex flex-col p-4 space-y-2 bg-white">
                                    <div className="font-medium leading-tight truncate text-black">Configuración Solicitud</div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="p-4 border-t text-right bg-gray-50">
                                        <button
                                            type="button"
                                            className="btn btn-confirm btn-block btn-md"
                                            onClick={(e) => handleClickItemConfiguracionSolicitud(e)}
                                        >
                                            <strong>Editar configuración</strong>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        {usuarioOriginador.iIdPerfil === Perfiles.Admin &&
                            <div className="flex flex-col bg-card shadow rounded-2xl overflow-hidden">
                                <img
                                    className="w-full h-40 object-contain bg-cyan-300 py-8 cursor-pointer"
                                    src={imgFormatosSolicitud}
                                    alt=""
                                    onClick={(e) => handleClickItemFormatosSolicitud(e)}
                                />
                                <div className="flex flex-col p-4 space-y-2 bg-white">
                                    <div className="font-medium leading-tight truncate text-black">Formatos Solicitud</div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="p-4 border-t text-right bg-gray-50">
                                        <button
                                            type="button"
                                            className="btn btn-confirm btn-block btn-md"
                                            onClick={(e) => handleClickItemFormatosSolicitud(e)}
                                        >
                                            <strong>Ver formatos</strong>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        {(usuarioOriginador.iIdPerfil === Perfiles.Admin || usuarioOriginador.iIdPerfil === Perfiles.Usuario) &&
                            <div className="flex flex-col bg-card shadow rounded-2xl overflow-hidden">
                                <img
                                    className="w-full h-40 object-contain bg-teal-300 py-8 cursor-pointer"
                                    src={imgSeguimientosSolicitud}
                                    alt=""
                                    onClick={(e) => handleClickItemSeguimientosSolicitud(e)}
                                />
                                <div className="flex flex-col p-4 space-y-2 bg-white">
                                    <div className="font-medium leading-tight truncate text-black">Seguimiento Solicitudes</div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="p-4 border-t text-right bg-gray-50">
                                        <button
                                            type="button"
                                            className="btn btn-confirm btn-block btn-md"
                                            onClick={(e) => handleClickItemSeguimientosSolicitud(e)}
                                        >
                                            <strong>Ver seguimientos</strong>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        {usuarioOriginador.iIdPerfil === Perfiles.Admin &&
                            <div className="flex flex-col bg-card shadow rounded-2xl overflow-hidden">
                                <img
                                    className="w-full h-40 object-contain bg-emerald-100 py-8 cursor-pointer"
                                    src={imgConfigurarOCR}
                                    alt=""
                                    onClick={(e) => handleClickItemConfiguracionOCR(e)}
                                />
                                <div className="flex flex-col p-4 space-y-2 bg-white">
                                    <div className="font-medium leading-tight truncate text-black">Configuración OCR</div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="p-4 border-t text-right bg-gray-50">
                                        <button
                                            type="button"
                                            className="btn btn-confirm btn-block btn-md"
                                            onClick={(e) => handleClickItemConfiguracionOCR(e)}
                                        >
                                            <strong>Editar configuración OCR</strong>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        {usuarioOriginador.iIdPerfil === Perfiles.Admin &&
                            <div className="flex flex-col bg-card shadow rounded-2xl overflow-hidden">
                                <img
                                    className="w-full h-40 object-contain bg-yellow-100 py-8 cursor-pointer"
                                    src={imgCatalogosGenerales}
                                    alt=""
                                    onClick={(e) => handleClickItemCatalogos(e)}
                                />
                                <div className="flex flex-col p-4 space-y-2 bg-white">
                                    <div className="font-medium leading-tight truncate text-black">Catálogos Generales</div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="p-4 border-t text-right bg-gray-50">
                                        <button
                                            type="button"
                                            className="btn btn-confirm btn-block btn-md"
                                            onClick={(e) => handleClickItemCatalogos(e)}
                                        >
                                            <strong>Ver Catálogos</strong>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        {usuarioOriginador.iIdPerfil === Perfiles.Admin &&
                            <div className="flex flex-col bg-card shadow rounded-2xl overflow-hidden">
                                <img
                                    className="w-full h-40 object-contain bg-emerald-100 py-8 cursor-pointer"
                                    src={imgConfigurarDatos}
                                    alt=""
                                    onClick={(e) => handleClickItemConfiguracionDatos(e)}
                                />
                                <div className="flex flex-col p-4 space-y-2 bg-white">
                                    <div className="font-medium leading-tight truncate text-black">Configuración de información</div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="p-4 border-t text-right bg-gray-50">
                                        <button
                                            type="button"
                                            className="btn btn-confirm btn-block btn-md"
                                            onClick={(e) => handleClickItemConfiguracionDatos(e)}
                                        >
                                            <strong>Editar configuración</strong>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
}