import React, { useEffect, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { keyApiGoogleMaps } from "../../../../settings/mapsConfig";
import DinercapModal from "../../../utils/DinercapModal";
import Mapa from "./Mapa";
import { useZonasGeograficas } from "../../../../context/catalogos_generales/ZonasGeograficasContext";
import { Grid, TextField, InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import { inputColor, inputVariant } from "../../../../settings/inputConfig";

export const containerStyle = {
    width: "100%",
    height: "600px",
};

export default function FormZonaGeografica() {
    const {
        openFormZona,
        handleOpenFormZona,
        formZonaGeografica,
        handleChangeFormTipoSolicitud,
        listaGeoAreas,
        handleObtenerGeoAreas,
        handleSubmitFormTipoSolicitud,
        zonaGeograficaSeleccionada,
        setIsExistZonaGeografica
    } = useZonasGeograficas();

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: keyApiGoogleMaps,
        libraries: ["drawing"],
    });

    const { txtDescripcion, slTipoArea } = formZonaGeografica;

    useEffect(() => {
        handleObtenerGeoAreas();
    }, []);

    useEffect(() => {
        const compDescripcion = document.getElementById(txtDescripcion.name);
        const compTipoArea = document.getElementById(slTipoArea.name);
        if (compDescripcion && !txtDescripcion.error) {
            compDescripcion.scrollIntoView();
            
        } else if (compTipoArea && !slTipoArea.error) {
            compTipoArea.scrollIntoView();
            
        }
    });

    setIsExistZonaGeografica(txtDescripcion.error);

    return (
        <DinercapModal
            title={
                zonaGeograficaSeleccionada.iIDGeocerca === -1 ? "Nueva zona geográfica" : "Actualiza zona geográfica"
            }
            okFunc={handleSubmitFormTipoSolicitud}
            setOpen={handleOpenFormZona}
            open={openFormZona}
            okMessage="Guardar"
        >
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        id={txtDescripcion.name}
                        name={txtDescripcion.name}
                        label={txtDescripcion.label}
                        required={txtDescripcion.required}
                        value={txtDescripcion.value}
                        error={txtDescripcion.error || txtDescripcion.isExist}
                        helperText={(txtDescripcion.isExist && txtDescripcion.value != "" && txtDescripcion.messageUnique) || (txtDescripcion.error && txtDescripcion.value == "" && txtDescripcion.message)}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormTipoSolicitud}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        className="mb-2"
                        inputProps={{
                            maxLength: txtDescripcion.maxlength,
                        }}
                    />
                </Grid>
                <Grid item xs={12} mb={1}>
                    <FormControl fullWidth error={slTipoArea.error}>
                        <InputLabel id="lblTipoArea">{slTipoArea.label}</InputLabel>
                        <Select
                            id={slTipoArea.name}
                            name={slTipoArea.name}
                            labelId="lblTipoArea"
                            label={slTipoArea.label}
                            required={slTipoArea.required}
                            value={slTipoArea.value}
                            helperText={slTipoArea.error && slTipoArea.message}
                            variant={inputVariant}
                            color={inputColor}
                            onChange={handleChangeFormTipoSolicitud}
                            autoComplete="off"
                            defaultValue={0}
                        >
                            <MenuItem value={0}>
                                <em>Seleccionar</em>
                            </MenuItem>
                            {listaGeoAreas.length > 0 ? (
                                listaGeoAreas.map((item) => <MenuItem value={item.id}>{item.text}</MenuItem>)
                            ) : (
                                <></>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel className="m-3">**Para comenzar a dibujar el polígono en el mapa, diríjase a la parte superior del mismo
                        y seleccione el ícono que está a lado de la mano.</InputLabel>
                </Grid>
                <Grid item xs={12}>
                    {isLoaded ? <Mapa containerStyle={containerStyle} polygonEditable={true} /> : <></>}
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
