import { createContext, useContext, useState } from "react";
import Enumerable from "linq";
import { useRequest } from "../RequestContext";
import { useLayout } from "../LayoutContext";
import { apiCGetCatFinancieras, apiCSaveCatFinanciera } from "../../settings/apiConfig";
import Swal from "sweetalert2";

import { getFormCatalogoFinanciera, getModelCatalogoFinanciera } from "../../models/FinancieraModel";
import { fnPrevent } from "../../settings/preventConfig";
import { fnHandleChangeText } from "../../settings/handleConfig";

export const FinancierasContext = createContext();

export function FinancierasProvider(props) {
    const { handleOpenAlert } = useLayout();
    const { Get, Post } = useRequest();
    
    //Catalogo de Financieras
    const [openCatFinancieras, setOpenCatFinancieras] = useState(false);
    const [listaFinancieras, setListaFinancieras] = useState([]);
    const [listaBuros, setListaBuros] = useState([]);
    const [formCatalogoFinanciera, setFormCatalogoFinanciera] = useState(getFormCatalogoFinanciera());
    const [financieraSeleccionada, setFinancieraSeleccionada] = useState(getModelCatalogoFinanciera());

    const [openFormFinanciera, setOpenFormFinanciera] = useState(false);

    const handleOpenCatFinanciera = (value) => {
        setOpenCatFinancieras(value);
    };

    const handleObtenerFinancieras = async () => {
        const payload = {
            loader: "Consultando financieras",
            url: apiCGetCatFinancieras,
            alert: true,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            if (response.result.length > 0) {
                const listaFinancieras = Enumerable.from(response.result)
                    .select((finan) => ({
                        iIdFinanciera: finan.iIdFinanciera,
                        sNombre: finan.sNombre,
                        entBuro: finan.entBuro,
                        sUsuarioBuroCred: finan.sUsuarioBuroCred,
                        sPwdBuroCred: finan.sPwdBuroCred,
                    }))
                    .toArray();
                setListaFinancieras(listaFinancieras);

                const _listaBuros = listaFinancieras.map( a => a.entBuro );
                setListaBuros(_listaBuros.filter((v,i,a)=>a.findIndex(v2=>(JSON.stringify(v2) === JSON.stringify(v)))===i));

            } else {
                handleOpenAlert("Error al obtener las financieras", "error");
            }
        }
    };

    const handleOpenFormFinanciera = (value) => {
        setOpenFormFinanciera(value);
        if (!value) {
            setFormCatalogoFinanciera(getFormCatalogoFinanciera());
            setFinancieraSeleccionada(getModelCatalogoFinanciera());
        }
    }

    const handleChangeFormFinanciera = fnHandleChangeText(formCatalogoFinanciera, setFormCatalogoFinanciera);

    const handleSubmitFormFinanciera = async (e) => {
        fnPrevent(e);
        const iIdFinan = financieraSeleccionada.iIdFinanciera === undefined ? -1 : financieraSeleccionada.iIdFinanciera;
        const { txtNombreFinanciera, cmbBoxIdBuro, txtUsuBuroCred, txtPwdBuroCred, } = formCatalogoFinanciera;

        const form = {
            ...formCatalogoFinanciera,
            txtNombreFinanciera: {
                ...txtNombreFinanciera,
                error: txtNombreFinanciera.value === "",
            },
            cmbBoxIdBuro: {
                ...cmbBoxIdBuro,
                error: cmbBoxIdBuro.value === "",
            },
            txtUsuBuroCred: {
                ...txtUsuBuroCred,
                error: txtUsuBuroCred.value === "",
            },
            txtPwdBuroCred: {
                ...txtPwdBuroCred,
                error: txtPwdBuroCred.value === "",
            },
        };

        setFormCatalogoFinanciera(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }

        const entFinanciera = {
            iIdFinanciera: iIdFinan,
            sNombre: txtNombreFinanciera.value,
            iIdBuro: cmbBoxIdBuro.value,
            sUsuarioBuroCred: txtUsuBuroCred.value,
            sPwdBuroCred: txtPwdBuroCred.value,
            iIDUsuarioCreacion: iIdFinan === -1 ? 1 : 0,
            iIDUsuarioModificacion: iIdFinan !== -1 ? 1 : 0,
            iIDUsuarioElimina: 0,
        };

        await fn_guardarFinanciera(entFinanciera);
    };

    const fn_guardarFinanciera = async (entFinanciera) => {
        let loader = "";
        if (entFinanciera.iIDUsuarioCreacion > 0) {
            loader = "Guardando el registro de financiera.";
        } else if (entFinanciera.iIDUsuarioModificacion > 0) {
            loader = "Actualizando el registro de financiera.";
        } else if (entFinanciera.iIDUsuarioElimina > 0) {
            loader = "Eliminando el registro de financiera.";
        }

        const payload = {
            loader: loader,
            body: entFinanciera,
            url: apiCSaveCatFinanciera,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            handleOpenFormFinanciera(false);
            setFinancieraSeleccionada(getModelCatalogoFinanciera());
            setFormCatalogoFinanciera(getFormCatalogoFinanciera());
            await handleObtenerFinancieras();
        }
    };

    const handleActualizarFinanciera = (e, financiera) => {
        fnPrevent(e);
        setFinancieraSeleccionada(financiera);

        const { txtNombreFinanciera, cmbBoxIdBuro, txtUsuBuroCred, txtPwdBuroCred } = formCatalogoFinanciera;

        let form = {
            ...formCatalogoFinanciera,
            txtNombreFinanciera: {
                ...txtNombreFinanciera,
                value: financiera.sNombre,
            },
            cmbBoxIdBuro: {
                ...cmbBoxIdBuro,
                value: financiera.entBuro.iidburo,
            },
            txtUsuBuroCred: {
                ...txtUsuBuroCred,
                value: financiera.sUsuarioBuroCred,
            },
            txtPwdBuroCred: {
                ...txtPwdBuroCred,
                value: financiera.sPwdBuroCred,
            },
        };

        setFormCatalogoFinanciera(form);
        setOpenFormFinanciera(true);
    };

    const handleEliminarFinanciera = async (e, financiera) => {
        fnPrevent(e);
        Swal.fire({
            title: "Eliminar financiera",
            text: "¿Está seguro de eliminar la financiera " + financiera.sNombre + "?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await fn_eliminarFinanciera(financiera);
            }
        });
    };

    const fn_eliminarFinanciera = async (financiera) => {
        const entFinanciera = {
            iIdFinanciera: financiera.iIdFinanciera,
            iIDUsuarioElimina: 1,
            iIDUsuarioCreacion: 0,
            iIDUsuarioModificacion: 0,
        };
        await fn_guardarFinanciera(entFinanciera);
    };

    return (
        <FinancierasContext.Provider
            value={{
                openCatFinancieras,
                openFormFinanciera,
                formCatalogoFinanciera,
                financieraSeleccionada,
                listaFinancieras,
                listaBuros,
                handleOpenCatFinanciera,
                handleActualizarFinanciera,
                handleObtenerFinancieras,
                handleOpenFormFinanciera,
                handleChangeFormFinanciera,
                handleSubmitFormFinanciera,
                handleEliminarFinanciera,
            }}
        >
            {props.children}
        </FinancierasContext.Provider>
    );

}

export function useFinancieras() {
    return useContext(FinancierasContext);
}
