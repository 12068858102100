export const getModelCatalogoDocumento = () => ({
    id: -1,
    iIdDocumetosocr: undefined,
    sNombre: "",
    sNombrePatron: "",
    fvalorJW: "",
});

export const getFormCatalogoDocumentos = () => ({
    txtNombreDocto: {
        label: "Nombre documento:",
        name: "txtNombreDocto",
        required: true,
        value: "",
        error: false,
        message: "El nombre del documento es requerido",
        placeholder: "Ej. INE",
        maxlength: 50,
    },
    txtNombrePatron: {
        label: "Nombre patrón:",
        name: "txtNombrePatron",
        required: true,
        value: "",
        error: false,
        message: "El nombre del patrón es requerido",
        placeholder: "Ej. patron.xml",
        maxlength: 50,
    },
    txtArchivo: {
        label: "Archivo:",
        required: false,
        value: "",
        nombreArchivo: "",
        error: false,
        message: "Seleccione el archivo",
        placeholder: "",
        maxlength: 20000,
    },
    txtTipo: {
        label: "Tipo:",
        name: "txtTipo",
        required: true,
        value: "",
        error: false,
        message: "El tipo es requerido",
        placeholder: "",
        maxlength: 50,
    },
    txtValorJW: {
        label: "Porcentaje de modificación de los datos capturados:",
        name: "txtValorJW",
        required: true,
        value: "",
        error: false,
        message: "El valor es requerido",
        placeholder: "",
    },
});

export const getModelCatalogoConfiguracion = () => ({
    id: -1,
    iIdConfig: undefined,
    sNombre: "",
    sValor: "",
    sTipo: "",
    sDescripcion: "",
});

export const getFormCatalogoConfiguracion = () => ({
    txtNombreConfig: {
        label: "Nombre configuración:",
        name: "txtNombreConfig",
        required: true,
        value: "",
        error: false,
        message: "El nombre de la configuración es requerido",
        placeholder: "Ej. Config",
        maxlength: 50,
    },
    txtValor: {
        label: "Valor:",
        name: "txtValor",
        required: true,
        value: "",
        error: false,
        message: "El valor es requerido",
        placeholder: "Ej. valor",
        maxlength: 700,
    },
    slTipoDeDatoCampoBloque: {
        label: "Tipo de dato:",
        name: "slTipoDeDatoCampoBloque",
        required: true,
        value: "",
        error: false,
        message: "El tipo de dato es requerido",
        placeholder: "",
    },
    txtDescripcion: {
        label: "Descripcion:",
        name: "txtDescripcion",
        required: true,
        value: "",
        error: false,
        message: "La descripción es requerida",
        placeholder: "Ej. valor",
        //maxlength: 100,
    },
});

export const getModelCatalogoSolicitudRechazada = () => ({
    id: -1,
    iIdInfosolicitudrechazada: undefined,
    sTipoSolicitud: "",
    sDescripcion: "",
});

export const getFormCatalogoSolicitudRechazada = () => ({
    slTipoSolicitudFilter: {
        label: "Tipo de Solicitud:",
        name: "slTipoSolicitudFilter",
        value: 0,
        placeholder: "Buscar por tipo de solicitud",
    },
    slTipoSolicitud: {
        label: "Tipo de solicitud:",
        required: true,
        value: 0,
        error: false,
        message: "El tipo de solicitud es requerido",
        placeholder: "",
    },
    txtDescripcion: {
        label: "Descripcion:",
        name: "txtDescripcion",
        required: true,
        value: "",
        error: false,
        message: "La descripción es requerida",
        placeholder: "Ej. valor",
        maxlength: 100,
    },
});

export const getFormZonaGeograficaModel = () => ({
    txtDescripcion: {
        label: "Descripción:",
        name: "txtDescripcion",
        required: true,
        value: "",
        error: false,
        isExist: false,
        message: "La descripción es requerida",
        messageUnique: " No se puede repetir la zona geográfica",
        placeholder: "Ej. Zona centro",
        maxlength: 50,
    },
    slTipoArea: {
        label: "Tipo Area:",
        name: "slTipoArea",
        required: true,
        value: 0,
        error: false,
        message: "El tipo de área es requerido",
        placeholder: "",
        maxlength: 80,
    },
});

export const getModelTipoArea = () => ({
    id: -1,
    iIDTipoArea: undefined,
    sNombreArea: "",
});

export const getFormTipoAreaModel = () => ({
    txtDescripcion: {
        label: "Descripción:",
        name: "txtDescripcion",
        required: true,
        value: "",
        error: false,
        isExist: false,
        message: "La descripción es requerida.",
        messageUnique: " No se puede repetir el tipo de área",
        placeholder: "Ej. ÁREA 1",
        maxlength: 50,
    }
});

export const getModelCatReglasOperadores = () => ({
    id: undefined,
    sNombreValidacion: "",
    sTipoDato: "",
    sOperador: "",
});

export const getFormCatReglasOperadores = () => ({
    cmbBoxValidacion: {
        label: "Validación:",
        name: "cmbBoxValidacion",
        value: "",
    },
    // txtValidacion: {
    //     label: "Validacion:",
    //     name: "txtValidacion",
    //     value: "",
    //     maxlength: 100,
    // },
    slValidacion: {
        label: "Validacion:",
        required: true,
        value: 0,
        error: false,
        message: "El tipo de validación es requerido",
        placeholder: "",
    },
    slTipoDato: {
        label: "Tipo de dato:",
        required: true,
        value: 0,
        error: false,
        message: "El tipo de dato es requerido",
        placeholder: "",
    },
    slOperador: {
        label: "Operador:",
        required: true,
        value: 0,
        error: false,
        message: "El operador es requerido",
        placeholder: "",
    },
});

export const enumTipos = 
[
    {id:1, text:"COMPARE_WITH"},
    {id:2, text:"DEPEND_FROM"},
    {id:3, text:"GREATHER_THAN_INT"},
    {id:4, text:"HAS_IMAGE_LOCATION"},
    {id:5, text:"IS_DISABLED"},
    {id:6, text:"IS_ENABLE_IF"},
    {id:7, text:"IS_PASSWORD"},
    {id:8, text:"IS_REQUIRED"},
    {id:9, text:"IS_VISIBLE"},
    {id:10, text:"IS_VISIBLE_IF"},
    {id:11, text:"IS_VISIBLE_IF_LIST"},
    {id:12, text:"LINK_TO"},
    {id:13, text:"MAX_LEN"},
    {id:14, text:"MAX_VALUE"},
    {id:15, text:"MIN_LEN"},
    {id:16, text:"MIN_VALUE"},
    {id:17, text:"ONLY_UPPERCASE"},
    {id:18, text:"REQUIRED_IF"},
    {id:19, text:"VAL_DATE"}
];