import { makeStyles } from "tss-react/mui";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { IoArrowDownOutline, IoArrowUpOutline, IoReorderTwoOutline, IoSwapVerticalOutline } from "react-icons/io5";
import { Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles()((theme) => {
    return {
        draggingListItem: {
            background: "rgb(235,235,235)",
        },
    };
});

export default function DinercapDraggableListItem(props) {
    const { length, item, index } = props;

    const classes = useStyles();

    const IconItem = () => {
        const { length, index } = props;
        if (index === 0) {
            if (length > 1) {
                return <IoArrowDownOutline className="text-gray-900 text-2xl mr-3" />;
            } else {
                return <IoReorderTwoOutline className="text-gray-900 text-2xl mr-3" />;
            }
        } else if (index === 1) {
            if (length > 2) {
                return <IoArrowDownOutline className="text-gray-900 text-2xl mr-3" />;
            } else {
                return <IoReorderTwoOutline className="text-gray-900 text-2xl mr-3" />;
            }
        } else if (index === length - 1) {
            return <IoArrowUpOutline className="text-gray-900 text-2xl mr-3" />;
        } else {
            return <IoSwapVerticalOutline className="text-gray-900 text-2xl mr-3" />;
        }
    };

    return (
        <Draggable draggableId={item.id} index={index}>
            {(provided, snapshot) => (
                <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`bg-card bg-white shadow rounded-xl !py-4 ${
                        snapshot.isDragging ? classes.draggingListItem : ""
                    }`}
                    // onClick={() => handleClick(parseInt(item.id))}
                >
                    <IconItem index={index} length={length} />
                    <ListItemText primary={item.primary} secondary={item.secondary} />
                </ListItem>
            )}
        </Draggable>
    );
}
