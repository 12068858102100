import React, { useEffect, useState, Fragment } from "react";
import Enumerable from "linq";
import ReactSpeedometer from "react-d3-speedometer"
import { saveAs } from "file-saver";
import { makeStyles } from "tss-react/mui";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { Link, TextField } from "@mui/material";
import { inputColor, inputVariant } from "../../../settings/inputConfig";
import { useSolicitud } from "../../../context/SolicitudContext";

//DataGrid
import { DataGrid, esES, GridRow, GridToolbarExport, GridToolbarContainer } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import clsx from "clsx";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
//DataGrid

import { experimentalStyled as styled } from '@mui/material/styles';
import { Button, Grid, Paper, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import InnerImageZoom from "react-inner-image-zoom";
import { CloudDownloadOutlined, Diversity1, PhotoCameraOutlined, Refresh } from "@mui/icons-material";
import RoomIcon from "@mui/icons-material/Room";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import { imgFaceDetection, imgFaceNoDetection } from "../../../settings/imgConfig";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles()((theme) => {
    return {
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        container: {
            maxHeight: 440,
        },
        root: {
            width: "100%",
        },
    };
});

//DataGrid
function CustomRow(props) {
    const { className, index, ...other } = props;

    return <GridRow index={index} className={clsx(className, index % 2 === 0 ? "odd" : "even")} {...other} />;
}

//QuickSearchToolbar
function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

function QuickSearchToolbar(props) {
    const classes = useStyles();

    return (
        <div>
            <div>
                <GridToolbarContainer>
                    <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
                </GridToolbarContainer>
            </div>
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? "visible" : "hidden" }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

// Dialog para confirmar...
function DialogConfirm(props) {
    const {
        openConfirm,
        handleCloseConfirm,
        texto,
        camposPorValidar,
        solicitudSeleccionado,
        handleSubmitUpdEstadoSolicitud,
        textoRechazo,
    } = props;

    const [motivo, setMotivo] = React.useState("");
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState("");
    const handleChange = (event) => {
        const valor = event.target.value;
        if (valor.length <= 250) {
            setMotivo(valor);
            setError(false);
            setHelperText("");
        } else {
            setError(true);
            setHelperText("Ingrese un máximo de 250 caracteres");
        }
    };

    const handleClickSi = async (e) => {
        e.preventDefault();
        await handleSubmitUpdEstadoSolicitud(e, solicitudSeleccionado.iIdSolicitud, camposPorValidar, motivo);
        handleCloseConfirm();
    };

    useEffect(() => {
        setMotivo(textoRechazo === "Sin motivo" ? "" : textoRechazo);
    }, []);

    return (
        <Dialog
            fullWidth={true}
            size="sm"
            open={openConfirm}
            onClose={handleCloseConfirm}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Confirmación</DialogTitle>
            <DialogContent>
                <DialogContentText>{texto}</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Observación"
                    fullWidth
                    multiline
                    rows={4}
                    onChange={handleChange}
                    value={motivo}
                    variant="outlined"
                    error={error}
                    helperText={helperText}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseConfirm} className="btn btn-danger">
                    Cancelar
                </Button>
                <Button onClick={handleClickSi} className="btn btn-primary">
                    Si
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// Prueba de vida
function DialogPruebaVida(props) {
    const { srcImagen, openModalPruebaVida, handleOpenModalPruebaVida } = props;

    return (
        <Dialog fullWidth={true} size="xl" open={openModalPruebaVida} onClose={() => handleOpenModalPruebaVida(false)}>
            <DialogTitle id="form-dialog-title">Prueba de vida</DialogTitle>
            <DialogContent>
                <img className="img-fluid mx-auto d-block" src={srcImagen} alt="..." />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleOpenModalPruebaVida(false)}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default function SeguimientoSolicitudDetalle(props) {
    const {
        tipoSolicitudSeleccionado,
        listaDetalleSolicitud,
        modalOpenSolicitudDetalle,
        handleChangeGetListaSolicitudDetail,
        handleOpenModalSolicitudDetalle,
        solicitudSeleccionado,
        handleSubmitUpdEstadoSolicitud,
        handleSubmitFormCpColonia,
        handleClickNotificarCliente,
        handleOpenModalPruebaVida,
        openModalPruebaVida,
        handleChangeFormCPColonia,
        listaCamposAutoFill,
        handleChangeCreateReporteCreditoPDF,
        handleGetBuroSolicitud,
        scoreSolicitud,
        handleGetInfoFinancieraByIdSolicitud,
        disabledBtnReporteCredito,
        formCPColonia,
        listaColonias,
        listaEstatusUpd,
        handleObtenerColoniasxCP,
        handleGetEstadosSolicitudUpd,
    } = useSolicitud();

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const [urlImg, setUrlImg] = useState({ nombre: "", url: "" });

    const [filaSelecionadas, setFilaSelecionadas] = useState([]);
    const [rowSelected, setRowSelected] = useState(null);

    const [habiliarControles, setHabiliarControles] = useState(false);

    const [imgPruebaVida, setImgPruebaVida] = useState("");
    const [ubicacionPruebaVida, setUbicacionPruebaVida] = useState({ lat: "", lng: "" });

    const { txtCodigoPostal, cmbBoxColonias } = formCPColonia;

    const MapsURL = "https://www.google.com/maps/search/?api=1&query={lat},{lng}";

    // #region Confirmacion rechazo campos
    const [openDialog, setOpenDialog] = React.useState(false);
    const [textoValidar, setTextoValidar] = React.useState("");
    const [camposPorValidar, setCamposPorValidar] = React.useState([]);
    const [textoRechazo, setTextoRechazo] = React.useState("");

    const handleCloseConfirm = () => {
        setOpenDialog(false);
        setCamposPorValidar([]);
        setTextoValidar("");
    };
    // #endregion

    const handleClickVerImg = (e, row) => {
        if (row.Valor !== "") {
            setRowSelected(row);
            setUrlImg({
                ...urlImg,
                nombre: row.Campo,
                url: row.Valor,
            });
            setOpen(true);
        } else {
            Swal.fire({
                title: "Imagen",
                text: "El campo es de tipo imagen pero no tiene valor.",
                icon: "warning",
            });
        }
    };

    const handleCloseModalImg = () => {
        setUrlImg({
            ...urlImg,
            nombre: "",
            url: "",
        });
        setOpen(false);
    };

    const verInfo = (e, row) => {
        e.preventDefault();
        let campo = row.Campo;
        let valor = row.Valor;
        Swal.fire({
            title: campo + ":",
            text: valor,
            icon: "info",
        });
    };

    const handleChangeStatus = (e, row) => {
        e.preventDefault();

        if (row.Rechazado === false) {
            if (habiliarControles) {
                let estado = row.Rechazado ? "Aprobado" : "Rechazado";
                let texto = `¿Notificar al cliente que corrobore la información del campo: ${row.Campo}?`;

                let campos = [];
                campos.push(row);
                setCamposPorValidar(campos);

                setTextoValidar(texto);
                setTextoRechazo(row.MotivoRechazo !== null ? row.MotivoRechazo : "");
                setOpenDialog(true);
            }
        } else {
            Swal.fire({
                title: "Validando",
                html:
                    "El registro se está validando por el cliente.<br><br><b>Observación: </b><i>" +
                    row.MotivoRechazo +
                    "</i>",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ACEPTAR",
            });
        }
    };

    const handleRechazoMasivo = (e) => {
        e.preventDefault();

        let campos = [];
        data.forEach((item) => {
            if (filaSelecionadas.indexOf(item.id) >= 0) {
                campos.push(item);
            }
        });

        setCamposPorValidar(campos);
        setTextoValidar("¿Notificar al cliente que corrobore la información de los campos seleccionados?");
        setOpenDialog(true);
    };

    const handleClickUpdDatosDircampo = async (e) => {
        Swal.fire({
            title: "Aviso",
            html: "¿Desea cambiar el Código Postal y Colonia <br> a esta Solicitud?",
            icon: "info",
            showCloseButton: true,
            showDenyButton: true,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#3085d6",
            denyButtonText: "Cancelar",
            denyButtonColor: '#8CD4F5',
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await handleSubmitFormCpColonia(e);
            }
        });

    }

    //<i className={"fas fa-" + tipoSolicitud.sIcono}></i>
    const columns = [
        {
            field: "Acciones",
            align: "center",
            disableExport: true,
            renderCell: (cellValues) => {
                return (
                    <div>
                        {cellValues.row.Valor !== "" &&
                            (() => {
                                switch (cellValues.row.Tipo) {
                                    case "IMG":
                                        return (
                                            <Fragment>
                                                <Tooltip title="Ver imagen">
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="upload picture"
                                                        component="span"
                                                        onClick={(event) => {
                                                            handleClickVerImg(event, cellValues.row);
                                                        }}
                                                    >
                                                        <PhotoCameraOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                                {cellValues.row.ubicacion.lng !== null &&
                                                    cellValues.row.ubicacion.lat !== null ? (
                                                    <Tooltip title="Ubicación">
                                                        <a
                                                            href={MapsURL.replace(
                                                                "{lat}",
                                                                cellValues.row.ubicacion.lat
                                                            ).replace("{lng}", cellValues.row.ubicacion.lng)}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <LocationOnOutlinedIcon />
                                                        </a>
                                                    </Tooltip>
                                                ) : (
                                                    <></>
                                                )}
                                            </Fragment>
                                        );
                                    case "FILE":
                                        return (
                                            <Fragment>
                                                &nbsp;&nbsp;&nbsp;
                                                <Tooltip title="Descargar archivo">
                                                    <a href={cellValues.row.Valor} target="_blank" rel="noreferrer">
                                                        <CloudDownloadOutlined />
                                                    </a>
                                                </Tooltip>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {cellValues.row.ubicacion.lng !== null &&
                                                    cellValues.row.ubicacion.lat !== null ? (
                                                    <Tooltip title="Ubicación">
                                                        <a
                                                            href={MapsURL.replace(
                                                                "{lat}",
                                                                cellValues.row.ubicacion.lat
                                                            ).replace("{lng}", cellValues.row.ubicacion.lng)}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <LocationOnOutlinedIcon />
                                                        </a>
                                                    </Tooltip>
                                                ) : (
                                                    <></>
                                                )}
                                            </Fragment>
                                        );
                                    default:
                                        return "";
                                }
                            })()}
                    </div>
                );
            },
        },
        {
            field: "Rechazado",
            headerName: "Validar",
            align: "center",
            width: 100,
            disableExport: true,
            renderCell: (cellValues) => {
                return !listaCamposAutoFill.some((el) => cellValues.row.Campo === el) ? (
                    <div>
                        {cellValues.row.Valor !== "" && (
                            <Tooltip
                                title={
                                    cellValues.row.RechazadoReal && !cellValues.row.ReqCorrecion
                                        ? "Motivo: " + String(cellValues.row.MotivoRechazo)
                                        : ""
                                }
                            >
                                <Chip
                                    style={{
                                        background:
                                            cellValues.row.RechazadoReal && !cellValues.row.ReqCorrecion
                                                ? "#ffc107"
                                                : cellValues.row.Rechazado
                                                    ? "#dc3545"
                                                    : "#20c997",
                                        color: "white",
                                        cursor: habiliarControles
                                            ? "pointer"
                                            : cellValues.row.RechazadoReal && !cellValues.row.ReqCorrecion
                                                ? "default"
                                                : cellValues.row.Rechazado
                                                    ? "pointer"
                                                    : "default",
                                    }}
                                    size="small"
                                    label={cellValues.row.Rechazado === false ? "NO" : "SI"}
                                    clickable
                                    onClick={(e) => {
                                        handleChangeStatus(e, cellValues.row);
                                    }}
                                />
                            </Tooltip>
                        )}
                    </div>
                ) : (
                    <></>
                );
            },
        },
        { field: "id", headerName: "ID", width: 100, hide: true },
        {
            field: "iIDBloqueSolicitudCampos",
            headerName: "Folio",
            width: 100,
            hide: true,
        }, //
        { field: "Campo", headerName: "Campo", width: 300 },
        {
            field: "Valor",
            headerName: "Valor",
            width: 900,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Link
                        underline="none"
                        href="#"
                        onClick={(e) => {
                            verInfo(e, cellValues.row);
                        }}
                    >
                        {cellValues.row.Valor}
                    </Link>
                );
            },
        },
        {
            field: "FechaCreacion",
            headerName: "Fecha alta",
            width: 200,
            hide: true,
            //editable: true,
        },
    ];

    const [rows, setRows] = useState([]);
    const [data, setData] = useState([]);

    const createData = () => {
        let data = [];
        let id = 1;

        for (let index = 0; index < listaDetalleSolicitud.length; index++) {
            let { aBloques } = listaDetalleSolicitud[index];
            if (aBloques != null) {
                for (let c = 0; c < aBloques.length; c++) {
                    let { bGrupo, sNombreBloque, aCampos } = aBloques[c];
                    if (!bGrupo) {
                        for (let b = 0; b < aCampos.length; b++) {
                            let { sNombre, vValor, ifInputForm } = aCampos[b];
                            let valorCampo = vValor == null ? "" : vValor.sTexto;
                            let rechazado = vValor == null ? "" : vValor.bRechazado;
                            let motivoRechazo = vValor == null ? "" : vValor.sMotivoRechazo;
                            let bReqCorrecion = vValor == null ? "" : vValor.bReqCorrecion;
                            let fechaC = vValor == null ? "" : vValor.dtFechaCreacion;
                            let Tipo = ifInputForm == null ? "" : ifInputForm.sNombre;
                            let iIDBloqueSolicitudCampos = vValor === null ? 0 : vValor.iIDBloqueSolicitudCampos;
                            data.push({
                                id,
                                iIDBloqueSolicitudCampos,
                                Bloque: sNombreBloque,
                                Campo: sNombre,
                                Valor: valorCampo !== null ? String(valorCampo).trim() : "",
                                Rechazado: rechazado !== "" ? (rechazado && !bReqCorrecion ? false : rechazado) : "",
                                bGrupo,
                                FechaCreacion: fechaC,
                                Tipo,
                                ubicacion: {
                                    lng: vValor !== null ? vValor.sLongitud : "",
                                    lat: vValor !== null ? vValor.sLatitud : "",
                                },
                                MotivoRechazo: motivoRechazo === null ? "Sin motivo" : motivoRechazo,
                                ReqCorrecion: bReqCorrecion,
                                RechazadoReal: rechazado,
                            });
                            id++;
                        }
                    } else {
                        try {
                            for (let c = 0; c < aCampos.length; c++) {
                                const { aCamposAgrupado } = aCampos[c];
                                const sNombreCampo = aCampos[c].sNombre;
                                if (aCamposAgrupado.length > 0) {
                                    for (let b = 0; b < aCamposAgrupado.length; b++) {
                                        let { sNombre, vValor, ifInputForm } = aCamposAgrupado[b];
                                        let valorCampo = vValor == null ? "" : vValor.sTexto;
                                        let rechazado = vValor == null ? "" : vValor.bRechazado;
                                        let bReqCorrecion = vValor == null ? "" : vValor.bReqCorrecion;
                                        let motivoRechazo = vValor == null ? "" : vValor.sMotivoRechazo;
                                        let fechaC = vValor == null ? "" : vValor.dtFechaCreacion;
                                        let Tipo = ifInputForm == null ? "" : ifInputForm.sNombre;
                                        let iIDBloqueSolicitudCampos =
                                            vValor === null ? 0 : vValor.iIDBloqueSolicitudCampos;
                                        data.push({
                                            id,
                                            iIDBloqueSolicitudCampos,
                                            Bloque: sNombreBloque,
                                            Campo: sNombreCampo + " - " + sNombre,
                                            Valor: valorCampo !== null ? String(valorCampo).trim() : "",
                                            Rechazado:
                                                rechazado !== ""
                                                    ? rechazado && !bReqCorrecion
                                                        ? false
                                                        : rechazado
                                                    : "",
                                            bGrupo,
                                            FechaCreacion: fechaC,
                                            Tipo,
                                            ubicacion: {
                                                lng: vValor !== null ? vValor.sLongitud : "",
                                                lat: vValor !== null ? vValor.sLatitud : "",
                                            },
                                            MotivoRechazo: motivoRechazo === null ? "Sin motivo" : motivoRechazo,
                                            ReqCorrecion: bReqCorrecion,
                                            RechazadoReal: rechazado,
                                        });
                                        id++;
                                    }
                                } else {
                                    let { sNombre, vValor, ifInputForm } = aCampos[c];
                                    let valorCampo = vValor == null ? "" : vValor.sTexto;
                                    let rechazado = vValor == null ? "" : vValor.bRechazado;
                                    let bReqCorrecion = vValor == null ? "" : vValor.bReqCorrecion;
                                    let motivoRechazo = vValor == null ? "" : vValor.sMotivoRechazo;
                                    let fechaC = vValor == null ? "" : vValor.dtFechaCreacion;
                                    let Tipo = ifInputForm == null ? "" : ifInputForm.sNombre;
                                    let iIDBloqueSolicitudCampos =
                                        vValor === null ? 0 : vValor.iIDBloqueSolicitudCampos;
                                    data.push({
                                        id,
                                        iIDBloqueSolicitudCampos,
                                        Bloque: sNombreBloque,
                                        Campo: sNombre,
                                        Valor: valorCampo !== null ? String(valorCampo).trim() : "",
                                        Rechazado:
                                            rechazado !== "" ? (rechazado && !bReqCorrecion ? false : rechazado) : "",
                                        bGrupo,
                                        FechaCreacion: fechaC,
                                        Tipo,
                                        ubicacion: {
                                            lng: vValor !== null ? vValor.sLongitud : "",
                                            lat: vValor !== null ? vValor.sLatitud : "",
                                        },
                                        MotivoRechazo: motivoRechazo === null ? "Sin motivo" : motivoRechazo,
                                        ReqCorrecion: bReqCorrecion,
                                        RechazadoReal: rechazado,
                                    });
                                    id++;
                                }
                            }
                        } catch (error) {
                        }
                    }
                }
            }
        }
        setRows(data);
        setData(data);
    };

    useEffect(() => {
        if (modalOpenSolicitudDetalle) {
            handleChangeGetListaSolicitudDetail();
        }
    }, [modalOpenSolicitudDetalle]);

    useEffect(() => {
        createData();
        setHabiliarControles(solicitudSeleccionado.iIDestatus === 4 ? true : false);
        setImgPruebaVida(solicitudSeleccionado.sPruebaVida);
        setUbicacionPruebaVida(solicitudSeleccionado.ubicacion);
    }, [listaDetalleSolicitud]);

    useEffect(() => {
    }, [listaColonias]);

    useEffect(() => {
        if (listaEstatusUpd.length == 0)
            handleGetEstadosSolicitudUpd();
    }, [listaEstatusUpd]);

    const handleClose = () => {
        setFilaSelecionadas([]);
        handleOpenModalSolicitudDetalle(false);
    };

    const [pageSize, setPageSize] = useState(100);

    const arrayEst = [4];

    const [searchText, setSearchText] = useState("");
    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = data.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));


    const downloadImage = () => {
        const separador = "/";
        const result = rowSelected.Valor.split(separador);
        let fileName = result.length !== 0 ? result[result.length - 1] : "image.jpg";
        saveAs(rowSelected.Valor, fileName);
    };

    const abrirUrlPruebaVida = url => {
        //alert(url);
        if (url !== undefined) {
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };

    //Reporte de cédito
    const handleClickPDF = (e) => {
        e.preventDefault();
        handleChangeCreateReporteCreditoPDF();
    };

    useEffect(() => {
        handleGetBuroSolicitud(solicitudSeleccionado.iIdSolicitud);
        handleGetInfoFinancieraByIdSolicitud();
    }, [])

    return (
        <Fragment>
            <Dialog fullScreen open={modalOpenSolicitudDetalle} onClose={handleClose} TransitionComponent={Transition}>
                <Box sx={{ flexFlow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title} component="div" sx={{ flexGrow: 1 }}>
                                Detalle de la solicitud
                            </Typography>
                            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
                <div className="flex flex-col gap-2  p-4">
                    <Box
                        sx={{
                            height: 620,
                            width: "100%",
                            "& .odd": {
                                bgcolor: "rgba(0, 0, 0, 0.05)",
                            },
                            "& .even": {
                                bgcolor: "#ffffff",
                            },
                        }}
                    >
                        <div className="row">
                            <div className="col-md-10">
                                <div className="card-deck">
                                    <div className="card mb-3" style={{ maxWidth: "10rem" }}>
                                        <div className="card-header bg-blue-300 text-white">
                                            <Typography variant="button" display="block" gutterBottom>
                                                Prueba de vida
                                            </Typography>
                                        </div>
                                        <div className="card-body text-center">
                                            {solicitudSeleccionado.bPruebaVida ? (
                                                <Fragment>
                                                    <Tooltip title="Prueba de vida verificada">
                                                        <img
                                                            style={{ cursor: "pointer" }}
                                                            className="rounded mx-auto d-block"
                                                            //maxWidth="10rem"
                                                            src={imgFaceDetection}
                                                            alt="..."
                                                            // onMouseOver={(e) => {
                                                            //     handleOpenModalPruebaVida(true);
                                                            // }}
                                                            onClick={() => abrirUrlPruebaVida(imgPruebaVida)}
                                                        />
                                                    </Tooltip>
                                                    {solicitudSeleccionado.ubicacion !== undefined && (
                                                        <Tooltip title="Ubicación">
                                                            <a
                                                                href={MapsURL.replace(
                                                                    "{lat}",
                                                                    ubicacionPruebaVida.lat
                                                                ).replace("{lng}", ubicacionPruebaVida.lng)}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <RoomIcon />
                                                            </a>
                                                        </Tooltip>
                                                    )}
                                                </Fragment>
                                            ) : (
                                                <Tooltip title="Prueba de vida NO verificada">
                                                    <img
                                                        className="rounded mx-auto d-block"
                                                        width="150px"
                                                        src={imgFaceNoDetection}
                                                        alt="..."
                                                    />
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                    <div className="card mb-3" style={{ maxWidth: "30rem", height: "15rem" }}>
                                        <div className="card-header bg-blue-300 text-white">
                                            <Typography variant="button" display="block" gutterBottom>
                                                Información
                                            </Typography>
                                        </div>
                                        <div className="card-body">
                                            <Typography variant="button" display="block" gutterBottom>
                                                Tipo de solicitud: {tipoSolicitudSeleccionado.sDescripcion}
                                            </Typography>
                                            <Typography variant="button" display="block" gutterBottom>
                                                Folio: {solicitudSeleccionado.iIdSolicitud}
                                            </Typography>
                                            <Typography variant="button" display="block" gutterBottom>
                                                RFC: {solicitudSeleccionado.srfc}
                                            </Typography>
                                            <Typography variant="button" display="block" gutterBottom>
                                                Nombre: {solicitudSeleccionado.snombre}
                                            </Typography>
                                            <Typography variant="button" display="block" gutterBottom>
                                                Estado: {solicitudSeleccionado.sEstado}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="card mb-3" style={{ maxWidth: "30rem", height: "18rem" }}>
                                        <div className="card-header bg-blue-300 text-white">
                                            <Typography variant="button" display="block" gutterBottom>
                                                Reporte de crédito
                                            </Typography>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">

                                                    {/* <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> */}
                                                    <Button
                                                        className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                                        size="small"
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={handleClickPDF}
                                                        disabled={disabledBtnReporteCredito}
                                                    >
                                                        {disabledBtnReporteCredito ? <>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Descargando...
                                                        </> : "Descargar Reporte"}
                                                    </Button>
                                                </div>
                                            </div>
                                            {scoreSolicitud ?
                                                <div className="row">
                                                    <div className="mb-12">
                                                        <div className="mx-5">
                                                            <>
                                                                <ReactSpeedometer
                                                                    width={280}
                                                                    maxValue={850}
                                                                    value={scoreSolicitud.entScoreCredito.iscore}
                                                                    valueFormat={'d'}
                                                                    customSegmentStops={[0, scoreSolicitud.inivel1, scoreSolicitud.inivel2, 850]}
                                                                    segmentColors={['#E7432C', '#ECDB23', '#6AD72E',]}
                                                                    textColor={"#848482"}
                                                                    key={scoreSolicitud.entScoreCredito.iscore}
                                                                />
                                                            </>
                                                        </div>

                                                    </div>
                                                </div> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                {listaEstatusUpd.findIndex(x => x.iIdEstatus === solicitudSeleccionado.iIDestatus) > -1 ?
                                    <div className="row">
                                        <div className="col-md-2">
                                            <TextField
                                                name={txtCodigoPostal.name}
                                                label={txtCodigoPostal.label}
                                                required={txtCodigoPostal.required}
                                                value={txtCodigoPostal.value}
                                                error={txtCodigoPostal.error}
                                                helperText={txtCodigoPostal.error && txtCodigoPostal.message}
                                                variant={inputVariant}
                                                color={inputColor}
                                                onChange={handleChangeFormCPColonia}
                                                fullWidth
                                                autoFocus
                                                autoComplete="off"
                                                className="mb-2"
                                            />
                                        </div>
                                        <div>
                                            <Tooltip title="Buscar colonias por código postal">
                                                <Button
                                                    className="!text-sm !normal-case !px-4 !py-2.5"
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    disableElevation
                                                    onClick={async (event) => {
                                                        await handleObtenerColoniasxCP();
                                                    }}
                                                >
                                                    Buscar
                                                </Button>
                                            </Tooltip>
                                        </div>
                                        {/* {listaColonias.length > 0 && ( */}
                                        <div className="col-md-4">
                                            <FormControl fullWidth variant="outlined" error={cmbBoxColonias.error}>
                                                <InputLabel id={cmbBoxColonias.name}>{cmbBoxColonias.label}</InputLabel>
                                                <Select
                                                    name={cmbBoxColonias.name}
                                                    labelId={cmbBoxColonias.label}
                                                    id={cmbBoxColonias.name}
                                                    label={cmbBoxColonias.label}
                                                    required={cmbBoxColonias.required}
                                                    value={cmbBoxColonias.value}
                                                    error={cmbBoxColonias.error}
                                                    onChange={handleChangeFormCPColonia}
                                                    autoComplete="off"
                                                    defaultValue={0}
                                                >
                                                    <MenuItem value={0}>
                                                        <em>Seleccione una colonia</em>
                                                    </MenuItem>
                                                    {listaColonias.map((colonia) => {
                                                        return (
                                                            <MenuItem
                                                                key={colonia.id}
                                                                value={colonia.asentamiento}
                                                            >
                                                                {colonia.asentamiento}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                                <FormHelperText>{cmbBoxColonias.error && cmbBoxColonias.message}</FormHelperText>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <Tooltip title="Actualizar CP">
                                                <Button
                                                    className="!text-sm !normal-case !px-4 !py-2.5"
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    disableElevation
                                                    onClick={async (event) => {
                                                        await handleClickUpdDatosDircampo();
                                                    }}
                                                >
                                                    Actualizar CP
                                                </Button>
                                            </Tooltip>
                                        </div>
                                        {/* )} */}
                                    </div>
                                    : <></>}
                            </div>
                            <div className="col-md-2 text-right">
                                {Enumerable.from(data)
                                    .where((w) => w.Rechazado === true)
                                    .toArray().length > 0 && (
                                        <Button
                                            className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                            size="small"
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            onClick={async (event) => {
                                                await handleClickNotificarCliente();
                                                setHabiliarControles(false);
                                            }}
                                            disabled={!habiliarControles}
                                        >
                                            {/* <Cancel className="text-xl mr-2" /> */}
                                            Notificar al cliente
                                        </Button>
                                    )}
                                &nbsp;
                                {filaSelecionadas.length > 1 && (
                                    <Button
                                        className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        onClick={handleRechazoMasivo}
                                        disabled={!habiliarControles}
                                    >
                                        {/* <Cancel className="text-xl mr-2" /> */}
                                        Marcar campos para validar
                                    </Button>
                                )}
                                <Tooltip title="Actualizar">
                                    <Button
                                        className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                        size="small"
                                        variant="contained"
                                        color="secondary"
                                        disableElevation
                                        onClick={(event) => {
                                            handleChangeGetListaSolicitudDetail();
                                        }}
                                    >
                                        <Refresh className="text-xl mr-2" />
                                        Actualizar
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                        <br />
                        <DataGrid
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            rows={rows}
                            columns={columns}
                            pageSize={pageSize}
                            disableSelectionOnClick
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            components={{ Row: CustomRow, Toolbar: QuickSearchToolbar }}
                            checkboxSelection={habiliarControles}
                            isRowSelectable={
                                //(params) => params.row.Valor !== null && params.row.Rechazado === false
                                (params) =>
                                    listaCamposAutoFill.some((el) => params.row.Campo === el)
                                        ? false
                                        : params.row.Valor !== "" && params.row.Rechazado === false
                            }
                            onSelectionModelChange={(newSelectionModel) => {
                                setFilaSelecionadas(newSelectionModel);
                            }}
                            componentsProps={{
                                toolbar: {
                                    value: searchText,
                                    onChange: (event) => requestSearch(event.target.value),
                                    clearSearch: () => requestSearch(""),
                                },
                            }}
                        />
                    </Box>
                </div>
            </Dialog>
            {/* Confirmacion */}
            {openDialog && (
                <DialogConfirm
                    openConfirm={openDialog}
                    handleCloseConfirm={handleCloseConfirm}
                    texto={textoValidar}
                    camposPorValidar={camposPorValidar}
                    solicitudSeleccionado={solicitudSeleccionado}
                    handleSubmitUpdEstadoSolicitud={handleSubmitUpdEstadoSolicitud}
                    textoRechazo={textoRechazo}
                />
            )}
            {openModalPruebaVida && (
                <DialogPruebaVida
                    srcImagen={imgPruebaVida}
                    openModalPruebaVida={openModalPruebaVida}
                    handleOpenModalPruebaVida={handleOpenModalPruebaVida}
                />
            )}
            {urlImg.url !== "" && (
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseModalImg}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">Campo: {urlImg.nombre}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description" className="text-center">
                            <InnerImageZoom src={urlImg.url} zoomSrc={urlImg.url} zoomScale={1} width={500} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={downloadImage} color="secondary">
                            Descargar
                        </Button>
                        <Button onClick={handleCloseModalImg} color="primary">
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Fragment>
    );
}
