import React, { useEffect, useState, Fragment } from "react";
import { AppBar, Button, Dialog, FormControl, Grid, InputLabel, MenuItem, Select, Slide, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { IoAdd } from "react-icons/io5";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Enumerable from "linq";
import Swal from "sweetalert2";
import _ from 'lodash';

import { useTipoSolicitud } from "../../../../context/TipoSolicitudContext";
import { useAprobados } from "../../../../context/catalogos_generales/AprobadosContext";
import DinercapTableHandle from "../../../utils/DinercapTableHandle";
import FormAprobado from "./FormAprobado";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles()((theme) => {
    return {
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        container: {
            maxHeight: 800,
        },
        root: {
            width: "100%",
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    };
});

export default function AprobadosModal() {
    const {
        formAprobado,
        openAprobados,
        listaAprobados,
        openFormAprobado,
        handleOpenAprobados,
        handleOpenFormAprobado,
        handleObtenerAprobados,
        handleEliminarAprobado,
        handleActualizarAprobado,
        tipoSolicitudSeleccionado,
        handleOnChangeTipoSolicitud,
        handleListarTipoSolicitudes,
    } = useAprobados();

    const {
        listaTiposSolicitud,
        handleClickObtenerTiposSolicitud,
    } = useTipoSolicitud();
    
    const { cmbBoxIdTipoSolicitud } = formAprobado;

    const classes = useStyles();

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({
        iIdInfoSolicitudAprobada: -1,
    });

    const crearData = () => {
        const _data = Enumerable.from(listaAprobados)
            .select((aprobado, index) => ({
                id: index + 1,
                iIdInfoSolicitudAprobada: aprobado.iIdInfoSolicitudAprobada,
                iIdTipoSolicitud: aprobado.iIdTipoSolicitud,
                sNombreTipoSolicitud: aprobado.sNombreTipoSolicitud,
                sDescripcion: aprobado.sDescripcion,
                iIdTipoDato: aprobado.iIdTipoDato,
                sNombreTipoDato: aprobado.sNombreTipoDato,
                iIdInputForms: aprobado.iIdInputForms,
                sNombreInputForm: aprobado.sNombreInputForm,
                jReglas: aprobado.jReglas,
                iOrden: aprobado.iOrden,

            }))
            .toArray();
        setData(_data);
    };

    const columns = [
        { field: "id", title: "#"},
        { field: "sNombreTipoSolicitud", title: "Nombre Solicitud"},
        { field: "sDescripcion", title: "Descripcion"},
        { field: "sNombreTipoDato", title: "Tipo Dato"},
        { field: "sNombreInputForm", title: "Input Forms"},
        {
            field: "jReglas",
            title: "Reglas",
            Element: ({ row }) => {
                return (
                    <Button onClick={() => {
                        Swal.fire({
                            title: "Reglas",
                            text: row.jReglas,
                            confirmButtonText: "Ok",
                        });
                    }}>
                        Reglas {row.iIdTipoSolicitud}
                    </Button>
                );
            },
        },
        { field: "iOrden", title: "Orden"},
    ];

    const onDelete = (e, row) => {
        handleEliminarAprobado(e, row);
    };

    const onUpdate = (e, row) => {
        handleActualizarAprobado(e, row);
    };

    useEffect(() => {
        handleListarTipoSolicitudes();
        handleClickObtenerTiposSolicitud();
    }, []);

    useEffect(() => {
        if (tipoSolicitudSeleccionado !== 0){
            handleObtenerAprobados();
        } else {
            handleListarTipoSolicitudes();
        }
    }, [tipoSolicitudSeleccionado]);

    useEffect(() => {
        crearData();
    }, [listaAprobados]);

    return (
        <Fragment>
            <Dialog
                key="form"
                fullScreen
                open={openAprobados}
                onClose={() => handleOpenAprobados(false)}
                TransitionComponent={Transition}
            >
                <Box sx={{ flexFlow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title} component="div" sx={{ flexGrow: 1 }}>
                                Aprobados
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => handleOpenAprobados(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
                <div className="flex flex-col gap-2 p-4">
                    <Grid container direction="row" justifyContent="center" alignItems="center" >
                        <Grid md={2}/>
                        <Grid xs md>
                            <FormControl fullWidth variant="outlined"
                                sx={{
                                    width: 250,
                                }} >
                                <InputLabel id={cmbBoxIdTipoSolicitud.name}>{cmbBoxIdTipoSolicitud.label}</InputLabel>
                                <Select
                                    name={cmbBoxIdTipoSolicitud.name}
                                    labelId={cmbBoxIdTipoSolicitud.label}
                                    id={cmbBoxIdTipoSolicitud.name}
                                    label={cmbBoxIdTipoSolicitud.label}
                                    value={tipoSolicitudSeleccionado}
                                    onChange={handleOnChangeTipoSolicitud}
                                >
                                    <MenuItem value={0}>
                                        <em>Filtrar por tipo de solicitud</em>
                                    </MenuItem>
                                    {listaTiposSolicitud.map((solicitud) => {
                                            return (
                                                <MenuItem
                                                    key={solicitud.iIDTipoSolicitud}
                                                    value={solicitud.iIDTipoSolicitud}
                                                >
                                                    {solicitud.sNombre}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={4} md={4} />
                        <Grid md>
                            <Button
                                className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={(e) => handleOpenFormAprobado(true)}
                            >
                                <IoAdd className="text-xl mr-2" />
                                Crear
                            </Button>
                        </Grid>
                    </Grid>
                    <Box
                        component="div"
                        sx={{
                            width: "100%",
                            "& .odd": {
                                bgcolor: "rgba(0, 0, 0, 0.05)",
                            },
                            "& .even": {
                                bgcolor: "#ffffff",
                            },
                        }}
                    >
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                            <Grid item xs={12} md={8}>
                                <DinercapTableHandle
                                    id="iIdInfoSolicitudAprobada"
                                    data={data}
                                    columns={columns}
                                    selectedRow={selectedRow}
                                    setRow={setSelectedRow}
                                    filter
                                    onDelete={onDelete}
                                    onUpdate={onUpdate}
                                    //onView={() => {}}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Dialog>
            {openFormAprobado && < FormAprobado/>}
        </Fragment>
    );
}