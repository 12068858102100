import { FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import { inputColor, inputVariant } from "../../../settings/inputConfig";
import { makeStyles } from "tss-react/mui";
import DinercapModal from "../../utils/DinercapModal";
import { useTipoSolicitud } from "../../../context/TipoSolicitudContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useLayout } from "../../../context/LayoutContext";
import Skeleton from "@mui/lab/Skeleton";

import swal from "sweetalert";
import {
    faUserCircle,
    faMapMarkedAlt,
    faBlenderPhone,
    faMoneyCheckAlt,
    faFileAlt,
    faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { imgCargaPreview } from "../../../settings/imgConfig";

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: "none",
        },
        selectImg: {
            cursor: "pointer",
        },
    };
});

export default function FormTipoSolicitud() {
    const classes = useStyles();
    const {
        modalOpenFormTipoSolicitud,
        formTipoSolicitud,
        handleOpenFormTipoSolicitud,
        handleChangeFormTipoSolicitud,
        handleSubmitFormTipoSolicitud,
        handleChangeFormImgTipoSolicitud,
        handleChangeFormCheckTipoSolicitud,
        tipoSolicitudSeleccionado,
        listaFinancieras,
        listaTiposArea,
    } = useTipoSolicitud();

    const { txtDescripcionTipoSolicitud, txtNombre, txtUrl, slIcono, txtImagen, slFinanciera, slTipoArea, chkVisible } = formTipoSolicitud;

    const handleChangeImg = (e) => {
        let pesoValido = 1024;
        let archivo = e.target.files[0];
        let pesoKb = parseInt(archivo.size / 1024);
        if (pesoKb <= pesoValido) {
            handleChangeFormImgTipoSolicitud(e);
        } else {
            swal("Aviso", "Solo se permite un peso menor o igual a " + pesoValido + " Kb", "warning");
        }
    };
    const { loader } = useLayout();

    return (
        <DinercapModal
            title={
                tipoSolicitudSeleccionado.iIDTipoSolicitud === 0 ||
                tipoSolicitudSeleccionado.iIDTipoSolicitud === undefined
                    ? "Nuevo tipo solicitud"
                    : "Editar tipo solicitud"
            }
            okFunc={handleSubmitFormTipoSolicitud}
            setOpen={handleOpenFormTipoSolicitud}
            open={modalOpenFormTipoSolicitud}
            okMessage="Guardar"
            size="small"
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        name="txtNombre"
                        label={txtNombre.label}
                        required={txtNombre.required}
                        value={txtNombre.value}
                        error={txtNombre.error}
                        helperText={txtNombre.error && txtNombre.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormTipoSolicitud}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        className="mb-2"
                        inputProps={{
                            maxLength: txtNombre.maxlength,
                        }}
                    />
                    <TextField
                        name="txtDescripcionTipoSolicitud"
                        label={txtDescripcionTipoSolicitud.label}
                        required={txtDescripcionTipoSolicitud.required}
                        value={txtDescripcionTipoSolicitud.value}
                        error={txtDescripcionTipoSolicitud.error}
                        helperText={txtDescripcionTipoSolicitud.error && txtDescripcionTipoSolicitud.message}
                        onChange={handleChangeFormTipoSolicitud}
                        variant={inputVariant}
                        color={inputColor}
                        fullWidth
                        autoComplete="off"
                        className="mb-2"
                        inputProps={{
                            maxLength: txtDescripcionTipoSolicitud.maxlength,
                        }}
                    />
                    <TextField
                        name="txtUrl"
                        label={txtUrl.label}
                        required={txtUrl.required}
                        value={txtUrl.value}
                        error={txtUrl.error}
                        helperText={txtUrl.error && txtUrl.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormTipoSolicitud}
                        fullWidth
                        autoComplete="off"
                        className="mb-2"
                        inputProps={{
                            maxLength: txtUrl.maxlength,
                        }}
                    />
                    <InputLabel id="lblFinanciera">{slFinanciera.label}</InputLabel>
                    <Select
                        name="slFinanciera"
                        labelId="lblFinanciera"
                        id="slIcono"
                        label={slFinanciera.label}
                        required={slFinanciera.required}
                        value={slFinanciera.value}
                        error={slFinanciera.error}
                        helperText={slFinanciera.error && slFinanciera.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormTipoSolicitud}
                        autoComplete="off"
                        fullWidth
                        defaultValue={0}
                    >
                        <MenuItem value={0}>
                            <em>Seleccionar</em>
                        </MenuItem>
                        {listaFinancieras.length > 0 ? (
                            listaFinancieras.map((item) => <MenuItem value={item.id}>{item.text}</MenuItem>)
                        ) : (
                            <></>
                        )}
                    </Select>
                    {/*  */}
                    <InputLabel id="lblTipoArea">{slTipoArea.label}</InputLabel>
                    <Select
                        name="slTipoArea"
                        labelId="lblTipoArea"
                        id="slTipoArea"
                        label={slTipoArea.label}
                        required={slTipoArea.required}
                        value={slTipoArea.value}
                        error={slTipoArea.error}
                        helperText={slTipoArea.error && slTipoArea.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormTipoSolicitud}
                        autoComplete="off"
                        fullWidth
                        defaultValue={0}
                    >
                        <MenuItem value={0}>
                            <em>Seleccionar</em>
                        </MenuItem>
                        {listaTiposArea.length > 0 ? (
                            listaTiposArea.map((item) => <MenuItem value={item.id}>{item.text}</MenuItem>)
                        ) : (
                            <></>
                        )}
                    </Select>
                    {/*  */}
                    <InputLabel id="lblIcono">Icono:</InputLabel>
                    <Select
                        name="slIcono"
                        labelId="lblIcono"
                        id="slIcono"
                        label={slIcono.label}
                        required={slIcono.required}
                        value={slIcono.value}
                        error={slIcono.error}
                        helperText={slIcono.error && slIcono.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormTipoSolicitud}
                        autoComplete="off"
                        fullWidth
                        defaultValue={0}
                    >
                        <MenuItem value={0}>
                            <em>Seleccionar</em>
                        </MenuItem>
                        <MenuItem value="user-circle;f2bd">
                            <FontAwesomeIcon icon={faUserCircle} size="2x" className="mr-1" />
                            f2bd
                        </MenuItem>
                        <MenuItem value="map-marked-alt;f5a0">
                            <FontAwesomeIcon icon={faMapMarkedAlt} size="2x" className="mr-1" />
                            f5a0
                        </MenuItem>
                        <MenuItem value="blender-phone;f6b6">
                            <FontAwesomeIcon icon={faBlenderPhone} size="2x" className="mr-1" />
                            f6b6
                        </MenuItem>
                        <MenuItem value="money-check-alt;f53d">
                            <FontAwesomeIcon icon={faMoneyCheckAlt} size="2x" className="mr-1" />
                            f53d
                        </MenuItem>
                        <MenuItem value="file-alt;f15c">
                            <FontAwesomeIcon icon={faFileAlt} size="2x" className="mr-1" />
                            f15c
                        </MenuItem>
                    </Select>
                    <FormHelperText error={slIcono.error}>{slIcono.error && slIcono.message}</FormHelperText>
                    <FormControl component="fieldset">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="chkVisible"
                                    checked={chkVisible.value}
                                    onChange={handleChangeFormCheckTipoSolicitud}
                                />
                            }
                            label={chkVisible.label}
                            labelPlacement="start"
                        />
                    </FormControl>
                    <Card sx={{ maxWidth: 600 }} className="dashed">
                        <CardActionArea>
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    SELECCIONAR IMAGEN
                                </Typography>
                            </CardContent>
                            {loader.open ? (
                                <div>
                                    <Skeleton variant="rect" width={600} height={140} />
                                </div>
                            ) : (
                                <label htmlFor="contained-button-file">
                                    {txtImagen.url === "" ? (
                                        <div className="align-div-center card-header">
                                            <div class="dashed text-center">
                                                <FontAwesomeIcon icon={faUpload} size="2x" />
                                            </div>
                                        </div>
                                    ) : (
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={txtImagen.url === "" ? imgCargaPreview : txtImagen.url}
                                            alt="green iguana"
                                        />
                                    )}
                                </label>
                            )}
                        </CardActionArea>
                    </Card>
                    <input
                        accept="image/*"
                        //className={classes.input}
                        style={{ display: "none" }}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={handleChangeImg}
                        name="txtImagen"
                        required={txtImagen.required}
                        error={txtImagen.error}
                        helperText={txtImagen.error && txtImagen.message}
                    />
                    <FormHelperText error={txtImagen.error}>{txtImagen.error && txtImagen.message}</FormHelperText>
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
