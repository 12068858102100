export default function DinercapInfoField(props) {
    const { label, value, classes, br } = props;

    return (
        <div className={classes ? classes : ""}>
            <span className="ops-nor bold color-3 size-15">{label}</span>
            {br ? <br /> : " "}
            <span className="ops-nor color-4 size-15">{value}</span>
        </div>
    );
}
