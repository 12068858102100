const Version = "";//QA,Preproduccion
const config = {
    local: "http://localhost:5001/",
    QA: "http://52.116.38.18:8086/",
    Preproduccion: "http://52.116.38.18:8085/",
    default: "https://aplicacion.dinercap.mx:8084/"
};

// Selecciona el servidor según la versión
export const serverMain = config[Version] || config.default;

// Exporta la versión también si es necesario
export { Version };