import { IconButton, Tooltip } from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function DinercapPassword(props) {
    const { view, setView, white } = props;

    return (
        <Tooltip title={view ? "Ocultar contraseña" : "Ver contraseña"} arrow placement="top">
            <IconButton onMouseDown={() => setView(true)} onMouseUp={() => setView(false)}>
                {view ? (
                    <VisibilityIcon className={white && "color-0"} />
                ) : (
                    <VisibilityOffIcon className={white && "color-0"} />
                )}
            </IconButton>
        </Tooltip>
    );
}
