export const getModelEnvOriginador = () => ({
    id: -1,
    iIdSolicitud: undefined,
    sNombre: "",
});

export const getFormEnvOriginador = () => ({
    txtNombre: {
        value: "",
        placeholder: "",
    },
    txtAP: {
         value: "",
        placeholder: "",
    },
    txtAM: {
         value: "",
        placeholder: "",
    },
    txtCURP: {
         value: "",
        placeholder: "",
    },
    txtFecNac: {
        value: "",
        placeholder: "",
    },
    txtRFC: {
        value: "",
        placeholder: "",
    },
    txtGenero: {
        value: "",
        placeholder: "",
    },
    txtEC: {
        value: "",
        placeholder: "",
    },
    txtDepEco: {
        value: "",
        placeholder: "",
    },
    txtNivEst: {
        value: "",
        placeholder: "",
    },
    txtNacionalidad: {
        value: "",
        placeholder: "",
    },
    txtTipoPF: {
        value: "",
        placeholder: "",
    },
    txtAR: {
        value: "",
        placeholder: "",
    },
    txtPaisNac: {
        value: "",
        placeholder: "",
    },
    txtEntNac: {
        value: "",
        placeholder: "",
    },
    txtFIEL: {
        value: "",
        placeholder: "",
    },
    txtGiroMerc: {
        value: "",
        placeholder: "",
    },
    txtActSol: {
        value: "",
        placeholder: "",
    },
    txtPersonaPE: {
        value: "",
        placeholder: "",
    },
    // # region
    // # endregion
    
    chkNombre: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkAP: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkAM: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkCURP: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkFecNac: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkRFC: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkGenero: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkEC: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkDepEco: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkNivEst: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkNacionalidad: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkTipoPF: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkAR: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkPaisNac: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkEntNac: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkFIEL: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkGiroMerc: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkActSol: {
        value: false,
        message: "",
        placeholder: "",
    },
    chkPersonaPE: {
        value: false,
        message: "",
        placeholder: "",
    },
});

export const getFormEnvNOriginador = () =>
({
     txtNNombre: {
        value: "",
        placeholder: "",
    },
    txtNApellidoP: {
        value: "",
        placeholder: "",
    },
    txtNApellidoM: {
        value: "",
        placeholder: "",
    },
    txtNCURP: {
         value: "",
        placeholder: "",
    },
    txtNFecNac: {
        value: "",
        placeholder: "",
    },
    txtNRFC: {
        value: "",
        placeholder: "",
    },
    txtNGenero: {
        value: "",
        placeholder: "",
    },
    txtNEstC: {
        value: "",
        placeholder: "",
    },
    txtNDepEco: {
        value: "",
        placeholder: "",
    },
    txtNNivEst: {
        value: "",
        placeholder: "",
    },
    txtNNacionalidad: {
        value: "",
        placeholder: "",
    },
    txtNTipoPF: {
        value: "",
        placeholder: "",
    },
    txtNAniosRe: {
        value: "",
        placeholder: "",
    },
    txtNPaisNac: {
        value: "",
        placeholder: "",
    },
    txtNEntNac: {
        value: "",
        placeholder: "",
    },
    txtNFIEL: {
        value: "",
        placeholder: "",
    },
    txtNGiroMerc: {
        value: "",
        placeholder: "",
    },
    txtActSol: {
        value: "",
        placeholder: "",
    },
    txtNPersonaPE: {
        value: "",
        placeholder: "",
    },
});