export default function DinercapFooter() {
    return (
        <div className="price-footer-container">
            <div className="price-footer">
                <div>
                    <span className="price-footer-contact">
                        &copy;
                        {new Date().getFullYear()} Dinercap
                    </span>
                </div>
            </div>
        </div>
    );
}
