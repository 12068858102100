import React, { useEffect, useState, Fragment } from "react";
import { makeStyles } from "tss-react/mui";
import { AppBar, Button, Dialog, Grid, Slide, Toolbar, Typography, Link } from "@mui/material";
import { IoAdd } from "react-icons/io5";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Enumerable from "linq";
import _ from "lodash";

import { useOriginador } from "../../../../context/catalogos_generales/OriginadorContext";
import DinercapTableHandle from "../../../utils/DinercapTableHandle";
import FormOriginador from "./FormOriginador";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles()((theme) => {
    return {
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        container: {
            maxHeight: 800,
        },
        root: {
            width: "100%",
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    };
});

export default function OriginadorModal() {
    const {
        openOriginador,
        listaOriginador,
        openFormOriginador,
        handleOpenOriginador,
        handleObtenerOriginador,
        handleOpenFormOriginador,
        handleEliminarOriginador,
        handleActualizarOriginador,
        handleDescargarXML
    } = useOriginador();

    const classes = useStyles();

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({
        iIdOriginadorTSolicitud: -1,
    });

    const crearData = () => {
        const _data = Enumerable.from(listaOriginador)
            .select((originador, index) => ({
                id: index + 1,
                iIdOriginadorTSolicitud: originador.iIdOriginadorTSolicitud,
                sNombre: originador.sNombre,
                iIdTipoSolicitud: originador.iIdTipoSolicitud,
                sTipoSolicitudNombre: originador.entTipoSolicitud.sNombre,
                sNombrePatron: originador.sNombrePatron,
                sNombrePatronDoctos: originador.sNombrePatronDoctos,
                urlXML: originador.urlXML,
            }))
            .toArray();
        setData(_data);
    };

    const columns = [
        { field: "id", title: "#" },
        { field: "sNombre", title: "Nombre" },
        { field: "sTipoSolicitudNombre", title: "Tipo Solicitud" },
        {
            field: "sNombrePatron",
            title: "Datos",
            align: "center",
            Element: ({ row }) => {
                return (
                    <Link
                        component="button"
                        variant="body2"
                        onClick={(e) => {
                            handleDescargarXML(e, row.sNombrePatron);
                        }}
                    >
                        {row.sNombrePatron}
                    </Link>
                );
            },
        },
        {
            field: "sNombrePatronDoctos",
            title: "Doctos",
            align: "center",
            Element: ({ row }) => {
                return (
                    <Link
                        component="button"
                        variant="body2"
                        onClick={(e) => {
                            handleDescargarXML(e, row.sNombrePatronDoctos);
                        }}
                    >
                        {row.sNombrePatronDoctos}
                    </Link>
                );
            },
        },
    ];

    const onDelete = (e, row) => {
        handleEliminarOriginador(e, row);
    };

    const onUpdate = (e, row) => {
        handleActualizarOriginador(e, row);
    };

    useEffect(() => {
        handleObtenerOriginador();
    }, []);

    useEffect(() => {
        crearData();
    }, [listaOriginador]);

    return (
        <Fragment>
            <Dialog
                key="form"
                fullScreen
                open={openOriginador}
                onClose={() => handleOpenOriginador(false)}
                TransitionComponent={Transition}
            >
                <Box sx={{ flexFlow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title} component="div" sx={{ flexGrow: 1 }}>
                                Originador
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => handleOpenOriginador(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
                <div className="flex flex-col gap-2 p-4">
                    <div className="flex flex-row justify-between items-center flex-wrap ml-2 mt-2 mr-2 ">
                        <div></div>
                        <div>
                            <Button
                                className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={(e) => handleOpenFormOriginador(true)}
                            >
                                <IoAdd className="text-xl mr-2" />
                                Crear
                            </Button>
                        </div>
                    </div>
                    <Box
                        component="div"
                        sx={{
                            height: 600,
                            width: "100%",
                            "& .odd": {
                                bgcolor: "rgba(0, 0, 0, 0.05)",
                            },
                            "& .even": {
                                bgcolor: "#ffffff",
                            },
                        }}
                    >
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                            <Grid item xs={12} md={8}>
                                <DinercapTableHandle
                                    id="iIdOriginadorTSolicitud"
                                    data={data}
                                    columns={columns}
                                    selectedRow={selectedRow}
                                    setRow={setSelectedRow}
                                    filter
                                    onDelete={onDelete}
                                    onUpdate={onUpdate}
                                    //onView={() => {}}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Dialog>
            {openFormOriginador && <FormOriginador />}
        </Fragment>
    );
}
