import React, { useState, useCallback, useEffect, useRef } from "react";
import { GoogleMap, Polygon, DrawingManager, MarkerClusterer } from "@react-google-maps/api";
import { useZonasGeograficas } from "../../../../context/catalogos_generales/ZonasGeograficasContext";

export function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}

export default function Mapa(props) {
    const { containerStyle, polygonEditable = false } = props;
    const { handleAddPolygon, listaPolygon, handleUpdatePolygon, handleRemovePolygon, center } = useZonasGeograficas();
    const [activeMarker, setActiveMarker] = useState(null);
    const [map, setMap] = useState(null);
    const [zoom, setZoom] = useState(10);

    let polygonRef = [];

    // #region Mapa
    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);
    // #endregion

    const addReference = (ref) => {
        polygonRef.push(ref);
    };

    const handlePolygonComplete = useCallback((polygon) => {
        const paths = polygon.getPath().getArray();
        if (paths.length >= 3) {
            const coords = paths.map((a) => ({ lat: a.lat(), lng: a.lng() }));
            handleAddPolygon({
                id: getRandomInt(20000, 50000),
                accion: "ADD",
                paths: coords,
            });
            polygon.setPath([]);
        }
    });

    const onEditPolygon = (index, idPolygon) => {
        const polygon = polygonRef[index].state.polygon;
        const coords = polygon
            .getPath()
            .getArray()
            .map((latLng) => {
                return { lat: latLng.lat(), lng: latLng.lng() };
            });

        handleUpdatePolygon(idPolygon, coords);
    };

    const onDeletePolygon = (index, idPolygon) => {
        handleRemovePolygon(idPolygon);
    };

    useEffect(() => {
        setTimeout(() => {
            setZoom(11);
        }, 300);
    }, []);

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onClick={() => setActiveMarker(null)}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            {listaPolygon.length == 0 && (
                <DrawingManager
                    onPolygonComplete={handlePolygonComplete}
                    options={{
                        drawingControl: true,
                        drawingControlOptions: {
                            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                            position: window.google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
                        },
                        polygonOptions: {
                            fillColor: "#199ee0",
                            fillOpacity: 0.2,
                            strokeWeight: 2,
                            strokeColor: "#113460",
                            clickable: true,
                            editable: true,
                            geodesic: false,
                            visible: true,
                            zIndex: 1,
                        },
                    }}
                />
            )}

            {listaPolygon &&
                listaPolygon
                    .filter((pol) => pol.accion !== "DELETE")
                    .map((item, i) => (
                        <Polygon
                            editable={polygonEditable}
                            draggable={polygonEditable}
                            key={item.id}
                            ref={(ref) => {
                                addReference(ref);
                            }}
                            paths={item.paths}
                            onMouseUp={() => {
                                onEditPolygon(i, item.id);
                            }}
                            onDragEnd={() => {
                                onEditPolygon(i, item.id);
                            }}
                            onRightClick={() => {
                                onDeletePolygon(i, item.id);
                            }}
                        />
                    ))}
        </GoogleMap>
    );
}
