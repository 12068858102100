import { createContext, useContext, useState } from "react";
import { getModelTipoSolicitud } from "../models/TipoSolicitudModel";
import {
    getModelBloqueSolicitud,
    getModelCampoBloqueSolicitud,
    getFormBloqueSolicitud,
    getFormCampoBloqueSolicitud,
    getFormCamposValidacion,
} from "../models/ConfiguracionSolicitudModel";

import { fnHandleChangeChek, fnHandleChangeText } from "../settings/handleConfig";
import { fnPrevent, fnPreventBlur } from "../settings/preventConfig";
import {
    apiBloqueGetBloques,
    apiBloqueCreateBloque,
    apiBloqueMoveBloque,
    apiCampoBloqueGetCamposBloque,
    apiCampoBloqueMoveCamposBloque,
    apiCampoBloqueCreateCamposBloque,
    apiBloqueDeleteBloque,
    apiCampoBloqueDeleteCamposBloque,
    apiInputFormsGetAll,
    apiCampoBloqueValidacionList,
    apiBloqueUpdBloque,
    apiCampoBloqueUpdCampo,
    apiInputTypeGetAllByForm,
    apiCampoBloqueGetCamposByCampoAgrupables,
    apiInputBuscaTypesByGrupo,
} from "../settings/apiConfig";
import { useRequest } from "./RequestContext";
import { useLayout } from "./LayoutContext";

export const ConfiguracionSolicitudContext = createContext();

export function ConfiguracionSolicitudProvider(props) {
    const { handleOpenAlert } = useLayout();
    const { Get, Post } = useRequest();

    const [listaBloquesSolicitud, setListaBloquesSolicitud] = useState([]);

    const [listaTipoDatos, setListaTipoDatos] = useState([]);
    const [listaTipoForm, setListaTipoForm] = useState([]);

    const [listaCampoValidaciones, setListaCampoValidaciones] = useState([]);

    const [listaCamposBloqueSolicitud, setListaCamposBloqueSolicitud] = useState([]);

    const [tipoSolicitudSeleccionado, setTipoSolicitudSeleccionado] = useState(getModelTipoSolicitud());
    const [bloqueSolicitudSeleccionado, setBloqueSolicitudSeleccionado] = useState(getModelBloqueSolicitud());
    const [campoBloqueSolicitudSeleccionado, setCampoBloqueSolicitudSeleccionado] = useState(
        getModelCampoBloqueSolicitud()
    );

    const [modalOpenFormBloqueSolicitud, setModalOpenFormBloqueSolicitud] = useState(false);
    const [modalOpenFormCampoBloqueSolicitud, setModalOpenFormCampoBloqueSolicitud] = useState(false);
    const [modalOpenConfigurarBloque, setModalOpenConfigurarBloque] = useState(false);
    const [modalOpenFormCampoBloqueValid, setModalOpenFormCampoBloqueValid] = useState(false);

    const [modalOpenEliminarBloqueSolicitud, setModalOpenEliminarBloqueSolicitud] = useState(false);
    const [modalOpenEliminarCampoBloqueSolicitud, setModalOpenEliminarCampoBloqueSolicitud] = useState(false);

    const [formBloqueSolicitud, setFormBloqueSolicitud] = useState(getFormBloqueSolicitud());
    const [formCampoBloqueSolicitud, setFormCampoBloqueSolicitud] = useState(getFormCampoBloqueSolicitud());

    const [formCamposValidacion, setFormCamposValidacion] = useState(getFormCamposValidacion());
    const [modalOpenFormCampoValidCU, setModalOpenFormCampoValidCU] = useState(false);

    const [iIDBloqueSolicitudCampoSeleccionadoRel, setIIDBloqueSolicitudCampoSeleccionadoRel] = useState(0);

    const handleClickObtenerBloquesSolicitud = async (value) => {
        await fnObtenerBloquesSolicitud(value);
    };

    const handleClickObtenerCamposBloqueSolicitud = async (value) => {
        await fnObtenerCamposBloqueSolicitud(value);
    };

    const handleOpenFormBloqueSolicitud = (value) => {
        setModalOpenFormBloqueSolicitud(value);
    };

    const handleOpenFormCampoBloqueSolicitud = (value) => {
        setModalOpenFormCampoBloqueSolicitud(value);
    };

    const handleOpenModalConfigurarBloque = (value) => {
        setModalOpenConfigurarBloque(value);
    };

    const handleOpenEliminarBloqueSolicitud = (value) => {
        setModalOpenEliminarBloqueSolicitud(value);
    };

    const handleOpenEliminarCampoBloqueSolicitud = (value) => {
        setModalOpenEliminarCampoBloqueSolicitud(value);
    };

    const handleChangeTipoSolicitudSeleccionado = async (value) => {
        await setTipoSolicitudSeleccionado(value);
    };

    const handleChangeBloqueSolicitudSeleccionado = (value) => {
        setBloqueSolicitudSeleccionado(value);
    };

    const handleChangeCampoBloqueSolicitudSeleccionado = (value) => {
        setCampoBloqueSolicitudSeleccionado(value);
    };

    const handleChangeCampoValidacionCU = (value) => {
        setFormCamposValidacion(value);
    };

    const handleChangeFormBloqueSolicitud = fnHandleChangeText(formBloqueSolicitud, setFormBloqueSolicitud);
    const handleChangeFormBloqueSolicitudCheck = fnHandleChangeChek(formBloqueSolicitud, setFormBloqueSolicitud);

    const handleChangeFormCampoBloqueSolicitud = fnHandleChangeText(
        formCampoBloqueSolicitud,
        setFormCampoBloqueSolicitud
    );
    const handleChangeFormCampoBloqueSolicitudCheck = fnHandleChangeChek(
        formCampoBloqueSolicitud,
        setFormCampoBloqueSolicitud
    );
    const handleChangeFormCamposValidacion = fnHandleChangeText(formCamposValidacion, setFormCamposValidacion);

    const handleClickNuevoBloqueSolicitud = () => {
        const bloqueSolicitud = getModelBloqueSolicitud();
        setBloqueSolicitudSeleccionado(bloqueSolicitud);
        fnFillFormBloqueSolicitud(bloqueSolicitud);
        setModalOpenFormBloqueSolicitud(true);
    };

    const handleClickNuevoCampoBloqueSolicitud = () => {
        const campoBloqueSolicitud = getModelCampoBloqueSolicitud();
        setCampoBloqueSolicitudSeleccionado(campoBloqueSolicitud);
        fnFillFormCampoBloqueSolicitud(campoBloqueSolicitud);
        setModalOpenFormCampoBloqueSolicitud(true);
    };

    const handleClickEditarBloqueSolicitud = () => {
        if (bloqueSolicitudSeleccionado.iIdBloqueSolicitud === 0) {
            handleOpenAlert("Seleccione un bloque para editar");
            return;
        }
        fnFillFormBloqueSolicitud(bloqueSolicitudSeleccionado);
        setModalOpenFormBloqueSolicitud(true);
    };

    const handleClickEditarCampoBloqueSolicitud = () => {
        if (campoBloqueSolicitudSeleccionado.iIDBloqueSolicitudCampos === 0) {
            handleOpenAlert("Seleccione un campo para editar");
            return;
        }
        fnFillFormCampoBloqueSolicitud(campoBloqueSolicitudSeleccionado);
        setModalOpenFormCampoBloqueSolicitud(true);
    };

    const handleClickEliminarBloqueSolicitud = () => {
        if (bloqueSolicitudSeleccionado.iIDBloqueSolicitud === 0) {
            handleOpenAlert("Seleccione un bloque para eliminar");
            return;
        }
        setModalOpenEliminarBloqueSolicitud(true);
    };

    const handleClickEliminarCampoBloqueSolicitud = () => {
        if (campoBloqueSolicitudSeleccionado.iIDBloqueSolicitudCampos === 0) {
            handleOpenAlert("Seleccione un campo para eliminar");
            return;
        }
        setModalOpenEliminarCampoBloqueSolicitud(true);
    };

    const handleClickBloqueSolicitud = (value) => {
        const bloque = listaBloquesSolicitud.find((x) => x.iIDBloqueSolicitud === value);
        setIIDBloqueSolicitudCampoSeleccionadoRel(0);
        handleChangeBloqueSolicitudSeleccionado(bloque);
        //handleClickObtenerCamposBloqueSolicitud(value);
        setModalOpenConfigurarBloque(true);
    };

    const handleClickCampoBloqueSolicitud = (value) => {
        const campoBloque = listaCamposBloqueSolicitud.find((x) => x.iIDBloqueSolicitudCampos === value);
        handleChangeCampoBloqueSolicitudSeleccionado(campoBloque);

        handleListaCampoValidaciones(value);
        setModalOpenFormCampoBloqueValid(true);
    };

    const handleSubmitFormBloqueSolicitud = (e) => {
        fnPrevent(e);
        const { txtNombreBloque, txtDescripcionBloque, slIcono, swGrupos } = formBloqueSolicitud;

        const form = {
            ...formBloqueSolicitud,
            txtNombreBloque: {
                ...txtNombreBloque,
                error: txtNombreBloque.value === "",
            },
            txtDescripcionBloque: {
                ...txtDescripcionBloque,
                error: txtDescripcionBloque.value === "",
            },
            slIcono: {
                ...slIcono,
                error: slIcono.value === "",
            },
        };
        setFormBloqueSolicitud(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }

        if (
            bloqueSolicitudSeleccionado.iIDBloqueSolicitud === 0 ||
            bloqueSolicitudSeleccionado.iIDBloqueSolicitud === undefined
        ) {
            const entBloqueSolicitud = {
                aBloque: [
                    {
                        //iIDBloqueSolicitud: bloqueSolicitudSeleccionado.iIDBloqueSolicitud,
                        iIDTipoSolicitud: tipoSolicitudSeleccionado.iIDTipoSolicitud,
                        sNombreBloque: txtNombreBloque.value,
                        sDescripcion: txtDescripcionBloque.value,
                        sIcono: slIcono.value.split(";")[0],
                        sUnicode: slIcono.value.split(";")[1],
                        iIDUsuarioCreacion: 1,
                        bActivo: true,
                        bBaja: false,
                        bGrupo: swGrupos.value,
                    },
                ],
                iTipoSolicitud: tipoSolicitudSeleccionado.iIDTipoSolicitud,
            };
            fnGuardarBloqueSolicitud(entBloqueSolicitud);
        } else {
            const entBloqueSolicitud = {
                iIDBloqueSolicitud: bloqueSolicitudSeleccionado.iIDBloqueSolicitud,
                iIDTipoSolicitud: tipoSolicitudSeleccionado.iIDTipoSolicitud,
                sNombreBloque: txtNombreBloque.value,
                sDescripcion: txtDescripcionBloque.value,
                sIcono: slIcono.value.split(";")[0],
                sUnicode: slIcono.value.split(";")[1],
                iIDUsuarioModificacion: 1,
                bActivo: true,
                bBaja: false,
                bGrupo: swGrupos.value,
            };
            fnGuardarBloqueSolicitud(entBloqueSolicitud, bloqueSolicitudSeleccionado.iIDBloqueSolicitud);
        }
    };

    const handleSubmitFormCampoBloqueSolicitud = (e, bAgrupado = false) => {
        fnPrevent(e);
        const {
            txtNombreCampoBloque,
            slTipoDeDatoCampoBloque,
            slTipoFormCampoBloque,
            slIcono,
            txtDescripcionBloque,
            txtFuenteDatosBloque,
            chkReqOCR,
            chkSinTildes,
        } = formCampoBloqueSolicitud;

        const form = {
            ...formCampoBloqueSolicitud,
            txtNombreCampoBloque: {
                ...txtNombreCampoBloque,
                error: txtNombreCampoBloque.value === "",
            },
            slTipoDeDatoCampoBloque: {
                ...slTipoDeDatoCampoBloque,
                error: slTipoDeDatoCampoBloque.value === 0,
            },
            slTipoFormCampoBloque: {
                ...slTipoFormCampoBloque,
                error: slTipoFormCampoBloque.value === 0,
            },
            txtDescripcionBloque: {
                ...txtDescripcionBloque,
                error: txtDescripcionBloque.value === "",
            },
            slIcono: {
                ...slIcono,
                error: slIcono.value === "",
            },
        };
        setFormCampoBloqueSolicitud(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }
        if (
            campoBloqueSolicitudSeleccionado.iIDBloqueSolicitudCampos === 0 ||
            campoBloqueSolicitudSeleccionado.iIDBloqueSolicitudCampos === undefined
        ) {
            const entCampoBloqueSolicitud = {
                aCamposBloque: [
                    {
                        iIDUsuarioCreacion: 1,
                        iIDBloqueSolicitud: bloqueSolicitudSeleccionado.iIDBloqueSolicitud,
                        iIDInputForms: slTipoFormCampoBloque.value,
                        iIDTipoDato: slTipoDeDatoCampoBloque.value,
                        sNombre: txtNombreCampoBloque.value,
                        sDescripcion: txtDescripcionBloque.value,
                        sIcono: slIcono.value.split(";")[0],
                        sUnicode: slIcono.value.split(";")[1],
                        bActivo: true,
                        bBaja: false,
                        sfuentededato: txtFuenteDatosBloque.value,
                        bReqOCR: chkReqOCR.value,
                        bSinTildes: chkSinTildes.value,
                    },
                ],
                iTipoBloque: bloqueSolicitudSeleccionado.iIDBloqueSolicitud,
                iIdBloqueSolicitudCampoRel: iIDBloqueSolicitudCampoSeleccionadoRel,
            };
            fnGuardarCampoBloqueSolicitud(entCampoBloqueSolicitud);
        } else {
            const entCampoBloqueSolicitud = {
                iIDBloqueSolicitudCampos: campoBloqueSolicitudSeleccionado.iIDBloqueSolicitudCampos,
                iIDBloqueSolicitud: bloqueSolicitudSeleccionado.iIDBloqueSolicitud,
                sNombre: txtNombreCampoBloque.value,
                sDescripcion: txtDescripcionBloque.value,
                iIDInputForms: slTipoFormCampoBloque.value,
                iIDTipoDato: slTipoDeDatoCampoBloque.value,
                sIcono: slIcono.value.split(";")[0],
                sUnicode: slIcono.value.split(";")[1],
                iIDUsuarioModificacion: 1,
                bActivo: true,
                bBaja: false,
                sfuentededato: txtFuenteDatosBloque.value,
                bReqOCR: chkReqOCR.value,
                bSinTildes: chkSinTildes.value,
                bAgrupado: campoBloqueSolicitudSeleccionado.bAgrupado,
            };
            fnGuardarCampoBloqueSolicitud(
                entCampoBloqueSolicitud,
                campoBloqueSolicitudSeleccionado.iIDBloqueSolicitudCampos
            );
        }
    };

    const handleSubmitEliminarBloqueSolicitud = (e) => {
        fnPrevent(e);
        const entBloqueSolicitud = {
            iIDTipoSolicitud: bloqueSolicitudSeleccionado.iIDTipoSolicitud,
            iIDBloqueSolicitud: bloqueSolicitudSeleccionado.iIDBloqueSolicitud,
            iIDUsuarioElimina: 1,
        };
        fnEliminarBloqueSolicitud(entBloqueSolicitud);
    };

    const handleSubmitEliminarCampoBloqueSolicitud = (e) => {
        fnPrevent(e);
        const entCampoBloqueSolicitud = {
            oCampo: {
                iIDBloqueSolicitudCampos: campoBloqueSolicitudSeleccionado.iIDBloqueSolicitudCampos,
                iIDBloqueSolicitud: campoBloqueSolicitudSeleccionado.iIDBloqueSolicitud,
            },
            iTipoSolicitud: bloqueSolicitudSeleccionado.iIDTipoSolicitud,
        };
        fnEliminarCampoBloqueSolicitud(entCampoBloqueSolicitud);
    };

    const handleMoveBloqueSolicitud = async (value) => {
        return await fnMoverBloqueSolicitud(value);
    };

    const handleMoveCampoBloqueSolicitud = async (value) => {
        return await fnMoverCampoBloqueSolicitud(value);
    };

    const handleListarTipoDatos = (value) => {
        fnObtenerTipoDeDatos(value);
    };

    const handleListarTipoForms = (value) => {
        fnObtenerTipoForm();
    };

    const handleListaCampoValidaciones = (value) => {
        fnObtenerListCampoValidaciones(value);
    };

    const handleOpenFormCampoValidCU = (value) => {
        setModalOpenFormCampoValidCU(value);
    };

    const handleChangeGrupoSeleccionado = async (e, iIDBloqueSolicitudCampo) => {
        setIIDBloqueSolicitudCampoSeleccionadoRel(iIDBloqueSolicitudCampo);
        if (iIDBloqueSolicitudCampo > 0) {
            fnObtenerCamposBloqueSolicitudByGrupo(iIDBloqueSolicitudCampo);
        }
    };

    //============================================================================================================

    const fnObtenerBloquesSolicitud = async (value) => {
        const payload = {
            loader: "Consultando bloques de la solicitud...",
            url: apiBloqueGetBloques.replace("{piIDTipoSolicitud}", value),
            alert: false,
        };

        const response = await Get(payload);
        if (response.code === 201) {
            setListaBloquesSolicitud(response.result);
        } else {
            setListaBloquesSolicitud([]);
        }
    };

    const fnObtenerCamposBloqueSolicitudByGrupo = async (value) => {
        const payload = {
            loader: "Consultando campos del bloque...",
            url: apiCampoBloqueGetCamposByCampoAgrupables.replace("{iIDBloqueSolicitudCampos}", value),
            alert: false,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setListaCamposBloqueSolicitud(response.result);
        } else {
            setListaCamposBloqueSolicitud([]);
        }
    };

    const fnObtenerCamposBloqueSolicitud = async (value) => {
        const payload = {
            loader: "Consultando campos del bloque...",
            url: apiCampoBloqueGetCamposBloque
                .replace("{iIDBloque}", value)
                .replace("{bAgrupado}", bloqueSolicitudSeleccionado.bGrupo),
            alert: false,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setListaCamposBloqueSolicitud(response.result);
        } else {
            setListaCamposBloqueSolicitud([]);
        }
    };

    const fnFillFormBloqueSolicitud = (bloqueSolicitud) => {
        const { txtNombreBloque, txtDescripcionBloque, slIcono, swGrupos } = getFormBloqueSolicitud();

        setFormBloqueSolicitud({
            ...formBloqueSolicitud,
            txtNombreBloque: {
                ...txtNombreBloque,
                value: !bloqueSolicitud.sNombreBloque ? "" : bloqueSolicitud.sNombreBloque,
            },
            txtDescripcionBloque: {
                ...txtDescripcionBloque,
                value: !bloqueSolicitud.sDescripcion ? "" : bloqueSolicitud.sDescripcion,
            },
            slIcono: {
                ...slIcono,
                value: !bloqueSolicitud.sIcono ? "" : bloqueSolicitud.sIcono + ";" + bloqueSolicitud.sUnicode,
            },
            swGrupos: {
                ...swGrupos,
                value: !bloqueSolicitud.bGrupo ? false : bloqueSolicitud.bGrupo,
            },
        });
    };

    const fnFillFormCampoBloqueSolicitud = (campoBloqueSolicitud) => {
        const {
            txtNombreCampoBloque,
            slTipoDeDatoCampoBloque,
            slTipoFormCampoBloque,
            txtDescripcionBloque,
            slIcono,
            txtFuenteDatosBloque,
            chkReqOCR,
            chkSinTildes,
        } = getFormCampoBloqueSolicitud();

        setFormCampoBloqueSolicitud({
            ...formCampoBloqueSolicitud,
            txtNombreCampoBloque: {
                ...txtNombreCampoBloque,
                value: !campoBloqueSolicitud.sNombre ? "" : campoBloqueSolicitud.sNombre,
            },
            txtDescripcionBloque: {
                ...txtDescripcionBloque,
                value: !campoBloqueSolicitud.sDescripcion ? "" : campoBloqueSolicitud.sDescripcion,
            },
            slTipoFormCampoBloque: {
                ...slTipoFormCampoBloque,
                value: campoBloqueSolicitud.iIDInputForms <= 0 ? 0 : campoBloqueSolicitud.iIDInputForms,
            },
            slTipoDeDatoCampoBloque: {
                ...slTipoDeDatoCampoBloque,
                value: campoBloqueSolicitud.iIDTipoDato <= 0 ? 0 : campoBloqueSolicitud.iIDTipoDato,
            },
            slIcono: {
                ...slIcono,
                value: campoBloqueSolicitud.sIcono
                    ? campoBloqueSolicitud.sIcono + ";" + campoBloqueSolicitud.sUnicode
                    : "",
            },
            txtFuenteDatosBloque: {
                ...txtFuenteDatosBloque,
                value: campoBloqueSolicitud.sFuenteDeDato ? campoBloqueSolicitud.sFuenteDeDato : "",
            },
            chkReqOCR: {
                ...chkReqOCR,
                value: campoBloqueSolicitud.bReqOCR,
            },
            chkSinTildes: {
                ...chkSinTildes,
                value: campoBloqueSolicitud.bSinTildes,
            },
        });
    };

    const fnGuardarBloqueSolicitud = async (entBloqueSolicitud, iIDBloqueSolicitud = 0) => {
        const payload = {
            loader: iIDBloqueSolicitud === 0 ? "Guardando bloque..." : "Modificar bloque...",
            url: iIDBloqueSolicitud === 0 ? apiBloqueCreateBloque : apiBloqueUpdBloque,
            body: entBloqueSolicitud,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            setModalOpenFormBloqueSolicitud(false);
            // setBloqueSolicitudSeleccionado(entBloqueSolicitud);
            await fnObtenerBloquesSolicitud(tipoSolicitudSeleccionado.iIDTipoSolicitud);
        }
        fnPreventBlur();
    };

    const fnGuardarCampoBloqueSolicitud = async (entCampoBloqueSolicitud, iIDBloqueSolicitudCampos = 0) => {
        const payload = {
            loader: iIDBloqueSolicitudCampos === 0 ? "Guardando campo..." : "Modificando campo...",
            url: iIDBloqueSolicitudCampos === 0 ? apiCampoBloqueCreateCamposBloque : apiCampoBloqueUpdCampo,
            body: entCampoBloqueSolicitud,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            setModalOpenFormCampoBloqueSolicitud(false);
            // setCampoBloqueSolicitudSeleccionado(entCampoBloqueSolicitud);
            if (iIDBloqueSolicitudCampoSeleccionadoRel === 0)
                await fnObtenerCamposBloqueSolicitud(bloqueSolicitudSeleccionado.iIDBloqueSolicitud);
            else await fnObtenerCamposBloqueSolicitudByGrupo(iIDBloqueSolicitudCampoSeleccionadoRel);
        }
        fnPreventBlur();
    };

    const fnEliminarBloqueSolicitud = async (entBloqueSolicitud) => {
        const payload = {
            loader: "Eliminando bloque...",
            url: apiBloqueDeleteBloque,
            body: entBloqueSolicitud,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            setModalOpenEliminarBloqueSolicitud(false);
            //setBloqueSolicitudSeleccionado(getModelBloqueSolicitud());
            await fnObtenerBloquesSolicitud(tipoSolicitudSeleccionado.iIDTipoSolicitud);
        }
        fnPreventBlur();
    };

    const fnEliminarCampoBloqueSolicitud = async (entCampoBloqueSolicitud) => {
        const payload = {
            loader: "Eliminando campo...",
            url: apiCampoBloqueDeleteCamposBloque,
            body: entCampoBloqueSolicitud,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            setModalOpenEliminarCampoBloqueSolicitud(false);
            setCampoBloqueSolicitudSeleccionado(getModelCampoBloqueSolicitud());
            await fnObtenerCamposBloqueSolicitud(campoBloqueSolicitudSeleccionado.iIDBloqueSolicitud);
        }
        fnPreventBlur();
    };

    const fnMoverBloqueSolicitud = async (entMoverBloqueSolicitud) => {
        const payload = {
            loader: "Guardando nueva posición del bloque...",
            url: apiBloqueMoveBloque,
            body: entMoverBloqueSolicitud,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            handleClickObtenerBloquesSolicitud(tipoSolicitudSeleccionado.iIDTipoSolicitud);
        }
        return response.iCodigo === 201;
    };

    const fnMoverCampoBloqueSolicitud = async (entMoverCampoBloqueSolicitud) => {
        const payload = {
            loader: "Guardando nueva posición del campo...",
            url: apiCampoBloqueMoveCamposBloque,
            body: entMoverCampoBloqueSolicitud,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            handleClickObtenerCamposBloqueSolicitud(bloqueSolicitudSeleccionado.iIDBloqueSolicitud);
        }
        return response.iCodigo === 201;
    };

    const fnObtenerTipoDeDatos = async (iIdTipoForm) => {
        const payload = {
            loader: "Consultando lista de tipo de datos...",
            url: apiInputTypeGetAllByForm.replace("{InputForm}", iIdTipoForm),
            alert: false,
        };

        const response = await Get(payload);
        if (response.code === 201) {
            setListaTipoDatos(response.result);
        } else {
            setListaTipoDatos([]);
        }
    };

    const fnObtenerTipoForm = async () => {
        const payload = {
            loader: "Consultando lista de tipo de campor form...",
            url: !bloqueSolicitudSeleccionado.bGrupo ? apiInputFormsGetAll : apiInputBuscaTypesByGrupo,
            alert: false,
        };

        const response = await Get(payload);
        if (response.code === 201) {
            setListaTipoForm(response.result);
        } else {
            setListaTipoForm([]);
        }
    };

    const fnObtenerListCampoValidaciones = async (value) => {
        const payload = {
            loader: "Consultando listado de validaciones por campo...",
            url: apiCampoBloqueValidacionList.replace("{iIdCampo}", value),
            alert: false,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setListaCampoValidaciones(response.result);
        } else {
            setListaCampoValidaciones([]);
        }
    };

    return (
        <ConfiguracionSolicitudContext.Provider
            value={{
                listaTipoDatos,
                listaTipoForm,
                listaBloquesSolicitud,
                listaCamposBloqueSolicitud,
                tipoSolicitudSeleccionado,
                bloqueSolicitudSeleccionado,
                campoBloqueSolicitudSeleccionado,
                modalOpenFormBloqueSolicitud,
                modalOpenFormCampoBloqueSolicitud,
                modalOpenConfigurarBloque,
                modalOpenEliminarBloqueSolicitud,
                modalOpenEliminarCampoBloqueSolicitud,
                formBloqueSolicitud,
                formCampoBloqueSolicitud,
                modalOpenFormCampoBloqueValid,
                listaCampoValidaciones,
                formCamposValidacion,
                modalOpenFormCampoValidCU,
                iIDBloqueSolicitudCampoSeleccionadoRel,
                handleOpenFormCampoValidCU,
                handleChangeCampoValidacionCU,
                handleChangeFormCamposValidacion,
                handleOpenFormBloqueSolicitud,
                handleOpenFormCampoBloqueSolicitud,
                handleOpenModalConfigurarBloque,
                handleOpenEliminarBloqueSolicitud,
                handleOpenEliminarCampoBloqueSolicitud,
                handleChangeTipoSolicitudSeleccionado,
                handleChangeBloqueSolicitudSeleccionado,
                handleChangeCampoBloqueSolicitudSeleccionado,
                handleChangeFormBloqueSolicitud,
                handleChangeFormBloqueSolicitudCheck,
                handleChangeFormCampoBloqueSolicitud,
                handleClickObtenerBloquesSolicitud,
                handleClickObtenerCamposBloqueSolicitud,
                handleClickNuevoBloqueSolicitud,
                handleClickNuevoCampoBloqueSolicitud,
                handleClickEditarBloqueSolicitud,
                handleClickEditarCampoBloqueSolicitud,
                handleClickEliminarBloqueSolicitud,
                handleClickEliminarCampoBloqueSolicitud,
                handleClickBloqueSolicitud,
                handleClickCampoBloqueSolicitud,
                handleSubmitFormBloqueSolicitud,
                handleSubmitFormCampoBloqueSolicitud,
                handleSubmitEliminarBloqueSolicitud,
                handleSubmitEliminarCampoBloqueSolicitud,
                handleMoveBloqueSolicitud,
                handleMoveCampoBloqueSolicitud,
                handleListarTipoDatos,
                handleListarTipoForms,
                handleListaCampoValidaciones,
                setModalOpenFormCampoBloqueValid,
                setFormCamposValidacion,
                setModalOpenFormCampoValidCU,
                handleChangeGrupoSeleccionado,
                handleChangeFormCampoBloqueSolicitudCheck,
            }}
        >
            {props.children}
        </ConfiguracionSolicitudContext.Provider>
    );
}

export function useConfiguracionSolicitud() {
    return useContext(ConfiguracionSolicitudContext);
}
