import { urlBase } from "./urlConfig";

export const imgLogoDinercap = urlBase + "/img/logo_dinercap.png";
export const imgTiposSolicitudes = urlBase + "/img/TiposSolicitudes.png";
export const imgConfigurarSolicitud = urlBase + "/img/ConfigurarSolicitud.png";
export const imgFormatosSolicitud = urlBase + "/img/FormatosSolicitud.png";
export const imgSeguimientosSolicitud = urlBase + "/img/SeguimientosSolicitud.png";
export const imgConfigurarOCR = urlBase + "/img/ConfigurarOCR.png";
export const imgConfigurarDatos = urlBase + "/img/Configuracion-de-informacion.png";
export const imgCatalogosGenerales = urlBase + "/img/CatalogosGenerales.png";
export const imgFaceDetection = urlBase + "/img/face-detection.png";
export const imgFaceNoDetection = urlBase + "/img/face-no-detection.png";
export const imgCargaPreview = "";
