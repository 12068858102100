import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useConfiguracionSolicitud } from "../../../context/ConfiguracionSolicitudContext";
import { TextField, Tooltip } from "@mui/material";
import { IoAdd } from "react-icons/io5";
import FormCampoValidacionCU from "./FormCampoValidacionCU";
import { getFormCamposValidacion } from "../../../models/ConfiguracionSolicitudModel";
import swal from "sweetalert";
import { useCampoBloqueValidacion } from "../../../context/CampoBloqueValidacionContext";
import { CancelOutlined, VisibilityOutlined } from "@mui/icons-material";

//DataGrid
import { DataGrid, esES, GridRow, GridToolbarExport, GridToolbarContainer } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import clsx from "clsx";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
//DataGrid

const useStyles = makeStyles()((theme) => {
    return {
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        container: {
            maxHeight: 440,
        },
        root: {
            width: "100%",
        },
    };
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//DataGrid
function CustomRow(props) {
    const { className, index, ...other } = props;

    return <GridRow index={index} className={clsx(className, index % 2 === 0 ? "odd" : "even")} {...other} />;
}

//QuickSearchToolbar
function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

function QuickSearchToolbar(props) {
    const classes = useStyles();

    return (
        <div>
            <div>
                <GridToolbarContainer>
                    <GridToolbarExport />
                </GridToolbarContainer>
            </div>
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? "visible" : "hidden" }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

export default function FormCampoBloqueValidacion() {
    const {
        campoBloqueSolicitudSeleccionado,
        modalOpenFormCampoBloqueValid,
        setModalOpenFormCampoBloqueValid,
        listaCampoValidaciones,
        handleListaCampoValidaciones,
        handleOpenFormCampoValidCU,
        handleChangeCampoValidacionCU,
        modalOpenFormCampoValidCU,
    } = useConfiguracionSolicitud();

    const { handleSubmitDeleteValidacion, handleClickVerValidacion, handleChangeValidacionNuevo } =
        useCampoBloqueValidacion();

    useEffect(() => {
        if (campoBloqueSolicitudSeleccionado.iIDBloqueSolicitudCampos > 0) {
            handleListaCampoValidaciones(campoBloqueSolicitudSeleccionado.iIDBloqueSolicitudCampos);
        }
    }, []);

    const classes = useStyles();

    const handleClickNuevo = async () => {
        await handleChangeCampoValidacionCU(getFormCamposValidacion());
        handleChangeValidacionNuevo();
        handleOpenFormCampoValidCU(true);
    };
    const handleClose = () => {
        setModalOpenFormCampoBloqueValid(false);
    };

    const handleClicEliminarValidacion = (item, e) => {
        let msj = "El registro será eliminado. ¿Desea continuar?";

        swal({
            title: "Eliminar registro",
            text: msj,
            icon: "warning",
            buttons: ["Cancelar", "Sí"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                handleSubmitDeleteValidacion(e, item.iIDBloqSolCampValidaciones);
            }
        });
    };

    const handleClicVer = (item, e) => {
        e.preventDefault();
        handleClickVerValidacion(item);
    };

    const [rows, setRows] = useState([]);
    const [data, setData] = useState([]);

    const insertDataRows = () => {
        let data = [];
        for (let index = 0; index < listaCampoValidaciones.length; index++) {
            let {
                iIDBloqSolCampValidaciones,
                sNombre,
                svalorref,
                smensajeerror,
                dtFechaCreacion,
                bActivo,
                iIdCamposValidacionReglas,
                iidbloqueref,
                iidcamporef,
                ioperador,
            } = listaCampoValidaciones[index];
            data.push({
                id: index,
                iIDBloqSolCampValidaciones,
                sNombre,
                svalorref,
                smensajeerror,
                dtFechaCreacion,
                bActivo,
                iIdCamposValidacionReglas,
                iidbloqueref,
                iidcamporef,
                ioperador,
            });
        }
        setRows(data);
        setData(data);
    };

    useEffect(() => {
        insertDataRows();
    }, [listaCampoValidaciones]);

    const columns = [
        { field: "id", headerName: "ID", width: 100, hide: true },
        { field: "iIDBloqSolCampValidaciones", headerName: "Folio", width: 100, hide: true },
        { field: "sNombre", headerName: "Nombre", width: 300 },
        { field: "svalorref", headerName: "Valor de ref", width: 200 },
        { field: "smensajeerror", headerName: "Mensaje", width: 400 },
        { field: "dtFechaCreacion", headerName: "Fecha Alta", width: 200 },
        { field: "iIdCamposValidacionReglas", headerName: "ID Regla", width: 100, hide: true },
        { field: "iidbloqueref", headerName: "ID Bloque Ref", width: 100, hide: true },
        { field: "iidcamporef", headerName: "ID Campo Ref", width: 100, hide: true },
        { field: "ioperador", headerName: "ID Operador", width: 100, hide: true },
        {
            field: "Acciones",
            width: 340,
            renderCell: (cellValues) => {
                return (
                    <div>
                        <Tooltip title="Ver">
                            <Button
                                variant="contained"
                                aria-label="detail"
                                size="small"
                                className="btn btn-primary mr-1"
                                onClick={(e) => {
                                    handleClicVer(cellValues.row, e);
                                }}
                            >
                                <VisibilityOutlined />
                            </Button>
                        </Tooltip>

                        <Tooltip title="Eliminar">
                            <Button
                                variant="contained"
                                aria-label="Eliminar"
                                size="small"
                                className="btn-danger"
                                onClick={(e) => {
                                    handleClicEliminarValidacion(cellValues.row, e);
                                }}
                            >
                                <CancelOutlined />
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const [pageSize, setPageSize] = useState(20);

    const [searchText, setSearchText] = useState("");
    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = data.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
    };

    return (
        <Dialog fullScreen open={modalOpenFormCampoBloqueValid} onClose={handleClose} TransitionComponent={Transition}>
            <Box sx={{ flexFlow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" className={classes.title} component="div" sx={{ flexGrow: 1 }}>
                            Validaciones del campo bloque
                        </Typography>
                        <div>
                            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
            </Box>
            <div className="flex flex-col gap-2  p-4">
                <div className="flex flex-row justify-between items-center flex-wrap ml-2 mt-2 mr-2 ">
                    <div>
                        <h1 className="text-gray-700">
                            <b>Campo: {campoBloqueSolicitudSeleccionado.sNombre}</b>
                        </h1>
                    </div>
                    <div>
                        <Button
                            className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleClickNuevo}
                        >
                            <IoAdd className="text-xl mr-2" />
                            Crear
                        </Button>
                    </div>
                </div>
                <Box
                    sx={{
                        height: 600,
                        width: "100%",
                        "& .odd": {
                            bgcolor: "rgba(0, 0, 0, 0.05)",
                        },
                        "& .even": {
                            bgcolor: "#ffffff",
                        },
                    }}
                >
                    <DataGrid
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        rows={rows}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        components={{ Row: CustomRow, Toolbar: QuickSearchToolbar }}
                        componentsProps={{
                            toolbar: {
                                value: searchText,
                                onChange: (event) => requestSearch(event.target.value),
                                clearSearch: () => requestSearch(""),
                            },
                        }}
                    />
                </Box>
            </div>
            {modalOpenFormCampoValidCU && <FormCampoValidacionCU></FormCampoValidacionCU>}
        </Dialog>
    );
}
