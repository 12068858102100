import { createContext, useContext, useState } from "react";

import { apiPoliticasGetSistemaCatalogos } from "../settings/apiConfig";
import { useRequest } from "./RequestContext";

export const CatalogoContext = createContext();

export function CatalogoProvider(props) {
    const { Get } = useRequest();

    const [entCatalogos, setEntCatalogos] = useState(null);

    const handleClickObtenerCatalogos = () => {
        fnObtenerCatalogos();
    };

    const fnObtenerCatalogos = async () => {
        const payload = {
            loader: "Consultando información del sistema...",
            url: apiPoliticasGetSistemaCatalogos,
            alert: false,
        };

        const response = await Get(payload);
        if (response.Code === 0) {
            setEntCatalogos(response.Result);
        }
    };

    return (
        <CatalogoContext.Provider value={{ entCatalogos, handleClickObtenerCatalogos }}>
            {props.children}
        </CatalogoContext.Provider>
    );
}

export function useCatalogo() {
    return useContext(CatalogoContext);
}
