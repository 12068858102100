import { createTheme } from "@mui/material/styles";
import { esES } from "@mui/material/locale";

const theme = createTheme(
    {
        palette: {
            primary: {
                main: "#3b82f6",
                contrastText: "#FFFFFF",
            },
            secondary: {
                main: "#115C8A",
                contrastText: "#FFFFFF",
            },
            error: {
                main: "#ff4842",
                contrastText: "#fff",
            },
        },
        overrides: {
            MuiTooltip: {
                tooltip: {
                    fontSize: 15,
                    fontWeight: "normal",
                },
            },
        },
    },
    esES
);

export default theme;
