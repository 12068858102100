import React from "react";
import { urlDefault } from "../../../settings/urlConfig";

import { IoHomeOutline, IoOptionsOutline } from "react-icons/io5";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { useLogin } from "../../../context/LoginContext";
import { useDocumentosOCR } from "../../../context/catalogos_generales/DocumentosOCRContext";
import { useZonasGeograficas } from "../../../context/catalogos_generales/ZonasGeograficasContext";

import { useTipoArea } from "../../../context/catalogos_generales/TipoAreasContext";
import TipoAreaModal from "./tipoAreas/TipoAreaModal";

import ZonasGeograficasModal from "./zonas_geograficas/ZonasGeograficasModal";
import { useFinancieras } from "../../../context/catalogos_generales/FinancierasContext";
import { useAvisosPrivacidad } from "../../../context/catalogos_generales/AvisosPrivacidadContext";
import { useAprobados } from "../../../context/catalogos_generales/AprobadosContext";
import { useConfiguracion } from "../../../context/catalogos_generales/ConfiguracionContext";
import { useCatSolicitudRechazada } from "../../../context/catalogos_generales/SolicitudRechazadaContext";
import { useOriginador } from "../../../context/catalogos_generales/OriginadorContext";
import { useReglasOpeContext } from "../../../context/catalogos_generales/ReglasOperadoresContext";
import { useOriginadorDinercap } from "../../../context/catalogos_generales/OriginadorDinercapContext";

import CatalogoFinancierasModal from "./financieras/CatalogoFinancierasModal";
import AvisosPrivacidadModal from "./avisosPrivacidad/AvisosPrivacidadModal";
import CatalogoDocumentosModal from "./documentosOCR/CatalogoDocumentosModal";
import AprobadoModal from "./aprobados/AprobadosModal";
import CatalogoConfigSistemaModal from "./configuracion_sistema/CatalogoConfigSistemaModal";
import CatalogoSolicitudRechazadaModal from "./solicitud_rechazada/CatalogoSolicitudRechazadaModal";
import OriginadorModal from "./originador/OriginadorModal";
import CatalogoAsociarReglasOpeModal from "./reglas_operadores/CatalogoReglasOperadoresModal";
import OriginadorDinercapModal from "./originadorDinercap/OriginadorDinercapModal";

export default function CatalogosScreen() {
    const { handleClickRuta } = useLogin();
    const { openCatDoctos, handleOpenCatDoctos } = useDocumentosOCR();
    const { openZonas, handleOpenZonas } = useZonasGeograficas();
    const { openCatFinancieras, handleOpenCatFinanciera } = useFinancieras();
    const { openAvisosPrivacidad, handleOpenAvisoPrivacidad } = useAvisosPrivacidad();
    const { openAprobados, handleOpenAprobados } = useAprobados();
    const { openCatConfiguracion, handleOpenCatConfig } = useConfiguracion();
    const { openCatSolicitudRechazada, handleOpenCatSolicitudRechazada } = useCatSolicitudRechazada();
    const { openOriginador, handleOpenOriginador } = useOriginador();
    const { openTipoArea, handleOpenTipoArea } = useTipoArea();
    const { openCatReglasOpe, handleOpenCatReglasOpe } = useReglasOpeContext();
    const { openOriginadorDinercap, handleOpenOriginadorDinercap } = useOriginadorDinercap();

    const handleClickHome = (e) => {
        handleClickRuta(e, {
            pathname: urlDefault,
        });
    };

    const handleClickListItem = (e, opcion) => {
        switch (opcion) {
            case "1":
                handleOpenCatDoctos(true);
                break;
            case "2":
                handleOpenTipoArea(true);
                break;
            case "3":
                handleOpenZonas(true);
                break;
            case "4":
                handleOpenCatConfig(true);
                break;
            case "5":
                handleOpenCatFinanciera(true);
                break;
            case "6":
                handleOpenAvisoPrivacidad(true);
                break;
            case "7":
                handleOpenAprobados(true);
                break;
            case "8":
                handleOpenCatSolicitudRechazada(true);
                break;
            case "9":
                handleOpenOriginador(true);
                break;
            case "10":
                handleOpenOriginadorDinercap(true);
                break;
            case "11":
                handleOpenCatReglasOpe(true);
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <div className="flex flex-col md:flex-row items-start md:items-center md:justify-between h-14 p-3 border-b bg-header">
                <div className="flex flex-row items-center">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            className={
                                "flex !font-medium px-2 py-1 hover:!no-underline hover:!cursor-pointer hover:!bg-gray-100 hover:!rounded-full"
                            }
                            color="inherit"
                            onClick={(e) => handleClickHome(e)}
                        >
                            <IoHomeOutline className="text-2xl mr-2" />
                            Inicio
                        </Link>
                        <Typography color="textPrimary" className={"flex !font-medium px-2 py-1 "}>
                            <IoOptionsOutline className="text-2xl mr-2" />
                            <span>Catálogos Generales</span>
                        </Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <div className="max-w-3xl mx-auto p-4">
                <List className="flex flex-col gap-2">
                    <ListItem
                        className="flex bg-card !bg-white shadow !rounded-xl !py-4"
                        role={undefined}
                        onClick={(e) => handleClickListItem(e, "1")}
                        button
                    >
                        <ListItemText
                            className="ml-2 flex-1 sm:mr-40 text-gray-900"
                            id="1"
                            primary="Catálogo de documentos OCR"
                        />
                    </ListItem>
                    <ListItem
                        className="flex bg-card !bg-white shadow !rounded-xl !py-4"
                        role={undefined}
                        onClick={(e) => handleClickListItem(e, "2")}
                        button
                    >
                        <ListItemText
                            className="ml-2 flex-1 sm:mr-40 text-gray-900"
                            id="9"
                            primary="Catalogo tipo de áreas geográficas"
                        />
                    </ListItem>
                    <ListItem
                        className="flex bg-card !bg-white shadow !rounded-xl !py-4"
                        role={undefined}
                        onClick={(e) => handleClickListItem(e, "3")}
                        button
                    >
                        <ListItemText
                            className="ml-2 flex-1 sm:mr-40 text-gray-900"
                            id="2"
                            primary="Catálogo zonas geográficas"
                        />
                    </ListItem>
                    <ListItem
                        className="flex bg-card !bg-white shadow !rounded-xl !py-4"
                        role={undefined}
                        onClick={(e) => handleClickListItem(e, "4")}
                        button
                    >
                        <ListItemText
                            className="ml-2 flex-1 sm:mr-40 text-gray-900"
                            id="3"
                            primary="Configuración sistema"
                        />
                    </ListItem>
                    <ListItem
                        className="flex bg-card !bg-white shadow !rounded-xl !py-4"
                        role={undefined}
                        onClick={(e) => handleClickListItem(e, "5")}
                        button
                    >
                        <ListItemText
                            className="ml-2 flex-1 sm:mr-40 text-gray-900"
                            id="4"
                            primary="Catálogo financieras"
                        />
                    </ListItem>
                    <ListItem
                        className="flex bg-card !bg-white shadow !rounded-xl !py-4"
                        role={undefined}
                        onClick={(e) => handleClickListItem(e, "6")}
                        button
                    >
                        <ListItemText
                            className="ml-2 flex-1 sm:mr-40 text-gray-900"
                            id="5"
                            primary="Avisos de privacidad"
                        />
                    </ListItem>
                    <ListItem
                        className="flex bg-card !bg-white shadow !rounded-xl !py-4"
                        role={undefined}
                        onClick={(e) => handleClickListItem(e, "7")}
                        button
                    >
                        <ListItemText
                            className="ml-2 flex-1 sm:mr-40 text-gray-900"
                            id="6"
                            primary="Configuracion aprobados"
                        />
                    </ListItem>
                    <ListItem
                        className="flex bg-card !bg-white shadow !rounded-xl !py-4"
                        role={undefined}
                        onClick={(e) => handleClickListItem(e, "8")}
                        button
                    >
                        <ListItemText
                            className="ml-2 flex-1 sm:mr-40 text-gray-900"
                            id="7"
                            primary="Solicitud rechazada"
                        />
                    </ListItem>
                    <ListItem
                        className="flex bg-card !bg-white shadow !rounded-xl !py-4"
                        role={undefined}
                        onClick={(e) => handleClickListItem(e, "9")}
                        button
                    >
                        <ListItemText
                            className="ml-2 flex-1 sm:mr-40 text-gray-900"
                            id="8"
                            primary="Mapeo originador"
                        />
                    </ListItem>
                    <ListItem
                        className="flex bg-card !bg-white shadow !rounded-xl !py-4"
                        role={undefined}
                        onClick={(e) => handleClickListItem(e, "10")}
                        button
                    >
                        <ListItemText
                            className="ml-2 flex-1 sm:mr-40 text-gray-900"
                            id="10"
                            primary="Mapeo de catálogos de originador"
                        />
                    </ListItem>
                    <ListItem
                        className="flex bg-card !bg-white shadow !rounded-xl !py-4"
                        role={undefined}
                        onClick={(e) => handleClickListItem(e, "11")}
                        button
                    >
                        <ListItemText
                            className="ml-2 flex-1 sm:mr-40 text-gray-900"
                            id="11"
                            primary="Catálogo reglas y operadores"
                        />
                    </ListItem>
                </List>
            </div>
            {openCatDoctos && <CatalogoDocumentosModal />}
            {openZonas && <ZonasGeograficasModal />}
            {openCatFinancieras && <CatalogoFinancierasModal />}
            {openAvisosPrivacidad && <AvisosPrivacidadModal />}
            {openAprobados && <AprobadoModal />}
            {openCatConfiguracion && <CatalogoConfigSistemaModal />}
            {openCatSolicitudRechazada && <CatalogoSolicitudRechazadaModal />}
            {openOriginador && <OriginadorModal />}
            {openTipoArea && <TipoAreaModal />}
            {openCatReglasOpe && <CatalogoAsociarReglasOpeModal />}
            {openOriginadorDinercap && <OriginadorDinercapModal />}
        </div>
    );
}
