export const getModelSolicitud = () => ({
    iIdSolicitud: 0,
});

export const getModelError = () => ({
    error: false,
});

export const getSolicitudColumns = () => [
    // { title: "ID", field: "iIdSolicitud" },
    { title: "Folio solicitud", field: "iIdSolicitud" },
    { title: "Estatus", field: "iIDestatus" },
    { title: "Fecha Creación", field: "dtFechaCreacion" },
    { title: "Fecha Modificación", field: "dtFechaModificacion" },
];

export const getFormCPColonia = () => ({
    txtCodigoPostal: {
        label: "Código Postal:",
        name: "txtCodigoPostal",
        required: true,
        value: "",
        error: false,
        message: "El código postal es requerido",
        placeholder: "",
    },
    cmbBoxColonias: {
        label: "Colonia:",
        name: "cmbBoxColonias",
        required: true,
        value: "",
        error: false,
        message: "La colonia es requerida",
        placeholder: "",
    },
});