import { Grid, Typography } from "@mui/material";

import DinercapInfoField from "../../utils/DinercapInfoField";
import DinercapModal from "../../utils/DinercapModal";
import { useTipoSolicitud } from "../../../context/TipoSolicitudContext";

export default function EliminarTipoSolicitud() {
    const {
        tipoSolicitudSeleccionado,
        modalOpenEliminarTipoSolicitud,
        handleOpenEliminarTipoSolicitud,
        handleSubmitEliminarTipoSolicitud,
    } = useTipoSolicitud();

    return (
        <DinercapModal
            title="Eliminar Tipo Solicitud"
            open={modalOpenEliminarTipoSolicitud}
            setOpen={handleOpenEliminarTipoSolicitud}
            okFunc={handleSubmitEliminarTipoSolicitud}
            // align="center"
            size="small"
        >
            <Grid container spacing={1} className="center">
                <Grid item xs={12}>
                    <Typography paragraph align="center">
                        ¿Desea eliminar el siguiente tipo de solictud?
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <DinercapInfoField label="Tipo Solicitud:" value={tipoSolicitudSeleccionado.sDescripcion} />
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
