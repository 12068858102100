import { createContext, useContext, useState } from "react";
import Enumerable from "linq";

import {
    apiTipoSolicitudGetTiposSolicitud,
    apiBloqueGetBloques_SinDetalle,
    apiCampoBloqueGetCamposAllBloque,
    apiCGetCatDocumentoDatos,
    apiCUpdateCampoReqOCR,
    apiCSaveDatosDir,
    apiCDeleteDatosDir,
} from "../settings/apiConfig";
import { getModelTipoSolicitud } from "../models/TipoSolicitudModel";
import { getModelBloqueSolicitud, getModelCampoBloqueSolicitud } from "../models/ConfiguracionSolicitudModel";

import { useRequest } from "./RequestContext";
import { useLayout } from "./LayoutContext";
import { fnPrevent } from "../settings/preventConfig";

export const ConfiguracionDatosContext = createContext();

export function ConfiguracionDatosDirProvider(props) {
    const { handleOpenAlert } = useLayout();
    const { Get, Post } = useRequest();

    const [openDocumentos, setOpenDocumentos] = useState(false);
    const [documentosSeleccionados, setDocumentosSeleccionados] = useState([]);

    const [listaSolicitudes, setListaSolicitudes] = useState([]);
    const [listaBloques, setListaBloques] = useState([]);
    const [listaCampos, setListaCampos] = useState([]);
    const [listaDocumentos, setListaDocumentos] = useState([]);

    const [solicitudSeleccionado, setSolicitudSeleccionado] = useState(getModelTipoSolicitud());
    const [bloqueSeleccionado, setBloqueSeleccionado] = useState(getModelBloqueSolicitud());
    const [campoSeleccionado, setCampoSeleccionado] = useState(getModelCampoBloqueSolicitud());
    // #region Solicitudes...
    const handleObtenerSolicitudes = async () => {
        const payload = {
            loader: "Consultando tipos de solicitudes...",
            url: apiTipoSolicitudGetTiposSolicitud,
            alert: false,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            const listSolicitudes = Enumerable.from(response.result)
                .select((sol) => ({
                    iIDTipoSolicitud: sol.iIDTipoSolicitud,
                    sDescripcion: sol.sDescripcion,
                    sNombre: sol.sNombre,
                    sIcono: sol.sIcono,
                }))
                .toArray();
            setListaSolicitudes(listSolicitudes);

            // Limpiar listas
            setSolicitudSeleccionado(getModelTipoSolicitud());
            setListaBloques([]);
            setBloqueSeleccionado(getModelBloqueSolicitud());
            setListaCampos([]);
            setCampoSeleccionado(getModelCampoBloqueSolicitud());
        } else {
            setListaSolicitudes([]);
            setSolicitudSeleccionado(getModelTipoSolicitud());
            setListaBloques([]);
            setBloqueSeleccionado(getModelBloqueSolicitud());
            setListaCampos([]);
            setCampoSeleccionado(getModelCampoBloqueSolicitud());
        }
    };

    const handleClickItemSolicitud = (solicitud) => {
        setSolicitudSeleccionado(solicitud);

        handleObtenerBloques(solicitud.iIDTipoSolicitud);
    };
    // #endregion

    // #region Bloques...
    const handleObtenerBloques = async (value) => {
        const payload = {
            loader: "Consultando bloques de la solicitud...",
            url: apiBloqueGetBloques_SinDetalle.replace("{piIDTipoSolicitud}", value),
            alert: false,
        };

        const response = await Get(payload);
        if (response.code === 201) {
            const listBloques = Enumerable.from(response.result)
                .select((bloque) => ({
                    iIDBloqueSolicitud: bloque.iIDBloqueSolicitud,
                    sNombreBloque: bloque.sNombreBloque,
                    sIcono: bloque.sIcono,
                    bGrupo: bloque.bGrupo,
                }))
                .toArray();
            setListaBloques(listBloques);

            //Limpiar controles
            setBloqueSeleccionado(getModelBloqueSolicitud());
            setListaCampos([]);
            setCampoSeleccionado(getModelCampoBloqueSolicitud());
        } else {
            setListaBloques([]);
            setBloqueSeleccionado(getModelBloqueSolicitud());
            setListaCampos([]);
            setCampoSeleccionado(getModelCampoBloqueSolicitud());
        }
    };

    const handleClickItemBloque = (bloque) => {
        setBloqueSeleccionado(bloque);

        handleObtenerCampos(bloque.iIDBloqueSolicitud, bloque.bGrupo);
    };
    // #endregion

    // #region Campos
    const handleObtenerCampos = async (value, bGrupo) => {
        const payload = {
            loader: "Consultando campos del bloque...",
            url: apiCampoBloqueGetCamposAllBloque.replace("{iIDBloque}", value).replace("{bGrupo}", bGrupo),
            alert: false,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            const listCampos = Enumerable.from(response.result)
                .select((campo) => ({
                    iIDBloqueSolicitudCampos: campo.iIDBloqueSolicitudCampos,
                    sNombre: campo.sNombre,
                    sIcono: campo.sIcono,
                    aCamposAgrupado: Enumerable.from(campo.aCamposAgrupado)
                        .select((campoAgrup) => ({
                            iIDBloqueSolicitudCampos: campoAgrup.iIDBloqueSolicitudCampos,
                            sNombre: campoAgrup.sNombre,
                            sIcono: campoAgrup.sIcono,
                            aCamposAgrupado: campoAgrup.aCamposAgrupado,
                            bReqOCR: campoAgrup.bReqOCR,
                            tipoDato: {
                                iIDTipoDato: campoAgrup.itInputType.iIDTipoDato,
                                sNombre: campoAgrup.itInputType.sNombre,
                            },
                            inputForms: {
                                sNombre: campoAgrup.ifInputForm.sNombre,
                            },
                        }))
                        .toArray(),
                    bReqOCR: campo.bReqOCR,
                    tipoDato: {
                        iIDTipoDato: campo.itInputType.iIDTipoDato,
                        sNombre: campo.itInputType.sNombre,
                    },
                    inputForms: {
                        sNombre: campo.ifInputForm.sNombre,
                    },
                    entCatDocumentoDatos: Enumerable.from(campo.entCatDocumentoDatos)
                        .select((docto) => ({
                            iIdDatosDireccion: docto.iIdDatosDireccion,
                            sNombre: docto.sNombre,
                        }))
                        .toArray(),
                }))
                .toArray();
            setListaCampos(listCampos);
        } else {
            setListaCampos([]);
            setBloqueSeleccionado(getModelCampoBloqueSolicitud());
        }
    };

    const handleClickItemCampo = (campo) => {
        setCampoSeleccionado(campo);

        let doctos = [];
        campo.entCatDocumentoDatos.forEach((element) => {
            doctos.push(element.iIdDatosDireccion);
        });

        setDocumentosSeleccionados(doctos);
        handleOpenDocumentos(true);
    };
/*
    const handleChangeItemCampo = async (e, campo) => {
        const { target } = e;

        const payload = {
            loader: "Actualizando campo",
            url: apiCUpdateCampoReqOCR,
            body: {
                iIDBloqueSolicitudCampos: campo.iIDBloqueSolicitudCampos,
                bReqOCR: target.checked,
                iIDUsuarioModificacion: 1,
            },
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            await handleObtenerCampos(bloqueSeleccionado.iIDBloqueSolicitud, bloqueSeleccionado.bGrupo);
        }
    };*/
    // #endregion

    // #region DocumentosOCR
    const handleObtenerDatosDir = async () => {
        const payload = {
            loader: "Consultando documentos",
            url: apiCGetCatDocumentoDatos,
            alert: true,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            if (response.result.length > 0) {
                const listaDoctos = Enumerable.from(response.result)
                    .select((docto) => ({
                        iIdDatosDireccion: docto.iIdDatosDireccion,
                        sNombre: docto.sNombre,
                    }))
                    .toArray();
                setListaDocumentos(listaDoctos);
            } else {
                handleOpenAlert("Error al obtener los documentos", "error");
            }
        }
    };

    const handleOpenDocumentos = (value) => {
        setOpenDocumentos(value);

        if (value === false) {
            setDocumentosSeleccionados([]);
            setCampoSeleccionado(getModelCampoBloqueSolicitud());
        }
    };

    const handleSubmitDocumentoCampo = async (e, doctos) => {
        fnPrevent(e);

        let payload = null;
        if (doctos.length === 0) {
            //Eliminar lo configurado...
            payload = {
                loader: "Eliminando...",
                url: apiCDeleteDatosDir.replace("{piIdCampo}", campoSeleccionado.iIDBloqueSolicitudCampos),
                alert: true,
            };
        } else {
            let entDocDatosCampo = [];
            doctos.forEach((element) => {
                entDocDatosCampo.push({
                    iIDUsuarioCreacion: 1,
                    iIdDocumentosDatosCampo: -1,
                    iidbloquesolicitudcampo: campoSeleccionado.iIDBloqueSolicitudCampos,
                    iIdDatoDireccion: element,
                });
            });

            payload = {
                loader: "Guardando...",
                url: apiCSaveDatosDir,
                body: entDocDatosCampo,
                alert: true,
            };

        }

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            await handleObtenerCampos(bloqueSeleccionado.iIDBloqueSolicitud, bloqueSeleccionado.bGrupo);
            handleOpenDocumentos(false);
        }
    };
    // #endregion

    return (
        <ConfiguracionDatosContext.Provider
            value={{
                listaSolicitudes,
                listaBloques,
                listaCampos,
                listaDocumentos,
                solicitudSeleccionado,
                bloqueSeleccionado,
                campoSeleccionado,
                openDocumentos,
                documentosSeleccionados,
                handleObtenerSolicitudes,
                handleClickItemSolicitud,
                handleClickItemBloque,
                handleClickItemCampo,
                handleOpenDocumentos,
                handleObtenerDatosDir,
                // handleChangeItemCampo,
                handleSubmitDocumentoCampo,
            }}
        >
            {props.children}
        </ConfiguracionDatosContext.Provider>
    );
}

export function useConfiguracionDatos() {
    return useContext(ConfiguracionDatosContext);
}
