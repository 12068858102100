import { useEffect, useState } from "react";
import { Grid, TextField, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { inputColor, inputVariant } from "../../../settings/inputConfig";

import DinercapModal from "../../utils/DinercapModal";
import { useConfiguracionSolicitud } from "../../../context/ConfiguracionSolicitudContext";
import { withStyles } from "tss-react/mui";
import InputBase from "@mui/material/InputBase";

import { useCampoBloqueValidacion } from "../../../context/CampoBloqueValidacionContext";
import swal from "sweetalert";

export default function FormCampoValidacionCU(props) {
    const {
        formCamposValidacion,
        modalOpenFormCampoValidCU,
        handleOpenFormCampoValidCU,
        handleChangeFormCamposValidacion,
    } = useConfiguracionSolicitud();

    const {
        listaReglas,
        listaCamposRef,
        listaBloqueRef,
        listaOperadores,
        listaServers,
        handleListarReglas,
        handleListarCamposRef,
        handleListarBloquesRef,
        handleSubmitFormCampoValidacionCU,
        handleListarOperdoresPorTipoDato,
        handleGetListServers,
        validacionSeleccionado,
    } = useCampoBloqueValidacion();

    const { txtNombre, slReglaId, slCampoRef, txtValorRef, slBloqueRef, slOperadorId, txtMensajeError, slServerId } =
        formCamposValidacion;

    const CustomSelect = withStyles(InputBase, (theme) => ({
        input: {
            border: "0px",
            paddingTop: "0px",
            paddingBottom: "0px",
            paddingLeft: "8px",
            paddingRight: "4px",
            marginTop: theme.spacing(3),
            "&:focus": {
                borderRadius: 4,
                boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
            },
        },
    }));

    useEffect(() => {
        if (modalOpenFormCampoValidCU) {
            handleListarReglas();
        }
    }, [modalOpenFormCampoValidCU]);

    useEffect(() => {
        if (modalOpenFormCampoValidCU) {
            handleListarCamposRef();
        }
    }, [modalOpenFormCampoValidCU]);

    useEffect(() => {
        if (modalOpenFormCampoValidCU) {
            handleListarBloquesRef();
        }
    }, [modalOpenFormCampoValidCU]);

    useEffect(() => {
        if (modalOpenFormCampoValidCU) {
            handleListarOperdoresPorTipoDato();
            handleGetListServers();
        } else {
            setRuleSelected("");
        }
    }, [modalOpenFormCampoValidCU]);

    const [ruleSelected, setRuleSelected] = useState("");

    const handleChangeRule = (e, text) => {
        setRuleSelected(text);
    };

    const handleSaveFormValidacion = (e) => {
        e.preventDefault();
        if (ruleSelected === "LINK_TO" && slServerId.value === 0) {
            swal("Guardar validación", "URL Server es requerido.", "error");
        } else {
            handleSubmitFormCampoValidacionCU(e);
        }
    };

    return (
        <DinercapModal
            title={validacionSeleccionado.iIDBloqSolCampValidaciones === 0 ? "Nueva validación" : "Ver validación"}
            okFunc={handleSaveFormValidacion}
            setOpen={handleOpenFormCampoValidCU}
            open={modalOpenFormCampoValidCU}
            okMessage="Guardar"
            size="small"
            form="true"
            hideOk={validacionSeleccionado.iIDBloqSolCampValidaciones !== 0}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        name="txtNombre"
                        label={txtNombre.label}
                        required={txtNombre.required}
                        value={txtNombre.value}
                        error={txtNombre.error}
                        helperText={txtNombre.error && txtNombre.message}
                        onChange={handleChangeFormCamposValidacion}
                        variant={inputVariant}
                        color={inputColor}
                        autoComplete="off"
                        autoFocus
                        fullWidth
                        input={<CustomSelect />}
                        className="mb-2"
                        inputProps={{
                            maxLength: txtNombre.maxlength,
                            disabled: validacionSeleccionado.iIDBloqSolCampValidaciones !== 0 ? true : false,
                        }}
                    />
                    <InputLabel id="lblTipoRegla">Regla: </InputLabel>
                    <Select
                        name="slReglaId"
                        labelId="lblTipoRegla"
                        id="slReglaId"
                        label={slReglaId.label}
                        required={slReglaId.required}
                        value={slReglaId.value}
                        defaultValue={0}
                        error={slReglaId.error}
                        onChange={handleChangeFormCamposValidacion}
                        fullWidth
                        className="mb-2"
                        inputProps={{
                            disabled: validacionSeleccionado.iIDBloqSolCampValidaciones !== 0 ? true : false,
                        }}
                    >
                        <MenuItem value={0}>
                            <em>Seleccionar</em>
                        </MenuItem>
                        {listaReglas.map((item) => {
                            return (
                                <MenuItem
                                    onClick={(e) => handleChangeRule(e, item.sNombreValidacion)}
                                    key={item.iIdCamposValidacionReglas}
                                    value={item.iIdCamposValidacionReglas}
                                >
                                    {item.sNombreValidacion}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText error={slReglaId.error}>{slReglaId.error && slReglaId.message}</FormHelperText>
                    {ruleSelected === "LINK_TO" && (
                        <>
                            <InputLabel id="lblServerId">URL Server: </InputLabel>
                            <Select
                                name="slServerId"
                                labelId="lblServerId"
                                id="slServerId"
                                label={slServerId.label}
                                required={slServerId.required}
                                value={slServerId.value}
                                defaultValue={0}
                                error={slServerId.error}
                                onChange={handleChangeFormCamposValidacion}
                                fullWidth
                                className="mb-2"
                                inputProps={{
                                    disabled: validacionSeleccionado.iIDBloqSolCampValidaciones !== 0 ? true : false,
                                }}
                            >
                                <MenuItem value={0}>
                                    <em>Seleccionar</em>
                                </MenuItem>
                                {listaServers.map((item) => {
                                    return (
                                        <MenuItem key={item.iIDApiServer} value={item.iIDApiServer}>
                                            {item.sDescripcion} ({item.sURL})
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <FormHelperText error={slServerId.error}>
                                {slServerId.error && slServerId.message}
                            </FormHelperText>
                        </>
                    )}
                    <TextField
                        name="txtValorRef"
                        label={txtValorRef.label}
                        required={txtValorRef.required}
                        value={txtValorRef.value}
                        error={txtValorRef.error && txtValorRef.required}
                        helperText={txtValorRef.error && txtValorRef.message && txtValorRef.required}
                        onChange={handleChangeFormCamposValidacion}
                        variant={inputVariant}
                        color={inputColor}
                        autoComplete="off"
                        fullWidth
                        input={<CustomSelect />}
                        className="mb-2"
                        inputProps={{
                            maxLength: txtValorRef.maxlength,
                            disabled: validacionSeleccionado.iIDBloqSolCampValidaciones !== 0 ? true : false,
                        }}
                    />

                    <InputLabel id="lblBloqueRef">Bloque de referencia: </InputLabel>
                    <Select
                        name="slBloqueRef"
                        labelId="lblBloqueRef"
                        id="slBloqueRef"
                        label={slBloqueRef.label}
                        required={slBloqueRef.required}
                        value={slBloqueRef.value}
                        error={slBloqueRef.error}
                        defaultValue={0}
                        fullWidth
                        onChange={handleChangeFormCamposValidacion}
                        className="mb-2"
                        inputProps={{
                            disabled: validacionSeleccionado.iIDBloqSolCampValidaciones !== 0 ? true : false,
                        }}
                    >
                        <MenuItem value={0}>
                            <em>Seleccionar</em>
                        </MenuItem>
                        {listaBloqueRef.map((item) => {
                            return (
                                <MenuItem key={item.iIDBloqueSolicitud} value={item.iIDBloqueSolicitud}>
                                    {item.sNombreBloque}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText error={slBloqueRef.error}>
                        {slBloqueRef.error && slBloqueRef.message}
                    </FormHelperText>

                    <InputLabel id="lblCampoRef">Campo de referencia: </InputLabel>
                    <Select
                        name="slCampoRef"
                        labelId="lblCampoRef"
                        id="slCampoRef"
                        label={slCampoRef.label}
                        required={slCampoRef.required}
                        value={slCampoRef.value}
                        error={slCampoRef.error}
                        defaultValue={0}
                        fullWidth
                        onChange={handleChangeFormCamposValidacion}
                        className="mb-2"
                        inputProps={{
                            disabled: validacionSeleccionado.iIDBloqSolCampValidaciones !== 0 ? true : false,
                        }}
                    >
                        <MenuItem value={0}>
                            <em>Seleccionar</em>
                        </MenuItem>
                        {listaCamposRef.map((item) => {
                            return (
                                <MenuItem key={item.iIDBloqueSolicitudCampos} value={item.iIDBloqueSolicitudCampos}>
                                    {item.sNombre}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText error={slCampoRef.error}>{slCampoRef.error && slCampoRef.message}</FormHelperText>

                    <InputLabel id="lblOperadorId">Operador: </InputLabel>
                    <Select
                        name="slOperadorId"
                        labelId="lblOperadorId"
                        id="slOperadorId"
                        label={slOperadorId.label}
                        required={slOperadorId.required}
                        value={slOperadorId.value}
                        error={slOperadorId.error}
                        defaultValue={0}
                        fullWidth
                        onChange={handleChangeFormCamposValidacion}
                        className="mb-2"
                        inputProps={{
                            disabled: validacionSeleccionado.iIDBloqSolCampValidaciones !== 0 ? true : false,
                        }}
                    >
                        <MenuItem value={0}>
                            <em>Seleccionar</em>
                        </MenuItem>
                        {listaOperadores.map((item) => {
                            return (
                                <MenuItem key={item.iIdOperador} value={item.iIdOperador}>
                                    {item.sOperador}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText error={slOperadorId.error}>
                        {slOperadorId.error && slOperadorId.message}
                    </FormHelperText>

                    <TextField
                        name="txtMensajeError"
                        label={txtMensajeError.label}
                        required={txtMensajeError.required}
                        value={txtMensajeError.value}
                        error={txtMensajeError.error}
                        helperText={txtMensajeError.error && txtMensajeError.message}
                        onChange={handleChangeFormCamposValidacion}
                        variant={inputVariant}
                        color={inputColor}
                        autoComplete="off"
                        fullWidth
                        input={<CustomSelect />}
                        className="mb-2"
                        inputProps={{
                            maxLength: txtMensajeError.maxlength,
                            disabled: validacionSeleccionado.iIDBloqSolCampValidaciones !== 0 ? true : false,
                        }}
                    />
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
