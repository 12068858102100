import { createContext, useContext, useState } from "react";
import Enumerable from "linq";
import { useRequest } from "../RequestContext";
import { useLayout } from "../LayoutContext";
import { apiCGetCatDocumentoOCR, apiCSaveCatDocumentoOCR } from "../../settings/apiConfig";
import Swal from "sweetalert2";

import { getFormCatalogoDocumentos, getModelCatalogoDocumento } from "../../models/CatalogosModel";
import { fnPrevent } from "../../settings/preventConfig";
import { fnHandleChangeText, fnHandleChangeFile, fnHandleChangeDecimal } from "../../settings/handleConfig";

export const DocumentosOCRContext = createContext();

export function DocumentosOCRProvider(props) {
    const { handleOpenAlert } = useLayout();
    const { Get, Post } = useRequest();

    //Catalogo de documentos OCR
    const [openCatDoctos, setOpenCatDoctos] = useState(false);
    const [listaDocumentos, setListaDocumentos] = useState([]);
    const [formCatalogoDocumento, setFormCatalogoDocumento] = useState(getFormCatalogoDocumentos());
    const [documentoSeleccionado, setDocumentoSeleccionado] = useState(getModelCatalogoDocumento());

    // Form documento
    const [openFormDocumento, setOpenFormDocumento] = useState(false);

    // #region Catalogo de documentos OCR
    const handleOpenCatDoctos = (value) => {
        setOpenCatDoctos(value);
    };

    const handleObtenerDocumentos = async () => {
        const payload = {
            loader: "Consultando documentos",
            url: apiCGetCatDocumentoOCR,
            alert: true,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            if (response.result.length > 0) {
                const listaDoctos = Enumerable.from(response.result)
                    .select((docto) => ({
                        iIdDocumetosocr: docto.iIdDocumetosocr,
                        sNombre: docto.sNombre,
                        sTipo: docto.sTipo,
                        sNombrePatron:
                            docto.sNombrePatron != null
                                ? docto.sNombrePatron.split("/").length > 1
                                    ? docto.sNombrePatron.split("/")[docto.sNombrePatron.split("/").length - 1]
                                    : docto.sNombrePatron
                                : "",
                        urlXML: docto.sNombrePatron,
                        fvalorJW: docto.fvalorJW,
                    }))
                    .toArray();
                setListaDocumentos(listaDoctos);
            } else {
                handleOpenAlert("Error al obtener los documentos", "error");
            }
        }
    };
    //#endregion Catalogo de documentos OCR

    // #region formulario documento
    const handleOpenFormDocumento = (value) => {
        setOpenFormDocumento(value);
        if (!value) {
            setFormCatalogoDocumento(getFormCatalogoDocumentos());
            setDocumentoSeleccionado(getModelCatalogoDocumento());
        }
    };

    const handleChangeFormDocumento = fnHandleChangeText(formCatalogoDocumento, setFormCatalogoDocumento);
    const handleChangeFormValor = fnHandleChangeDecimal(formCatalogoDocumento, setFormCatalogoDocumento);
    const handleChangeFileFormDocumento = fnHandleChangeFile(formCatalogoDocumento, setFormCatalogoDocumento);

    const handleSubmitFormDocumento = async (e) => {
        fnPrevent(e);

        const iIdDoctoOCR =
            documentoSeleccionado.iIdDocumetosocr === undefined ? -1 : documentoSeleccionado.iIdDocumetosocr;

        const { txtNombreDocto, txtArchivo, txtTipo, txtValorJW } = formCatalogoDocumento;

        const form = {
            ...formCatalogoDocumento,
            txtNombreDocto: {
                ...txtNombreDocto,
                error: txtNombreDocto.value === "",
            },
            txtArchivo: {
                ...txtArchivo,
                error: iIdDoctoOCR === -1 ? txtArchivo.value === "" && txtArchivo.nombreArchivo === "" : false,
            },
            txtTipo: {
                ...txtTipo,
                error: txtTipo.value === "",
            },
            txtValorJW: {
                ...txtValorJW,
                error: txtValorJW.value === "",
            },
        };

        setFormCatalogoDocumento(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }

        const entDocumentoOCR = {
            iIdDocumetosocr: iIdDoctoOCR,
            sNombre: txtNombreDocto.value,
            sNombrePatron: txtArchivo.nombreArchivo,
            sPatronBlob: txtArchivo.value !== "" ? txtArchivo.value.split(",")[1] : "",
            sTipo: txtTipo.value,
            fvalorJW: txtValorJW.value,
            iIDUsuarioCreacion: iIdDoctoOCR === -1 ? 1 : 0,
            iIDUsuarioModificacion: iIdDoctoOCR !== -1 ? 1 : 0,
            iIDUsuarioElimina: 0,
        };

        await fn_guardarDocumento(entDocumentoOCR);
    };

    const fn_guardarDocumento = async (entDocumentoOCR) => {
        let loader = "";
        if (entDocumentoOCR.iIDUsuarioCreacion > 0) {
            loader = "Guardando el documento.";
        } else if (entDocumentoOCR.iIDUsuarioModificacion > 0) {
            loader = "Actualizando el documento.";
        } else if (entDocumentoOCR.iIDUsuarioElimina > 0) {
            loader = "Eliminando el documento.";
        }

        const payload = {
            loader: loader,
            body: entDocumentoOCR,
            url: apiCSaveCatDocumentoOCR,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            handleOpenFormDocumento(false);
            setDocumentoSeleccionado(getModelCatalogoDocumento());
            setFormCatalogoDocumento(getFormCatalogoDocumentos());
            await handleObtenerDocumentos();
        }
    };

    const handleActualizarDocumento = (e, documento) => {
        fnPrevent(e);
        setDocumentoSeleccionado(documento);

        const { txtNombreDocto, txtNombrePatron, txtTipo, txtValorJW } = formCatalogoDocumento;
        let form = {
            ...formCatalogoDocumento,
            txtNombreDocto: {
                ...txtNombreDocto,
                value: documento.sNombre,
            },
            txtNombrePatron: {
                ...txtNombrePatron,
                value: documento.sNombrePatron,
            },
            txtTipo: {
                ...txtTipo,
                value: documento.sTipo,
            },
            txtValorJW: {
                ...txtValorJW,
                value: documento.fvalorJW,
            },
        };

        setFormCatalogoDocumento(form);
        setOpenFormDocumento(true);
    };

    const handleEliminarDocumento = async (e, documento) => {
        fnPrevent(e);
        Swal.fire({
            title: "Eliminar documento",
            text: "¿Está seguro de eliminar el documento " + documento.sNombre + "?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await fn_eliminarDocumento(documento);
            }
        });
    };

    const fn_eliminarDocumento = async (documento) => {
        const entDocumentoOCR = {
            iIdDocumetosocr: documento.iIdDocumetosocr,
            iIDUsuarioElimina: 1,
            iIDUsuarioCreacion: 0,
            iIDUsuarioModificacion: 0,
        };

        await fn_guardarDocumento(entDocumentoOCR);
    };
    // #endregion

    return (
        <DocumentosOCRContext.Provider
            value={{
                openCatDoctos,
                listaDocumentos,
                openFormDocumento,
                formCatalogoDocumento,
                documentoSeleccionado,
                handleOpenCatDoctos,
                handleObtenerDocumentos,
                handleOpenFormDocumento,
                handleSubmitFormDocumento,
                handleChangeFormDocumento,
                handleActualizarDocumento,
                handleEliminarDocumento,
                handleChangeFileFormDocumento,
                handleChangeFormValor,
            }}
        >
            {props.children}
        </DocumentosOCRContext.Provider>
    );
}

export function useDocumentosOCR() {
    return useContext(DocumentosOCRContext);
}
