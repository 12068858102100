export const fnHandleChangeText = (form, setForm) => (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setForm({
        ...form,
        [name]: {
            ...form[name],
            value: value,
            error: value === "" || value === 0,
        },
    });
};

export const fnHandleChangeTextValidations = (form, setForm) => (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setForm({
        ...form,
        [name]: {
            ...form[name],
            value: value,
            error: value === "" || value === 0,
            isExist: value === "" || value === 0,
        },
    });
};

export const fnHandleChangeTextUpper = (form, setForm) => (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setForm({
        ...form,
        [name]: {
            ...form[name],
            value: typeof value === "string" ? value.toUpperCase() : value,
            error: value === "",
        },
    });
};

export const fnHandleChangeDate = (form, setForm) => (date, name) => {
    setForm({
        ...form,
        [name]: {
            ...form[name],
            value: date,
            error: !date,
        },
    });
};

export const fnHandleChangeChek = (form, setForm) => (e) => {
    const name = e.target.name;
    const value = e.target.checked;

    setForm({
        ...form,
        [name]: {
            ...form[name],
            value: value,
            error: value === "",
        },
    });
};

export const fnHandleChangeInteger = (form, setForm) => (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (value !== "" && value !== "-" && value !== "0" && value % 1 !== 0) {
        return;
    }

    setForm({
        ...form,
        [name]: {
            ...form[name],
            value: value,
            error: value === "",
        },
    });
};

export const fnHandleChangeUInteger = (form, setForm) => (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (value !== "" && value !== "0" && value % 1 !== 0) {
        return;
    }

    setForm({
        ...form,
        [name]: {
            ...form[name],
            value: value,
            error: value === "",
        },
    });
};

export const fnHandleChangeDecimal = (form, setForm) => (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (isNaN(value) && value !== "" && value !== "-") {
        return;
    }

    setForm({
        ...form,
        [name]: {
            ...form[name],
            value: value,
            error: value === "",
        },
    });
};

export const fnHandleChangeUDecimal = (form, setForm) => (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (isNaN(value) && value !== "") {
        return;
    }

    setForm({
        ...form,
        [name]: {
            ...form[name],
            value: value,
            error: value === "",
        },
    });
};

export const fnHandleChangeFile = (form, setForm) => (e) => {
    fnFileBase64(form, setForm, e);
};

const fnFileBase64 = async (form, setForm, e) => {
    const name = e.target.name;
    let file = e.target.files[0];
    let fileName = file.name;
    let srcData = "";

    await toBase64(file).then((data) => {
        srcData = data;
    });

    setForm({
        ...form,
        [name]: {
            ...form[name],
            nombreArchivo: fileName,
            value: srcData,
        },
    });
};

export const fnHandleChangeImagen = (form, setForm) => (e) => {
    /*reader.onload = async(fileLoadedEvent) => {
        srcData = fileLoadedEvent.target.result; // <--- data: base64
    }*/
    fnImgBase64(form, setForm, e);
};

const fnImgBase64 = async (form, setForm, e) => {
    const name = e.target.name;
    let srcData = "";
    let archivo = e.target.files[0];
    let nombreArchivo = archivo.name;
    let url_img = URL.createObjectURL(archivo);
    await toBase64(archivo).then((data) => {
        srcData = data;
    });
    setForm({
        ...form,
        [name]: {
            ...form[name],
            url: url_img,
            value: srcData,
            nombre: nombreArchivo,
        },
    });
};

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
