import { useEffect, useState } from "react";
import {
    Grid,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    FormControl,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { inputColor, inputVariant } from "../../../settings/inputConfig";

import DinercapModal from "../../utils/DinercapModal";
import { useConfiguracionSolicitud } from "../../../context/ConfiguracionSolicitudContext";
import { withStyles } from "tss-react/mui";
import InputBase from "@mui/material/InputBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCamera,
    faHome,
    faHandHoldingUsd,
    faInfoCircle,
    faPeopleArrows,
    faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";

export default function FormCampoBloqueSolicitud() {
    const {
        campoBloqueSolicitudSeleccionado,
        modalOpenFormCampoBloqueSolicitud,
        formCampoBloqueSolicitud,
        listaTipoDatos,
        listaTipoForm,
        handleOpenFormCampoBloqueSolicitud,
        handleChangeFormCampoBloqueSolicitud,
        handleSubmitFormCampoBloqueSolicitud,
        handleListarTipoDatos,
        handleListarTipoForms,
        bloqueSolicitudSeleccionado,
        iIDBloqueSolicitudCampoSeleccionadoRel,
        handleChangeFormCampoBloqueSolicitudCheck,
    } = useConfiguracionSolicitud();

    const {
        txtNombreCampoBloque,
        slTipoDeDatoCampoBloque,
        slTipoFormCampoBloque,
        txtDescripcionBloque,
        slIcono,
        txtFuenteDatosBloque,
        chkReqOCR,
        chkSinTildes,
    } = formCampoBloqueSolicitud;

    const [titulo, setTitulo] = useState("campo");

    useEffect(() => {
        handleListarTipoForms();
        if (bloqueSolicitudSeleccionado.bGrupo) {
            if (iIDBloqueSolicitudCampoSeleccionadoRel === 0) setTitulo("grupo");
            else setTitulo("campo del grupo");
        }
        if (campoBloqueSolicitudSeleccionado) {
            const { itInputType } = campoBloqueSolicitudSeleccionado;
            if (itInputType != null) {
                setTipoDatoSeleccionado(itInputType.sNombre);
            }
        }
    }, []);

    useEffect(() => {
        handleListarTipoDatos(slTipoFormCampoBloque.value);
    }, [slTipoFormCampoBloque]);

    const handleSave = (e) => {
        e.preventDefault();
        let continuar = true;
        if (tipoDatoSeleccionado === "LIST") {
            if (txtFuenteDatosBloque.value) {
                if (txtFuenteDatosBloque.value === "") {
                    continuar = false;
                    swal("Guardar", "Debe de ingresar un valor para Fuente de datos.", "error");
                } else {
                    try {
                        JSON.parse(txtFuenteDatosBloque.value);
                        if (
                            txtFuenteDatosBloque.value.indexOf("[") === -1 ||
                            txtFuenteDatosBloque.value.indexOf("]") === -1
                        ) {
                            continuar = false;
                            swal("Guardar", "El valor json ingresado en Fuente de datos, no es válido.", "error");
                        }
                    } catch (error) {
                        continuar = false;
                        swal("Guardar", "El valor ingresado en Fuente de datos, no es válido.", "error");
                    }
                }
            }
        }
        if (continuar) {
            if (iIDBloqueSolicitudCampoSeleccionadoRel === 0)
                handleSubmitFormCampoBloqueSolicitud(e, bloqueSolicitudSeleccionado.bGrupo);
            else handleSubmitFormCampoBloqueSolicitud(e);
        }
    };

    const CustomSelect = withStyles(InputBase, (theme) => ({
        input: {
            border: "0px",
            paddingTop: "0px",
            paddingBottom: "0px",
            paddingLeft: "8px",
            paddingRight: "4px",
            marginTop: theme.spacing(3),
            "&:focus": {
                borderRadius: 4,
                boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
            },
        },
    }));

    const [tipoDatoSeleccionado, setTipoDatoSeleccionado] = useState("");

    const handleChangeTipoDato = (e, text) => {
        setTipoDatoSeleccionado(text);
    };

    return (
        <DinercapModal
            title={
                campoBloqueSolicitudSeleccionado.iIDBloqueSolicitudCampos === 0 ? `Nuevo ${titulo}` : `Editar ${titulo}`
            }
            okFunc={handleSave}
            setOpen={handleOpenFormCampoBloqueSolicitud}
            open={modalOpenFormCampoBloqueSolicitud}
            okMessage="Guardar"
            size="small"
            form="true"
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        name="txtNombreCampoBloque"
                        label={txtNombreCampoBloque.label}
                        required={txtNombreCampoBloque.required}
                        value={txtNombreCampoBloque.value}
                        error={txtNombreCampoBloque.error}
                        helperText={txtNombreCampoBloque.error && txtNombreCampoBloque.message}
                        onChange={handleChangeFormCampoBloqueSolicitud}
                        variant={inputVariant}
                        color={inputColor}
                        autoComplete="off"
                        autoFocus
                        fullWidth
                        input={<CustomSelect />}
                        className="mb-2"
                        inputProps={{
                            maxLength: txtNombreCampoBloque.maxlength,
                        }}
                    />
                    <TextField
                        name="txtDescripcionBloque"
                        label={txtDescripcionBloque.label}
                        required={txtDescripcionBloque.required}
                        value={txtDescripcionBloque.value}
                        error={txtDescripcionBloque.error}
                        helperText={txtDescripcionBloque.error && txtDescripcionBloque.message}
                        onChange={handleChangeFormCampoBloqueSolicitud}
                        variant={inputVariant}
                        color={inputColor}
                        autoComplete="off"
                        fullWidth
                        input={<CustomSelect />}
                        className="mb-2"
                        inputProps={{
                            maxLength: txtDescripcionBloque.maxlength,
                        }}
                    />
                    <InputLabel id="lblCampoForm">Tipo campo form</InputLabel>
                    <Select
                        name="slTipoFormCampoBloque"
                        labelId="lblCampoForm"
                        id="slCampoForm"
                        label={slTipoFormCampoBloque.label}
                        required={slTipoFormCampoBloque.required}
                        value={slTipoFormCampoBloque.value}
                        defaultValue={0}
                        error={slTipoFormCampoBloque.error}
                        fullWidth
                        onChange={handleChangeFormCampoBloqueSolicitud}
                        className="mb-2"
                    >
                        <MenuItem value={0}>
                            <em>Seleccionar</em>
                        </MenuItem>
                        {listaTipoForm.map((tipoF) => {
                            return (
                                <MenuItem
                                    key={tipoF.iIDInputForms ? tipoF.iIDInputForms : tipoF.piIDInputForm}
                                    value={tipoF.iIDInputForms ? tipoF.iIDInputForms : tipoF.piIDInputForm}
                                >
                                    {tipoF.sNombre ? tipoF.sNombre : tipoF.sInputData}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText error={slTipoFormCampoBloque.error}>
                        {slTipoFormCampoBloque.error && slTipoFormCampoBloque.message}
                    </FormHelperText>
                    <InputLabel id="lblTipoDato">Tipo de dato</InputLabel>
                    <Select
                        name="slTipoDeDatoCampoBloque"
                        labelId="lblTipoDato"
                        id="slTipoDato"
                        label={slTipoDeDatoCampoBloque.label}
                        required={slTipoDeDatoCampoBloque.required}
                        value={slTipoDeDatoCampoBloque.value}
                        error={slTipoDeDatoCampoBloque.error}
                        defaultValue={0}
                        fullWidth
                        onChange={handleChangeFormCampoBloqueSolicitud}
                        className="mb-2"
                    >
                        <MenuItem value={0}>
                            <em>Seleccionar</em>
                        </MenuItem>
                        {listaTipoDatos.map((tipoI) => {
                            return (
                                <MenuItem
                                    onClick={(e) => handleChangeTipoDato(e, tipoI.sInputForm)}
                                    key={tipoI.piIDInputData}
                                    value={tipoI.piIDInputData}
                                >
                                    {tipoI.sInputForm}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText error={slTipoFormCampoBloque.error}>
                        {slTipoDeDatoCampoBloque.error && slTipoDeDatoCampoBloque.message}
                    </FormHelperText>

                    {tipoDatoSeleccionado === "LIST" && (
                        <TextField
                            name="txtFuenteDatosBloque"
                            label={txtFuenteDatosBloque.label}
                            required={txtFuenteDatosBloque.required}
                            value={txtFuenteDatosBloque.value}
                            error={txtFuenteDatosBloque.error}
                            helperText={txtFuenteDatosBloque.error && txtFuenteDatosBloque.message}
                            onChange={handleChangeFormCampoBloqueSolicitud}
                            variant={inputVariant}
                            color={inputColor}
                            autoComplete="off"
                            fullWidth
                            input={<CustomSelect />}
                            className="mb-2"
                            multiline
                            rows={4}
                            placeholder={txtFuenteDatosBloque.placeholder}
                        />
                    )}

                    <InputLabel id="lblSlIcono">Icono:</InputLabel>
                    <Select
                        labelId="lblSlIcono"
                        id="slIcono"
                        name="slIcono"
                        label={slIcono.label}
                        required={slIcono.required}
                        value={slIcono.value}
                        error={slIcono.error}
                        onChange={handleChangeFormCampoBloqueSolicitud}
                        fullWidth
                        defaultValue={0}
                    >
                        <MenuItem value="0">
                            <em>Seleccionar</em>
                        </MenuItem>
                        <MenuItem value="camera;f030">
                            <FontAwesomeIcon icon={faCamera} size="2x" className="mr-1" />
                            f030
                        </MenuItem>
                        <MenuItem value="home;f015">
                            <FontAwesomeIcon icon={faHome} size="2x" className="mr-1" />
                            f015
                        </MenuItem>
                        <MenuItem value="hand-holding-usd;f4c0">
                            <FontAwesomeIcon icon={faHandHoldingUsd} size="2x" className="mr-1" />
                            f4c0
                        </MenuItem>
                        <MenuItem value="info-circle;f05a">
                            <FontAwesomeIcon icon={faInfoCircle} size="2x" className="mr-1" />
                            f05a
                        </MenuItem>
                        <MenuItem value="people-arrows;e068">
                            <FontAwesomeIcon icon={faPeopleArrows} size="2x" className="mr-1" />
                            e068
                        </MenuItem>
                        <MenuItem value="briefcase;f0b1">
                            <FontAwesomeIcon icon={faBriefcase} size="2x" className="mr-1" />
                            f0b1
                        </MenuItem>
                    </Select>
                    <FormHelperText error={slIcono.error}>{slIcono.error && slIcono.message}</FormHelperText>
                    {slTipoFormCampoBloque.value === 10 && (
                        <FormControl component="fieldset">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="chkReqOCR"
                                        checked={chkReqOCR.value}
                                        onChange={handleChangeFormCampoBloqueSolicitudCheck}
                                    />
                                }
                                label={chkReqOCR.label}
                                labelPlacement="start"
                            />
                        </FormControl>
                    )}
                    {slTipoFormCampoBloque.value === 1 && tipoDatoSeleccionado === 'STRING' && (
                        <FormControl component="fieldset">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="chkSinTildes"
                                        checked={chkSinTildes.value}
                                        onChange={handleChangeFormCampoBloqueSolicitudCheck}
                                    />
                                }
                                label={chkSinTildes.label}
                                labelPlacement="start"
                            />
                        </FormControl>
                    )}
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
