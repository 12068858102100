import { createContext, useContext, useState } from "react";
import Swal from "sweetalert2";
import Enumerable from "linq";

import { getFormOriginadorDinercap, getModelOriginadorDinercap } from "../../models/OriginadorDinercapModel";
import { apiCGetOriginadorDinercap, apiCSaveOriginadorDinercap } from "../../settings/apiConfig";
import { fnHandleChangeText } from "../../settings/handleConfig";
import { fnPrevent } from "../../settings/preventConfig";
import { useRequest } from "../RequestContext";
import { useLayout } from "../LayoutContext";

export const OriginadorDinercapContext = createContext();

export function OriginadorDinercapProvider(props) {
    const { handleOpenAlert } = useLayout();
    const { Get, Post } = useRequest();
    
    //OriginadorDinercap
    const [openOriginadorDinercap, setOpenOriginadorDinercap] = useState(false);
    const [listaOriginadorDinercap, setListaOriginadorDinercap] = useState([]);
    const [formOriginadorDinercap, setFormOriginadorDinercap] = useState(getFormOriginadorDinercap());
    const [originadorDinercapSeleccionado, setOriginadorDinercapSeleccionado] = useState(getModelOriginadorDinercap());

    const [openFormOriginadorDinercap, setOpenFormOriginadorDinercap] = useState(false);

    const handleOpenOriginadorDinercap = (value) => {
        setOpenOriginadorDinercap(value);
    };

    const handleObtenerOriginadorDinercap = async () => {
        const payload = {
            loader: "Consultando OriginadorDinercap",
            url: apiCGetOriginadorDinercap,
            alert: true,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            if (response.result.length > 0) {
                const _listaOriginadorDinercap = Enumerable.from(response.result)
                    .select((originadorDinercap) => ({
                        iIdCatOriginadorDinercap: originadorDinercap.iIdCatOriginadorDinercap,
                        sCatalogo: originadorDinercap.sCatalogo,
                        sDescripcion: originadorDinercap.sDescripcion,
                        iIdCatSolicitudes: originadorDinercap.iIdCatSolicitudes,
                        iIdCatOriginador: originadorDinercap.iIdCatOriginador,
                    }))
                    .toArray();
                setListaOriginadorDinercap(_listaOriginadorDinercap);
            } else {
                handleOpenAlert("No se encontraron registros", "error");
            }
        } else {
                handleOpenAlert("Error al obtener los Originadores", "error");
            }
    };

    const handleOpenFormOriginadorDinercap = (value) => {
        setOpenFormOriginadorDinercap(value);
        if (!value) {
            setFormOriginadorDinercap(getFormOriginadorDinercap());
            setOriginadorDinercapSeleccionado(getModelOriginadorDinercap());
        }
    }

    const handleChangeFormOriginadorDinercap = fnHandleChangeText(formOriginadorDinercap, setFormOriginadorDinercap);

    const handleSubmitFormOriginadorDinercap = async (e) => {
        fnPrevent(e);
        const iIdCatOD = originadorDinercapSeleccionado.iIdCatOriginadorDinercap === undefined ? -1 : originadorDinercapSeleccionado.iIdCatOriginadorDinercap;
        const { txtCatalogo, txtDescripcion, txtIdCatalogoSolicitud, txtIdCatalogoOriginador } = formOriginadorDinercap;

        const form = {
            ...formOriginadorDinercap,
            txtCatalogo: {
                ...txtCatalogo,
                error: txtCatalogo.value === "",
            },
            txtDescripcion: {
                ...txtDescripcion,
                error: txtDescripcion.value === "",
            },
            txtIdCatalogoSolicitud: {
                ...txtIdCatalogoSolicitud,
                error: txtIdCatalogoSolicitud.value === "",
            },
            txtIdCatalogoOriginador: {
                ...txtIdCatalogoOriginador,
                error: txtIdCatalogoOriginador.value === "",
            },
        };

        setFormOriginadorDinercap(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }

        const entOriginadorDinercap = {
            iIdCatOriginadorDinercap: iIdCatOD,
            sCatalogo: txtCatalogo.value,
            sDescripcion: txtDescripcion.value,
            iIdCatSolicitudes: txtIdCatalogoSolicitud.value,
            iIdCatOriginador: txtIdCatalogoOriginador.value,
            iIDUsuarioCreacion: iIdCatOD === -1 ? 1 : 0,
            iIDUsuarioModificacion: iIdCatOD !== -1 ? 1 : 0,
            iIDUsuarioElimina: 0,
        };

        await fn_guardarOriginadorDinercap(entOriginadorDinercap);
    };

    const fn_guardarOriginadorDinercap = async (entOriginadorDinercap) => {
        let loader = "";
        if (entOriginadorDinercap.iIDUsuarioCreacion > 0) {
            loader = "Guardando el documento.";
        } else if (entOriginadorDinercap.iIDUsuarioModificacion > 0) {
            loader = "Actualizando el documento.";
        } else if (entOriginadorDinercap.iIDUsuarioElimina > 0) {
            loader = "Eliminando el documento.";
        }

        const payload = {
            loader: loader,
            body: entOriginadorDinercap,
            url: apiCSaveOriginadorDinercap,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            handleOpenFormOriginadorDinercap(false);
            setOriginadorDinercapSeleccionado(getModelOriginadorDinercap());
            setFormOriginadorDinercap(getFormOriginadorDinercap());
            await handleObtenerOriginadorDinercap();
        }
    };

    const handleActualizarOriginadorDinercap = (e, originadorDinercap) => {
        fnPrevent(e);
        setOriginadorDinercapSeleccionado(originadorDinercap);
        
        const { txtCatalogo, txtDescripcion, txtIdCatalogoSolicitud, txtIdCatalogoOriginador } = formOriginadorDinercap;

        const form = {
            ...formOriginadorDinercap,
            txtCatalogo: {
                ...txtCatalogo,
                value: originadorDinercap.sCatalogo,
            },
            txtDescripcion: {
                ...txtDescripcion,
                value: originadorDinercap.sDescripcion,
            },
            txtIdCatalogoSolicitud: {
                ...txtIdCatalogoSolicitud,
                value: originadorDinercap.iIdCatSolicitudes,
            },
            txtIdCatalogoOriginador: {
                ...txtIdCatalogoOriginador,
                value: originadorDinercap.iIdCatOriginador,
            },
        };

        setFormOriginadorDinercap(form);
        setOpenFormOriginadorDinercap(true);
    };

    const handleEliminarOriginadorDinercap = async (e, originadorDinercap) => {
        fnPrevent(e);
        Swal.fire({
            title: "Eliminar Originador Dinercap",
            text: "¿Está seguro de eliminar este registro?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await fn_eliminarOriginadorDinercap(originadorDinercap);
            }
        });
    };

    const fn_eliminarOriginadorDinercap = async (originadorDinercap) => {
        const entOriginadorDinercap = {
            iIdCatOriginadorDinercap: originadorDinercap.iIdCatOriginadorDinercap,
            iIDUsuarioElimina: 1,
            iIDUsuarioCreacion: 0,
            iIDUsuarioModificacion: 0,
        };
        await fn_guardarOriginadorDinercap(entOriginadorDinercap);
    };

    return (
        <OriginadorDinercapContext.Provider
            value={{
                openOriginadorDinercap,
                formOriginadorDinercap,
                listaOriginadorDinercap,
                openFormOriginadorDinercap,
                originadorDinercapSeleccionado,
                handleOpenOriginadorDinercap,
                handleObtenerOriginadorDinercap,
                handleOpenFormOriginadorDinercap,
                handleEliminarOriginadorDinercap,
                handleActualizarOriginadorDinercap,
                handleChangeFormOriginadorDinercap,
                handleSubmitFormOriginadorDinercap,
            }}
        >
            {props.children}
        </OriginadorDinercapContext.Provider>
    );

}

export function useOriginadorDinercap() {
    return useContext(OriginadorDinercapContext);
}
