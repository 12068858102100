import { createContext, useContext, useState } from "react";
import Enumerable from "linq";
import { useRequest } from "../RequestContext";
import { useLayout } from "../LayoutContext";
import { apiCGetAprobados, apiCGetAllAprobados, apiCSaveAprobado } from "../../settings/apiConfig";
import Swal from "sweetalert2";

import { getFormAprobado, getModelAprobado } from "../../models/AprobadoModel";
import { fnPrevent } from "../../settings/preventConfig";
import { fnHandleChangeText } from "../../settings/handleConfig";

export const AprobadosContext = createContext();

export function AprobadosProvider(props) {
    const { handleOpenAlert } = useLayout();
    const { Get, Post } = useRequest();
    
    //Aprobados
    const [openAprobados, setOpenAprobados] = useState(false);
    const [listaAprobados, setListaAprobados] = useState([]);
    const [formAprobado, setFormAprobado] = useState(getFormAprobado());
    const [aprobadoSeleccionado, setAprobadoSeleccionado] = useState(getModelAprobado());
    const [tipoSolicitudSeleccionado, setTipoSolicitudSeleccionado] = useState("");

    const [openFormAprobado, setOpenFormAprobado] = useState(false);

    const handleOpenAprobados = (value) => {
        setTipoSolicitudSeleccionado(0);
        setOpenAprobados(value);
    };

    const handleObtenerAprobados = async () => {

        const payload = {
            loader: "Consultando Aprobados",
            url: apiCGetAprobados.replace("{piIdTipoSolicitud}", tipoSolicitudSeleccionado),
            alert: true,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            if (response.result.length > 0) {
                const _listaAprobados = Enumerable.from(response.result)
                    .select((aprobado) => ({
                        iIdInfoSolicitudAprobada: aprobado.iIdInfoSolicitudAprobada,
                        iIdTipoSolicitud: aprobado.iIdTipoSolicitud,
                        sNombreTipoSolicitud: aprobado.entTipoSolicitud.sDescripcion,
                        sDescripcion: aprobado.sDescripcion,
                        iIdTipoDato: aprobado.iIdTipoDato,
                        sNombreTipoDato: aprobado.entInputType.sNombre,
                        iIdInputForms: aprobado.iIdInputForms,
                        sNombreInputForm: aprobado.entInputForm.sNombre,
                        jReglas: aprobado.jReglas,
                        iOrden: aprobado.iOrden,

                    }))
                    .toArray();
                setListaAprobados(_listaAprobados);

            } else {
                handleOpenAlert("No se encontró información","success");
            }
        }
    };

    const fnObtenerTipoDeSolicitudes = async () => {
        const payload = {
            loader: "Consultando lista de tipo de solicitudes...",
            url: apiCGetAllAprobados,
            alert: false,
        };

        const response = await Get(payload);
        if (response.code === 201) {
            if (response.result.length > 0) {
                const _listaAprobados = Enumerable.from(response.result)
                    .select((aprobado) => ({
                        iIdInfoSolicitudAprobada: aprobado.iIdInfoSolicitudAprobada,
                        iIdTipoSolicitud: aprobado.iIdTipoSolicitud,
                        sNombreTipoSolicitud: aprobado.entTipoSolicitud.sDescripcion,
                        sDescripcion: aprobado.sDescripcion,
                        iIdTipoDato: aprobado.iIdTipoDato,
                        sNombreTipoDato: aprobado.entInputType.sNombre,
                        iIdInputForms: aprobado.iIdInputForms,
                        sNombreInputForm: aprobado.entInputForm.sNombre,
                        jReglas: aprobado.jReglas,
                        iOrden: aprobado.iOrden,

                    }))
                    .toArray();
                setListaAprobados(_listaAprobados);

            } else {
                handleOpenAlert("Error al obtener las Aprobados", "error");
            }
        }
    };

    const handleOnChangeTipoSolicitud = (event) => {
        const { value } = event.target;
        setTipoSolicitudSeleccionado(value);
    }

    const handleOpenFormAprobado = (value) => {
        const { cmbBoxIdTipoSolicitud } = formAprobado;

        if(tipoSolicitudSeleccionado !== 0){
            let form = {
                ...formAprobado,
                cmbBoxIdTipoSolicitud: {
                    ...cmbBoxIdTipoSolicitud,
                    value: tipoSolicitudSeleccionado,
                },
            };

            setFormAprobado(form);
            setOpenFormAprobado(value);

            if (!value) {
                setFormAprobado(getFormAprobado());
                setAprobadoSeleccionado(getModelAprobado());
            }
        }
        else
        {
            Swal.fire({
                title: "Tipo de solicitud",
                text: "Seleccionar un valor en el filtro de tipo solicitud.",
                icon: "warning",
            });
        }        
    }

    const handleChangeFormAprobado = fnHandleChangeText(formAprobado, setFormAprobado);

    const handleSubmitFormAprobado = async (e) => {
        fnPrevent(e);
        const iIdAprobado = aprobadoSeleccionado.iIdInfoSolicitudAprobada === undefined ? -1 : aprobadoSeleccionado.iIdInfoSolicitudAprobada;
        const { cmbBoxIdTipoSolicitud, txtDescripcion, cmbBoxIdTipoDato, cmbBoxIdInputForms, txtReglas, txtOrden } = formAprobado;
        
        const form = {
            ...formAprobado,
            cmbBoxIdTipoSolicitud: {
                ...cmbBoxIdTipoSolicitud,
                error: cmbBoxIdTipoSolicitud.value === "",
            },
            txtDescripcion: {
                ...txtDescripcion,
                error: txtDescripcion.value === "",
            },
            cmbBoxIdTipoDato: {
                ...cmbBoxIdTipoDato,
                error: cmbBoxIdTipoDato.value === "",
            },
            cmbBoxIdInputForms: {
                ...cmbBoxIdInputForms,
                error: cmbBoxIdInputForms.value === "",
            },
            txtReglas: {
                ...txtReglas,
                error: txtReglas.value === "",
            },
            txtOrden: {
                ...txtOrden,
                error: txtOrden.value === "",
            },

        };

        setFormAprobado(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }

        const entAprobado = {
            iIdInfoSolicitudAprobada: iIdAprobado,
            iIdTipoSolicitud: cmbBoxIdTipoSolicitud.value,
            sDescripcion: txtDescripcion.value,
            iIdTipoDato: cmbBoxIdTipoDato.value,
            iIdInputForms: cmbBoxIdInputForms.value,
            jReglas: txtReglas.value,
            iOrden: txtOrden.value,
            iIDUsuarioCreacion: iIdAprobado === -1 ? 1 : 0,
            iIDUsuarioModificacion: iIdAprobado !== -1 ? 1 : 0,
            iIDUsuarioElimina: 0,
        };

        cmbBoxIdTipoSolicitud.value = tipoSolicitudSeleccionado;

        await fn_guardarAprobado(entAprobado);
    };

    const fn_guardarAprobado = async (entAprobado) => {
        let loader = "";
        if (entAprobado.iIDUsuarioCreacion > 0) {
            loader = "Guardando la aprobacion.";
        } else if (entAprobado.iIDUsuarioModificacion > 0) {
            loader = "Actualizando la aprobacion.";
        } else if (entAprobado.iIDUsuarioElimina > 0) {
            loader = "Eliminando la aprobacion.";
        }

        const payload = {
            loader: loader,
            body: entAprobado,
            url: apiCSaveAprobado,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            handleOpenFormAprobado(false);
            setAprobadoSeleccionado(getModelAprobado());
            setFormAprobado(getFormAprobado());
            await handleObtenerAprobados();
        }
    };

    const handleActualizarAprobado = (e, aprobado) => {
        fnPrevent(e);
        setAprobadoSeleccionado(aprobado);

        const { cmbBoxIdTipoSolicitud, txtDescripcion, cmbBoxIdTipoDato, cmbBoxIdInputForms, txtReglas, txtOrden } = formAprobado;

        const form = {
            ...formAprobado,
            cmbBoxIdTipoSolicitud: {
                ...cmbBoxIdTipoSolicitud,
                value: aprobado.iIdTipoSolicitud,
            },
            txtDescripcion: {
                ...txtDescripcion,
                value: aprobado.sDescripcion,
            },
            cmbBoxIdTipoDato: {
                ...cmbBoxIdTipoDato,
                value: aprobado.iIdTipoDato,
            },
            cmbBoxIdInputForms: {
                ...cmbBoxIdInputForms,
                value: aprobado.iIdInputForms,
            },
            txtReglas: {
                ...txtReglas,
                value: aprobado.jReglas,
            },
            txtOrden: {
                ...txtOrden,
                value: aprobado.iOrden,
            },

        };

        setFormAprobado(form);
        setOpenFormAprobado(true);
    };

    const handleEliminarAprobado = async (e, aprobado) => {
        fnPrevent(e);
        Swal.fire({
            title: "Eliminar Aprobado",
            text: "¿Está seguro de eliminar la Aprobado " + aprobado.sDescripcion + "?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await fn_eliminarAprobado(aprobado);
            }
        });
    };

    const fn_eliminarAprobado = async (aprobado) => {
        const entAprobado = {
            iIdInfoSolicitudAprobada: aprobado.iIdInfoSolicitudAprobada,
            iIDUsuarioElimina: 1,
            iIDUsuarioCreacion: 0,
            iIDUsuarioModificacion: 0,
        };

        await fn_guardarAprobado(entAprobado);
    };

    const handleListarTipoSolicitudes = () => {
        fnObtenerTipoDeSolicitudes();
    };

    return (
        <AprobadosContext.Provider
            value={{
                formAprobado,
                openAprobados,
                listaAprobados,
                openFormAprobado,
                aprobadoSeleccionado,
                tipoSolicitudSeleccionado,
                handleOpenAprobados,
                handleObtenerAprobados,
                handleOpenFormAprobado,
                handleEliminarAprobado,
                handleActualizarAprobado,
                handleChangeFormAprobado,
                handleSubmitFormAprobado,
                handleOnChangeTipoSolicitud,
                handleListarTipoSolicitudes,
            }}
        >
            {props.children}
        </AprobadosContext.Provider>
    );

}

export function useAprobados() {
    return useContext(AprobadosContext);
}
