export const getFormLogin = () => ({
    txtUsuarioDinercap: {
        label: "Usuario:",
        value: "",
        required: true,
        error: false,
        message: "",
    },
    txtPasswordDinercap: {
        label: "Contraseña:",
        value: "",
        required: true,
        error: false,
        message: "",
    },
});

export const getFormRecuperar = () => ({
    txtRecuperarDinercap: {
        label: "Correo de recuperación:",
        required: true,
        value: "",
        error: false,
        message: "",
    },
});

export const getFormCambiarPass = () => ({
    txtNuevoPasswordDinercap: {
        label: "Nueva contraseña:",
        value: "",
        error: false,
        message: "La contraseña es requerida",
    },
    txtConfirmarPasswordDinercap: {
        label: "Confirmar contraseña:",
        required: true,
        value: "",
        error: false,
        message: "Confirme la nueva contraseña",
    },
});
