export function fnPrevent(e) {
    if (e !== undefined) {
        if (typeof e.preventDefault === "function") {
            e.preventDefault();
        }
    }
}

export function fnPreventBlur() {
    if (typeof document.activeElement.blur === "function") {
        document.activeElement.blur();
    }
}

export function fnEmpty() {}
