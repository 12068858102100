import React, { useEffect, useState } from "react";
import { Box, CircularProgress, FormHelperText, Grid, FormControl, InputLabel, List, ListItemText, MenuItem, Paper, Select, TextField, Typography, } from "@mui/material";
import { withStyles } from "tss-react/mui";
import InputBase from "@mui/material/InputBase";
import axios from "axios";
import _ from 'lodash';

import { useConfiguracionSolicitud } from "../../../../context/ConfiguracionSolicitudContext";
import { useOriginadorDinercap } from "../../../../context/catalogos_generales/OriginadorDinercapContext";
import { useTipoSolicitud } from "../../../../context/TipoSolicitudContext";
import { inputColor, inputVariant } from "../../../../settings/inputConfig";
import DinercapModal from "../../../utils/DinercapModal";
import { list } from "postcss";

export default function FormOriginadorDinercap() {
    const {
        formOriginadorDinercap,
        openFormOriginadorDinercap,
        originadorDinercapSeleccionado,
        handleOpenFormOriginadorDinercap,
        handleSubmitFormOriginadorDinercap,
        handleChangeFormOriginadorDinercap,
    } = useOriginadorDinercap();

    const {
        listaTiposSolicitud,
        handleClickObtenerTiposSolicitud,
    } = useTipoSolicitud();

    const {
        listaBloquesSolicitud,
        handleClickObtenerBloquesSolicitud,
    } = useConfiguracionSolicitud();

    const { txtCatalogo, txtDescripcion, txtIdCatalogoSolicitud, txtIdCatalogoOriginador, cmbBoxBloques, cmbBoxCampos } = formOriginadorDinercap;

    const [listaCamposjFuenteDatos, setListaCamposjFuenteDatos] = useState([]);
    const [sFuenteDatos, setSFuenteDatos] = useState("");

    const CustomSelect = withStyles(InputBase, (theme) => ({
        input: {
            border: "0px",
            paddingTop: "0px",
            paddingBottom: "0px",
            paddingLeft: "8px",
            paddingRight: "4px",
            marginTop: theme.spacing(3),
            "&:focus": {
                borderRadius: 4,
                boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
            },
        },
    }));

    useEffect(() => {
        handleClickObtenerTiposSolicitud();
    }, []);


    useEffect(() => {
        if (cmbBoxBloques.value) {
            handleClickObtenerBloquesSolicitud(cmbBoxBloques.value);
        } else {
            handleClickObtenerBloquesSolicitud(-1);
        }
    }, [cmbBoxBloques.value]);

    useEffect(() => {
        if (listaBloquesSolicitud !== []){
            const _listaCamposjFuenteDatos = []
            listaBloquesSolicitud.map((bloqueSolicitud) => {
                bloqueSolicitud.aCampos.map((campoBloque) => {
                    if (campoBloque.sFuenteDeDato !== "") {
                        _listaCamposjFuenteDatos.push(campoBloque)
                    }
                })
            })
            setListaCamposjFuenteDatos(_listaCamposjFuenteDatos);
        }
    }, [listaBloquesSolicitud]);

    useEffect(() => {
        _.map(listaCamposjFuenteDatos, function(o) {
            if (o.iIDBloqueSolicitudCampos === cmbBoxCampos.value)
                setSFuenteDatos(o)
        })
    }, [cmbBoxCampos.value])

    return (
        <DinercapModal
            title={
                originadorDinercapSeleccionado.iIdCatOriginadorDinercap === -1 ? "Agregar Originador Dinercap" : "Actualizar Originador Dinercap"
            }
            okFunc={handleSubmitFormOriginadorDinercap}
            setOpen={handleOpenFormOriginadorDinercap}
            open={openFormOriginadorDinercap}
            okMessage="Guardar"
            size="normal"
        >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined" error={cmbBoxBloques.error}>
                        <InputLabel id="demo-simple-select-error-label">{cmbBoxBloques.label}</InputLabel>
                        <Select
                            labelId="demo-simple-select-error-label"
                            id={cmbBoxBloques.name}
                            name={cmbBoxBloques.name}
                            label={cmbBoxBloques.label}
                            required={cmbBoxBloques.required}
                            error={cmbBoxBloques.error}
                            value={cmbBoxBloques.value}
                            onChange={handleChangeFormOriginadorDinercap}
                        >
                            <MenuItem value="">
                                <em>Seleccione el Tipo de Solicitud</em>
                            </MenuItem>
                            {listaTiposSolicitud.map((tiposSolicitud) => {
                                    return (
                                        <MenuItem
                                            key={tiposSolicitud.iIDTipoSolicitud}
                                            value={tiposSolicitud.iIDTipoSolicitud}
                                        >
                                            {tiposSolicitud.sNombre}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                        <FormHelperText>{cmbBoxBloques.error && cmbBoxBloques.message}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="demo-simple-select-error-label">{cmbBoxCampos.label}</InputLabel>
                        <Select
                            labelId="demo-simple-select-error-label"
                            id={cmbBoxCampos.name}
                            name={cmbBoxCampos.name}
                            label={cmbBoxCampos.label}
                            value={cmbBoxCampos.value}
                            onChange={handleChangeFormOriginadorDinercap}
                            >
                            <MenuItem value="">
                                <em>Seleccione el Campo con Fuente de Datos</em>
                            </MenuItem>
                                {listaCamposjFuenteDatos.map((campos) => {
                                    return (
                                        <MenuItem
                                            key={campos.iIDBloqueSolicitudCampos}
                                            value={campos.iIDBloqueSolicitudCampos}
                                        >
                                            {campos.sNombre}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                        <FormHelperText>{cmbBoxCampos.error && cmbBoxCampos.message}</FormHelperText>
                    </FormControl>
                    <Typography
                        id="basic-list-demo"
                        level="body3"
                        textTransform="uppercase"
                        fontWeight="lg"
                    >
                        {sFuenteDatos.sFuenteDeDato}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name={txtCatalogo.name}
                        label={txtCatalogo.label}
                        required={txtCatalogo.required}
                        value={txtCatalogo.value}
                        error={txtCatalogo.error}
                        helperText={txtCatalogo.error && txtCatalogo.message}
                        onChange={handleChangeFormOriginadorDinercap}
                        variant={inputVariant}
                        color={inputColor}
                        autoComplete="off"
                        fullWidth
                        input={<CustomSelect />}
                        className="mb-2"
                        placeholder={txtCatalogo.placeholder}
                    />
                    <TextField
                        name={txtDescripcion.name}
                        label={txtDescripcion.label}
                        required={txtDescripcion.required}
                        value={txtDescripcion.value}
                        error={txtDescripcion.error}
                        helperText={txtDescripcion.error && txtDescripcion.message}
                        onChange={handleChangeFormOriginadorDinercap}
                        variant={inputVariant}
                        color={inputColor}
                        autoComplete="off"
                        fullWidth
                        input={<CustomSelect />}
                        className="mb-2"
                        placeholder={txtDescripcion.placeholder}
                    />
                    <TextField
                        type="number"
                        name={txtIdCatalogoSolicitud.name}
                        label={txtIdCatalogoSolicitud.label}
                        required={txtIdCatalogoSolicitud.required}
                        value={txtIdCatalogoSolicitud.value}
                        error={txtIdCatalogoSolicitud.error}
                        helperText={txtIdCatalogoSolicitud.error && txtIdCatalogoSolicitud.message}
                        onChange={handleChangeFormOriginadorDinercap}
                        variant={inputVariant}
                        color={inputColor}
                        autoComplete="off"
                        fullWidth
                        input={<CustomSelect />}
                        className="mb-2"
                        placeholder={txtIdCatalogoSolicitud.placeholder}
                    />
                    <TextField
                        type="number"
                        name={txtIdCatalogoOriginador.name}
                        label={txtIdCatalogoOriginador.label}
                        required={txtIdCatalogoOriginador.required}
                        value={txtIdCatalogoOriginador.value}
                        error={txtIdCatalogoOriginador.error}
                        helperText={txtIdCatalogoOriginador.error && txtIdCatalogoOriginador.message}
                        onChange={handleChangeFormOriginadorDinercap}
                        variant={inputVariant}
                        color={inputColor}
                        autoComplete="off"
                        fullWidth
                        input={<CustomSelect />}
                        className="mb-2"
                        placeholder={txtIdCatalogoOriginador.placeholder}
                    />
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
