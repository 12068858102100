import { createContext, useContext, useState } from "react";
import Enumerable from "linq";
import { useRequest } from "../RequestContext";
import { useLayout } from "../LayoutContext";
import { apiInputTypeGetAll, apiCGetReglasOperadoresAll, apiCSaveReglasOperadores } from "../../settings/apiConfig";
import Swal from "sweetalert2";
import { getFormCatReglasOperadores, getModelCatReglasOperadores } from "../../models/CatalogosModel";
import { fnPrevent } from "../../settings/preventConfig";
import { fnHandleChangeText } from "../../settings/handleConfig";
import { CleaningServices } from "@mui/icons-material";

export const ReglasOperadoresContext = createContext();

export function ReglasOperadoresProvider(props) {
    const { handleOpenAlert } = useLayout();
    const { Get, Post } = useRequest();

    //Catalogo
    const [openCatReglasOpe, setOpenCatReglasOpe] = useState(false);
    const [listaReglasOperadores, setListaReglasOperadores] = useState([]);
    const [listaTipoDatos, setListaTipoDatos] = useState([]);
    const [listaOperadores, setListaOperadores] = useState([]);
    const [formReglasOperadores, setFormReglasOperadores] = useState(getFormCatReglasOperadores());
    const [reglaOpeSeleccionado, setReglaOpeSeleccionado] = useState(getModelCatReglasOperadores());
    const [validacionSeleccionado, setValidacionSeleccionado] = useState("");
    const [validacionSelect, setValidacionSelect] = useState("");
    const [tipoDatoSeleccionado, setTipoDatoSeleccionado] = useState("");
    const [tipoDatoAnterior, setTipoDatoAnterior] = useState("");
    const [operadorSeleccionado, setOperadorSeleccionado] = useState("");

    // Form
    const [openFormReglasOperadores, setOpenFormReglasOperadores] = useState(false);

    //#region Catalogo
    const handleOpenCatReglasOpe = (value) => {
        const { cmbBoxValidacion } = formReglasOperadores;
        setValidacionSeleccionado(0);
        cmbBoxValidacion.value = 0;
        setOpenCatReglasOpe(value);
        if(value)
        {
            handleObtenerReglasOperadores();
        }
        else
        {
            setListaReglasOperadores([]);
        }
    };

    const handleObtenerReglasOperadores = async () => {
        if(listaTipoDatos.length == 0){
            handleListarTipoDatos();
        }
        const { cmbBoxValidacion } = formReglasOperadores;
        const payload = {
            loader: "Consultando reglas y operadores...",
            url: apiCGetReglasOperadoresAll.replace("{psNombreValidacion}", cmbBoxValidacion.value),
            alert: true,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            if (response.result.length > 0) {
                const listaReglasOpe = Enumerable.from(response.result)
                    .select((reglaope) => ({
                        sNombreValidacion: reglaope.sNombreValidacion,
                        iIDTipoDato:reglaope.iIDTipoDato,
                        sTipoDato: reglaope.sTipoDato,
                        iIDOperador: reglaope.iIDOperador,
                        sOperador: reglaope.sOperador,
                    }))
                    .toArray();
                setListaReglasOperadores(listaReglasOpe);
            } else {
                handleOpenAlert("Error al obtener las reglas y operadores", "error");
            }
        }
    };

    //#endregion Catalogo

    //#region formulario
    const fnObtenerTipoDeDatos = async () => {
        const payload = {
            loader: "Consultando lista de tipo de datos...",
            url: apiInputTypeGetAll,
            alert: false,
        };

        const response = await Get(payload);
        if (response.code === 201) {
            setListaTipoDatos(response.result);
        } else {
            setListaTipoDatos([]);
        }
    };

    const handleListarTipoDatos = () => {
        fnObtenerTipoDeDatos();
    };

    const handleOpenFormReglasOperador = (value) => {
        setOpenFormReglasOperadores(value);
        if (!value) {
            setFormReglasOperadores(getFormCatReglasOperadores());
            setReglaOpeSeleccionado(getModelCatReglasOperadores());
            setValidacionSelect("");
            setTipoDatoSeleccionado("");
            setOperadorSeleccionado("");
        }
    };

    const handleChangeSelect = (event) => {
       const { cmbBoxValidacion } = formReglasOperadores;
       const { value } = event.target;
       setValidacionSeleccionado(value);
       cmbBoxValidacion.value = value;
       handleObtenerReglasOperadores();
    }

    const handleOnChangeValidacion= (event) => {
        const { slValidacion } = formReglasOperadores;
        const { value } = event.target;
        setValidacionSelect(value);
        slValidacion.value = value;
    }


    const handleOnChangeTipoSolicitud = (event) => {
        const { slTipoDato } = formReglasOperadores;
        const { value } = event.target;
        setTipoDatoSeleccionado(value);
        slTipoDato.value = value;

        var tipodato = listaTipoDatos.find(x => x.iIDTipoDato == value);

        if(tipodato.aOperadores.length > 0){
            setListaOperadores(tipodato.aOperadores);
        }
        else
        {
            setListaOperadores([]);
        }
    }

    const handleOnChangeOperador = (event) => {
        const { sOperador } = formReglasOperadores;
        const { value } = event.target;
        setOperadorSeleccionado(value);
        sOperador.value = value;
    }

    const handleSubmitFormReglaOpe= async (e) => {
        fnPrevent(e);

        const id = reglaOpeSeleccionado.id === undefined ? -1 : reglaOpeSeleccionado.id;

        const { slValidacion, slTipoDato, slOperador } = formReglasOperadores;

        slValidacion.value = validacionSelect;
        slTipoDato.value = tipoDatoSeleccionado;
        slOperador.value = operadorSeleccionado;

        const form = {
            ...formReglasOperadores,
            slValidacion: {
                ...slValidacion,
                error: slValidacion.value === "",
            },
            slTipoDato: {
                ...slTipoDato,
                error: slTipoDato.value === "",
            },
            slOperador: {
                ...slOperador,
                error: slOperador.value === "",
            },
        };

        setFormReglasOperadores(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }

        const entReglasOpe = {
            sNombreValidacion: validacionSelect,
            iIDTipoDato: tipoDatoSeleccionado,
            iIDTipoDatoAnt: tipoDatoAnterior,
            iIDUsuarioCreacion: id === -1 ? 1 : 0,
            iIDUsuarioModificacion: id !== -1 ? 1 : 0,
            iIDUsuarioElimina: 0,
        };

        await fn_guardarReglasOpe(entReglasOpe);
    };

    const fn_guardarReglasOpe = async (entReglasOpe) => {
        let loader = "";
        if (entReglasOpe.iIDUsuarioCreacion > 0) {
            loader = "Guardando la configuración.";
        } else if (entReglasOpe.iIDUsuarioModificacion > 0) {
            loader = "Actualizando la configuración.";
        } else if (entReglasOpe.iIDUsuarioElimina > 0) {
            loader = "Eliminando la configuración.";
        }

        const payload = {
            loader: loader,
            body: entReglasOpe,
            url: apiCSaveReglasOperadores,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            handleOpenFormReglasOperador(false);
            setReglaOpeSeleccionado(getModelCatReglasOperadores());
            setOpenFormReglasOperadores(getFormCatReglasOperadores());
            await handleObtenerReglasOperadores();
        }
    };

    const handleActualizarReglaOperador = (e, reglaope) => {
        fnPrevent(e);
        setReglaOpeSeleccionado(reglaope);

        var tipodato = listaTipoDatos.find(x => x.sNombre == reglaope.sTipoDato);
        setTipoDatoAnterior(tipodato.iIDTipoDato);

        if(tipodato.aOperadores.length > 0){
            var operador = tipodato.aOperadores.find(x => x.sOperador == reglaope.sOperador);
            setListaOperadores(tipodato.aOperadores);
        }
        else
        {
            setListaOperadores([]);
        }
        
        setValidacionSelect(reglaope.sNombreValidacion);
        setTipoDatoSeleccionado(tipodato.iIDTipoDato);
        setOperadorSeleccionado(tipodato.aOperadores.length > 0 ? operador.iIdOperador : 0);
        
        setOpenFormReglasOperadores(true);
    };
    // #endregion

    return (
        <ReglasOperadoresContext.Provider
            value={{
                openCatReglasOpe,
                handleOpenCatReglasOpe,
                formReglasOperadores,
                listaReglasOperadores,
                handleObtenerReglasOperadores,
                listaTipoDatos,
                listaOperadores,
                handleListarTipoDatos,
                openFormReglasOperadores,
                handleOpenFormReglasOperador,
                reglaOpeSeleccionado,
                handleSubmitFormReglaOpe,
                handleActualizarReglaOperador,
                tipoDatoSeleccionado,
                operadorSeleccionado,
                handleOnChangeTipoSolicitud,
                handleOnChangeOperador,
                validacionSeleccionado,
                handleChangeSelect,
                validacionSelect,
                handleOnChangeValidacion,
            }}
        >
            {props.children}
        </ReglasOperadoresContext.Provider>
    );
}

export function useReglasOpeContext() {
    return useContext(ReglasOperadoresContext);
}
