import React, { Fragment, useState, useEffect } from "react";
import Swal from "sweetalert2";
import { makeStyles } from "tss-react/mui";
import { useZonasGeograficas } from "../../../../context/catalogos_generales/ZonasGeograficasContext";
import { Dialog, Slide, Box, AppBar, Typography, IconButton, Toolbar, Button, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IoAdd } from "react-icons/io5";
import Enumerable from "linq";
import FormZonaGeografica from "./FormZonaGeografica";
import DinercapTableHandle from "../../../utils/DinercapTableHandle";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles()((theme) => {
    return {
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        container: {
            maxHeight: 800,
        },
        root: {
            width: "100%",
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    };
});

export default function ZonasGeograficasModal() {
    const {
        openZonas,
        handleOpenZonas,
        handleObtenerGeocercas,
        listaZonas,
        openFormZona,
        handleOpenFormZona,
        handleUpdateForm,
        handleEliminarZonaGeografica,
    } = useZonasGeograficas();
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({
        iIDGeocerca: -1,
    });

    const crearData = () => {
        const listGeo = Enumerable.from(listaZonas)
            .select((item, index) => ({
                id: index + 1,
                iIDGeocerca: item.iIDGeocerca,
                sDescripcion: item.sDescripcion,
                iIDTipoArea: item.iIDTipoArea,
                sNombreArea: item.sNombreArea,
                paths: item.paths,
            }))
            .toArray();

        setData(listGeo);
    };

    const columnas = [
        { field: "id", title: "#", width: 50 },
        { field: "sDescripcion", title: "DESCRIPCION" },
        { field: "sNombreArea", title: "ÁREA" },
    ];

    const onDelete = (e, row) => {
        Swal.fire({
            title: "Eliminar zona geográfica",
            text: "¿Está seguro de eliminar la zona geográfica?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await handleEliminarZonaGeografica(row.iIDGeocerca);
            }
        });
    };

    const onUpdate = (e, row) => {
        handleUpdateForm(row);
    };

    useEffect(() => {
        handleObtenerGeocercas();
    }, []);

    useEffect(() => {
        crearData();
    }, [listaZonas]);

    return (
        <Fragment>
            <Dialog fullScreen open={openZonas} TransitionComponent={Transition}>
                <Box sx={{ flexFlow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title} component="div" sx={{ flexGrow: 1 }}>
                                Catálogo zonas geográficas
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => handleOpenZonas(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
                <div className="flex flex-col gap-2 p-4">
                    <div className="flex flex-row justify-between items-center flex-wrap ml-2 mt-2 mr-2 ">
                        <div></div>
                        <div>
                            <Button
                                className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={(e) => handleOpenFormZona(true)}
                            >
                                <IoAdd className="text-xl mr-2" />
                                Crear
                            </Button>
                        </div>
                    </div>
                    <Box
                        component="div"
                        sx={{
                            width: "100%",
                            "& .odd": {
                                bgcolor: "rgba(0, 0, 0, 0.05)",
                            },
                            "& .even": {
                                bgcolor: "#ffffff",
                            },
                        }}
                    >
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                            <Grid item xs={12} md={8}>
                                <DinercapTableHandle
                                    id="iIDGeocerca"
                                    data={data}
                                    columns={columnas}
                                    selectedRow={selectedRow}
                                    setRow={setSelectedRow}
                                    onDelete={onDelete}
                                    onUpdate={onUpdate}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Dialog>
            {openFormZona && <FormZonaGeografica />}
        </Fragment>
    );
}
