export const getModelAprobado = () => ({
    id: -1,
    iIdInfoSolicitudAprobada: -1,
    iIdTipoSolicitud: -1,
    sNombreTipoSolicitud: "",
    sDescripcion: "",
    iIdTipoDato: -1,
    sNombreTipoDato: "",
    iIdInputForms: -1,
    sNombreInputForm: "",
    jReglas: "",
    iOrden: -1,

});

export const getFormAprobado = () => ({
    cmbBoxIdTipoSolicitud: {
        label: "Tipo de Solicitud:",
        name: "cmbBoxIdTipoSolicitud",
        required: true,
        value: "",
        error: false,
        message: "El Tipo de Solicitud es requerido",
        placeholder: "",
    },
    txtDescripcion: {
        label: "Descripcion:",
        name: "txtDescripcion",
        required: true,
        value: "",
        error: false,
        message: "La Descripcion es requerida",
        placeholder: "",
    },
    cmbBoxIdTipoDato: {
        label: "Tipo de Dato:",
        name: "cmbBoxIdTipoDato",
        required: true,
        value: "",
        error: false,
        message: "El Tipo de Dato es requerido",
        placeholder: "",
    },
    cmbBoxIdInputForms: {
        label: "Input Forms:",
        name: "cmbBoxIdInputForms",
        required: true,
        value: "",
        error: false,
        message: "El Input Forms es requerido",
        placeholder: "",
    },
    txtReglas: {
        label: "Reglas:",
        name: "txtReglas",
        required: true,
        value: "",
        error: false,
        message: "Las Reglas son requeridas",
        placeholder: "",
    },
    txtOrden: {
        label: "Orden:",
        name: "txtOrden",
        required: true,
        value: "",
        error: false,
        message: "El Orden es requerido",
        placeholder: "",
    },
});
