export const EnumPerfilesPrincipales = {
    Superadministrador: 1,
    Administrador: 2,
    DoctorCallCenter: 3,
    DoctorEspecialista: 4,
    AdministradorEspecialiesta: 5,
    DoctorAdministrador: 6,
};

export const EnumIdiomas = {
    es: "Español",
    en: "Ingles",
};

export const Perfiles = {
    Admin: 1,
    Usuario: 0
}