export const getModelCatalogoFinanciera = () => ({
    id: -1,
    iIdFinanciera: -1,
    sNombre: "",
    entBuro: "",
});

export const getFormCatalogoFinanciera = () => ({
    txtNombreFinanciera: {
        label: "Nombre Financiera:",
        name: "txtNombreFinanciera",
        required: true,
        value: "",
        error: false,
        message: "El nombre de la financiera es requerido",
        placeholder: "Ej. financiera.xml",
        maxlength: 50,
    },
    cmbBoxIdBuro: {
        label: "Buro:",
        name: "cmbBoxIdBuro",
        required: true,
        value: "",
        error: false,
        message: "El buro es requerido",
        placeholder: "",
    },
    txtUsuBuroCred: {
        label: "Usuario de consulta de crédito:",
        name: "txtUsuBuroCred",
        required: true,
        value: "",
        error: false,
        message: "El usuario de consulta de crédito es requerido",
        placeholder: "",
        maxlength: 70,
    },
    txtPwdBuroCred: {
        label: "Contraseña de consulta de crédito:",
        name: "txtPwdBuroCred",
        required: true,
        value: "",
        error: false,
        message: "La contraseña de consulta de crédito es requerida",
        placeholder: "",
        maxlength: 70,
    },
});
