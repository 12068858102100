import { FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { inputColor, inputVariant } from "../../../settings/inputConfig";

import DinercapModal from "../../utils/DinercapModal";
import { useConfiguracionSolicitud } from "../../../context/ConfiguracionSolicitudContext";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCamera,
    faHome,
    faHandHoldingUsd,
    faInfoCircle,
    faPeopleArrows,
    faBriefcase,
} from "@fortawesome/free-solid-svg-icons";

export default function FormBloqueSolicitud() {
    const {
        bloqueSolicitudSeleccionado,
        modalOpenFormBloqueSolicitud,
        formBloqueSolicitud,
        handleOpenFormBloqueSolicitud,
        handleChangeFormBloqueSolicitud,
        handleChangeFormBloqueSolicitudCheck,
        handleSubmitFormBloqueSolicitud,
    } = useConfiguracionSolicitud();

    const { txtNombreBloque, txtDescripcionBloque, slIcono, swGrupos } = formBloqueSolicitud;

    const { iIDBloqueSolicitud } = bloqueSolicitudSeleccionado;

    const handleChangeCheck = (e) => {
        if (iIDBloqueSolicitud === 0 || iIDBloqueSolicitud === undefined) {
            handleChangeFormBloqueSolicitudCheck(e);
        } else {
            if (swGrupos.value) {
                e.preventDefault();
                swal({
                    title: "Grupo",
                    text: "No puede modificar grupo activado!",
                    icon: "error",
                });
            } else {
                handleChangeFormBloqueSolicitudCheck(e);
            }
        }
    };

    return (
        <DinercapModal
            title={iIDBloqueSolicitud === 0 || iIDBloqueSolicitud === undefined ? "Nuevo bloque" : "Editar bloque"}
            okFunc={handleSubmitFormBloqueSolicitud}
            setOpen={handleOpenFormBloqueSolicitud}
            open={modalOpenFormBloqueSolicitud}
            okMessage="Guardar"
            size="small"
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        name="txtNombreBloque"
                        label={txtNombreBloque.label}
                        required={txtNombreBloque.required}
                        value={txtNombreBloque.value}
                        error={txtNombreBloque.error}
                        helperText={txtNombreBloque.error && txtNombreBloque.message}
                        onChange={handleChangeFormBloqueSolicitud}
                        variant={inputVariant}
                        color={inputColor}
                        autoComplete="off"
                        autoFocus
                        fullWidth
                        className="mb-2"
                        inputProps={{
                            maxLength: txtNombreBloque.maxlength,
                        }}
                    />
                    <TextField
                        name="txtDescripcionBloque"
                        label={txtDescripcionBloque.label}
                        required={txtDescripcionBloque.required}
                        value={txtDescripcionBloque.value}
                        error={txtDescripcionBloque.error}
                        helperText={txtDescripcionBloque.error && txtDescripcionBloque.message}
                        onChange={handleChangeFormBloqueSolicitud}
                        variant={inputVariant}
                        color={inputColor}
                        autoComplete="off"
                        fullWidth
                        className="mb-2"
                        inputProps={{
                            maxLength: txtDescripcionBloque.maxlength,
                        }}
                    />
                    <InputLabel id="lblSlIcono">Icono:</InputLabel>
                    <Select
                        labelId="lblSlIcono"
                        id="slIcono"
                        name="slIcono"
                        label={slIcono.label}
                        required={slIcono.required}
                        value={slIcono.value}
                        error={slIcono.error}
                        onChange={handleChangeFormBloqueSolicitud}
                        fullWidth
                        defaultValue="0"
                    >
                        <MenuItem value="0">
                            <em>Seleccionar</em>
                        </MenuItem>
                        <MenuItem value="camera;f030">
                            <FontAwesomeIcon icon={faCamera} size="2x" className="mr-1" />
                            f030
                        </MenuItem>
                        <MenuItem value="home;f015">
                            <FontAwesomeIcon icon={faHome} size="2x" className="mr-1" />
                            f015
                        </MenuItem>
                        <MenuItem value="hand-holding-usd;f4c0">
                            <FontAwesomeIcon icon={faHandHoldingUsd} size="2x" className="mr-1" />
                            f4c0
                        </MenuItem>
                        <MenuItem value="info-circle;f05a">
                            <FontAwesomeIcon icon={faInfoCircle} size="2x" className="mr-1" />
                            f05a
                        </MenuItem>
                        <MenuItem value="people-arrows;e068">
                            <FontAwesomeIcon icon={faPeopleArrows} size="2x" className="mr-1" />
                            e068
                        </MenuItem>
                        <MenuItem value="briefcase;f0b1">
                            <FontAwesomeIcon icon={faBriefcase} size="2x" className="mr-1" />
                            f0b1
                        </MenuItem>
                    </Select>
                    <FormHelperText error={slIcono.error}>{slIcono.error && slIcono.message}</FormHelperText>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={swGrupos.value}
                                name="swGrupos"
                                label={swGrupos.label}
                                required={swGrupos.required}
                                error={swGrupos.error}
                                onChange={handleChangeCheck}
                                color="primary"
                                inputProps={{ "aria-label": "primary checkbox" }}
                            />
                        }
                        label="Usa grupos"
                    />
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
