import { Fragment, useEffect, useState } from "react";

import Button from "@mui/material/Button";

import DinercapModalFull from "../../utils/DinercapModalFull";
import { useConfiguracionSolicitud } from "../../../context/ConfiguracionSolicitudContext";
import DinercapDraggableList from "../../utils/DinercapDraggableList";
import { IoAdd, IoEllipsisVerticalOutline, IoTrashOutline } from "react-icons/io5";
import FormCampoBloqueSolicitud from "./FormCampoBloqueSolicitud";
import { getModelCampoBloqueSolicitud } from "../../../models/ConfiguracionSolicitudModel";
import {
    Divider,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    MenuItem,
    Switch,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

import EliminarCampoBloqueSolicitud from "./EliminarCampoBloqueSolicitud";
import { HiOutlinePencil } from "react-icons/hi";
import FormCampoBloqueValidacion from "./FormCampoBloqueValidacion";

export default function ConfigurarBloqueModal({ iIDTipoSolicitud }) {
    const {
        iIDBloqueSolicitudCampoSeleccionadoRel,
        listaCamposBloqueSolicitud,
        bloqueSolicitudSeleccionado,
        modalOpenConfigurarBloque,
        handleOpenModalConfigurarBloque,
        handleChangeCampoBloqueSolicitudSeleccionado,
        handleClickObtenerCamposBloqueSolicitud,
        handleClickNuevoCampoBloqueSolicitud,
        handleClickEditarCampoBloqueSolicitud,
        handleClickEliminarCampoBloqueSolicitud,
        handleClickCampoBloqueSolicitud,
        handleMoveCampoBloqueSolicitud,
        modalOpenFormCampoBloqueSolicitud,
        modalOpenFormCampoBloqueValid,
        handleChangeGrupoSeleccionado,
    } = useConfiguracionSolicitud();

    const [modeDragCampoBloqueSolicitud, setModeDragCampoBloqueSolicitud] = useState(false);
    const [anchorCampoBloqueSolicitud, setAnchorCampoBloqueSolicitud] = useState(null);

    const getItems = (count) =>
        Array.from({ length: count }, (v, k) => k).map((k) => ({
            id: `${listaCamposBloqueSolicitud[k].iIDBloqueSolicitudCampos}`,
            primary: `${listaCamposBloqueSolicitud[k].sNombre}`,
        }));

    const [items, setItems] = useState([]);

    useEffect(() => {
        if (modalOpenConfigurarBloque && bloqueSolicitudSeleccionado) {
            handleClickObtenerCamposBloqueSolicitud(bloqueSolicitudSeleccionado?.iIDBloqueSolicitud);
        }
        // eslint-disable-next-line
    }, [bloqueSolicitudSeleccionado.iIDBloqueSolicitud]);

    useEffect(() => {
        setItems(getItems(listaCamposBloqueSolicitud.length));
        // eslint-disable-next-line
    }, [listaCamposBloqueSolicitud]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = async ({ destination, source }) => {
        // dropped outside the list
        if (!destination) return;
        // dropped equal position
        if (destination.index === source.index) return;
        // dropped first position
        if (destination.index === 0) return;

        const newItems = reorder(items, source.index, destination.index);

        const iIDCampoBloqueSolicitudActual = parseInt(newItems[destination.index].id);
        const iIDCampoBloqueSolicitudPrevio = parseInt(newItems[destination.index - 1].id);
        const iIDCampoBloqueSolicitudSiguiente =
            destination.index === newItems.length - 1 ? undefined : parseInt(newItems[destination.index + 1].id);

        const campoPrevio = listaCamposBloqueSolicitud.find(
            (x) => x.iIDBloqueSolicitudCampos === iIDCampoBloqueSolicitudPrevio
        );
        let sNumeroPrevio = campoPrevio.iNivel.toString();
        if (campoPrevio.sNivel !== "" && campoPrevio.sNivel) {
            sNumeroPrevio = campoPrevio.iNivel.toString() + "." + campoPrevio.sNivel;
        }

        const campoSiguiente = iIDCampoBloqueSolicitudSiguiente
            ? listaCamposBloqueSolicitud.find((x) => x.iIDBloqueSolicitudCampos === iIDCampoBloqueSolicitudSiguiente)
            : "";
        let sNumeroSiguiente =
            campoSiguiente !== "" ? campoSiguiente.iNivel.toString() : (parseInt(campoPrevio.iNivel) + 1).toString();
        if (campoSiguiente !== "") {
            if (campoSiguiente.sNivel !== "" && campoSiguiente.sNivel) {
                sNumeroSiguiente =
                    campoSiguiente !== ""
                        ? campoSiguiente.iNivel.toString() + "." + campoSiguiente.sNivel
                        : (parseInt(campoPrevio.iNivel) + 1).toString();
            }
        }

        const entMoverCampoBloqueSolicitud = {
            oCampo: {
                iIDBloqueSolicitudCampos: iIDCampoBloqueSolicitudActual,
                iIDBloqueSolicitud: bloqueSolicitudSeleccionado.iIDBloqueSolicitud,
                sNumeroPrevio,
                sNumeroSiguiente,
            },
            iTipoSolicitud: iIDTipoSolicitud,
        };

        await handleMoveCampoBloqueSolicitud(entMoverCampoBloqueSolicitud);
    };

    const handleToggleModeDrag = async () => {
        setModeDragCampoBloqueSolicitud(!modeDragCampoBloqueSolicitud);
    };

    const handleClickNuevoCampoBloque = async () => {
        await handleChangeCampoBloqueSolicitudSeleccionado(getModelCampoBloqueSolicitud());
        handleClickNuevoCampoBloqueSolicitud();
    };

    const handleClickMenuCampoBloqueSolicitud = (e, value) => {
        setAnchorCampoBloqueSolicitud(e.currentTarget);
        handleChangeCampoBloqueSolicitudSeleccionado(value);
    };

    const handleClickMenuItemEditarCampoBloqueSolicitud = () => {
        handleCloseMenuCampoBloqueSolicitud();
        handleClickEditarCampoBloqueSolicitud();
    };

    const handleClickMenuItemEliminarCampoBloqueSolicitud = () => {
        handleCloseMenuCampoBloqueSolicitud();
        handleClickEliminarCampoBloqueSolicitud();
    };

    const handleCloseMenuCampoBloqueSolicitud = () => {
        setAnchorCampoBloqueSolicitud(null);
    };

    const handleGrupoCampoSeleccionado = (e, campoBloqueSolicitud) => {
        if (bloqueSolicitudSeleccionado.bGrupo && iIDBloqueSolicitudCampoSeleccionadoRel === 0) {
            let tipoForm = campoBloqueSolicitud.ifInputForm.sNombre;
            if (tipoForm === "IMG" || tipoForm === "FILE") {
                handleClickCampoBloqueSolicitud(campoBloqueSolicitud.iIDBloqueSolicitudCampos);
            } else {
                handleChangeGrupoSeleccionado(e, campoBloqueSolicitud.iIDBloqueSolicitudCampos);
            }
        } else {
            //es campo
            handleClickCampoBloqueSolicitud(campoBloqueSolicitud.iIDBloqueSolicitudCampos);
        }
    };

    const handleRegresarAGrupos = (e) => {
        e.preventDefault();
        handleChangeGrupoSeleccionado(e, 0);
        handleClickObtenerCamposBloqueSolicitud(bloqueSolicitudSeleccionado.iIDBloqueSolicitud);
    };

    useEffect(() => {
        if (!modalOpenConfigurarBloque) {
            handleChangeGrupoSeleccionado(null, 0);
            handleClickObtenerCamposBloqueSolicitud(bloqueSolicitudSeleccionado.iIDBloqueSolicitud);
        }
    }, [modalOpenConfigurarBloque]);

    return (
        <Fragment>
            <DinercapModalFull
                open={modalOpenConfigurarBloque}
                setOpen={handleOpenModalConfigurarBloque}
                title={bloqueSolicitudSeleccionado.iIDBloqueSolicitud + "-" + bloqueSolicitudSeleccionado.sNombreBloque}
            >
                <div className="flex flex-col gap-2 max-w-3xl mx-auto p-4">
                    <div className="flex flex-row justify-between items-center flex-wrap">
                        <div>
                            <Breadcrumbs aria-label="breadcrumb">
                                {bloqueSolicitudSeleccionado.bGrupo && iIDBloqueSolicitudCampoSeleccionadoRel !== 0 && (
                                    <Link href="#" color="primary" onClick={(e) => handleRegresarAGrupos(e)}>
                                        Grupos
                                    </Link>
                                )}
                                {iIDBloqueSolicitudCampoSeleccionadoRel === 0 ? (
                                    <h1 className="text-gray-700">
                                        {bloqueSolicitudSeleccionado.bGrupo ? "Grupos del bloque" : "Campos del bloque"}
                                    </h1>
                                ) : (
                                    <h1 className="text-gray-700">Campos del grupo</h1>
                                )}
                            </Breadcrumbs>
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name="ckModeDrag"
                                        color="primary"
                                        checked={modeDragCampoBloqueSolicitud}
                                        onChange={handleToggleModeDrag}
                                    />
                                }
                                label="Ordenar"
                            />
                            <Button
                                className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                size="small"
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={handleClickNuevoCampoBloque}
                            >
                                <IoAdd className="text-xl mr-2" />
                                Crear
                            </Button>
                        </div>
                    </div>

                    {modeDragCampoBloqueSolicitud ? (
                        <DinercapDraggableList
                            items={items}
                            onDragEnd={onDragEnd}
                            // handleClick={handleClickCampoBloqueSolicitud}
                        />
                    ) : (
                        <List className="flex flex-col gap-2 !p-0">
                            {listaCamposBloqueSolicitud.map((campoBloqueSolicitud) => {
                                const labelId = `list-label-${campoBloqueSolicitud.iIDBloqueSolicitudCampos}`;

                                return (
                                    <Fragment key={campoBloqueSolicitud.iIDBloqueSolicitudCampos}>
                                        <ListItem
                                            className="flex bg-card !bg-white shadow !rounded-xl !py-4"
                                            role={undefined}
                                            button
                                            onClick={(e) => handleGrupoCampoSeleccionado(e, campoBloqueSolicitud)}
                                        >
                                            <i className={"fas fa-" + campoBloqueSolicitud.sIcono}></i>
                                            <ListItemText
                                                className="ml-2 flex-1 sm:mr-40 text-gray-900"
                                                id={labelId}
                                                primary={campoBloqueSolicitud.iIDBloqueSolicitudCampos + "-" +campoBloqueSolicitud.sNombre}
                                            />
                                            <ListItemSecondaryAction className="flex">
                                                <IconButton
                                                    edge="end"
                                                    className="w-10 !text-xl !rounded-full !ml-1 !-mr-2 !p-2"
                                                    onClick={(e) =>
                                                        handleClickMenuCampoBloqueSolicitud(e, campoBloqueSolicitud)
                                                    }
                                                >
                                                    <IoEllipsisVerticalOutline />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </Fragment>
                                );
                            })}
                        </List>
                    )}

                    <Menu
                        id="menu-campo-bloque-solicitud"
                        anchorEl={anchorCampoBloqueSolicitud}
                        keepMounted
                        open={Boolean(anchorCampoBloqueSolicitud)}
                        onClose={handleCloseMenuCampoBloqueSolicitud}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                    >
                        <MenuItem dense onClick={handleClickMenuItemEditarCampoBloqueSolicitud}>
                            <HiOutlinePencil className="text-gray-600 text-xl mr-3" />
                            <ListItemText className="text-gray-600">Editar</ListItemText>
                        </MenuItem>

                        <Divider />

                        <MenuItem dense onClick={handleClickMenuItemEliminarCampoBloqueSolicitud}>
                            <IoTrashOutline className="text-gray-600 text-xl mr-3" />
                            <ListItemText className="text-gray-600">Eliminar</ListItemText>
                        </MenuItem>
                    </Menu>
                </div>
            </DinercapModalFull>
            {modalOpenFormCampoBloqueSolicitud && <FormCampoBloqueSolicitud />}
            {modalOpenFormCampoBloqueValid && <FormCampoBloqueValidacion />}
            <EliminarCampoBloqueSolicitud />
        </Fragment>
    );
}
