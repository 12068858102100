import React, { useEffect, useState, Fragment } from "react";
import Enumerable from "linq";
import { makeStyles } from "tss-react/mui";
import { AppBar, Button, Dialog, Slide, Toolbar, Typography, Box, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { IoAdd } from "react-icons/io5";
import { useConfiguracion } from "../../../../context/catalogos_generales/ConfiguracionContext";
import DinercapTableHandle from "../../../utils/DinercapTableHandle";
import FormConfiguracionSistema from "./FormConfigSistema";
import Swal from "sweetalert2";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles()((theme) => {
    return {
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        container: {
            maxHeight: 800,
        },
        root: {
            width: "100%",
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    };
});

export default function CatalogoConfiguracionModal() {
    const {
        openCatConfiguracion,
        handleOpenCatConfig,
        handleObtenerConfiguraciones,
        listaConfiguraciones,
        handleOpenFormConfiguracion,
        openFormConfiguracion,
        handleActualizarConfiguracion,
        handleEliminarConfiguracion,
    } = useConfiguracion();
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({
        iIdConfig: -1,
    });

    const crearData = () => {
        const _data = Enumerable.from(listaConfiguraciones)
            .select((config, index) => ({
                id: index + 1,
                iIdConfig: config.iIdConfig,
                sNombre: config.sNombre,
                sValor: config.sValor,
                sTipo: config.sTipo,
                sDescripcion: config.sDescripcion,
            }))
            .toArray();
        setData(_data);
    };

    const columns = [
        { field: "id", title: "#" },
        // { field: "iIdConfig", title: "ID Config" },
        { field: "sNombre", title: "Nombre" },
        { field: "sValor", title: "Valor", textOverflow: "ellipsis" },
        // {
        //     field: "sValor",
        //     title: "Valor",
        //     Element: ({ row }) => {
        //         return (
        //             <Button onClick={() => {
        //                 Swal.fire({
        //                     title: "Valor",
        //                     html: row.sValor,
        //                     // width: "30em",
        //                     confirmButtonText: "Ok",
        //                 });
        //             }}>
        //                 Valor {row.iIdConfig}
        //             </Button>
        //         );
        //     },
        // },
        { field: "sTipo", title: "Tipo" },
        { field: "sDescripcion", title: "Descripcion" },
    ];

    const onDelete = (e, row) => {
        handleEliminarConfiguracion(e, row);
    };

    const onUpdate = (e, row) => {
        handleActualizarConfiguracion(e, row);
    };

    useEffect(() => {
        handleObtenerConfiguraciones();
    }, []);

    useEffect(() => {
        crearData();
    }, [listaConfiguraciones]);

    return (
        <Fragment>
            <Dialog
                key="form"
                fullScreen
                open={openCatConfiguracion}
                onClose={() => handleOpenCatConfig(false)}
                TransitionComponent={Transition}
            >
                <Box sx={{ flexFlow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title} component="div" sx={{ flexGrow: 1 }}>
                                 Configuración de Sistema
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => handleOpenCatConfig(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
                <div className="flex flex-col gap-2 p-4">
                    <div className="flex flex-row justify-between items-center flex-wrap ml-2 mt-2 mr-2 ">
                        <div></div>
                        {/* <div>
                            <Button
                                className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={(e) => handleOpenFormConfiguracion(true)}
                            >
                                <IoAdd className="text-xl mr-2" />
                                Crear
                            </Button>
                        </div> */}
                    </div>
                    <Box
                        component="div"
                        sx={{
                            width: "100%",
                            "& .odd": {
                                bgcolor: "rgba(0, 0, 0, 0.05)",
                            },
                            "& .even": {
                                bgcolor: "#ffffff",
                            },
                        }}
                    >
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                            <Grid item xs={12} md={8}>
                                <DinercapTableHandle
                                    id="iIdConfig"
                                    data={data}
                                    columns={columns}
                                    selectedRow={selectedRow}
                                    setRow={setSelectedRow}
                                    //onDelete={onDelete}
                                    onUpdate={onUpdate}
                                    //onView={() => {}}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Dialog>
            {openFormConfiguracion && <FormConfiguracionSistema />}
        </Fragment>
    );
}
