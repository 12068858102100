export const ELEMENT_TYPE = {
    PRINT: "print",
    TEXTBOX: "TEXTBOX",
    PASSWORD: "PASSWORD",
    CHECKBOX: "CHECKBOX",
    RADIO: "RADIO",
    TEXTAREA: "TEXTAREA",
    COMBOBOX: "COMBOBOX",
};

export const ACTIONS = {
    DRAG_END: "DRAG_END",
    OPTION_DRAG_END: "OPTION_DRAG_END",
    CHANGE_VALUE: "CHANGE_VALUE",
    ADD_ELEMENT: "ADD_ELEMENT",
    REM_ELEMENT: "REM_ELEMENT",
    ADD_OPTION: "ADD_OPTION",
    CHANGE_EDIT_MODE: "CHANGE_EDIT_MODE",
    ON_CHANGE_RENDER: "ON_CHANGE_RENDER",
};

export const TYPES = {
    MAIN: "MAIN",
};
