export const apiCguCreateModulo = "Api/CGU/Create/Modulo";
export const apiCguCreateSubmodulo = "Api/CGU/Create/SubModulo";
export const apiCguCreateBoton = "Api/CGU/Create/Boton";
export const apiCguCreatePerfil = "Api/CGU/Create/Perfil";
export const apiCguCreateUsuario = "Api/CGU/Create/Usuario";
export const apiCguGetUsuarios = "Api/CGU/Get/Usuarios";
export const apiCguCreateCambiarPassword = "Api/CGU/Create/CambiarContrasenia";
export const apiCguUserLogin = "Api/CGU/User/Login";
export const apiCguRecuperarPassword = "Api/CGU/Recuperar/Password";
export const apiCguGetPermisoPerfil = "Api/CGU/GET/PermisoXPerfil";
export const apiCguGetUsuariosPermisos = "Api/CGU/Get/Usuarios/Permisos";
export const apiCguCreatePermiso = "Api/CGU/Create/Permiso";
export const apiCguGetPerfiles = "Api/CGU/Get/Perfiles";

export const apiConfirmaCorreo = "Api/UsuarioMeditoc/ConfirmaUsuario";

export const apiPoliticasGetSistemaCatalogos = "Api/Politicas/Get/Sistema/Catalogos";

export const apiAuthenticate = "api/Authenticate/login";

export const apiGetUsuarioOriginadorByPassandUser = "Originador/CGetUsuarioOriginador";


//export const apiTipoSolicitudGetTiposSolicitud = "TipoSolicitud/tipos_solicitud/true/false/false";
export const apiTipoSolicitudGetTiposSolicitud = "TipoSolicitud/CGetTipoSolicitud/true/false/false";
export const apiTipoSolicitudGetTiposSolicitudAll = "TipoSolicitud/CGetTipoSolicitudAll/false/false";
export const apiTipoSolicitudGetTiposSolicitudByActivo = "TipoSolicitud/CGetTipoSolicitudByActivo/true/false";
export const apiTipoSolicitudCreateTipoSolicitud = "TipoSolicitud/CSaveTipoSolicitud";
export const apiTipoSolicitudEditTipoSolicitud = "TipoSolicitud/edit_tiposolicitud";
export const apiTipoSolicitudDeleteTipoSolicitud = "TipoSolicitud/CDeleteTipoSolicitud";
export const apiTipoSolicitudCGetById = "TipoSolicitud/CGetTipoSolByID/{piIDTipoSolicitud}/true/false/false";
export const apiTipoSolicitudCGetByIdAll = "TipoSolicitud/CGetTipoSolByID/{piIDTipoSolicitud}/{pbActivo}/false/false";
export const apiTipoSolicitudUpdateTipoSolicitud = "TipoSolicitud/CUpdTipoSolicitud";
export const apiTipoSolicitudClone = "TipoSolicitud/CCloneTipoSolicitud";

export const apiBloqueGetBloques = "BloqueSolicitud/CBloquelByTipoSolicitud/{piIDTipoSolicitud}/true/false/true";
export const apiBloqueGetBloques_SinDetalle =
    "BloqueSolicitud/CBloquelByTipoSolicitud/{piIDTipoSolicitud}/true/false/false";
export const apiBloqueCreateBloque = "BloqueSolicitud/CSaveBloque";
export const apiBloqueUpdBloque = "BloqueSolicitud/CUpdBloque";
export const apiBloqueMoveBloque = "BloqueSolicitud/CMoveBloque";
export const apiBloqueDeleteBloque = "BloqueSolicitud/CDeleteBloque";

export const apiCampoBloqueGetCamposBloque = "CamposBloque/CGetCampoByBloque/{iIDBloque}/true/false/false";
export const apiCampoBloqueGetCamposAllBloque = "CamposBloque/CGetCampoAllByBloque/{iIDBloque}/{bGrupo}/true";
export const apiCampoBloqueCreateCamposBloque = "CamposBloque/CSaveCampo";
export const apiCampoBloqueUpdCampo = "CamposBloque/CUpdCampo";
export const apiCampoBloqueEditCamposBloque = "CamposBloque/edit_campo";
export const apiCampoBloqueMoveCamposBloque = "CamposBloque/CMoveCampo";
export const apiCampoBloqueDeleteCamposBloque = "CamposBloque/CDeleteCampo";
export const apiCampoBloqueValidacionList = "CamposBloque/CGetValidacionesByCampo/{iIdCampo}/true/false";
export const apiCampoBloqueValidacionSave = "CamposBloque/CSaveValidacion";
export const apiCampoBloqueValidacionDelete = "CamposBloque/CDeleteValidacion";
export const apiCampoBloqueGetCamposByCampoAgrupables =
    "CamposBloque/BGetCamposByCampoAgrupables/{iIDBloqueSolicitudCampos}/true/false";
export const apiCUpdateCampoReqOCR = "CamposBloque/CUpdateCampoReqOCR";
export const apiCGetCamposAutoFill = "CamposBloque/CGetCamposAutoFill";

export const apiGetServers = "ValidacionInterna/CGetServers";

export const apiCampoBloqueListaReglasByTipoDato = "CamposBloque/CGetReglaByInputData/{piIDTipoDato}/true/false";
export const apiCampoBloqueListaCamposRef = apiCampoBloqueGetCamposBloque.replace("{bAgrupado}", false);
export const apiCampoBloqueListaBloquesRef = apiBloqueGetBloques;

export const apiSolicitudGetSolicitudes =
    "Solicitud/CGetSolicitudesByTipoSolicitud/{iIDTipoSolicitud}/true/false/true/{bAllEstatus}";
export const apiSolicitudGetGetEstadosByOrigen = "TipoSolicitud/CGetEstadosByOrigen/2";
export const apiSolicituDetalle =
    "Solicitud/CGetTipoSolicitudWithValuesBySolicitudAndTipoSolicitud/{piIDSolicitud}/{piIDTipoSolicitud}/true/false/true";
export const apiSolicitudChangeStatus = "Solicitud/CRechazarCampoSolicitud";
export const apiSolicitudDisplayPDF =
    "Formato/CDisplayFormato?piId_solicitud={piIdSolicitud}&piId_tipoSolicitud={piIdTipoSolicitud}&piId_formato={piIdFormato}";

export const apiInputTypeGetAll = "InputType/CInputsTypes/true/false";
export const apiInputTypeGetAllByForm = "InputType/CInputsTypesByByInputForm/{InputForm}";
export const apiInputFormsGetAll = "InputForm/CInputsForm/true/false";
export const apiInputBuscaTypesByGrupo = "InputType/CBuscaTypesByAgrupador/true";
export const apiInputTypeBuscaOperadoresByInputType = "InputType/CBuscaOperadoresByInputType/{iIDTipoDato}";

export const apiCGetFormatoByTipoSolicitud = "Formato/CGetFormatoByTipoSolicitud/{piIDTipoSolicitud}/true/false/false";
export const apiCGetFormatoCampoByFormato =
    "FormatoCampo/CGetFormatoCampoByFormato/{piIDFormatoSolicitud}/true/false/true";
export const apiFormatoGetFormatos = "Formato/formatos_solicitud/true/false/true";
export const apiFormatoSaveFormatoCampoValor = "FormatoCampoValor/CSaveFormatoCampoValor";
export const apiFormatoDeleteCampoFormatoValor = "FormatoCampoValor/BDeleteCampoFormatoValor";

export const apiInfoSolicitudAprobada =
    "TipoSolicitud/CGetInfoSolicitudAprobByTipoSolicitud/{iIdTipoSolicitud}/true/false";
export const apiChangeStatusSolicitud = "Solicitud/CChangeStatus";
export const apiSaveInfoSolicitudAprobada = "SolicitudAprobada/CSaveSolicitud";
export const apiCGetSolicitudAprobada = "SolicitudAprobada/CGetSolicitudAprobada/{piIdSolicitud}";
export const apiGetInfoSolicitudRechaza =
    "TipoSolicitud/CGetInfoSolicitudRechazadaByTipoSolicitud/{iIdTipoSolicitud}/true/false";
export const apiSaveSolicitudRechazada = "SolicitudRechazada/CSaveSolicitud";
export const apiCGetSolicitudRechazada = "SolicitudRechazada/CGetSolicitudRechazada/{piIdSolicitud}";
export const apiCGetSolicitudBuro = "SolicitudBuro/CGetSolicitudBuro/{piIdSolicitud}";

export const apiCGetCatFinanciera = "CatFinanciera/CGetCatFinanciera/true/false/false";
export const apiCGetCatTipoArea = "TipoArea/CGetTipoAreas/true/false";
export const apiCGuardarOriginador = "Prospecto/CGuardarOriginador?iIdSolicitud={iIdSolicitud}";
export const apiCGetSolicitudesByRFC = "Solicitud/CGetSolicitudesByRFC/{rfc}";
export const apiCGetEstadosRelacionByTipoSolicitud =
    "TipoSolicitud/CGetEstadosRelacionByTipoSolicitud/{piIDTipoSolicitud}/2";

export const apiCGetCatDocumentoOCR = "CatDocumentosOCR/CGetCatDocumentoOCR/true/false/false";
export const apiCSaveCatDocumentoOCR = "CatDocumentosOCR/CSaveCatDocumentoOCR";

export const apiCGetCatDocumentoDatos = "CatDatosDireccion/CGetCatDatosDireccion/true/false";

export const apiCGetCatFinancieras = "CatFinanciera/CGetCatFinanciera/true/false/true";
export const apiCSaveCatFinanciera = "CatFinanciera/CSaveCatFinanciera";

export const apiCGetAvisosPrivacidad = "AvisoPrivacidad/CGetAvisoPrivacidad/true/false";
export const apiCSaveAvisosPrivacidad = "AvisoPrivacidad/CSaveAvisoPrivacidad";

export const apiCGetAprobados = "CatInfoSolicitudAprobada/CGetInfoSolicitudAprobadaController/{piIdTipoSolicitud}/true/false";
export const apiCGetAllAprobados = "CatInfoSolicitudAprobada/CGetAllInfoSolicitudAprobadaController/true/false";
export const apiCSaveAprobado = "CatInfoSolicitudAprobada/CSaveCatInfoSolicitudAprobada";

export const apiCSaveDocOCRCampo = "DocumentosOCRCampo/CSaveDocOCRCampo";
export const apiCDeleteDocOCRCampo = "DocumentosOCRCampo/CDeleteDocOCRCampo/{piIdCampo}";

export const apiCSaveDatosDir = "DatosDirCampos/CSaveDatosDirCampo";
export const apiCDeleteDatosDir = "DatosDirCampos/CDeleteDatosDirCampo/{piIdCampo}";

//#region Geoareas
export const apiGetGeocercas = "Geocerca/CGetGeocercas/true/false";
export const apiGetGeoAreas = "TipoArea/CGetTipoAreas/true/false";
export const apiSaveGeocercas = "Geocerca/CSaveGeocerca";
export const apiCSaveTipoArea = "TipoArea/CSaveTipoArea";
//#endregion
export const apiCGetConfigSistema = "CatConfiguracion/CGetCatConfiguracion/true/false";
export const apiCSaveConfigSistema = "CatConfiguracion/CSaveConfiguracion";

export const apiCGetIdBuroSolicitud = "Solicitud/CGetIdBuroSolicitud/{piIdSolicitud}";
export const apiCGetVerificarReporteCreditoExistente = "Solicitud/CGetReporteCreditoExistente/{piIDSolicitud}";
export const apiCGetReporteCreditoSolicitud = "Solicitud/CGetReporteCredito/{piIDSolicitud}/{iIdUsuario}/{bActualiza}";
export const apiCGetScoreSolicitudBySolicitud = "Solicitud/CGetScoreCreditoByIidsolicitud/{piIDSolicitud}";
export const apiCGetVerificarScoreSolicitud = "Solicitud/CGetVerificarScoreExistente/{piIdSolicitud}";
export const apiCGetInfoFinancieraByIidSolicitud = "Solicitud/CGetInfoFinancieraByIidSolicitud/{piIdSolicitud}"
export const apiCGetOriginador = "Originador_TipoSolicitud/CGetOriginador_TipoSolicitud/true/false";
export const apiCSaveOriginador = "Originador_TipoSolicitud/CSaveOriginador_TipoSolicitud";
export const apiCGetExisteProspecto = "Prospecto/CExisteProspectoxRFC?RFC={RFC}";
export const apiCGetExisteProspectoCURP = "Prospecto/CExisteProspectoxCURP?sCURP={sCURP}";
export const apiCGetDatosProspecto = "ProspectoGet/CGetDatosProspecto?pIdProspecto={pIdProspecto}";
export const apiCGetInfoEnvOriginador = "ProspectoGet/CGetInfoEnvOriginador?iIdSolicitud={iIdSolicitud}";
export const apiCUpdateProspecto = "Prospecto/CUpdateProspecto";
export const apiCGetColoniaxCP = "Prospecto/CGetColoniasxCP/{pCp}";
export const apiCDescargarXML = "Originador_TipoSolicitud/CDescargarXML/{sNombreArchivo}";
export const apiCUpdateDatosDirCampo = "DatosDirCampos/CUpdateValorDatosDirCampos";
export const apiCGetEstastusbyUpdCP = "Solicitud/CGetEstastusbyUpdCP";
export const apiCSendMailCancelSolicitud = "CancelacionSolicitud/CSendMailCancelSolicitud";

export const apiCGetOriginadorDinercap = "CatOriginadorDinercap/CGetCatOriginadorDinercap/true/false";
export const apiCSaveOriginadorDinercap = "CatOriginadorDinercap/CSaveCatOriginadorDinercap";

export const apiCGetCatSolicitudRechazada = "CatInfoSolicitudRechazada/CGetInfoSolicitudRechazada/{piIdTipoSolicitud}/true/false";
export const apiCSaveSolicitudRechazada = "CatInfoSolicitudRechazada/CSaveInfoSolicitudRechazada";

export const apiCGetReglasOperadoresAll = "ReglasOperadores/CGetReglasOperadoresAll/{psNombreValidacion}/true/false";
export const apiCSaveReglasOperadores = "ReglasOperadores/CSaveReglasValidacion";