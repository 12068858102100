import { createContext, useContext, useState } from "react";
import Enumerable from "linq";
import { useRequest } from "../RequestContext";
import { useLayout } from "../LayoutContext";
import { apiCGetCatSolicitudRechazada, apiTipoSolicitudGetTiposSolicitudByActivo, apiCSaveSolicitudRechazada } from "../../settings/apiConfig";
import Swal from "sweetalert2";

import { getFormCatalogoSolicitudRechazada, getModelCatalogoSolicitudRechazada } from "../../models/CatalogosModel";
import { fnPrevent } from "../../settings/preventConfig";
import { fnHandleChangeText } from "../../settings/handleConfig";

export const SolicitudRechazadaContext = createContext();

export function SolicitudRechazadaProvider(props) {
    const { handleOpenAlert } = useLayout();
    const { Get, Post } = useRequest();

    //Catalogo
    const [openCatSolicitudRechazada, setOpenCatSolicitudRechazada] = useState(false);
    const [listaTipoSolicitud, setListaTipoSolicitud] = useState([]);
    const [listaSolicitudRechazada, setListaSolicitudRechazada] = useState([]);
    const [formCatalogoSolicitudRechazada, setFormCatalogoSolicitudRechazada] = useState(getFormCatalogoSolicitudRechazada());
    const [solicitudRSeleccionado, setSolicitudRSeleccionado] = useState(getModelCatalogoSolicitudRechazada());
    const [tipoSolicitudSeleccionado, setTipoSolicitudSeleccionado] = useState("");

    // Form
    const [openFormSolicitudRechazada, setOpenFormSolicitudRechazada] = useState(false);

    //#region Catalogo
    const handleOpenCatSolicitudRechazada= (value) => {
        const { slTipoSolicitudFilter } = formCatalogoSolicitudRechazada;
        setTipoSolicitudSeleccionado(0);
        slTipoSolicitudFilter.value = 0;
        setOpenCatSolicitudRechazada(value);
        if(value)
        {
            handleObtenerCatSolicitudRechazada();
        }
        else
        {
            setListaSolicitudRechazada([]);
        }
    };

    const handleObtenerCatSolicitudRechazada = async () => {

        const { slTipoSolicitudFilter } = formCatalogoSolicitudRechazada;

        const payload = {
            loader: "Consultando catálogo de solicitudes rechazadas",
            url: apiCGetCatSolicitudRechazada.replace("{piIdTipoSolicitud}", slTipoSolicitudFilter.value),
            alert: true,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            if (response.result.length > 0) {
                const listaCatSolicitudR = Enumerable.from(response.result)
                    .select((cinfosolicitudr) => ({
                        iIdInfosolicitudrechazada: cinfosolicitudr.iIdInfosolicitudrechazada,
                        iIdTiposolicitud: cinfosolicitudr.iIdTiposolicitud,
                        sTipoSolicitud: cinfosolicitudr.entTipoSolicitud.sNombre,
                        sDescripcion: cinfosolicitudr.sDescripcion,
                    }))
                    .toArray();
                setListaSolicitudRechazada(listaCatSolicitudR);
            } else {
                setListaSolicitudRechazada([]);
                handleOpenAlert("No se encontró información","success");
            }
        }
        else
        {
            setListaSolicitudRechazada([]);
        }
    };
    //#endregion Catalogo

    //#region formulario

    const fnObtenerTipoDeSolicitudes = async () => {
        const payload = {
            loader: "Consultando lista de tipo de solicitudes...",
            url: apiTipoSolicitudGetTiposSolicitudByActivo,
            alert: false,
        };

        const response = await Get(payload);
        if (response.code === 201) {
            setListaTipoSolicitud(response.result);
        } else {
            setListaTipoSolicitud([]);
        }
    };

    const handleOnChangeTipoSolicitud = (event) => {
        const { slTipoSolicitudFilter } = formCatalogoSolicitudRechazada;
        const { value } = event.target;
        setTipoSolicitudSeleccionado(value);
        slTipoSolicitudFilter.value = value;
        handleObtenerCatSolicitudRechazada();
    }

    const handleOpenFormSolicitudRechazada = (value) => {
        const { slTipoSolicitud } = formCatalogoSolicitudRechazada;

        //if(tipoSolicitudSeleccionado != 0){
        let form = {
            ...formCatalogoSolicitudRechazada,
            slTipoSolicitud: {
                ...slTipoSolicitud,
                value: tipoSolicitudSeleccionado,
            },
        };
        setFormCatalogoSolicitudRechazada(form);
        setOpenFormSolicitudRechazada(value);
        if (!value) {
            setFormCatalogoSolicitudRechazada(getFormCatalogoSolicitudRechazada());
            setSolicitudRSeleccionado(getModelCatalogoSolicitudRechazada());
        } 
        // }
        // else
        // {
        //     Swal.fire({
        //         title: "Tipo de solicitud",
        //         text: "Seleccionar un valor en el filtro de tipo solicitud.",
        //         icon: "warning",
        //     });
        // }
    };

    const handleChangeFormSolicitudR = fnHandleChangeText(formCatalogoSolicitudRechazada, setFormCatalogoSolicitudRechazada);

    const handleSubmitFormSolicitudR = async (e) => {
        fnPrevent(e);

        const iIdInfosolicitudrechazada = solicitudRSeleccionado.iIdInfosolicitudrechazada === undefined ? -1 : solicitudRSeleccionado.iIdInfosolicitudrechazada;

        const { slTipoSolicitudFilter, slTipoSolicitud, txtDescripcion } = formCatalogoSolicitudRechazada;

        const form = {
            ...formCatalogoSolicitudRechazada,
            slTipoSolicitud: {
                ...slTipoSolicitud,
                error: slTipoSolicitud.value  === "",
            },
            txtDescripcion: {
                ...txtDescripcion,
                error: txtDescripcion.value === "",
            },
        };

        setFormCatalogoSolicitudRechazada(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }

        const entSolicitudR = {
            iIdInfosolicitudrechazada: iIdInfosolicitudrechazada,
            iIdTiposolicitud: slTipoSolicitud.value,
            sDescripcion: txtDescripcion.value,
            iIDUsuarioCreacion: iIdInfosolicitudrechazada === -1 ? 1 : 0,
            iIDUsuarioModificacion: iIdInfosolicitudrechazada !== -1 ? 1 : 0,
            iIDUsuarioElimina: 0,
        };

        //slTipoSolicitudFilter.value = tipoSolicitudSeleccionado;
        
        await fn_guardarSolicitudR(entSolicitudR);
    };

    const fn_guardarSolicitudR = async (entSolicitudR) => {
        let loader = "";
        if (entSolicitudR.iIDUsuarioCreacion > 0) {
            loader = "Guardando la causa de solicitud rechazada.";
        } else if (entSolicitudR.iIDUsuarioModificacion > 0) {
            loader = "Actualizando la causa de solicitud rechazada.";
        } else if (entSolicitudR.iIDUsuarioElimina > 0) {
            loader = "Eliminando la causa de solicitud rechazada.";
        }

        const payload = {
            loader: loader,
            body: entSolicitudR,
            url: apiCSaveSolicitudRechazada,
            alert: true,
        };

        const response = await Post(payload);
        const { slTipoSolicitudFilter } = formCatalogoSolicitudRechazada;
        if (response.iCodigo === 201) {
            handleOpenFormSolicitudRechazada(false);
            setSolicitudRSeleccionado(getFormCatalogoSolicitudRechazada());
            setFormCatalogoSolicitudRechazada(getFormCatalogoSolicitudRechazada());
            slTipoSolicitudFilter.value = tipoSolicitudSeleccionado;
            await handleObtenerCatSolicitudRechazada();
        }
    };

    const handleActualizarSolicitudR = (e, SolicitudR) => {
        fnPrevent(e);
        setSolicitudRSeleccionado(SolicitudR);

        const { slTipoSolicitud, txtDescripcion } = formCatalogoSolicitudRechazada;

        let form = {
            ...formCatalogoSolicitudRechazada,
            slTipoSolicitud: {
                ...slTipoSolicitud,
                value: SolicitudR.iIdTiposolicitud,
            },
            txtDescripcion: {
                ...txtDescripcion,
                value: SolicitudR.sDescripcion,
            },
        };
        setFormCatalogoSolicitudRechazada(form);
        setOpenFormSolicitudRechazada(true);
    };

    const handleEliminarSolicitudR = async (e, SolicitudR) => {
        fnPrevent(e);
        Swal.fire({
            title: "Eliminar causa de solicitud rechazada",
            text: "¿Está seguro de eliminar la causa de solicitud rechazada " + SolicitudR.sDescripcion + "?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await fn_eliminarSolicitudR(SolicitudR);
            }
        });
    };

    const fn_eliminarSolicitudR = async (SolicitudR) => {
        const entSolicitudR = {
            iIdInfosolicitudrechazada: SolicitudR.iIdInfosolicitudrechazada,
            iIDUsuarioElimina: 1,
            iIDUsuarioCreacion: 0,
            iIDUsuarioModificacion: 0,
        };

        await fn_guardarSolicitudR(entSolicitudR);
    };
    // #endregion

    const handleListarTipoSolicitudes = () => {
        fnObtenerTipoDeSolicitudes();
    };

    return (
        <SolicitudRechazadaContext.Provider
            value={{
                openCatSolicitudRechazada,
                handleOpenCatSolicitudRechazada,
                listaTipoSolicitud,
                listaSolicitudRechazada,
                handleObtenerCatSolicitudRechazada,
                handleListarTipoSolicitudes,
                handleOpenFormSolicitudRechazada,
                formCatalogoSolicitudRechazada,
                openFormSolicitudRechazada,
                solicitudRSeleccionado,
                tipoSolicitudSeleccionado,
                handleChangeFormSolicitudR,
                handleSubmitFormSolicitudR,
                handleActualizarSolicitudR,
                handleEliminarSolicitudR,
                handleOnChangeTipoSolicitud
            }}
        >
            {props.children}
        </SolicitudRechazadaContext.Provider>
    );
}

export function useCatSolicitudRechazada() {
    return useContext(SolicitudRechazadaContext);
}
