import { createContext, useContext, useState } from "react";

export const TokenContext = createContext();

export function TokenProvider(props) {
    const [appKey, setAppKey] = useState("");
    const [appToken, setAppToken] = useState("");

    const handleChangeKey = (key) => {
        setAppKey(key);
    };

    const handleChangeToken = (token) => {
        setAppToken(token);
    };

    return (
        <TokenContext.Provider value={{ appKey, appToken, handleChangeKey, handleChangeToken }}>
            {props.children}
        </TokenContext.Provider>
    );
}

export function useToken() {
    return useContext(TokenContext);
}
