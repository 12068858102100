import React, { useEffect } from "react";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, } from "@mui/material";
import { useAvisosPrivacidad } from "../../../../context/catalogos_generales/AvisosPrivacidadContext";
import { useTipoSolicitud } from "../../../../context/TipoSolicitudContext";

import DinercapModal from "../../../utils/DinercapModal";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function FormDocumento() {
    const {
        openFormAvisoPrivacidad,
        handleOpenFormAvisoPrivacidad,
        formAvisoPrivacidad,
        handleSubmitFormAvisoPrivacidad,
        avisoPrivacidadSeleccionada,
        handleChangeFormAvisoPrivacidad,
        setIsExistTipoSolicitud
    } = useAvisosPrivacidad();

    const {
        listaTiposSolicitud,
        handleClickObtenerTiposSolicitud,
    } = useTipoSolicitud();

    const { cmbBoxIdTipoSolicitud, txtAvisoPrivacidad, txtBuroCredito } = formAvisoPrivacidad;

    setIsExistTipoSolicitud(cmbBoxIdTipoSolicitud.error);

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
            ["link"],
        ],
    };

    const onChangeAP = (value) => {
        txtAvisoPrivacidad.value = value
    };

    const onChangeBC = (value) => {
        txtBuroCredito.value = value
    };

    useEffect(() => {
        handleClickObtenerTiposSolicitud();
    }, []);

    useEffect(() => {
        const element = document.getElementById(cmbBoxIdTipoSolicitud.name);
        if (element && !cmbBoxIdTipoSolicitud.error) {
            element.scrollIntoView();
            
        }
    });

    return (
        <DinercapModal
            title={
                avisoPrivacidadSeleccionada.iIdAvisoPrivacidad === -1
                    ? "Agregar aviso de privacidad"
                    : "Actualizar aviso de privacidad"
            }
            okFunc={handleSubmitFormAvisoPrivacidad}
            setOpen={handleOpenFormAvisoPrivacidad}
            open={openFormAvisoPrivacidad}
            okMessage="Guardar"
            size="small"
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" error={cmbBoxIdTipoSolicitud.error || cmbBoxIdTipoSolicitud.isExist}>
                        <InputLabel id="demo-simple-select-error-label">{cmbBoxIdTipoSolicitud.label}</InputLabel>
                        <Select
                            labelId="demo-simple-select-error-label"
                            id={cmbBoxIdTipoSolicitud.name}
                            name={cmbBoxIdTipoSolicitud.name}
                            label={cmbBoxIdTipoSolicitud.label}
                            required={cmbBoxIdTipoSolicitud.required}
                            error={cmbBoxIdTipoSolicitud.error || cmbBoxIdTipoSolicitud.isExist}
                            value={cmbBoxIdTipoSolicitud.value}
                            onChange={handleChangeFormAvisoPrivacidad}
                        >
                            <MenuItem value="">
                                <em>Seleccione un tipo de solicitud</em>
                            </MenuItem>
                            {listaTiposSolicitud.map((solicitud) => {
                                return (
                                    <MenuItem
                                        key={solicitud.iIDTipoSolicitud}
                                        value={solicitud.iIDTipoSolicitud}
                                    >
                                        {solicitud.sNombre}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>{(cmbBoxIdTipoSolicitud.isExist && cmbBoxIdTipoSolicitud.value != "" && cmbBoxIdTipoSolicitud.messageUnique) || (cmbBoxIdTipoSolicitud.error && cmbBoxIdTipoSolicitud.value == "" && cmbBoxIdTipoSolicitud.message)}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth variant="outlined" error={txtAvisoPrivacidad.error}>
                        <label htmlFor="formControlRange">{txtAvisoPrivacidad.label}</label>
                        <ReactQuill
                            modules={modules}
                            theme="snow"
                            value={txtAvisoPrivacidad.value}
                            onChange={onChangeAP}
                        />
                        <FormHelperText>{txtAvisoPrivacidad.error && txtAvisoPrivacidad.message}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth variant="outlined" error={txtBuroCredito.error}>
                        <label htmlFor="formControlRange">{txtBuroCredito.label}</label>
                        <ReactQuill
                            modules={modules}
                            theme="snow"
                            value={txtBuroCredito.value}
                            onChange={onChangeBC}
                            error={txtBuroCredito.error}
                            helperText={txtBuroCredito.error && txtBuroCredito.message}
                        />
                        <FormHelperText>{txtBuroCredito.error && txtBuroCredito.message}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
