import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { useTipoArea } from "../../../../context/catalogos_generales/TipoAreasContext";
import {
    Dialog,
    Slide,
    Box,
    Grid,
    AppBar,
    Typography,
    IconButton,
    Toolbar,
    Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IoAdd } from "react-icons/io5";
import Enumerable from "linq";
import FormTipoArea from "./FormTipoArea";
import DinercapTableHandle from "../../../utils/DinercapTableHandle";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles()((theme) => {
    return {
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        container: {
            maxHeight: 800,
        },
        root: {
            width: "100%",
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    };
});



export default function TipoAreaModal() {
    const { openTipoArea,
        handleOpenTipoArea,
        handleObtenerTipoArea,
        listaTipoArea,
        openFormTipoArea,
        handleOpenFormTipoArea,
        handleActualizarTipoArea,
        handleEliminarTipoArea,
    } = useTipoArea();
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({
        iIDTipoArea: -1,
    });

    const crearData = () => {
        const listTipoArea = Enumerable.from(listaTipoArea)
            .select((item, index) => ({
                id: index + 1,
                iIDTipoArea: item.iIDTipoArea,
                sNombreArea: item.sNombreArea,
            }))
            .toArray();

        setData(listTipoArea);
    };

    const columnas = [
        { field: "id", title: "#", width: 50 },
        { field: "sNombreArea", title: "ÁREA", hide: true },
    ];

    const onDelete = (e, row) => {
        handleEliminarTipoArea(e, row);
    };

    const onUpdate = (e, row) => {
        handleActualizarTipoArea(e, row);
    };

    useEffect(() => {
        handleObtenerTipoArea();
    }, []);

    useEffect(() => {
        crearData();
    }, [listaTipoArea]);

    return (
        <Fragment>
            <Dialog fullScreen open={openTipoArea} TransitionComponent={Transition}>
                <Box sx={{ flexFlow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title} component="div" sx={{ flexGrow: 1 }}>
                                Catálogo tipo áreas
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => handleOpenTipoArea(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
                <div className="flex flex-col gap-2 p-4">
                    <div className="flex flex-row justify-between items-center flex-wrap ml-2 mt-2 mr-2 ">
                        <div></div>
                        <div>
                            <Button
                                className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={(e) => handleOpenFormTipoArea(true)}
                            >
                                <IoAdd className="text-xl mr-2" />
                                Crear
                            </Button>
                        </div>
                    </div>
                    <Box
                        component="div"
                        sx={{
                            width: "100%",
                            "& .odd": {
                                bgcolor: "rgba(0, 0, 0, 0.05)",
                            },
                            "& .even": {
                                bgcolor: "#ffffff",
                            },
                        }}
                    >
                        <Grid container direction="row" justifyContent="center">
                            <Grid item xs={12} md={8}>
                                <DinercapTableHandle
                                    id="iIDTipoArea"
                                    data={data}
                                    columns={columnas}
                                    selectedRow={selectedRow}
                                    setRow={setSelectedRow}
                                    onDelete={onDelete}
                                    onUpdate={onUpdate}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Dialog>
            {openFormTipoArea && <FormTipoArea />}
        </Fragment>
    );
}