import {
    apiCguCreateCambiarPassword,
    apiCguGetUsuariosPermisos,
    apiCguRecuperarPassword,
    apiAuthenticate,
    apiConfirmaCorreo,
    apiGetUsuarioOriginadorByPassandUser,
    apiCSendMailCancelSolicitud,
} from "../settings/apiConfig";
import { createContext, useContext, useEffect, useState } from "react";
import { fnPrevent, fnPreventBlur } from "../settings/preventConfig";
import { getFormCambiarPass, getFormLogin, getFormRecuperar } from "../models/LoginModel";
import { getKey, getToken } from "../models/TokenModel";
import { urlDefault, urlSystem } from "../settings/urlConfig";

import { EnumPerfilesPrincipales } from "../settings/enumConfig";
import { fnHandleChangeText } from "../settings/handleConfig";
import { rxCorreo } from "../settings/regexConfig";
import { useCatalogo } from "./CatalogoContext";
import { useHistory } from "react-router";
import { useLayout } from "./LayoutContext";
import { useRequest } from "./RequestContext";
import { useToken } from "./TokenContext";
import CryptoJS from "crypto-js";

export const LoginContext = createContext();

export function LoginProvider(props) {
    const tokenName = "DinercapTkn";
    const keyName = "DinercapKey";

    const history = useHistory();
    const { Get, Post, GetTokenLogin } = useRequest();
    const { handleClickObtenerCatalogos } = useCatalogo();
    const { handleChangeKey, handleChangeToken } = useToken();
    const { rutaActual, handleOpenAlert, handleChangeRuta, handleOpenMenu, handleCloseUserMenu } = useLayout();

    const [usuarioSesion, setUsuarioSesion] = useState(null);
    const [usuarioActivo, setUsuarioActivo] = useState(true);
    const [usuarioOriginador, setUsuarioOriginador] = useState(null);
    const [usuarioPermisos, setUsuarioPermisos] = useState(null);
    const [formLogin, setFormLogin] = useState(getFormLogin());
    const [formRecuperar, setFormRecuperar] = useState(getFormRecuperar());
    const [formCambiarPass, setFormCambiarPass] = useState(getFormCambiarPass());
    const [openFormRecuperar, setOpenFormRecuperar] = useState(false);
    const [openFormEnvioCorreo, setOpenFormEnvioCorreo] = useState(false);
    const [ConfirmacionExitosa, setConfirmacionExitosa] = useState(null);
    const [openPortadaLogin, setOpenPortadaLogin] = useState(false);
    const [openFormCambiarPass, setOpenFormCambiarPass] = useState(false);
    const [mostrarPassword, setMostrarPassword] = useState(false);
    const [functionCloseAll, setFunctionCloseAll] = useState(null);
    const [openFormCancelAccount, setOpenFormCancelAccount] = useState(false);

    const [lngConfirmCorreo, setlngConfirmCorreo] = useState("es");

    const handleToggleFormRecuperar = (value) => {
        setOpenFormRecuperar(value);
    };
    const handleTogglePortadaLogin = (value) => {
        setOpenPortadaLogin(value);
    };
    const handleToggleFormCambiarPass = (value) => {
        setOpenFormCambiarPass(value);
    };

    const handleChangeMostrarPassword = (value) => {
        setMostrarPassword(value);
    };

    const handleChangeFunctionCloseAll = (value) => {
        setFunctionCloseAll(value);
    };

    const handleChangeUsuarioPermisos = (value) => {
        setUsuarioPermisos(value);
    };

    const handleChangeFormLogin = fnHandleChangeText(formLogin, setFormLogin);
    const handleChangeFormRecuperar = fnHandleChangeText(formRecuperar, setFormRecuperar);
    const handleChangeFormCambiarPass = fnHandleChangeText(formCambiarPass, setFormCambiarPass);

    const handleSubmitLogin = (e) => {
        fnPrevent(e);

        const { txtUsuarioDinercap, txtPasswordDinercap } = formLogin;
        if (txtUsuarioDinercap.value === "" || txtPasswordDinercap.value === "") {
            handleOpenAlert("Por favor, ingrese todos los datos", "warning");
            return;
        }

        const entLogin = {
            sUsuario: txtUsuarioDinercap.value,
            sPassword: txtPasswordDinercap.value,
        };

        fnLogin(entLogin);
    };

    const handleSubmitRecuperar = (e) => {
        fnPrevent(e);

        const { txtRecuperarDinercap } = formRecuperar;
        if (txtRecuperarDinercap.value === "") {
            handleOpenAlert("Por favor, ingrese su correo de recuperación", "warning");
            return;
        }

        if (!rxCorreo.test(txtRecuperarDinercap.value)) {
            handleOpenAlert("Por favor, ingrese un correo válido", "warning");
            return;
        }

        const entRecuperar = {
            sCorreo: txtRecuperarDinercap.value,
        };

        fnRecuperar(entRecuperar);
    };

    const handleSubmitCambiarPass = (e) => {
        fnPrevent(e);
        const { txtNuevoPasswordDinercap, txtConfirmarPasswordDinercap } = formCambiarPass;

        const form = {
            ...formCambiarPass,
            txtNuevoPasswordDinercap: {
                ...txtNuevoPasswordDinercap,
                error: txtNuevoPasswordDinercap.value === "",
            },
            txtConfirmarPasswordDinercap: {
                ...txtConfirmarPasswordDinercap,
                error: txtConfirmarPasswordDinercap.value === "",
            },
        };
        setFormCambiarPass(form);

        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }

        if (txtNuevoPasswordDinercap.value !== txtConfirmarPasswordDinercap.value) {
            handleOpenAlert("Las contraseñas no coinciden", "warning");
            return;
        }

        fnCambiarPass(usuarioSesion.iIdUsuario, txtConfirmarPasswordDinercap.value, usuarioSesion.iIdUsuario);
    };

    const handleClickCambiarPassword = () => {
        handleCloseUserMenu();
        setOpenFormCambiarPass(true);
    };

    const handleClickSignOff = async () => {
        //handleCloseUserMenu();
        // if (rutaActual === urlSystem.callcenter.consultas) {
        //     if (typeof functionCloseAll === "function") {
        //         await functionCloseAll();
        //     }
        //     //Esperar para reiniciar el chat
        //     setTimeout(() => {
        //         fnBorrarDatos();
        //     }, 1000);
        // } else {
        //     fnBorrarDatos();
        // }
        fnBorrarDatos();
    };

    const handleClickRuta = async (e, ruta) => {
        const funcCloseDrawer = handleOpenMenu(false);
        funcCloseDrawer(e);

        handleChangeRuta(ruta);
        history.push(ruta);
    };

    const fnLoginAuto = async (token) => {
        var stoken = token.substr(token.indexOf("token=") + 6);
        //Desencriptar
        var bytes = CryptoJS.AES.decrypt(stoken, "@dinercap");
        var textdescrifrado = bytes.toString(CryptoJS.enc.Utf8);

        let usuario = textdescrifrado.substring(
            textdescrifrado.indexOf("user=") + 5,
            textdescrifrado.indexOf("pass=") - 1
        );
        let password = textdescrifrado.substring(textdescrifrado.indexOf("pass=") + 5);

        if (usuario != "" && password != "") {
            const entLogin = {
                sUsuario: usuario,
                sPassword: password,
            };

            await fnLogin(entLogin);
            history.push(urlDefault);
        } else {
            handleOpenAlert("No se ha desencriptado el token.", "warning");
        }

        //user=admin&pass=adminQA
        //U2FsdGVkX19dndkRtor+eQ/XCtxCd9llVT+UrBtM0KG5c4/91+1Qd6NRAAhrbcSo
        //var textocifrado = CryptoJS.AES.encrypt('user=admin&pass=adminQA', '@dinercap').toString();
    };

    const fnGetUsuarioOriginador = async (entLogin) => {
        const payload = {
            loader: "",
            url: apiGetUsuarioOriginadorByPassandUser,
            body: entLogin,
            alert: false,
        };
        const response = await Post(payload);

        if (response.iCodigo === 201) {
            setUsuarioOriginador(response.result);
        }
    }

    const fnLogin = async (entLogin) => {

        const payload = {
            loader: "Validando datos de sesión...",
            url: apiAuthenticate,
            body: entLogin,
            alert: true,
        };

        const response = await GetTokenLogin(payload);

        if (response.Code === 201) {
            sessionStorage.setItem(tokenName, entLogin.sUsuario);
            sessionStorage.setItem(keyName, entLogin.sPassword);

            sessionStorage.setItem("tkn", response.Result.token);
            sessionStorage.setItem("exptkn", response.Result.expiration);
            if (response.Result.cokie != null) sessionStorage.setItem("cokie", response.Result.cokie);
            handleChangeKey(getKey());
            handleChangeToken(getToken());
            setUsuarioActivo(true);
            setUsuarioSesion(response.Result);
            fnGetUsuarioOriginador(entLogin);

        } else {
            sessionStorage.removeItem(tokenName);
            sessionStorage.removeItem(keyName);
            sessionStorage.removeItem("tkn");
            sessionStorage.removeItem("exptkn");
            handleOpenAlert("El usuario o la contraseña son incorrectos.", "warning");
        }
        // const response = await Post(payload);
        // if (response.Code === 0) {
        //     sessionStorage.setItem(tokenName, entLogin.sUsuario);
        //     sessionStorage.setItem(keyName, entLogin.sPassword);
        //     handleChangeKey(getKey());
        //     handleChangeToken(getToken());
        //     setUsuarioActivo(true);
        //     setUsuarioSesion(response.Result);
        // } else {
        //     sessionStorage.removeItem(tokenName);
        //     sessionStorage.removeItem(keyName);
        // }
    };

    const fnConfirmacionCorreo = async (token) => {
        var stoken = token.substr(token.indexOf("sIdToken=") + 9);
        var lng = token.substr(token.indexOf("lng=") + 4, 2);
        setlngConfirmCorreo(lng);
        const entToken = {
            sIdToken: stoken,
        };
        const payload = {
            loader: lng === "es" ? "Validando Correo..." : "Validating Mail ...",
            url: apiConfirmaCorreo,
            body: entToken,
        };

        const response = await Post(payload);
        if (response.Code === 0) {
            setConfirmacionExitosa(true);
        } else {
            setConfirmacionExitosa(false);
        }
    };

    const fnObtenerPermisosPerfiles = async (iIdPerfil) => {
        await fnGetPermisos(iIdPerfil);
        await handleClickObtenerCatalogos();
    };

    const fnGetPermisos = async (iIdPerfil) => {
        const payload = {
            loader: "Obteniendo permisos...",
            url: apiCguGetUsuariosPermisos + "?piIdPerfil=" + iIdPerfil,
            alert: false,
        };

        const response = await Get(payload);
        if (response.Code === 0) {
            setUsuarioPermisos(response.Result);
            fnRutaSesion(iIdPerfil);
        }
    };

    const fnRutaSesion = (iIdPerfil) => {
        let ruta = urlDefault;
        switch (iIdPerfil) {
            case EnumPerfilesPrincipales.DoctorCallCenter:
                ruta = urlSystem.callcenter.consultas;
                break;
            case EnumPerfilesPrincipales.DoctorEspecialista:
                ruta = urlSystem.callcenter.consultas;
                break;
            case EnumPerfilesPrincipales.AdministradorEspecialiesta:
                ruta = urlSystem.callcenter.administrarConsultas;
                break;
            default:
                ruta = urlDefault;
                break;
        }
        handleChangeRuta(ruta);
        history.push(ruta);
    };

    const fnValidarSesionGuardada = () => {
        const sUsuario = sessionStorage.getItem(tokenName);
        const sPassword = sessionStorage.getItem(keyName);

        if (sUsuario && sPassword) {
            fnLogin({ sUsuario, sPassword });
        }
    };

    const fnRecuperar = async (entRecuperar) => {
        const payload = {
            loader: "Recuperando y enviando correo...",
            url: apiCguRecuperarPassword,
            body: entRecuperar,
            alert: true,
        };

        const response = await Post(payload);
        if (response.Code === 0) {
            setOpenFormRecuperar(false);
            setFormLogin(getFormLogin());
            setFormRecuperar(getFormRecuperar());
        }
    };

    const fnBorrarDatos = () => {
        sessionStorage.removeItem(tokenName);
        sessionStorage.removeItem(keyName);

        sessionStorage.removeItem("tkn");
        sessionStorage.removeItem("exptkn");

        setUsuarioSesion(null);
        setUsuarioActivo(false);
        setUsuarioPermisos(null);
        setUsuarioOriginador(null);

        handleChangeKey("");
        handleChangeToken("");

        handleChangeRuta(urlDefault);
        history.push(urlDefault);
    };

    const fnCambiarPass = async (iIdUsuario = 0, sPassword = "", iIdUsuarioUltMod = 0) => {
        const payload = {
            loader: "Actualizando credenciales...",
            url:
                apiCguCreateCambiarPassword +
                "?iIdUsuario=" +
                iIdUsuario +
                "&sPassword=" +
                sPassword +
                "&iIdUsuarioUltMod=" +
                iIdUsuarioUltMod,
            body: {},
            alert: true,
        };
        const response = await Post(payload);
        if (response.Code === 0) {
            sessionStorage.setItem(keyName, sPassword);
            setOpenFormCambiarPass(false);
        }
        fnPreventBlur();
    };

    const handleCorreoCancelacionSolicitud = async (entCancelacionSolicitud) => {
        const payload = {
            loader: "Enviando Solicitud",
            url: apiCSendMailCancelSolicitud,
            body: {
                sRfc: entCancelacionSolicitud.sRfc,
                sCurp: entCancelacionSolicitud.sCurp,
                sNombreCompleto: entCancelacionSolicitud.sNombre,
                sTelefono: entCancelacionSolicitud.sTelefono,
                sArgumento: entCancelacionSolicitud.sArgumento
            },
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
        }
    };

    useEffect(() => {
        if (history.location.pathname === "/login") {
            fnLoginAuto(history.location.search);
            // setOpenPortadaLogin(true);
            // setOpenFormEnvioCorreo(true);
            // fnConfirmacionCorreo(history.location.search);
        }
        if (history.location.pathname === "/Cancelar") {
            setOpenFormCancelAccount(true);
        }
        // else {
        //     setOpenPortadaLogin(true);
        //     fnValidarSesionGuardada();
        // }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (usuarioSesion) {
            if (usuarioSesion.iIdPerfil) {

                fnObtenerPermisosPerfiles(usuarioSesion.iIdPerfil);
            }
        }
        // eslint-disable-next-line
    }, [usuarioSesion]);

    useEffect(() => {
        if (openFormCambiarPass) {
            setFormCambiarPass(getFormCambiarPass());
        }
        // eslint-disable-next-line
    }, [openFormCambiarPass]);

    return (
        <LoginContext.Provider
            value={{
                usuarioSesion,
                usuarioActivo,
                usuarioOriginador,
                usuarioPermisos,
                formLogin,
                formRecuperar,
                formCambiarPass,
                openFormRecuperar,
                openFormEnvioCorreo,
                ConfirmacionExitosa,
                openPortadaLogin,
                openFormCambiarPass,
                mostrarPassword,
                functionCloseAll,
                lngConfirmCorreo,
                openFormCancelAccount,
                handleToggleFormRecuperar,
                handleTogglePortadaLogin,
                handleToggleFormCambiarPass,
                handleChangeMostrarPassword,
                handleChangeFunctionCloseAll,
                handleChangeUsuarioPermisos,
                handleChangeFormLogin,
                handleChangeFormRecuperar,
                handleChangeFormCambiarPass,
                handleCorreoCancelacionSolicitud,
                handleSubmitLogin,
                handleSubmitRecuperar,
                handleSubmitCambiarPass,
                handleClickCambiarPassword,
                handleClickSignOff,
                handleClickRuta,
            }}
        >
            {props.children}
        </LoginContext.Provider>
    );
}

export function useLogin() {
    return useContext(LoginContext);
}
