import React, { useEffect, useState, Fragment } from "react";
import { AppBar, Button, Dialog, Slide, Toolbar, Typography, Box, Grid, FormControl, Select, MenuItem, Hidden } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { IoAdd } from "react-icons/io5";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Enumerable from "linq";
import _ from 'lodash';

import { useCatSolicitudRechazada } from "../../../../context/catalogos_generales/SolicitudRechazadaContext";
import FormSolicitudRechazada from "./FormSolicitudRechazada";
import DinercapTableHandle from "../../../utils/DinercapTableHandle";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles()((theme) => {
    return {
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        container: {
            maxHeight: 800,
        },
        root: {
            width: "100%",
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    };
});

export default function CatalogoSolicitudRechazadaModal() {
    const {
        openCatSolicitudRechazada,
        handleOpenCatSolicitudRechazada,
        handleObtenerCatSolicitudRechazada,
        listaSolicitudRechazada,
        handleOpenFormSolicitudRechazada,
        openFormSolicitudRechazada,
        handleActualizarSolicitudR,
        handleEliminarSolicitudR,
        listaTipoSolicitud,
        handleListarTipoSolicitudes,
        formCatalogoSolicitudRechazada,
        tipoSolicitudSeleccionado,
        handleOnChangeTipoSolicitud
    } = useCatSolicitudRechazada();

    const { slTipoSolicitudFilter } = formCatalogoSolicitudRechazada;

    const classes = useStyles();

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({
        iIdInfosolicitudrechazada: -1,
    });

    const crearData = () => {
        const _data = Enumerable.from(listaSolicitudRechazada)
            .select((cinfosolicitudr, index) => ({
                id: index + 1,
                iIdInfosolicitudrechazada: cinfosolicitudr.iIdInfosolicitudrechazada,
                iIdTiposolicitud: cinfosolicitudr.iIdTiposolicitud,
                sTipoSolicitud: cinfosolicitudr.sTipoSolicitud,
                sDescripcion: cinfosolicitudr.sDescripcion,
            }))
            .toArray();
        setData(_data);
    };

    const columns = [
        { field: "id", title: "#" },
        { field: "sTipoSolicitud", title: "Tipo Solicitud" },
        { field: "sDescripcion", title: "Descripcion" },
    ];

    const onDelete = (e, row) => {
        handleEliminarSolicitudR(e, row);
    };

    const onUpdate = (e, row) => {
        handleActualizarSolicitudR(e, row);
    };

    const handleChangeSelect = (e,value) => {
       slTipoSolicitudFilter.value = value;
       handleObtenerCatSolicitudRechazada();
    }

    useEffect(() => {
        handleListarTipoSolicitudes();
    }, []);

    useEffect(() => {
        crearData();
    }, [listaSolicitudRechazada]);

    return (
        <Fragment>
            <Dialog
                key="form"
                fullScreen
                open={openCatSolicitudRechazada}
                onClose={() => handleOpenCatSolicitudRechazada(false)}
                TransitionComponent={Transition}
            >
                <Box sx={{ flexFlow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title} component="div" sx={{ flexGrow: 1 }}>
                                Catálogo Solicitud Rechazada
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => handleOpenCatSolicitudRechazada(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
                <div className="flex flex-col gap-2 p-4">
                    <Grid container direction="row" justifyContent="center" alignItems="center" >
                        <Grid md={2}/>
                        <Grid xs md>
                            <FormControl fullWidth variant="outlined"
                            sx={{
                                        width: 250,
                                    }}>
                                <Select
                                    name={slTipoSolicitudFilter.name}
                                    id="slTipoSolicitudFilter"
                                    value={tipoSolicitudSeleccionado}
                                    onChange={handleOnChangeTipoSolicitud}
                                    autoComplete="off"
                                    defaultValue={0}
                                >
                                    <MenuItem value={0}>
                                        <em>Todos los tipos de solicitud</em>
                                    </MenuItem>
                                    {listaTipoSolicitud.map((solicitud) => {
                                            return (
                                                <MenuItem
                                                    //onClick={(e) => handleChangeSelect(e, solicitud.iIDTipoSolicitud)}
                                                    key={solicitud.iIDTipoSolicitud}
                                                    value={solicitud.iIDTipoSolicitud}
                                                >
                                                    {solicitud.sNombre}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={4} md={4} />
                        <Grid md>
                            <Button
                                className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={(e) => handleOpenFormSolicitudRechazada(true)}
                            >
                                <IoAdd className="text-xl mr-2" />
                                Crear
                            </Button>
                        </Grid>
                    </Grid>
                    <Box
                        component="div"
                        sx={{
                            width: "100%",
                            "& .odd": {
                                bgcolor: "rgba(0, 0, 0, 0.05)",
                            },
                            "& .even": {
                                bgcolor: "#ffffff",
                            },
                        }}
                    >
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                            <Grid item xs={12} md={8}>
                                <DinercapTableHandle
                                    id="iIdInfosolicitudrechazada"
                                    data={data}
                                    columns={columns}
                                    selectedRow={selectedRow}
                                    setRow={setSelectedRow}
                                    onDelete={onDelete}
                                    onUpdate={onUpdate}
                                    //onView={() => {}}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Dialog>
            {openFormSolicitudRechazada && <FormSolicitudRechazada />}
        </Fragment>
    );
}
