import { createContext, useContext, useState } from "react";
import Enumerable from "linq";
import { useRequest } from "../RequestContext";
import { useLayout } from "../LayoutContext";
import { apiCGetConfigSistema, apiInputTypeGetAll, apiCSaveConfigSistema } from "../../settings/apiConfig";
import Swal from "sweetalert2";

import { getFormCatalogoConfiguracion, getModelCatalogoConfiguracion } from "../../models/CatalogosModel";
import { fnPrevent } from "../../settings/preventConfig";
import { fnHandleChangeText } from "../../settings/handleConfig";

export const ConfiguracionContext = createContext();

export function ConfiguracionProvider(props) {
    const { handleOpenAlert } = useLayout();
    const { Get, Post } = useRequest();

    //Catalogo
    const [openCatConfiguracion, setOpenCatConfiguracion] = useState(false);
    const [listaTipoDatos, setListaTipoDatos] = useState([]);
    const [listaConfiguraciones, setListaConfiguraciones] = useState([]);
    const [formCatalogoConfiguracion, setFormCatalogoConfiguracion] = useState(getFormCatalogoConfiguracion());
    const [configuracionSeleccionado, setConfiguracionSeleccionado] = useState(getModelCatalogoConfiguracion());

    // Form
    const [openFormConfiguracion, setOpenFormConfiguracion] = useState(false);

    //#region Catalogo
    const handleOpenCatConfig = (value) => {
        setOpenCatConfiguracion(value);
    };

    const handleObtenerConfiguraciones = async () => {
        const payload = {
            loader: "Consultando configuraciones de sistema",
            url: apiCGetConfigSistema,
            alert: true,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            if (response.result.length > 0) {
                const listaConfig = Enumerable.from(response.result)
                    .select((config) => ({
                        iIdConfig: config.iIdconfig,
                        sNombre: config.sNombre,
                        sValor: config.sValor,
                        sTipo: config.sTipo,
                        sDescripcion: config.sDescripcion,
                    }))
                    .toArray();
                setListaConfiguraciones(listaConfig);
            } else {
                handleOpenAlert("Error al obtener las configuraciones de sistema", "error");
            }
        }
    };
    //#endregion Catalogo

    //#region formulario

    const fnObtenerTipoDeDatos = async () => {
        const payload = {
            loader: "Consultando lista de tipo de datos...",
            url: apiInputTypeGetAll,
            alert: false,
        };

        const response = await Get(payload);
        if (response.code === 201) {
            setListaTipoDatos(response.result);
        } else {
            setListaTipoDatos([]);
        }
    };
    
    const handleOpenFormConfiguracion = (value) => {
        setOpenFormConfiguracion(value);
        if (!value) {
            setFormCatalogoConfiguracion(getFormCatalogoConfiguracion());
            setConfiguracionSeleccionado(getModelCatalogoConfiguracion());
        }
    };

    const handleChangeFormConfig = fnHandleChangeText(formCatalogoConfiguracion, setFormCatalogoConfiguracion);

    const handleSubmitFormConfig= async (e) => {
        fnPrevent(e);

        const iIdConfig = configuracionSeleccionado.iIdConfig === undefined ? -1 : configuracionSeleccionado.iIdConfig;

        const { txtNombreConfig, txtValor, slTipoDeDatoCampoBloque, txtDescripcion } = formCatalogoConfiguracion;

        const form = {
            ...formCatalogoConfiguracion,
            txtNombreConfig: {
                ...txtNombreConfig,
                error: txtNombreConfig.value === "",
            },
            txtValor: {
                ...txtValor,
                error: txtValor.value === "",
            },
            slTipoDeDatoCampoBloque: {
                ...slTipoDeDatoCampoBloque,
                error: slTipoDeDatoCampoBloque.value  === "",
            },
            txtDescripcion: {
                ...txtDescripcion,
                error: txtDescripcion.value === "",
            },
        };

        setFormCatalogoConfiguracion(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }

        const entConfiguracion = {
            iIdConfig: iIdConfig,
            sNombre: txtNombreConfig.value,
            sValor: txtValor.value,
            sTipo: String(slTipoDeDatoCampoBloque.value).toLowerCase(),
            sDescripcion: txtDescripcion.value,
            iIDUsuarioCreacion: iIdConfig === -1 ? 1 : 0,
            iIDUsuarioModificacion: iIdConfig !== -1 ? 1 : 0,
            iIDUsuarioElimina: 0,
        };

        await fn_guardarConfiguracion(entConfiguracion);
    };

    const fn_guardarConfiguracion = async (entConfiguracion) => {
        let loader = "";
        if (entConfiguracion.iIDUsuarioCreacion > 0) {
            loader = "Guardando la configuración.";
        } else if (entConfiguracion.iIDUsuarioModificacion > 0) {
            loader = "Actualizando la configuración.";
        } else if (entConfiguracion.iIDUsuarioElimina > 0) {
            loader = "Eliminando la configuración.";
        }

        const payload = {
            loader: loader,
            body: entConfiguracion,
            url: apiCSaveConfigSistema,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            handleOpenFormConfiguracion(false);
            setConfiguracionSeleccionado(getFormCatalogoConfiguracion());
            setFormCatalogoConfiguracion(getFormCatalogoConfiguracion());
            await handleObtenerConfiguraciones();
        }
    };

    const handleActualizarConfiguracion = (e, configuracion) => {
        fnPrevent(e);
        setConfiguracionSeleccionado(configuracion);

        const { txtNombreConfig, txtValor, slTipoDeDatoCampoBloque, txtDescripcion } = formCatalogoConfiguracion;

        let form = {
            ...formCatalogoConfiguracion,
            txtNombreConfig: {
                ...txtNombreConfig,
                value: configuracion.sNombre,
            },
            txtValor: {
                ...txtValor,
                value: configuracion.sValor,
            },
            slTipoDeDatoCampoBloque: {
                ...slTipoDeDatoCampoBloque,
                value: configuracion.sTipo,
            },
            txtDescripcion: {
                ...txtDescripcion,
                value: configuracion.sDescripcion,
            },
        };

        setFormCatalogoConfiguracion(form);
        setOpenFormConfiguracion(true);
    };

    const handleEliminarConfiguracion = async (e, configuracion) => {
        fnPrevent(e);
        Swal.fire({
            title: "Eliminar configuración de sistema",
            text: "¿Está seguro de eliminar la configuración de sistema " + configuracion.sNombre + "?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await fn_eliminarConfiguracion(configuracion);
            }
        });
    };

    const fn_eliminarConfiguracion = async (configuracion) => {
        const entConfiguracion = {
            iIdConfig: configuracion.iIdConfig,
            iIDUsuarioElimina: 1,
            iIDUsuarioCreacion: 0,
            iIDUsuarioModificacion: 0,
        };

        await fn_guardarConfiguracion(entConfiguracion);
    };

    // #endregion

    const handleListarTipoDatos = () => {
        fnObtenerTipoDeDatos();
    };

    return (
        <ConfiguracionContext.Provider
            value={{
                openCatConfiguracion,
                listaTipoDatos,
                listaConfiguraciones,
                openFormConfiguracion,
                formCatalogoConfiguracion,
                configuracionSeleccionado,
                handleOpenCatConfig,
                handleObtenerConfiguraciones,
                handleOpenFormConfiguracion,
                handleListarTipoDatos,
                handleSubmitFormConfig,
                handleChangeFormConfig,
                handleActualizarConfiguracion,
                handleEliminarConfiguracion
            }}
        >
            {props.children}
        </ConfiguracionContext.Provider>
    );
}

export function useConfiguracion() {
    return useContext(ConfiguracionContext);
}
