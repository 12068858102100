import { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import {
    IoAdd,
    IoDocumentOutline,
    IoEllipsisVerticalOutline,
    IoHomeOutline,
    IoOptionsOutline,
    IoTrashOutline,
} from "react-icons/io5";

import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { urlSystem } from "../../../settings/urlConfig";
import { urlDefault } from "../../../settings/urlConfig";
import { useLogin } from "../../../context/LoginContext";
import DinercapDraggableList from "../../utils/DinercapDraggableList";

import { useTipoSolicitud } from "../../../context/TipoSolicitudContext";
import { useConfiguracionSolicitud } from "../../../context/ConfiguracionSolicitudContext";
import ConfigurarBloqueModal from "./ConfigurarBloqueModal";

import FormBloqueSolicitud from "./FormBloqueSolicitud";
import { getModelBloqueSolicitud } from "../../../models/ConfiguracionSolicitudModel";
import {
    Divider,
    FormControlLabel,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    Switch,
} from "@mui/material";
import { HiOutlinePencil } from "react-icons/hi";
import { useLayout } from "../../../context/LayoutContext";
import EliminarBloqueSolicitud from "./EliminarBloqueSolicitud";

export default function ConfiguracionSolicitudScreen() {
    const tokenUsuario = "TknUsu";
    const tokenContraseña = "TknPass";
    const { handleClickRuta } = useLogin();

    const { handleOpenAlert } = useLayout();

    const { listaTiposSolicitudAll, handleClickObtenerTiposSolicitud } = useTipoSolicitud();

    const {
        listaBloquesSolicitud,
        tipoSolicitudSeleccionado,
        handleChangeTipoSolicitudSeleccionado,
        handleChangeBloqueSolicitudSeleccionado,
        handleClickObtenerBloquesSolicitud,
        handleClickNuevoBloqueSolicitud,
        handleClickEditarBloqueSolicitud,
        handleClickEliminarBloqueSolicitud,
        handleClickBloqueSolicitud,
        handleMoveBloqueSolicitud,
        modalOpenConfigurarBloque,
    } = useConfiguracionSolicitud();

    let { bMostrarTipoSolicitud } = useParams();

    const [modeDragBloqueSolicitud, setModeDragBloqueSolicitud] = useState(false);
    const [anchorBloqueSolicitud, setAnchorBloqueSolicitud] = useState(null);

    const getItems = (count) =>
        Array.from({ length: count }, (v, k) => k).map((k) => ({
            id: `${listaBloquesSolicitud[k].iIDBloqueSolicitud}`,
            primary: `${listaBloquesSolicitud[k].sNombreBloque}`,
        }));

    const [items, setItems] = useState([]);

    useEffect(() => {
        // sessionStorage.setItem(tokenUsuario, "dinercap.webApp");
        // sessionStorage.setItem(tokenContraseña, "QFouWAUD");
        if (listaTiposSolicitudAll.length === 0) {
            handleClickObtenerTiposSolicitud();
        } else if (tipoSolicitudSeleccionado.iIDTipoSolicitud !== 0) {
            handleClickObtenerBloquesSolicitud(tipoSolicitudSeleccionado.iIDTipoSolicitud);
        } else {
            handleChangeTipoSolicitud(listaTiposSolicitudAll[0].iIDTipoSolicitud);
        }
        // eslint-disable-next-line
    }, [tipoSolicitudSeleccionado.iIDTipoSolicitud || listaTiposSolicitudAll]);

    useEffect(() => {
        setItems(getItems(listaBloquesSolicitud.length));
        // eslint-disable-next-line
    }, [listaBloquesSolicitud]);

    const handleChangeTipoSolicitud = async (value) => {
        handleChangeTipoSolicitudSeleccionado(listaTiposSolicitudAll.find((x) => x.iIDTipoSolicitud === value));
    };

    const handleToggleModeDrag = async () => {
        setModeDragBloqueSolicitud(!modeDragBloqueSolicitud);
    };

    const handleClickNuevoBloque = async () => {
        await handleChangeBloqueSolicitudSeleccionado(getModelBloqueSolicitud());
        handleClickNuevoBloqueSolicitud();
    };

    const handleClickHome = (e) => {
        handleClickRuta(e, {
            pathname: urlDefault,
        });
    };

    const handleClickTipoSolicitudes = (e) => {
        handleClickRuta(e, {
            pathname: urlSystem.tiposSolicitudes,
        });
    };

    const handleClickMenuBloqueSolicitud = (e, value) => {
        setAnchorBloqueSolicitud(e.currentTarget);
        handleChangeBloqueSolicitudSeleccionado(value);
    };

    const handleClickMenuItemEditarBloqueSolicitud = () => {
        handleCloseMenuBloqueSolicitud();
        handleClickEditarBloqueSolicitud();
    };

    const handleClickMenuItemEliminarBloqueSolicitud = () => {
        handleCloseMenuBloqueSolicitud();
        handleClickEliminarBloqueSolicitud();
    };

    const handleCloseMenuBloqueSolicitud = () => {
        setAnchorBloqueSolicitud(null);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = async ({ destination, source }) => {
        // dropped outside the list
        if (!destination) return;
        // dropped equal position
        if (destination.index === source.index) return;
        // dropped first position
        if (destination.index === 0) {
            handleOpenAlert("No se puede mover un bloque a la primera posición", "warning");
            return;
        }

        const newItems = reorder(items, source.index, destination.index);

        const iIDBloqueSolicitudActual = parseInt(newItems[destination.index].id);
        const iIDBloqueSolicitudPrevio = parseInt(newItems[destination.index - 1].id);
        const iIDBloqueSolicitudSiguiente =
            destination.index === newItems.length - 1 ? undefined : parseInt(newItems[destination.index + 1].id);

        const bloqueSolicitudPrevio = listaBloquesSolicitud.find(
            (x) => x.iIDBloqueSolicitud === iIDBloqueSolicitudPrevio
        );
        let sNumeroPrevio = bloqueSolicitudPrevio.iSecuencia.toString();
        if (bloqueSolicitudPrevio.sSecuencia !== "" && bloqueSolicitudPrevio.sSecuencia) {
            sNumeroPrevio = bloqueSolicitudPrevio.iSecuencia.toString() + "." + bloqueSolicitudPrevio.sSecuencia;
        }

        const bloqueSolicitudSiguiente = iIDBloqueSolicitudSiguiente
            ? listaBloquesSolicitud.find((x) => x.iIDBloqueSolicitud === iIDBloqueSolicitudSiguiente)
            : "";
        let sNumeroSiguiente =
            bloqueSolicitudSiguiente !== ""
                ? bloqueSolicitudSiguiente.iSecuencia.toString()
                : (parseInt(bloqueSolicitudPrevio.iSecuencia) + 1).toString();
        if (bloqueSolicitudSiguiente !== "") {
            if (bloqueSolicitudSiguiente.sSecuencia !== "" && bloqueSolicitudSiguiente.sSecuencia) {
                sNumeroSiguiente =
                    bloqueSolicitudSiguiente !== ""
                        ? bloqueSolicitudSiguiente.iSecuencia.toString() + "." + bloqueSolicitudSiguiente.sSecuencia
                        : (parseInt(bloqueSolicitudPrevio.iSecuencia) + 1).toString();
            }
        }

        const entMoverBloqueSolicitud = {
            iIDBloqueSolicitud: iIDBloqueSolicitudActual,
            iIDTipoSolicitud: tipoSolicitudSeleccionado.iIDTipoSolicitud,
            sNumeroPrevio,
            sNumeroSiguiente,
        };

        await handleMoveBloqueSolicitud(entMoverBloqueSolicitud);
    };

    return (
        <div>
            <div className="flex flex-col md:flex-row items-start md:items-center md:justify-between h-14 p-3 border-b bg-header">
                <div className="flex flex-row items-center">
                    {/* <IoDocumentOutline className="text-2xl mr-1" />
                    <div className="text-xl font-medium tracking-tight leading-none">
                        Tipo de Solicitud 1
                    </div> */}

                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            className={
                                "flex !font-medium px-2 py-1 hover:!no-underline hover:!cursor-pointer hover:!bg-gray-100 hover:!rounded-full"
                            }
                            color="inherit"
                            onClick={(e) => handleClickHome(e)}
                        >
                            <IoHomeOutline className="text-2xl mr-2" />
                            Inicio
                        </Link>
                        {bMostrarTipoSolicitud === "true" && (
                            <Link
                                className={
                                    "flex !font-medium px-2 py-1 hover:!no-underline hover:!cursor-pointer hover:!bg-gray-100 hover:!rounded-full"
                                }
                                color="inherit"
                                onClick={(e) => handleClickTipoSolicitudes(e)}
                            >
                                <IoDocumentOutline className="text-2xl mr-2" />
                                <span>Tipos de Solicitudes</span>
                            </Link>
                        )}
                        <Typography color="textPrimary" className={"flex !font-medium px-2 py-1 "}>
                            <IoOptionsOutline className="text-2xl mr-2" />
                            <span>Configuración Solicitud</span>
                        </Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <div className="flex flex-col gap-2 max-w-3xl mx-auto p-4">
                <div className="flex flex-row justify-between items-center flex-wrap">
                    <div>
                        <InputLabel>
                            <i className="fa fa-filter"></i>&nbsp;<b>Tipo de Solicitud:</b>
                        </InputLabel>
                        <FormControl size="small" className="">
                            <Select
                                variant="filled"
                                labelId="tipo-solicitud-select-label"
                                id="tipo-solicitud-select"
                                value={tipoSolicitudSeleccionado.iIDTipoSolicitud}
                                label="Tipo de Solicitud"
                                defaultValue=""
                                fullWidth
                                onChange={(e) => handleChangeTipoSolicitud(e.target.value)}
                            >
                                {listaTiposSolicitudAll.map((tipoSolicitud) => {
                                    return (
                                        <MenuItem
                                            key={tipoSolicitud.iIDTipoSolicitud}
                                            value={tipoSolicitud.iIDTipoSolicitud}
                                        >
                                            {tipoSolicitud.sNombre}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControlLabel
                            control={
                                <Switch
                                    name="ckModeDrag"
                                    color="primary"
                                    checked={modeDragBloqueSolicitud}
                                    onChange={handleToggleModeDrag}
                                />
                            }
                            label="Ordenar"
                        />
                        <Button
                            className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                            size="small"
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={handleClickNuevoBloque}
                        >
                            <IoAdd className="text-xl mr-2" />
                            Crear
                        </Button>
                    </div>
                </div>

                {modeDragBloqueSolicitud ? (
                    <DinercapDraggableList
                        items={items}
                        onDragEnd={onDragEnd}
                        // handleClick={handleClickBloqueSolicitud}
                    />
                ) : (
                    <List className="flex flex-col gap-2 !p-0">
                        {listaBloquesSolicitud.map((bloqueSolicitud) => {
                            const labelId = `list-label-${bloqueSolicitud.iIDBloqueSolicitud}`;

                            return (
                                <Fragment key={bloqueSolicitud.iIDBloqueSolicitud}>
                                    <ListItem
                                        className="flex bg-card !bg-white shadow !rounded-xl !py-4"
                                        role={undefined}
                                        button
                                        onClick={() => handleClickBloqueSolicitud(bloqueSolicitud.iIDBloqueSolicitud)}
                                    >
                                        <i className={"fas fa-" + bloqueSolicitud.sIcono}></i>
                                        <ListItemText
                                            className="flex-1 sm:mr-40 text-gray-900 ml-2"
                                            id={labelId}
                                            primary={bloqueSolicitud.iIDBloqueSolicitud + "-" + bloqueSolicitud.sNombreBloque}
                                        />
                                        <ListItemSecondaryAction className="flex">
                                            <IconButton
                                                edge="end"
                                                className="w-10 !text-xl !rounded-full !ml-1 !-mr-2 !p-2"
                                                onClick={(e) => handleClickMenuBloqueSolicitud(e, bloqueSolicitud)}
                                            >
                                                <IoEllipsisVerticalOutline />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </Fragment>
                            );
                        })}
                    </List>
                )}
            </div>

            <FormBloqueSolicitud />
            {modalOpenConfigurarBloque && (
                <ConfigurarBloqueModal iIDTipoSolicitud={tipoSolicitudSeleccionado.iIDTipoSolicitud} />
            )}
            <EliminarBloqueSolicitud />

            <Menu
                id="menu-bloque-solicitud"
                anchorEl={anchorBloqueSolicitud}
                keepMounted
                open={Boolean(anchorBloqueSolicitud)}
                onClose={handleCloseMenuBloqueSolicitud}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
                <MenuItem dense onClick={handleClickMenuItemEditarBloqueSolicitud}>
                    <HiOutlinePencil className="text-gray-600 text-xl mr-3" />
                    <ListItemText className="text-gray-600">Editar</ListItemText>
                </MenuItem>

                <Divider />

                <MenuItem dense onClick={handleClickMenuItemEliminarBloqueSolicitud}>
                    <IoTrashOutline className="text-gray-600 text-xl mr-3" />
                    <ListItemText className="text-gray-600">Eliminar</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
}
