import React, { useEffect } from "react";
import { FormHelperText, Grid, FormControl, InputLabel, MenuItem, Select, TextField, } from "@mui/material";
import { inputColor, inputVariant } from "../../../../settings/inputConfig";
import { useFinancieras } from "../../../../context/catalogos_generales/FinancierasContext";
import DinercapModal from "../../../utils/DinercapModal";

export default function FormFinanciera() {
    const {
        openFormFinanciera,
        handleOpenFormFinanciera,
        formCatalogoFinanciera,
        handleSubmitFormFinanciera,
        financieraSeleccionada,
        listaBuros,
        handleChangeFormFinanciera,
    } = useFinancieras();

    const { txtNombreFinanciera, cmbBoxIdBuro, txtUsuBuroCred, txtPwdBuroCred, } = formCatalogoFinanciera;

    return (
        <DinercapModal
            title={
                financieraSeleccionada.iIdFinanciera === -1
                    ? "Agregar financiera"
                    : "Actualizar financiera"
            }
            okFunc={handleSubmitFormFinanciera}
            setOpen={handleOpenFormFinanciera}
            open={openFormFinanciera}
            okMessage="Guardar"
            size="small"
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        name={txtNombreFinanciera.name}
                        label={txtNombreFinanciera.label}
                        required={txtNombreFinanciera.required}
                        value={txtNombreFinanciera.value}
                        error={txtNombreFinanciera.error}
                        helperText={txtNombreFinanciera.error && txtNombreFinanciera.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormFinanciera}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        className="mb-2"
                        inputProps={{
                            maxLength: txtNombreFinanciera.maxlength,
                        }}
                    />
                    <FormControl fullWidth variant="outlined" error={cmbBoxIdBuro.error}>
                        <InputLabel id="demo-simple-select-error-label">{cmbBoxIdBuro.label}</InputLabel>
                        <Select
                            labelId="demo-simple-select-error-label"
                            id={cmbBoxIdBuro.name}
                            name={cmbBoxIdBuro.name}
                            label={cmbBoxIdBuro.label}
                            required={cmbBoxIdBuro.required}
                            error={cmbBoxIdBuro.error}

                            value={cmbBoxIdBuro.value}
                            onChange={handleChangeFormFinanciera}
                        >
                            <MenuItem value="">
                                <em>Seleccione el buro</em>
                            </MenuItem>
                            {listaBuros.map((buro) => {
                                    return (
                                        <MenuItem
                                            key={buro.iidburo}
                                            value={buro.iidburo}
                                        >
                                            {buro.snombre}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                        <FormHelperText>{cmbBoxIdBuro.error && cmbBoxIdBuro.message}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1}}>
                        <TextField
                            name={txtUsuBuroCred.name}
                            label={txtUsuBuroCred.label}
                            required={txtUsuBuroCred.required}
                            value={txtUsuBuroCred.value}
                            error={txtUsuBuroCred.error}
                            helperText={txtUsuBuroCred.error && txtUsuBuroCred.message}
                            variant={inputVariant}
                            color={inputColor}
                            onChange={handleChangeFormFinanciera}
                            fullWidth
                            autoFocus
                            autoComplete="off"
                            className="mb-2"
                            inputProps={{
                                maxLength: txtUsuBuroCred.maxlength,
                            }}
                        />
                    </FormControl>
                    <TextField
                        name={txtPwdBuroCred.name}
                        label={txtPwdBuroCred.label}
                        required={txtPwdBuroCred.required}
                        value={txtPwdBuroCred.value}
                        error={txtPwdBuroCred.error}
                        helperText={txtPwdBuroCred.error && txtPwdBuroCred.message}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormFinanciera}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        className="mb-2"
                        inputProps={{
                            maxLength: txtPwdBuroCred.maxlength,
                        }}
                    />
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
