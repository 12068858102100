import { createContext, useContext, useState } from "react";
import Swal from "sweetalert2";
import Enumerable from "linq";

import { getFormOriginador, getModelOriginador } from "../../models/OriginadorModel";
import { fnHandleChangeText, fnHandleChangeFile } from "../../settings/handleConfig";
import { apiCGetOriginador, apiCSaveOriginador, apiCDescargarXML } from "../../settings/apiConfig";
import { fnPrevent } from "../../settings/preventConfig";
import { useRequest } from "../RequestContext";
import { useLayout } from "../LayoutContext";

export const OriginadorContext = createContext();

export function OriginadorProvider(props) {
    const { handleOpenAlert } = useLayout();
    const { Get, Post, DownloadFileBlob } = useRequest();

    //Originador
    const [openOriginador, setOpenOriginador] = useState(false);
    const [listaOriginador, setListaOriginador] = useState([]);
    const [formOriginador, setFormOriginador] = useState(getFormOriginador());
    const [originadorSeleccionado, setOriginadorSeleccionado] = useState(getModelOriginador());

    const [openFormOriginador, setOpenFormOriginador] = useState(false);

    const handleOpenOriginador = (value) => {
        setOpenOriginador(value);
    };

    const handleObtenerOriginador = async () => {
        const payload = {
            loader: "Consultando Originador",
            url: apiCGetOriginador,
            alert: true,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            if (response.result.length > 0) {
                const _listaOriginador = Enumerable.from(response.result)
                    .select((originador) => ({
                        iIdOriginadorTSolicitud: originador.iIdOriginadorTSolicitud,
                        iIdTipoSolicitud: originador.iIdTipoSolicitud,
                        entTipoSolicitud: originador.entTipoSolicitud,
                        sNombre: originador.sNombre,
                        sNombrePatron:
                            originador.sNombrePatron != null
                                ? originador.sNombrePatron.split("/").length > 1
                                    ? originador.sNombrePatron.split("/")[
                                    originador.sNombrePatron.split("/").length - 1
                                    ]
                                    : originador.sNombrePatron
                                : "",
                        sNombrePatronDoctos:
                            originador.sNombrePatronDoctos != null
                                ? originador.sNombrePatronDoctos.split("/").length > 1
                                    ? originador.sNombrePatronDoctos.split("/")[
                                    originador.sNombrePatronDoctos.split("/").length - 1
                                    ]
                                    : originador.sNombrePatronDoctos
                                : "",
                        urlXML: originador.sNombrePatron,
                    }))
                    .toArray();
                setListaOriginador(_listaOriginador);
            } else {
                handleOpenAlert("No se encontraron registros", "error");
            }
        } else {
            handleOpenAlert("Error al obtener los Originadores", "error");
        }
    };

    const handleOpenFormOriginador = (value) => {
        setOpenFormOriginador(value);
        if (!value) {
            setFormOriginador(getFormOriginador());
            setOriginadorSeleccionado(getModelOriginador());
        }
    };

    const handleChangeFormOriginador = fnHandleChangeText(formOriginador, setFormOriginador);
    const handleChangeFileFormDocumento = fnHandleChangeFile(formOriginador, setFormOriginador);

    const handleSubmitFormOriginador = async (e) => {
        fnPrevent(e);
        const iIdOrigTS =
            originadorSeleccionado.iIdOriginadorTSolicitud === undefined
                ? -1
                : originadorSeleccionado.iIdOriginadorTSolicitud;
        const { cmbBoxTipoSolicitud, txtNombreOriginador, txtFileDatos, txtFileDocto } = formOriginador;

        const form = {
            ...formOriginador,
            cmbBoxTipoSolicitud: {
                ...cmbBoxTipoSolicitud,
                error: cmbBoxTipoSolicitud.value === "",
            },
            txtNombreOriginador: {
                ...txtNombreOriginador,
                error: txtNombreOriginador.value === "",
            },
            txtFileDatos: {
                ...txtFileDatos,
                error: iIdOrigTS === -1 ? txtFileDatos.value === "" && txtFileDatos.nombreArchivo === "" : false,
            },
            txtFileDocto: {
                ...txtFileDocto,
                error: iIdOrigTS === -1 ? txtFileDocto.value === "" && txtFileDocto.nombreArchivo === "" : false,
            },
        };

        setFormOriginador(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }

        const entOriginador = {
            iIdOriginadorTSolicitud: iIdOrigTS,
            iIdTipoSolicitud: cmbBoxTipoSolicitud.value,
            sNombre: txtNombreOriginador.value,
            sNombrePatron: txtFileDatos.nombreArchivo,
            sPatronBlob: txtFileDatos.value !== "" ? txtFileDatos.value.split(",")[1] : "",
            sNombrePatronDoctos: txtFileDocto.nombreArchivo,
            sPatronBlobDoctos: txtFileDocto.value !== "" ? txtFileDocto.value.split(",")[1] : "",
            iIDUsuarioCreacion: iIdOrigTS === -1 ? 1 : 0,
            iIDUsuarioModificacion: iIdOrigTS !== -1 ? 1 : 0,
            iIDUsuarioElimina: 0,
        };

        await fn_guardarOriginador(entOriginador);
    };

    const fn_guardarOriginador = async (entOriginador) => {
        let loader = "";
        if (entOriginador.iIDUsuarioCreacion > 0) {
            loader = "Guardando el documento.";
        } else if (entOriginador.iIDUsuarioModificacion > 0) {
            loader = "Actualizando el documento.";
        } else if (entOriginador.iIDUsuarioElimina > 0) {
            loader = "Eliminando el documento.";
        }

        const payload = {
            loader: loader,
            body: entOriginador,
            url: apiCSaveOriginador,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            handleOpenFormOriginador(false);
            setOriginadorSeleccionado(getModelOriginador());
            setFormOriginador(getFormOriginador());
            await handleObtenerOriginador();
        }
    };

    const handleActualizarOriginador = (e, originador) => {
        fnPrevent(e);
        setOriginadorSeleccionado(originador);

        const { cmbBoxTipoSolicitud, txtNombreOriginador } = formOriginador;

        const form = {
            ...formOriginador,
            cmbBoxTipoSolicitud: {
                ...cmbBoxTipoSolicitud,
                value: originador.iIdTipoSolicitud,
            },
            txtNombreOriginador: {
                ...txtNombreOriginador,
                value: originador.sNombre,
            },
        };

        setFormOriginador(form);
        setOpenFormOriginador(true);
    };

    const handleEliminarOriginador = async (e, originador) => {
        fnPrevent(e);
        Swal.fire({
            title: "Eliminar Originador",
            text: "¿Está seguro de eliminar este registro?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await fn_eliminarOriginador(originador);
            }
        });
    };

    const fn_eliminarOriginador = async (originador) => {
        const entOriginador = {
            iIdOriginadorTSolicitud: originador.iIdOriginadorTSolicitud,
            iIDUsuarioElimina: 1,
            iIDUsuarioCreacion: 0,
            iIDUsuarioModificacion: 0,
        };
        await fn_guardarOriginador(entOriginador);
    };

    const handleDescargarXML = async (e, sNombreArchivo) => {
        fnPrevent(e);
        const payload = {
            loader: "Generando XML.",
            url: apiCDescargarXML.replace("{sNombreArchivo}", sNombreArchivo),
            alert: true,
            extension: "xml",
            nombre: sNombreArchivo
        };

        await DownloadFileBlob(payload);
    };

    return (
        <OriginadorContext.Provider
            value={{
                openOriginador,
                formOriginador,
                listaOriginador,
                openFormOriginador,
                originadorSeleccionado,
                handleOpenOriginador,
                handleObtenerOriginador,
                handleOpenFormOriginador,
                handleEliminarOriginador,
                handleActualizarOriginador,
                handleChangeFormOriginador,
                handleSubmitFormOriginador,
                handleChangeFileFormDocumento,
                handleDescargarXML
            }}
        >
            {props.children}
        </OriginadorContext.Provider>
    );
}

export function useOriginador() {
    return useContext(OriginadorContext);
}
