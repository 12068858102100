import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useFormatoSolicitud } from "../../../context/FormatoSolicitudContext";
import { AppBar, Button, Dialog, Paper, Slide, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import swal from "sweetalert";
import { CancelOutlined, Search, Clear } from "@mui/icons-material";
import Enumerable from "linq";

//DataGrid
import {
    DataGrid,
    esES,
    GridRow,
    GridToolbarExport,
    GridToolbarContainer,
    gridColumnsTotalWidthSelector,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import clsx from "clsx";
//DataGrid

const useStyles = makeStyles()((theme) => {
    return {
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        container: {
            maxHeight: 800,
        },
        root: {
            width: "100%",
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    };
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//DataGrid
function CustomRow(props) {
    const { className, index, ...other } = props;

    return <GridRow index={index} className={clsx(className, index % 2 === 0 ? "odd" : "even")} {...other} />;
}

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

function QuickSearchToolbar(props) {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <div>
                <GridToolbarContainer>
                    <GridToolbarExport />
                </GridToolbarContainer>
            </div>
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <Search fontSize="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? "visible" : "hidden" }}
                            onClick={props.clearSearch}
                        >
                            <Clear fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: "auto",
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    "& .MuiSvgIcon-root": {
                        mr: 0.5,
                    },
                    "& .MuiInput-underline:before": {
                        borderBottom: 1,
                        borderColor: "divider",
                    },
                }}
            />
        </Box>
    );
}

export default function ConfigurarFormatoModal() {
    const [pageSize, setPageSize] = useState(100);
    const {
        formatoSolicitudSeleccionado,
        modalOpenConfigurarFormato,
        handleOpenModalConfigurarFormato,
        listaFormatoCampos,
        listaBloquesSolicitud,
        handleSaveFormatoCampoValor,
        handleDeleteFormatoCampoValor,
        handleListaFormatoCampos,
    } = useFormatoSolicitud();

    const handleClose = () => {
        handleOpenModalConfigurarFormato(false);
    };

    const classes = useStyles();

    const [open, setOpen] = useState(-1);

    const handleClick = (e, index) => {
        if (index === open) {
            setOpen(-1);
        } else {
            setOpen(index);
        }
    };
    const [data, setData] = useState([]);
    const [rows, setRows] = useState([]);
    const [selected, setSelected] = useState([]);

    const [campos, setCampos] = useState([]);

    const createData = (msj) => {
        let _data = [];
        for (let index = 0; index < listaFormatoCampos.length; index++) {
            let { iIDCampoFormatoSolicitud, cvValor, cvValores } = listaFormatoCampos[index];
            if (cvValores === null) {
                _data.push({
                    iIDCampoFormatoSolicitud,
                    iIDValoresFormatoSolicitud: 0,
                    iIDBloqueSolicitudCampos: 0,
                    value: "",
                    cvValores: [],
                });
            } else {
                let { iIDValoresFormatoSolicitud, iIDBloqueSolicitudCampos } = cvValor;
                _data.push({
                    iIDCampoFormatoSolicitud,
                    iIDValoresFormatoSolicitud,
                    iIDBloqueSolicitudCampos,
                    value: obtenerNombreCampos(cvValores),
                    cvValores: cvValores,
                });
            }
        }
        setCampos(_data);
    };

    const obtenerNombreCampos = (cvValores) => {
        let sCampos = "";

        cvValores.forEach((item) => {
            if (item.ecCampo !== null) {
                let { sNombre } = item.ecCampo;
                sCampos += sNombre + " | ";
            } else {
            }
        });

        sCampos = sCampos.substring(0, sCampos.length - 2);

        return sCampos;
    };

    const insertDataRows = () => {
        setData([]);
        setRows([]);
        if (listaFormatoCampos.length > 0) {
            let _data = listaFormatoCampos.map(function (item, index) {
                let name = item.sNombre ? item.sNombre : "";
                return { id: index, iIDCampoFormatoSolicitud: item.iIDCampoFormatoSolicitud, sNombre: name };
            });
            setData(_data);
            if (searchText === "") setRows(_data);
            else {
                requestSearch(searchText);
            }
        }
    };

    useEffect(() => {
        if (modalOpenConfigurarFormato) {
            insertDataRows();
            createData("ejecutar");
        } else {
            setCampos([]);
            setRows([]);
            setData([]);
        }
    }, [listaFormatoCampos]);

    const handleClickCampo = async (e, sNombreCampo, iIDBloqueSolicitudCampo) => {
        if (selected.length > 0) {
            const iIDCampoFormatoSolicitudFila = data[selected[0]].iIDCampoFormatoSolicitud;
            for (let index = 0; index < campos.length; index++) {
                let { iIDCampoFormatoSolicitud, iIDBloqueSolicitudCampos, value } = campos[index];
                if (iIDCampoFormatoSolicitudFila === iIDCampoFormatoSolicitud) {
                    iIDBloqueSolicitudCampos = iIDBloqueSolicitudCampo;
                    await handleSaveFormatoCampoValor(
                        e,
                        iIDCampoFormatoSolicitud,
                        iIDBloqueSolicitudCampos,
                        formatoSolicitudSeleccionado.iIDFormatoSolicitud
                    );
                    handleListaFormatoCampos(formatoSolicitudSeleccionado.iIDFormatoSolicitud);
                    break;
                    // if (value === '') {
                    //     iIDBloqueSolicitudCampos = iIDBloqueSolicitudCampo;
                    //     await handleSaveFormatoCampoValor(
                    //         e,
                    //         iIDCampoFormatoSolicitud,
                    //         iIDBloqueSolicitudCampos,
                    //         formatoSolicitudSeleccionado.iIDFormatoSolicitud
                    //     );
                    //     handleListaFormatoCampos(formatoSolicitudSeleccionado.iIDFormatoSolicitud);
                    //     break;
                    // } else {
                    //     swal({
                    //         title: "Agregar campo",
                    //         text: "La fila seleccionada ya contiene un valor de campo!",
                    //         icon: "warning",
                    //     });
                    // }
                }
            }
        } else {
            swal({
                title: "Agrega campo",
                text: "Debe de seleccionar un fila de la tabla!",
                icon: "error",
            });
        }
    };

    const handleClicEliminarCampo = (e, row) => {
        let msj = "El registro será eliminado. ¿Desea continuar?";
        swal({
            title: "Eliminar campo de la fila",
            text: msj,
            icon: "warning",
            buttons: ["Cancelar", "Sí"],
            dangerMode: true,
            closeOnClickOutside: false,
        }).then((willDelete) => {
            if (willDelete) {
                eliminarCampoFila(e, row);
            }
        });
    };

    const eliminarCampoFila = async (e, row) => {
        let _data = [];
        for (let index = 0; index < campos.length; index++) {
            let { iIDCampoFormatoSolicitud, iIDValoresFormatoSolicitud, iIDBloqueSolicitudCampos, value, cvValores } =
                campos[index];

            let filter = cvValores.filter((item) => item.iIDCampoFormatoSolicitud === row.iIDCampoFormatoSolicitud);
            if (filter.length > 0) {
                //idcampo primaria del formato
                let camposEliminar = Enumerable.from(cvValores)
                    .select((item) => ({
                        iIDValoresFormatoSolicitud: item.iIDValoresFormatoSolicitud,
                        iIDCampoFormatoSolicitud: item.iIDCampoFormatoSolicitud,
                    }))
                    .toArray();

                await handleDeleteFormatoCampoValor(
                    e,
                    camposEliminar,
                    formatoSolicitudSeleccionado.iIDFormatoSolicitud
                );
                //handleListaFormatoCampos(row.iIDFormatoSolicitud);
                break;
            } else {
                _data.push({
                    iIDCampoFormatoSolicitud,
                    iIDValoresFormatoSolicitud,
                    iIDBloqueSolicitudCampos,
                    value,
                });
            }
        }
        setCampos(_data);
    };

    const obtenerCampoValor = (_iIDCampoFormatoSolicitud) => {
        let valor = "";
        for (let index = 0; index < campos.length; index++) {
            let { iIDCampoFormatoSolicitud, value } = campos[index];
            if (iIDCampoFormatoSolicitud === _iIDCampoFormatoSolicitud) {
                valor = value;
            }
        }
        return valor;
    };

    const columns = [
        { field: "id", headerName: "Indice", width: 100, hide: true },
        { field: "iIDCampoFormatoSolicitud", headerName: "ID", width: 50 },
        { field: "sNombre", headerName: "Nombre", width: 350 },
        {
            field: "Campo",
            headerName: "Campo",
            width: 350,
            renderCell: (cellValues) => {
                return obtenerCampoValor(cellValues.row.iIDCampoFormatoSolicitud);
            },
        },
        {
            field: "Acciones",
            width: 100,
            renderCell: (cellValues) => {
                return (
                    <div>
                        {obtenerCampoValor(cellValues.row.iIDCampoFormatoSolicitud) !== "" ? (
                            <Tooltip title="Rechazar">
                                <Button
                                    variant="contained"
                                    aria-label="rechazar"
                                    size="small"
                                    className="btn-danger"
                                    onClick={(event) => {
                                        handleClicEliminarCampo(event, cellValues.row);
                                    }}
                                >
                                    <CancelOutlined />
                                </Button>
                            </Tooltip>
                        ) : (
                            ""
                        )}
                    </div>
                );
            },
        },
    ];

    const [searchText, setSearchText] = useState("");
    const requestSearch = async (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = data.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
    };

    return (
        <Dialog
            fullScreen
            open={modalOpenConfigurarFormato}
            onClose={handleClose}
            TransitionComponent={Transition}
            key="form"
        >
            <Box sx={{ flexFlow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" className={classes.title} component="div" sx={{ flexGrow: 1 }}>
                            Formato de solicitud
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Box>
            <div className="flex flex-col gap-2  p-4">
                <div className="flex flex-row justify-between items-center flex-wrap ml-2 mt-2 mr-2 ">
                    <div>
                        <h1 className="text-gray-700">
                            <b>Formato: {formatoSolicitudSeleccionado.sNombreArchivo}</b>
                        </h1>
                        <p>
                            Seleccionar un campo del formato (Tabla de la izquierda), posteriormente seleccione de los
                            bloques (Lista de la derecha) el valor que se le asignará{" "}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <div className="card mb-3">
                            <div className="card-header">
                                <b>Campos del formato</b>
                            </div>
                            <div className="card-body">
                                <Box
                                    sx={{
                                        height: 600,
                                        width: "100%",
                                        "& .odd": {
                                            bgcolor: "rgba(0, 0, 0, 0.05)",
                                        },
                                        "& .even": {
                                            bgcolor: "#ffffff",
                                        },
                                    }}
                                >
                                    <DataGrid
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        rows={rows}
                                        components={{ Toolbar: QuickSearchToolbar, Row: CustomRow }}
                                        componentsProps={{
                                            toolbar: {
                                                value: searchText,
                                                onChange: (event) => requestSearch(event.target.value),
                                                clearSearch: () => requestSearch(""),
                                            },
                                        }}
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setSelected(newSelectionModel);
                                        }}
                                        pageSize={pageSize}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                        columns={columns.map((column) => ({
                                            ...column,
                                        }))}
                                    />
                                </Box>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="card mb-3">
                            <div className="card-header">
                                <b>Bloques</b>
                            </div>
                            <div className="card-body">
                                <Paper style={{ maxHeight: 600, overflow: "auto" }}>
                                    <List
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                        subheader={
                                            <ListSubheader component="div" id="nested-list-subheader">
                                                Seleccione el campo para enviar a la fila seleccionada
                                            </ListSubheader>
                                        }
                                        className={classes.root}
                                    >
                                        {listaBloquesSolicitud.map((bloqueSolicitud, index) => {
                                            return (
                                                <div>
                                                    <ListItem
                                                        button
                                                        key={bloqueSolicitud.iIDBloqueSolicitud}
                                                        onClick={(e) => handleClick(e, index)}
                                                    >
                                                        <ListItemIcon>
                                                            <i className={"fas fa-" + bloqueSolicitud.sIcono}></i>
                                                        </ListItemIcon>
                                                        <ListItemText primary={bloqueSolicitud.sNombreBloque} />
                                                        {open === index ? <ExpandLess /> : <ExpandMore />}
                                                    </ListItem>
                                                    <Collapse in={open === index} timeout="auto" unmountOnExit>
                                                        {bloqueSolicitud.aCampos.map((campo) => {
                                                            return (
                                                                <List
                                                                    component="div"
                                                                    disablePadding
                                                                    key={campo.iIDBloqueSolicitudCampos}
                                                                    onClick={(e) => {
                                                                        handleClickCampo(
                                                                            e,
                                                                            campo.sNombre,
                                                                            campo.iIDBloqueSolicitudCampos
                                                                        );
                                                                    }}
                                                                >
                                                                    <ListItem button className={classes.nested}>
                                                                        <ListItemIcon>
                                                                            <i className={"fas fa-" + campo.sIcono}></i>
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={campo.sNombre} />
                                                                    </ListItem>
                                                                </List>
                                                            );
                                                        })}
                                                    </Collapse>
                                                </div>
                                            );
                                        })}
                                    </List>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
