import { Dialog, IconButton, Paper } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import { Fragment } from "react";
import { fnPrevent } from "../../settings/preventConfig";
import { useStyle } from "../../context/StyleContext";
import DinercapLoader from "./DinercapLoader";

const PaperComponent = (id) => {
    return function (propsPaper) {
        return (
            <Draggable handle={"#dinercap-drag-" + id} cancel={'[class*="MuiDialogContent-root"]'}>
                <Paper {...propsPaper} style={{ borderRadius: 12 }} />
            </Draggable>
        );
    };
};

const PaperComponent1 = PaperComponent(1);
const PaperComponent2 = PaperComponent(2);
const PaperComponent3 = PaperComponent(3);

export default function DinercapModal(props) {
    const {
        size,
        title,
        children,
        open,
        setOpen,
        level,
        okMessage,
        cancelMessage,
        okFunc,
        cancelFunc,
        okDisabled,
        align,
        hideCancel,
        hideOk,
        form,
    } = props;

    const { classes } = useStyle();
    const mmLevel = level === undefined ? 1 : level;

    const mtOkMessage = okMessage === undefined ? "Confirmar" : okMessage;
    const mtCancelMessage = cancelMessage === undefined ? "Cancelar" : cancelMessage;
    const mtForm = form ? form : false;

    const mtOkFunc =
        okFunc === undefined
            ? mtForm
                ? (e) => {
                      fnPrevent(e);
                      setOpen(false);
                  }
                : () => {
                      setOpen(false);
                  }
            : okFunc;
    const mtCancelFunc =
        cancelFunc === undefined
            ? () => {
                  setOpen(false);
              }
            : cancelFunc;

    const mtAlign = align === undefined ? "right" : align;

    const mtHideCancel = hideCancel === undefined ? false : hideCancel;
    const mtHideOk = hideOk === undefined ? false : hideOk;

    const mtOkDisabled = okDisabled === undefined ? false : okDisabled;

    const ModalBotones = () => (
        <div className={mtAlign + " mar-top-20"}>
            {!mtHideCancel && (
                <button
                    disableElevation
                    // style={{ color: "#555", marginRight: "10px" }}
                    className="btn btn-secondary btn-md mr-2"
                    onClick={mtCancelFunc}
                >
                    {mtCancelMessage}
                </button>
            )}
            {!mtHideOk && (
                <button className="btn btn-confirm btn-md" disableElevation disabled={mtOkDisabled} onClick={mtOkFunc}>
                    {mtOkMessage}
                </button>
            )}
            <button type="submit" hidden>
                SUBMIT
            </button>
        </div>
    );

    return (
        <Dialog
            open={open}
            onClose={mtCancelFunc}
            BackdropProps={{
                timeout: 300,
                style: { backgroundColor: "rgb(255 255 255 / 0.7)" },
            }}
            scroll="body"
            PaperComponent={mmLevel === 2 ? PaperComponent2 : mmLevel === 3 ? PaperComponent3 : PaperComponent1}
            disableBackdropClick
            fullWidth={true}
            maxWidth={size === "small" ? "sm" : size === "normal" ? "md" : "lg"}
            classes={{ paperScrollBody: classes.paperScrollBody, root: classes.dialogRoot }}
            {...props}
        >
            <div className="flex cursor-move pt-2 pr-2" id={"dinercap-drag-" + mmLevel}>
                <div className="flx-grw-1 align-self-center">
                    <span className="rob-nor size-20 pad-left-20">{title}</span>
                </div>
                <IconButton onClick={mtCancelFunc}>
                    <CloseIcon className="" />
                </IconButton>
            </div>
            <div className="modal-from-content">
                {mtForm ? (
                    <form onSubmit={mtOkFunc} noValidate>
                        {children}
                        <ModalBotones />
                    </form>
                ) : (
                    <Fragment>
                        {children}
                        <ModalBotones />
                    </Fragment>
                )}
                <DinercapLoader></DinercapLoader>
            </div>
        </Dialog>
    );
}
