import { Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import DinercapFooter from "./DinercapFooter";

import { urlSystem } from "../../settings/urlConfig";
import HomeScreen from "../content/home/HomeScreen";
import TiposSolicitudesScreen from "../content/tipos_solicitudes/TiposSolicitudesScreen";
import ConfiguracionSolicitudScreen from "../content/configuracion_solicitud/ConfiguracionSolicitudScreen";
import FormatosSolicitudScreen from "../content/formatos_solicitud/FormatosSolicitudScreen";
import SeguimientosSolicitudScreen from "../content/seguimientos_solicitud/SeguimientosSolicitudScreen";
import ConfiguracionOCRScreen from "../content/configuracion_ocr/ConfiguracionOCRScreen";
import ConfiguracionDatosScreen from "../content/configuracion_datos/ConfiguracionDatosScreen";
import CatalogosScreen from "../content/catalogos/CatalogosScreen";

export default function DinercapContent() {

    return (
        <Fragment>
            <div className="flx-grw-1 pos-rel">
                {/* <DinercapNavBar /> */}
                {/* <DinercapDrawerLeft /> */}
                <div>
                    <Switch>
                        <Route exact path="/">
                            <HomeScreen />
                        </Route>
                        <Route exact path={urlSystem.login}>
                            <HomeScreen />
                        </Route>
                        <Route exact path={urlSystem.tiposSolicitudes}>
                            <TiposSolicitudesScreen />
                        </Route>
                        <Route exact path={urlSystem.configuracionSolicitud}>
                            <ConfiguracionSolicitudScreen />
                        </Route>
                        <Route exact path={urlSystem.formatosSolicitud}>
                            <FormatosSolicitudScreen />
                        </Route>
                        <Route exact path={urlSystem.seguimientosSolicitud}>
                            <SeguimientosSolicitudScreen />
                        </Route>
                        <Route exact path={urlSystem.configuracionOCR}>
                            <ConfiguracionOCRScreen />
                        </Route>
                        <Route exact path={urlSystem.configuracionDatos}>
                            <ConfiguracionDatosScreen />
                        </Route>
                        <Route exact path={urlSystem.catalogosGenerales}>
                            <CatalogosScreen />
                        </Route>
                        <Redirect to="/" />
                    </Switch>
                </div>
            </div>
            {<DinercapFooter />}
        </Fragment>
    );
}
