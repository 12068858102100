import { createContext, useContext, useState } from "react";
import { getModelCampoFormatoSolicitud, getModelFormatoSolicitud } from "../models/FormatoSolicitudModel";
import { useConfiguracionSolicitud } from "./ConfiguracionSolicitudContext";
import { useRequest } from "./RequestContext";
import { fnPrevent, fnPreventBlur } from "../settings/preventConfig";
import {
    apiCGetFormatoByTipoSolicitud,
    apiCGetFormatoCampoByFormato,
    apiFormatoSaveFormatoCampoValor,
    apiFormatoDeleteCampoFormatoValor,
} from "../settings/apiConfig";
import Enumerable from "linq";

export const FormatoSolicitudContext = createContext();

export function FormatoSolicitudProvider(props) {
    const { listaBloquesSolicitud, tipoSolicitudSeleccionado, handleClickObtenerBloquesSolicitud } =
        useConfiguracionSolicitud();

    const { Get, Post } = useRequest();

    const [listaFormatosSolicitud, setListaFormatosSolicitud] = useState([]);
    const [listaFormatoCampos, setListaFormatoCampos] = useState([]);

    const [formatoSolicitudSeleccionado, setFormatoSolicitudSeleccionado] = useState(getModelFormatoSolicitud());
    const [campoFormatoSolicitudSeleccionado, setCampoFormatoSolicitudSeleccionado] = useState(
        getModelCampoFormatoSolicitud()
    );

    const [modalOpenConfigurarFormato, setModalOpenConfigurarFormato] = useState(false);

    const handleClickObtenerFormatosSolicitud = async (value) => {
        await fnObtenerFormatosSolicitud(value);
    };

    const handleOpenModalConfigurarFormato = (value) => {
        setModalOpenConfigurarFormato(value);
    };

    const handleChangeFormatoSolicitudSeleccionado = (value) => {
        setFormatoSolicitudSeleccionado(value);
    };

    const handleChangeCampoFormatoSolicitudSeleccionado = (value) => {
        setCampoFormatoSolicitudSeleccionado(value);
    };

    const handleClickFormatoSolicitud = async (value) => {
        const formato = listaFormatosSolicitud.find((x) => x.iIDFormatoSolicitud === value);
        handleChangeFormatoSolicitudSeleccionado(formato);

        handleListaFormatoCampos(formato.iIDFormatoSolicitud);
        handleClickObtenerBloquesSolicitud(tipoSolicitudSeleccionado.iIDTipoSolicitud);
        setModalOpenConfigurarFormato(true);
    };

    const handleClickCampoFormato = (value) => {
        const campoFormato = formatoSolicitudSeleccionado.aCampos.find((x) => x.iIDCampoFormatoSolicitud === value);
        handleChangeCampoFormatoSolicitudSeleccionado(campoFormato);
    };

    const handleListaFormatoCampos = (value) => {
        fnObtenerListFormatoCampos(value);
    };

    const handleSaveFormatoCampoValor = (
        e,
        iIDCampoFormatoSolicitud,
        iIDBloqueSolicitudCampos,
        iIDFormatoSolicitud
    ) => {
        fnPrevent(e);
        const request = {
            iIDUsuarioCreacion: 1,
            bActivo: true,
            bBaja: false,
            iIDCampoFormatoSolicitud: iIDCampoFormatoSolicitud,
            iIDBloqueSolicitudCampos: iIDBloqueSolicitudCampos,
        };
        fnSaveFormatoCampoValor(request, iIDFormatoSolicitud);
    };

    const handleDeleteFormatoCampoValor = (e, camposEliminar, iIDFormatoSolicitud) => {
        fnPrevent(e);

        const request = Enumerable.from(camposEliminar)
            .select((item) => ({
                iIDUsuarioElimina: 1,
                iIDValoresFormatoSolicitud: item.iIDValoresFormatoSolicitud,
                iIDCampoFormatoSolicitud: item.iIDCampoFormatoSolicitud,
            }))
            .toArray();
        fnDeleteFormatoCampoValor(request, iIDFormatoSolicitud);
    };
    //==================================================================================
    const fnSaveFormatoCampoValor = async (request, iIDFormatoSolicitud) => {
        const payload = {
            loader: "Guardando formato campo valor...",
            url: apiFormatoSaveFormatoCampoValor,
            body: request,
            alert: true,
        };
        const response = await Post(payload);
        if (response.iCodigo === 201) {
            await fnObtenerListFormatoCampos(iIDFormatoSolicitud);
        }
        fnPreventBlur();
    };

    const fnDeleteFormatoCampoValor = async (request, iIDFormatoSolicitud) => {
        const payload = {
            loader: "Eliminando formato campo valor...",
            url: apiFormatoDeleteCampoFormatoValor,
            body: request,
            alert: true,
        };
        const response = await Post(payload);
        await fnObtenerListFormatoCampos(iIDFormatoSolicitud);
        fnPreventBlur();
    };

    const fnObtenerListFormatoCampos = async (value) => {
        const payload = {
            loader: "Consultando listado formato campos...",
            url: apiCGetFormatoCampoByFormato.replace("{piIDFormatoSolicitud}", value),
            alert: false,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setListaFormatoCampos(response.result);
        } else {
            setListaFormatoCampos([]);
        }
    };

    const fnObtenerFormatosSolicitud = async (value) => {
        const payload = {
            loader: "Consultando formatos de solicitud...",
            url: apiCGetFormatoByTipoSolicitud.replace("{piIDTipoSolicitud}", value),
            alert: false,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setListaFormatosSolicitud(response.result);
        } else {
            setListaFormatosSolicitud([]);
        }
    };

    return (
        <FormatoSolicitudContext.Provider
            value={{
                listaBloquesSolicitud,
                listaFormatoCampos,
                listaFormatosSolicitud,
                formatoSolicitudSeleccionado,
                campoFormatoSolicitudSeleccionado,
                modalOpenConfigurarFormato,
                setModalOpenConfigurarFormato,
                handleOpenModalConfigurarFormato,
                handleChangeFormatoSolicitudSeleccionado,
                handleChangeCampoFormatoSolicitudSeleccionado,
                handleClickObtenerFormatosSolicitud,
                handleClickFormatoSolicitud,
                handleClickCampoFormato,
                handleListaFormatoCampos,
                handleSaveFormatoCampoValor,
                handleDeleteFormatoCampoValor,
            }}
        >
            {props.children}
        </FormatoSolicitudContext.Provider>
    );
}

export function useFormatoSolicitud() {
    return useContext(FormatoSolicitudContext);
}
