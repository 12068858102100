import { Grid, Typography } from "@mui/material";

import DinercapInfoField from "../../utils/DinercapInfoField";
import DinercapModal from "../../utils/DinercapModal";
import { useConfiguracionSolicitud } from "../../../context/ConfiguracionSolicitudContext";

export default function EliminarCampoBloqueSolicitud() {
    const {
        campoBloqueSolicitudSeleccionado,
        modalOpenEliminarCampoBloqueSolicitud,
        handleOpenEliminarCampoBloqueSolicitud,
        handleSubmitEliminarCampoBloqueSolicitud,
    } = useConfiguracionSolicitud();

    return (
        <DinercapModal
            title="Eliminar Campo"
            open={modalOpenEliminarCampoBloqueSolicitud}
            setOpen={handleOpenEliminarCampoBloqueSolicitud}
            okFunc={handleSubmitEliminarCampoBloqueSolicitud}
            // align="center"
            size="small"
        >
            <Grid container spacing={1} className="center">
                <Grid item xs={12}>
                    <Typography paragraph align="center">
                        ¿Desea eliminar el siguiente campo del bloque?
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <DinercapInfoField label="Campo:" value={campoBloqueSolicitudSeleccionado.sNombre} />
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
