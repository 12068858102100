import { BrowserRouter } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import { LayoutProvider } from "./context/LayoutContext";
import { LoginProvider } from "./context/LoginContext";
import { CatalogoProvider } from "./context/CatalogoContext";
import DinercapPage from "./components/DinercapPage";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { RequestProvider } from "./context/RequestContext";
import { StyleProvider } from "./context/StyleContext";
import { ThemeProvider } from "@mui/material/styles";
import { TokenProvider } from "./context/TokenContext";
import es from "date-fns/locale/es";
import theme from "./settings/themeConfig";
import { urlBase } from "./settings/urlConfig";
import { TipoSolicitudProvider } from "./context/TipoSolicitudContext";
import { ConfiguracionSolicitudProvider } from "./context/ConfiguracionSolicitudContext";
import { FormatoSolicitudProvider } from "./context/FormatoSolicitudContext";
import { SolicitudProvider } from "./context/SolicitudContext";
import { CampoBloqueValidacionProvider } from "./context/CampoBloqueValidacionContext";
import CatalogosGeneralesProvider from "./context/CatalogosGeneralesContext";
import { ConfiguracionOCRProvider } from "./context/ConfiguracionOCRContext";
import { ConfiguracionDatosDirProvider } from "./context/ConfiguracionDatosContext";

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter basename={urlBase}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                    <StyleProvider>
                        <LayoutProvider>
                            <TokenProvider>
                                <RequestProvider>
                                    <CatalogosGeneralesProvider>
                                        <CatalogoProvider>
                                            <LoginProvider>
                                                <TipoSolicitudProvider>
                                                    <ConfiguracionSolicitudProvider>
                                                        <FormatoSolicitudProvider>
                                                            <SolicitudProvider>
                                                                <CampoBloqueValidacionProvider>
                                                                    <ConfiguracionOCRProvider>
                                                                        <ConfiguracionDatosDirProvider>
                                                                            <DinercapPage />
                                                                        </ConfiguracionDatosDirProvider>
                                                                    </ConfiguracionOCRProvider>
                                                                </CampoBloqueValidacionProvider>
                                                            </SolicitudProvider>
                                                        </FormatoSolicitudProvider>
                                                    </ConfiguracionSolicitudProvider>
                                                </TipoSolicitudProvider>
                                            </LoginProvider>
                                        </CatalogoProvider>
                                    </CatalogosGeneralesProvider>
                                </RequestProvider>
                            </TokenProvider>
                        </LayoutProvider>
                    </StyleProvider>
                </MuiPickersUtilsProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}
