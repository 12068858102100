export const getModelBloqueSolicitud = () => ({
    iIDBloqueSolicitud: 0,
    sNombreBloque: "",
    sDescripcion: "",
    sIcono: "",
    sUnicode: "",
    bGrupo: false,
});

export const getModelCampoBloqueSolicitud = () => ({
    iIDBloqueSolicitudCampos: 0,
    sNombre: "",
    iIDInputForms: 0,
    iIDTipoDato: 0,
    iIDBloqueSolicitud: 0,
    sDescripcion: "",
    sIcono: "",
    sUnicode: "",
    bGrupo: false,
    bReqOCR: false,
    bSinTildes: false
});

export const getModelValidacionCampos = () => ({
    iIDBloqSolCampValidaciones: 0,
    iIDBloqueSolicitudCampos: 0,
    iIdCamposValidacionReglas: 0,
    iIDTipoDato: 0,
    iidcamporef: 0,
    iidreglaref: 0,
    iidbloqueref: 0,
    svalorref: "",
    iidforeignkey: 0,
    sNombre: "",
    smensajeerror: "",
    ioperador: 0,
});

export const getFormBloqueSolicitud = () => ({
    txtNombreBloque: {
        label: "Nombre bloque:",
        required: true,
        value: "",
        error: false,
        message: "El Nombre del bloque es requerido",
        placeholder: "Ej. Datos Generales",
        maxlength: 50,
    },
    txtDescripcionBloque: {
        label: "Descripción bloque:",
        required: true,
        value: "",
        error: false,
        message: "Descripción del bloque es requerido",
        placeholder: "Descripción bloque",
        maxlength: 200,
    },
    slIcono: {
        label: "Icono de tipo solicitud:",
        required: true,
        value: 0,
        error: false,
        message: "Icono es requerido",
        placeholder: "",
    },
    swGrupos: {
        label: "Usa grupos:",
        required: true,
        value: false,
        error: false,
        message: "Usa grupos es requerido",
        placeholder: "",
    },
});

export const getFormCampoBloqueSolicitud = () => ({
    txtNombreCampoBloque: {
        label: "Nombre campo:",
        required: true,
        value: "",
        error: false,
        message: "Nombre es requerido",
        placeholder: "",
        maxlength: 100,
    },
    txtDescripcionBloque: {
        label: "Descripción:",
        required: true,
        value: "",
        error: false,
        message: "Descripción es requerido",
        placeholder: "",
        maxlength: 200,
    },
    slTipoDeDatoCampoBloque: {
        label: "Tipo de dato:",
        required: true,
        value: 0,
        error: false,
        message: "El tipo de dato es requerido",
        placeholder: "",
    },
    slTipoFormCampoBloque: {
        label: "Tipo campo form:",
        required: true,
        value: 0,
        error: false,
        message: "El tipo campo form es requerido",
        placeholder: "",
    },
    txtFuenteDatosBloque: {
        label: "Fuente de datos: *",
        required: false,
        value: "",
        error: false,
        message: "Fuente de datos es requerido",
        placeholder:
            'Ejemplo: [ {"TEXT":"PROPIA_SIN_HIPOTECA","ID":1}, {"TEXT":"PROPIA_CON_HIPOTECA","ID":2}, {"TEXT":"DE_PADRES_O_FAMILIARES","ID":3}, {"TEXT":"RENTADA", "ID": 4}, {"TEXT":"OTRO", "ID":5} ]',
    },
    slIcono: {
        label: "Icono:",
        required: true,
        value: 0,
        error: false,
        message: "Icono es requerido",
        placeholder: "",
    },
    chkReqOCR: {
        label: "Requiere OCR:",
        required: false,
        value: false,
        error: false,
        message: "",
        placeholder: "",
    },
    chkSinTildes: {
        label: "Sin Tildes:",
        required: false,
        value: false,
        error: false,
        message: "",
        placeholder: "",
    },
});

export const getFormCamposValidacion = () => ({
    iIDBloqSolCampValidaciones: 0,
    txtNombre: {
        label: "Nombre:",
        required: true,
        value: "",
        error: false,
        message: "Nombre es requerido",
        placeholder: "",
        maxlength: 50,
    },
    slReglaId: {
        label: "Tipo de regla:",
        required: true,
        value: 0,
        error: false,
        message: "Tipo de regla es requerido",
        placeholder: "",
    },
    txtValorRef: {
        label: "Valor de referencia:",
        required: false,
        value: "",
        error: false,
        message: "Valor de referencia es requerido",
        placeholder: "",
        maxlength: 200,
    },
    slCampoRef: {
        label: "Campo de referencia:",
        required: false,
        value: 0,
        error: false,
        message: "Campo de referencia es requerido",
        placeholder: "",
    },
    slBloqueRef: {
        label: "Bloque de referencia:",
        required: false,
        value: 0,
        error: false,
        message: "Bloque de referencia es requerido",
        placeholder: "",
    },
    slOperadorId: {
        label: "Tipo operador:",
        required: false,
        value: 0,
        error: false,
        message: "Tipo operador es requerido",
        placeholder: "",
    },
    txtMensajeError: {
        label: "Mensaje de error:",
        required: true,
        value: "",
        error: false,
        message: "Mensaje de error es requerido",
        placeholder: "",
        maxlength: 200,
    },
    slServerId: {
        label: "Server",
        required: false,
        value: 0,
        error: false,
        message: "Server",
        placeholder: "",
    },
});
