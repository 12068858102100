export const getModelFormatoSolicitud = () => ({
    iIDFormatoSolicitud: 0,
    sNombreArchivo: ''
});

export const getModelCampoFormatoSolicitud = () => ({
    iIDCampoFormatoSolicitud: 0,
});

export const getFormFormatoSolicitud = () => ({
    txtNombreFormatoSolicitud: {
        label: "Nombre formato solicitud:",
        required: true,
        value: "",
        error: false,
        message: "El Nombre del formato de solicitud es requerida",
        placeholder: "",
    },
});