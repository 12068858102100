import React, { useEffect, useState, Fragment } from "react";
import { makeStyles } from "tss-react/mui";
import { AppBar, Button, Dialog, Grid, Slide, Toolbar, Typography } from "@mui/material";
import { IoAdd } from "react-icons/io5";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Enumerable from "linq";
import _ from 'lodash';

import { useOriginadorDinercap } from "../../../../context/catalogos_generales/OriginadorDinercapContext";
import DinercapTableHandle from "../../../utils/DinercapTableHandle";
import FormOriginadorDinercap from "./FormOriginadorDinercap";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles()((theme) => {
    return {
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        container: {
            maxHeight: 800,
        },
        root: {
            width: "100%",
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    };
});

export default function OriginadorDinercapModal() {

    const {
        openOriginadorDinercap,
        listaOriginadorDinercap,
        openFormOriginadorDinercap,
        handleOpenOriginadorDinercap,
        handleObtenerOriginadorDinercap,
        handleOpenFormOriginadorDinercap,
        handleEliminarOriginadorDinercap,
        handleActualizarOriginadorDinercap,
    } = useOriginadorDinercap();

    const classes = useStyles();

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({
        iIdCatOriginadorDinercap: -1,
    });

    const crearData = () => {
        const _data = Enumerable.from(listaOriginadorDinercap)
            .select((originadorDinercap, index) => ({
                id: index + 1,
                iIdCatOriginadorDinercap: originadorDinercap.iIdCatOriginadorDinercap,
                sCatalogo: originadorDinercap.sCatalogo,
                sDescripcion: originadorDinercap.sDescripcion,
                iIdCatSolicitudes: originadorDinercap.iIdCatSolicitudes,
                iIdCatOriginador: originadorDinercap.iIdCatOriginador,
                
            }))
            .toArray();
        setData(_data);
    };

    const columns = [
        { field: "id", title: "#"},
        { field: "sCatalogo", title: "Catalogo"},
        { field: "sDescripcion", title: "Descripcion"},
        { field: "iIdCatSolicitudes", title: "Cat Solicitudes"},
        { field: "iIdCatOriginador", title: "Cat Originador"},
    ];

    const onDelete = (e, row) => {
        handleEliminarOriginadorDinercap(e, row);
    };

    const onUpdate = (e, row) => {
        handleActualizarOriginadorDinercap(e, row);
    };

    useEffect(() => {
        handleObtenerOriginadorDinercap();
    }, []);

    useEffect(() => {
        crearData();
    }, [listaOriginadorDinercap]);


    return (
        <Fragment>
            <Dialog
                key="form"
                fullScreen
                open={openOriginadorDinercap}
                onClose={() => handleOpenOriginadorDinercap(false)}
                TransitionComponent={Transition}
            >
                <Box sx={{ flexFlow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title} component="div" sx={{ flexGrow: 1 }}>
                                Originador Dinercap
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => handleOpenOriginadorDinercap(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
                <div className="flex flex-col gap-2 p-4">
                    <div className="flex flex-row justify-between items-center flex-wrap ml-2 mt-2 mr-2 ">
                        <div></div>
                        <div>
                            <Button
                                className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={(e) => handleOpenFormOriginadorDinercap(true)}
                            >
                                <IoAdd className="text-xl mr-2" />
                                Crear
                            </Button>
                        </div>
                    </div>
                    <Box
                        component="div"
                        sx={{
                            height: 600,
                            width: "100%",
                            "& .odd": {
                                bgcolor: "rgba(0, 0, 0, 0.05)",
                            },
                            "& .even": {
                                bgcolor: "#ffffff",
                            },
                        }}
                    >
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                            <Grid item xs={12} md={8}>
                                <DinercapTableHandle
                                    id="iIdCatOriginadorDinercap"
                                    data={data}
                                    columns={columns}
                                    selectedRow={selectedRow}
                                    setRow={setSelectedRow}
                                    filter
                                    onDelete={onDelete}
                                    onUpdate={onUpdate}
                                    //onView={() => {}}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Dialog>
            {openFormOriginadorDinercap && < FormOriginadorDinercap/>}
        </Fragment>
    );
}