import React, { useReducer } from "react";
import { useForm } from "react-hook-form";

import orderBy from "lodash/fp/orderBy";
import flow from "lodash/fp/flow";
import map from "lodash/fp/map";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Box, Grid, Typography } from "@mui/material";

import FormElementComponent from "../../dynamic_form/FormElementComponent";
import formRenderReducer from "../../dynamic_form/formRender";
import { ACTIONS } from "../../dynamic_form/contants";
import { useSolicitud } from "../../../context/SolicitudContext";

function ListElements(props) {
    const { valueMap, setValue, register, errors, setValueForm } = props;
    const { listaForm } = useSolicitud();
    const { formElements } = listaForm;

    return flow(
        orderBy("displayOrder", "asc"),
        map((element) => {
            return (
                <FormElementComponent
                    key={element.elementId}
                    formElements={formElements}
                    element={element}
                    setValue={setValue}
                    valueMap={valueMap}
                    register={register}
                    errors={errors}
                    setValueForm={setValueForm}
                />
            );
        })
    )(formElements);
}

function init() {
    return { valueMap: {} };
}

export default function FormSolicitudAprobada(props) {
    const { openForm, handleCloseForm } = props;
    const { handleSaveInfoSolicitudAprobada } = useSolicitud();
    const {
        formState: { errors },
        handleSubmit,
        register,
        reset,
        watch,
        setValue,
    } = useForm();

    const [state, dispatch] = useReducer(formRenderReducer, init());
    const setValor = (id, value) => dispatch({ type: ACTIONS.ON_CHANGE_RENDER, id, value });

    const handleAprobar = async (data) => {
        await handleSaveInfoSolicitudAprobada(data);
        handleCloseForm();
    };

    return (
        <Dialog
            fullWidth={true}
            size="sm"
            open={openForm}
            onClose={handleCloseForm}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Solicitud Aprobada</DialogTitle>
            <DialogContent>
                <Box sx={{ mt: 1 }} component="form" onSubmit={handleSubmit(handleAprobar)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1">Ingrese la información solicitada.</Typography>
                        </Grid>
                        <ListElements
                            valueMap={state.valueMap}
                            setValue={setValor}
                            register={register}
                            errors={errors}
                            setValueForm={setValue}
                            watch={watch}
                        />
                        <Grid item xs={12}>
                            <center>
                                <Button onClick={handleCloseForm} className="btn btn-danger">
                                    Cancelar
                                </Button>
                                &nbsp;&nbsp;&nbsp;
                                <Button type="submit" className="btn btn-primary">
                                    Guardar
                                </Button>
                            </center>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
