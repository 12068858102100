import { DocumentosOCRProvider } from "./catalogos_generales/DocumentosOCRContext";
import { FinancierasProvider } from "./catalogos_generales/FinancierasContext";
import { AvisosPrivacidadProvider } from "./catalogos_generales/AvisosPrivacidadContext";
import { ZonasGeograficasProvider } from "./catalogos_generales/ZonasGeograficasContext";
import { ConfiguracionProvider } from "./catalogos_generales/ConfiguracionContext";
import { AprobadosProvider } from "./catalogos_generales/AprobadosContext";
import { SolicitudRechazadaProvider } from "./catalogos_generales/SolicitudRechazadaContext";
import { OriginadorProvider } from "./catalogos_generales/OriginadorContext";
import { OriginadorDinercapProvider } from "./catalogos_generales/OriginadorDinercapContext";
import { TipoAreaProvider } from "./catalogos_generales/TipoAreasContext";
import { ReglasOperadoresProvider } from "./catalogos_generales/ReglasOperadoresContext";

export default function CatalogosGeneralesProvider(props) {
    return (
        <ReglasOperadoresProvider>
            <SolicitudRechazadaProvider>
                <ZonasGeograficasProvider>
                    <TipoAreaProvider>
                        <FinancierasProvider>
                            <AvisosPrivacidadProvider>
                                <ConfiguracionProvider>
                                    <AprobadosProvider>
                                        <OriginadorProvider>
                                            <OriginadorDinercapProvider>
                                                <DocumentosOCRProvider>{props.children}</DocumentosOCRProvider>
                                            </OriginadorDinercapProvider>
                                        </OriginadorProvider>
                                    </AprobadosProvider>
                                </ConfiguracionProvider>
                            </AvisosPrivacidadProvider>
                        </FinancierasProvider>
                    </TipoAreaProvider>
                </ZonasGeograficasProvider>
            </SolicitudRechazadaProvider>
        </ReglasOperadoresProvider>
    );
}
