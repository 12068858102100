import React, { useEffect, useState, Fragment } from "react";
import Enumerable from "linq";
import { makeStyles } from "tss-react/mui";
import { AppBar, Button, Dialog, Grid, Slide, Toolbar, Typography } from "@mui/material";
import { IoAdd } from "react-icons/io5";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

import { useFinancieras } from "../../../../context/catalogos_generales/FinancierasContext";
import DinercapTableHandle from "../../../utils/DinercapTableHandle";
import FormFinanciera from "./FormFinanciera";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles()((theme) => {
    return {
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        container: {
            maxHeight: 800,
        },
        root: {
            width: "100%",
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    };
});

export default function CatalogoFinancierasModal() {
    const {
        openCatFinancieras,
        handleOpenCatFinanciera,
        handleObtenerFinancieras,
        handleActualizarFinanciera,
        listaFinancieras,
        openFormFinanciera,
        handleOpenFormFinanciera,
        handleEliminarFinanciera,
    } = useFinancieras();
    
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({
        iIdFinanciera: -1,
    });

    const crearData = () => {
        const _data = Enumerable.from(listaFinancieras)
            .select((financiera, index) => ({
                id: index + 1,
                iIdFinanciera: financiera.iIdFinanciera,
                sNombre: financiera.sNombre,
                sNombreBuro: financiera.entBuro.snombre,
                entBuro: financiera.entBuro,
                sUsuarioBuroCred: financiera.sUsuarioBuroCred,
                sPwdBuroCred: financiera.sPwdBuroCred,
            }))
            .toArray();
        setData(_data);
    };

    const columns = [
        { field: "id", title: "#"},
        { field: "sNombre", title: "Nombre"},
        { field: "sNombreBuro", title: "Nombre Buro"},
    ];

    const onDelete = (e, row) => {
        handleEliminarFinanciera(e, row);
    };

    const onUpdate = (e, row) => {
        handleActualizarFinanciera(e, row);
    };

    useEffect(() => {
        handleObtenerFinancieras();
    }, []);

    useEffect(() => {
        crearData();
    }, [listaFinancieras]);

    return (
        <Fragment>
            <Dialog
                key="form"
                fullScreen
                open={openCatFinancieras}
                onClose={() => handleOpenCatFinanciera(false)}
                TransitionComponent={Transition}
            >
                <Box sx={{ flexFlow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title} component="div" sx={{ flexGrow: 1 }}>
                                Financieras
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => handleOpenCatFinanciera(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
                <div className="flex flex-col gap-2 p-4">
                    <div className="flex flex-row justify-between items-center flex-wrap ml-2 mt-2 mr-2 ">
                        <div></div>
                        <div>
                            <Button
                                className="!text-sm !normal-case !px-4 !py-2.5 !rounded-full"
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={(e) => handleOpenFormFinanciera(true)}
                            >
                                <IoAdd className="text-xl mr-2" />
                                Crear
                            </Button>
                        </div>
                    </div>
                    <Box
                        component="div"
                        sx={{
                            height: 600,
                            width: "100%",
                            "& .odd": {
                                bgcolor: "rgba(0, 0, 0, 0.05)",
                            },
                            "& .even": {
                                bgcolor: "#ffffff",
                            },
                        }}
                    >
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                            <Grid item xs={12} md={8}>
                                <DinercapTableHandle
                                    id="iIdFinanciera"
                                    data={data}
                                    columns={columns}
                                    selectedRow={selectedRow}
                                    setRow={setSelectedRow}
                                    onDelete={onDelete}
                                    onUpdate={onUpdate}
                                    //onView={() => {}}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Dialog>
            {openFormFinanciera && < FormFinanciera/>}
        </Fragment>
    );
}