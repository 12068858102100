import { createContext, useContext, useState } from "react";
import Enumerable from "linq";
import { getModelTipoSolicitud, getFormTipoSolicitud } from "../models/TipoSolicitudModel";

import { fnHandleChangeText, fnHandleChangeImagen, fnHandleChangeChek } from "../settings/handleConfig";
import { fnPrevent, fnPreventBlur } from "../settings/preventConfig";
import {
    apiTipoSolicitudGetTiposSolicitud,
    apiTipoSolicitudGetTiposSolicitudAll,
    apiTipoSolicitudCreateTipoSolicitud,
    apiTipoSolicitudDeleteTipoSolicitud,
    apiTipoSolicitudCGetById,
    apiTipoSolicitudCGetByIdAll,
    apiTipoSolicitudUpdateTipoSolicitud,
    apiTipoSolicitudClone,
    apiCGetCatFinanciera,
    apiCGetCatTipoArea,
} from "../settings/apiConfig";
import { useRequest } from "./RequestContext";
import { useLayout } from "./LayoutContext";

export const TipoSolicitudContext = createContext();

export function TipoSolicitudProvider(props) {
    const { handleOpenAlert } = useLayout();
    const { Get, Post } = useRequest();

    const [listaTiposSolicitud, setListaTiposSolicitud] = useState([]);

    const [listaTiposSolicitudAll, setListaTiposSolicitudAll] = useState([]);

    const [tipoSolicitudSeleccionado, setTipoSolicitudSeleccionado] = useState(getModelTipoSolicitud());

    const [modalOpenFormTipoSolicitud, setModalOpenFormTipoSolicitud] = useState(false);

    const [modalOpenEliminarTipoSolicitud, setModalOpenEliminarTipoSolicitud] = useState(false);

    const [formTipoSolicitud, setFormTipoSolicitud] = useState(getFormTipoSolicitud());

    const [listaFinancieras, setListaFinancieras] = useState([]);

    const [listaTiposArea, setListaTiposArea] = useState([]);

    const handleClickObtenerTiposSolicitud = async () => {
        await fnObtenerTiposSolicitudAll();
    };

    const handleOpenFormTipoSolicitud = (value) => {
        setModalOpenFormTipoSolicitud(value);
    };

    const handleOpenEliminarTipoSolicitud = (value) => {
        setModalOpenEliminarTipoSolicitud(value);
    };

    const handleChangeTipoSolicitudSeleccionado = (value) => {
        setTipoSolicitudSeleccionado(value);
    };

    const handleChangeFormTipoSolicitud = fnHandleChangeText(formTipoSolicitud, setFormTipoSolicitud);
    const handleChangeFormImgTipoSolicitud = fnHandleChangeImagen(formTipoSolicitud, setFormTipoSolicitud);
    const handleChangeFormCheckTipoSolicitud = fnHandleChangeChek(formTipoSolicitud,setFormTipoSolicitud);

    const handleClickNuevoTipoSolicitud = async () => {
        await fnObtenerCatalogoFinancieras();
        await fnObtenerCatalogoTiposArea();

        setTipoSolicitudSeleccionado(0);
        setFormTipoSolicitud(getFormTipoSolicitud());
        setModalOpenFormTipoSolicitud(true);
    };

    const handleClickEditarTipoSolicitud = async () => {
        if (tipoSolicitudSeleccionado.iIDTipoSolicitud === 0) {
            handleOpenAlert("Seleccione un tipo de solicitud para editar");
            return;
        }
        await fnObtenerCatalogoFinancieras();
        await fnObtenerCatalogoTiposArea();
        await fnFillFormTipoSolicitud(tipoSolicitudSeleccionado);
        setModalOpenFormTipoSolicitud(true);
    };

    const handleClickEliminarTipoSolicitud = () => {
        if (tipoSolicitudSeleccionado.iIDTipoSolicitud === 0) {
            handleOpenAlert("Seleccione un tipo de solicitud para eliminar");
            return;
        }
        setModalOpenEliminarTipoSolicitud(true);
    };

    const handleClickCloneTipoSolicitud = (e) => {
        if (tipoSolicitudSeleccionado.iIDTipoSolicitud === 0) {
            handleOpenAlert("Seleccione un tipo de solicitud para clonar");
            return;
        }
        const request = {
            iIDTipoSolicitud: tipoSolicitudSeleccionado.iIDTipoSolicitud,
            iIDUsuarioModificacion: 1,
            bActivo: true,
            bBaja: false,
        };
        fnClonarTipoSolcitud(request);
    };

    const handleSubmitFormTipoSolicitud = (e) => {
        fnPrevent(e);

        const { txtDescripcionTipoSolicitud, txtNombre, txtUrl, slIcono, txtImagen, slFinanciera, slTipoArea, chkVisible } = formTipoSolicitud;

        const form = {
            ...formTipoSolicitud,
            txtDescripcionTipoSolicitud: {
                ...txtDescripcionTipoSolicitud,
                error: txtDescripcionTipoSolicitud.value === "",
            },
            txtNombre: {
                ...txtNombre,
                error: txtNombre.value === "",
            },
            txtUrl: {
                ...txtUrl,
                error: txtUrl.value === "",
            },
            slFinanciera: {
                ...slFinanciera,
                error: slFinanciera.value === "" || slFinanciera.value === 0,
            },
            slTipoArea: {
                ...slTipoArea,
                error: slTipoArea.value === "" || slTipoArea.value === 0,
            },
            slIcono: {
                ...slIcono,
                error: slIcono.value === "" || slIcono.value === 0,
            },
            txtImagen: {
                ...txtImagen,
                error: txtImagen.value === "" && txtImagen.url === "",
            },
        };

        setFormTipoSolicitud(form);
        if (
            Object.values(form)
                .map((x) => x.error)
                .includes(true)
        ) {
            return;
        }

        if (
            tipoSolicitudSeleccionado.iIDTipoSolicitud === 0 ||
            tipoSolicitudSeleccionado.iIDTipoSolicitud === undefined
        ) {
            const entTipoSolicitud = {
                //iIDTipoSolicitud: tipoSolicitudSeleccionado.iIDTipoSolicitud,
                sDescripcion: txtDescripcionTipoSolicitud.value,
                sNombre: txtNombre.value,
                sURL: txtUrl.value,
                sIcono: slIcono.value.split(";")[0],
                sUnicode: slIcono.value.split(";")[1],
                sImagen: txtImagen.value.replace("data:image/jpeg;base64,", ""),
                sNombreArchivo: txtImagen.nombre,
                iIDUsuarioCreacion: 1,
                bActivo: chkVisible.value,//true,
                bBaja: false,
                iIdFinanciera: slFinanciera.value,
                iIdTipoArea: slTipoArea.value,
            };

            fnGuardarTipoSolicitud(entTipoSolicitud);
        } else {
            let entTipoSolicitud = {
                iIDTipoSolicitud: tipoSolicitudSeleccionado.iIDTipoSolicitud,
                sDescripcion: txtDescripcionTipoSolicitud.value,
                sNombre: txtNombre.value,
                sURL: txtUrl.value,
                sIcono: slIcono.value.split(";")[0],
                sUnicode: slIcono.value.split(";")[1],
                iIDUsuarioModificacion: 1,
                bActivo: chkVisible.value,//true,
                bBaja: false,
                iIdFinanciera: slFinanciera.value,
                iIdTipoArea: slTipoArea.value,
            };
            if (txtImagen.value !== "") {
                entTipoSolicitud = {
                    iIDTipoSolicitud: tipoSolicitudSeleccionado.iIDTipoSolicitud,
                    sDescripcion: txtDescripcionTipoSolicitud.value,
                    sNombre: txtNombre.value,
                    sURL: txtUrl.value,
                    sIcono: slIcono.value.split(";")[0],
                    sUnicode: slIcono.value.split(";")[1],
                    sImagen: txtImagen.value.replace("data:image/jpeg;base64,", ""),
                    sNombreArchivo: txtImagen.nombre,
                    iIDUsuarioModificacion: 1,
                    bActivo: chkVisible.value,//true,
                    bBaja: false,
                    iIdFinanciera: slFinanciera.value,
                    iIdTipoArea: slTipoArea.value,
                };
            }

            fnGuardarTipoSolicitud(entTipoSolicitud, tipoSolicitudSeleccionado.iIDTipoSolicitud);
        }
    };

    const handleSubmitEliminarTipoSolicitud = (e) => {
        fnPrevent(e);
        const entTipoSolicitud = {
            iIDTipoSolicitud: tipoSolicitudSeleccionado.iIDTipoSolicitud,
            iIDUsuarioElimina: 1,
        };
        fnEliminarTipoSolicitud(entTipoSolicitud);
    };

    const fnObtenerTiposSolicitud = async () => {
        const payload = {
            loader: "Consultando tipos de solicitudes...",
            url: apiTipoSolicitudGetTiposSolicitud,
            alert: false,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setListaTiposSolicitud(response.result);
        }
    };

    const fnObtenerTiposSolicitudAll = async () => {
        const payload = {
            loader: "Consultando tipos de solicitudes...",
            url: apiTipoSolicitudGetTiposSolicitudAll,
            alert: false,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            setListaTiposSolicitudAll(response.result);
        }
    };

    const fnFillFormTipoSolicitud = async (tipoSolicitud) => {
        const payload = {
            loader: "Consultando bloques de la solicitud...",
            url: apiTipoSolicitudCGetByIdAll.replace("{piIDTipoSolicitud}", tipoSolicitudSeleccionado.iIDTipoSolicitud).replace("{pbActivo}", tipoSolicitudSeleccionado.bActivo),
            alert: false,
        };
        const { txtDescripcionTipoSolicitud, txtNombre, txtUrl, slIcono, txtImagen, slFinanciera, slTipoArea, chkVisible } =
            getFormTipoSolicitud();
        const response = await Get(payload);
        if (response.code === 201) {
            let data = response.result[0];
            setFormTipoSolicitud({
                ...formTipoSolicitud,
                txtDescripcionTipoSolicitud: {
                    ...txtDescripcionTipoSolicitud,
                    value: data.sDescripcion,
                },
                txtNombre: {
                    ...txtNombre,
                    value: data.sNombre,
                },
                txtUrl: {
                    ...txtUrl,
                    value: data.sURL,
                },
                slIcono: {
                    ...slIcono,
                    value: data.sIcono + ";" + data.sUnicode,
                },
                txtImagen: {
                    ...txtImagen,
                    url: data.sImagen ? data.sImagen : "",
                },
                slFinanciera: {
                    ...slFinanciera,
                    value: data.iIdFinanciera,
                },
                slTipoArea: {
                    ...slTipoArea,
                    value: data.iIdTipoArea,
                },
                chkVisible: {
                    ...chkVisible,
                    value: data.bActivo,
                },
            });
        } else {
            setFormTipoSolicitud({
                ...formTipoSolicitud,
                txtDescripcionTipoSolicitud: {
                    ...txtDescripcionTipoSolicitud,
                    value: tipoSolicitud.sDescripcion,
                },
                txtNombre: {
                    ...txtNombre,
                    value: tipoSolicitud.sNombre,
                },
                txtUrl: {
                    ...txtUrl,
                    value: tipoSolicitud.sUrl,
                },
                slIcono: {
                    ...slIcono,
                    value: tipoSolicitud.sIcono + ";" + tipoSolicitud.sUnicode,
                },
            });
        }
    };

    //=================================================================================================================

    const fnClonarTipoSolcitud = async (entTipoSolicitud) => {
        const payload = {
            loader: "Clonando tipo solicitud...",
            url: apiTipoSolicitudClone,
            body: entTipoSolicitud,
            alert: true,
        };
        const response = await Post(payload);
        if (response.iCodigo === 201) {
            setModalOpenFormTipoSolicitud(false);
            setTipoSolicitudSeleccionado(entTipoSolicitud);
            await fnObtenerTiposSolicitud();
            await fnObtenerTiposSolicitudAll();
        }
        fnPreventBlur();
    };

    const fnGuardarTipoSolicitud = async (entTipoSolicitud, iIDTipoSolicitud = 0) => {
        const payload = {
            loader: iIDTipoSolicitud === 0 ? "Guardando tipo solicitud..." : "Modificar tipo solicitud...",
            url: iIDTipoSolicitud === 0 ? apiTipoSolicitudCreateTipoSolicitud : apiTipoSolicitudUpdateTipoSolicitud,
            body: entTipoSolicitud,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            setModalOpenFormTipoSolicitud(false);
            setTipoSolicitudSeleccionado(entTipoSolicitud);
            await fnObtenerTiposSolicitud();
            await fnObtenerTiposSolicitudAll();
        }
        fnPreventBlur();
    };

    const fnEliminarTipoSolicitud = async (entTipoSolicitud) => {
        const payload = {
            loader: "Eliminando tipo solicitud...",
            url: apiTipoSolicitudDeleteTipoSolicitud,
            body: entTipoSolicitud,
            alert: true,
        };

        const response = await Post(payload);
        if (response.iCodigo === 201) {
            setModalOpenEliminarTipoSolicitud(false);
            setTipoSolicitudSeleccionado(getModelTipoSolicitud());
            await fnObtenerTiposSolicitud();
            await fnObtenerTiposSolicitudAll();
        }
        fnPreventBlur();
    };

    //=================================================================================================================

    const fnObtenerCatalogoFinancieras = async () => {
        const payload = {
            loader: "Obteniendo catálogo",
            url: apiCGetCatFinanciera,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            const lista = Enumerable.from(response.result)
                .select((item) => ({
                    id: item.iIdFinanciera,
                    text: item.sNombre,
                }))
                .toArray();
            setListaFinancieras(lista);
        } else {
            setListaFinancieras([]);
        }
    };

    const fnObtenerCatalogoTiposArea = async () => {
        const payload = {
            loader: "Obteniendo catálogo",
            url: apiCGetCatTipoArea,
        };

        const response = await Get(payload);
        if (response.iCodigo === 201) {
            const lista = Enumerable.from(response.result)
                .select((item) => ({
                    id: item.iIDTipoArea,
                    text: item.sNombre,
                }))
                .toArray();
            setListaTiposArea(lista);
        } else {
            setListaTiposArea([]);
        }
    };

    return (
        <TipoSolicitudContext.Provider
            value={{
                listaTiposSolicitud,
                listaTiposSolicitudAll,
                listaFinancieras,
                listaTiposArea,
                tipoSolicitudSeleccionado,
                modalOpenFormTipoSolicitud,
                modalOpenEliminarTipoSolicitud,
                formTipoSolicitud,
                handleChangeFormImgTipoSolicitud,
                handleChangeFormCheckTipoSolicitud,
                handleOpenFormTipoSolicitud,
                handleOpenEliminarTipoSolicitud,
                handleChangeTipoSolicitudSeleccionado,
                handleChangeFormTipoSolicitud,
                handleClickObtenerTiposSolicitud,
                handleClickNuevoTipoSolicitud,
                handleClickEditarTipoSolicitud,
                handleClickEliminarTipoSolicitud,
                handleSubmitFormTipoSolicitud,
                handleSubmitEliminarTipoSolicitud,
                handleClickCloneTipoSolicitud,
            }}
        >
            {props.children}
        </TipoSolicitudContext.Provider>
    );
}

export function useTipoSolicitud() {
    return useContext(TipoSolicitudContext);
}
