import React from "react";
import DinercapModal from "../../../utils/DinercapModal";
import { useTipoArea } from "../../../../context/catalogos_generales/TipoAreasContext";


import { Grid, TextField } from "@mui/material";
import { inputColor, redColor, inputVariant } from "../../../../settings/inputConfig";

export const containerStyle = {
    width: "100%",
    height: "600px",
};

export default function FormTipoArea() {
    const {
        openFormTipoArea,
        handleOpenFormTipoArea,
        formTipoArea,
        handleChangeFormTipoArea,
        handleSubmitFormTipoArea,
        tipoAreaSeleccionado,
        setIsExistTipoArea
    } = useTipoArea();
    const { txtDescripcion } = formTipoArea;
    setIsExistTipoArea(txtDescripcion.error);
    return (
        <DinercapModal
            title={
                tipoAreaSeleccionado.iIDTipoArea === 0 || tipoAreaSeleccionado.iIDTipoArea === undefined
                    ? "Agregar área"
                    : "Actualizar área"
            }
            okFunc={handleSubmitFormTipoArea}
            setOpen={handleOpenFormTipoArea}
            open={openFormTipoArea}
            okMessage="Guardar"
            size="small"
        >
            <Grid container>
                <Grid item xs={12} spacing={2}>
                    <TextField
                        name={txtDescripcion.name}
                        label={txtDescripcion.label}
                        required={txtDescripcion.required}
                        value={txtDescripcion.value}
                        error={txtDescripcion.error || txtDescripcion.isExist}
                        helperText={(txtDescripcion.isExist && txtDescripcion.value != "" && txtDescripcion.messageUnique) || (txtDescripcion.error && txtDescripcion.value == "" && txtDescripcion.message)}
                        variant={inputVariant}
                        color={inputColor}
                        onChange={handleChangeFormTipoArea}
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        className="mb-2"
                        inputProps={{
                            maxLength: txtDescripcion.maxlength,
                        }}
                    />
                </Grid>
            </Grid>
        </DinercapModal>
    );
}
